import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import styles from './styles.scss';

class ProgressBar extends Component {
    static propTypes = {
      animated: PropTypes.bool,
      value: PropTypes.number,
      className: PropTypes.string,
      title: PropTypes.string,
      uploadStatus: PropTypes.string,
      color: PropTypes.string,
      striped: PropTypes.bool
    }

    static defaultProps = {
      animated: true,
      value: 100,
      uploadStatus: 'Uploading',
      title: ' ',
      striped: true
    };

    render() {
      const {
        animated, value, title, uploadStatus, color, striped
      } = this.props;
      return (
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBarInfo}>
            <span className={styles.progressBarStatus}>{uploadStatus}</span>
            <span className={styles.progressBarTitle}>{title}</span>
          </div>
          <Progress
            striped={striped}
            className={styles.progress}
            animated={animated}
            value={value}
            color={color}
          />
        </div>
      );
    }
}

export default ProgressBar;
