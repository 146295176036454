import MixpanelTracker from '../../../mixpanelTracker';
import { VIRTUAL_EVENT_ON_CLICK_ORGANIZERS,
  VIRTUAL_EVENT_ON_ADD_ORGANIZER,
  VIRTUAL_EVENT_ON_DELETE_ORGANIZER,
  VIRTUAL_EVENT_ON_UPDATE_ORGANIZER,
  VIRTUAL_EVENT_ON_ADD_ORGANIZER_AS_SPEAKER,
  VIRTUAL_EVENT_ON_REMOVE_ORGANIZER_AS_SPEAKER } from '../../../mixpanelConstants';
import Action from '../../../Model/action';

const mixpanelTracker = new MixpanelTracker();

export function onVirtualEventOrganizersClick(eventKey, isOpen) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    isOpen
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_CLICK_ORGANIZERS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onCreateVirtualEventOrganizerTracker(eventKey, organizerKey, status) {
  const properties = {
    Action: Action.ADD,
    eventKey,
    organizerKey,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_ADD_ORGANIZER);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onDeleteVirtualEventOrganizerTracker(eventKey, organizerKey, status) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    organizerKey,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_DELETE_ORGANIZER);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onUpdateVirtualEventOrganizerTracker(eventKey, organizerKey, feildName, value, status) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    organizerKey,
    feildName,
    value,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_UPDATE_ORGANIZER);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onAddOrRemoveOrganizerAsSpeaker(eventKey, organizerKey, feildName, value, status) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    organizerKey,
    feildName,
    value,
    status: status || (value ? 'Adding' : 'Deleting')
  };
  const eventName = value ? VIRTUAL_EVENT_ON_ADD_ORGANIZER_AS_SPEAKER : VIRTUAL_EVENT_ON_REMOVE_ORGANIZER_AS_SPEAKER;
  mixpanelTracker.setEventName(eventName);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

