// Logs redux actions better than the redux-logger default one.
//
// Improvements:
// -You can filter these in the console. The default for redux-logger is to use console groups which can't be filtered.
// -You can pass another logger instead of console, for instance @getgo/logger which can send to logging service.
// Note:
// -Recent versions of redux-logger will log the differences in the state if you pass {diff: true} to createLogger().
//  If 'diff' is not supported in your redux-logger, this will still work without diffs.
// -inclPrev and inclNext flags each will log the entire store which can be excessive and not usually useful.

export default class BetterReduxLogger {
  constructor({ logger = console, inclPrev = false, inclNext = false } = {}) {
    this.logger = logger;
    this.inclPrev = inclPrev;
    this.inclNext = inclNext;
    this.currentGroup = null;
    this.groups = [];
    this.numGroups = 0;

    // in case redux-logger calls one of these methods
    this.warn = logger.warn || console.warn;
    this.error = logger.error || console.error;
    this.trace = logger.trace || console.trace;
    this.debug = logger.debug || console.debug;
  }

  group() {
    if (this.groups.length === 2) {
      this.error('unexpected: 1 or 2 groups expected. refactor this code to match the new redux-logger behavior');
    }
    this.currentGroup = [];
    this.groups.push(this.currentGroup);
    this.numGroups += 1;
  }

  groupCollapsed() {
    this.group();
  }

  groupEnd() {
    // redux-logger creates 1 console group with the action & states
    // if diff==true we'll get a 2nd group with the diffs
    this.numGroups -= 1;
    if (this.numGroups) {
      this.currentGroup = this.groups[this.numGroups - 1];
      return;
    }

    const actionGroup = this.groups[0]; // first group is action & states
    const prevState = actionGroup[0][2];
    const action = actionGroup[1][2];
    const nextState = actionGroup[2][2];

    // to turn on diff-logging in redux-logger call createLogger({diff: true})
    const diffsGroup = this.groups[1]; // 2nd group, if any, is diffs
    if (diffsGroup) {
      let changes = {};
      diffsGroup.forEach((change) => {
        if (change.length === 1) {
          changes = change[0]; // '-- no diff --'
        } else {
          const operation = change[0].substring(3); // e.g. 'ADDED:'
          const actionName = change[2]; // e.g. 'GET_XXX'
          const name = `${operation} ${actionName}`;
          const newValue = (operation === 'CHANGED:')
            ? [change[3], change[4], change[5]] // old -> new
            : change[3];
          changes[name] = newValue;
        }
      });
      action._diff = changes;
    }

    // use underbars to prevent collisions with action properties
    if (this.inclPrev) {
      action._prev = prevState;
    }
    if (this.inclNext) {
      action._next = nextState;
    }

    this.logger.log(`redux action ${action.type}`, action);
    this.groups = [];
    this.currentGroup = null;
  }

  log(...args) {
    if (this.currentGroup) {
      this.currentGroup.push(args);
    } else if (args[0] !== '—— log end ——') {
      this.logger.log(args);
    }
  }
}
