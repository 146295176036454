export function removeSensitiveDataFromWebinar(webinarInfo) {
  if (!webinarInfo) {
    return null;
  }

  const webinarData = Object.assign({}, webinarInfo);
  // Remove Organizer's name if it exists
  if (webinarData.organizerName) {
    delete webinarData.organizerName;
  }

  // Remove Email if it exists
  if (webinarData.organizerEmail) {
    delete webinarData.organizerEmail;
  }

  // The replyTo usually contains the organizer's email, so remove it.
  if (webinarData.replyTo) {
    delete webinarData.replyTo;
  }

  // Times are just extraneous and unnecessary to track.
  if (webinarData.times) {
    delete webinarData.times;
  }

  return webinarData;
}
