export const get = (obj, unsafeOperation, valueIfFail) => {
  try {
    return unsafeOperation(obj);
  } catch (error) {
    return valueIfFail;
  }
};

export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
};
