import React from 'react';
import { func, array, string } from 'prop-types';
import { ResponsiveModal } from '@getgo/chameleon-react';
import { FormGroup, Label, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ChameleonLoader from '../../Common/ChameleonLoader';
import { getRecordingByAssetKey } from '../../../Api/Assets/assetsService';
import { getAttributeValue } from '../../../Api/Assets/assetsHelper';
import { redirect } from '../../../Utils/redirector';
import { mixpanelEvent } from '../../../Utils/decorators';
import { originalVideoDownloadEvent, editedVideoDownloadEvent } from '../../../Mixpanel/Features/Video/eventBuilders';
import styles from './styles.scss';

class VideoDownloadOption extends React.PureComponent {
  static propTypes = {
    close: func,
    recordingVersionDetails: array,
    assetKey: string,
    latestRecordingUrl: string,
    t: func
  }

  state = {
    isFetching: false,
    editedSelected: true,
    originalSelected: false
  }

  selectEditedVideo = () => {
    this.setState({
      editedSelected: true,
      originalSelected: false
    });
  }

  selectOriginalVideo = () => {
    this.setState({
      editedSelected: false,
      originalSelected: true
    });
  }

  @mixpanelEvent(originalVideoDownloadEvent)
  async downloadOriginalVideo(recordingVersionDetails, assetKey) {
    this.setState({ isFetching: true });
    const [asset] = recordingVersionDetails.sort((a, b) => a.assetVersion - b.assetVersion);
    const recording = await getRecordingByAssetKey(assetKey, asset.assetVersion);
    const url = getAttributeValue(recording, 'storagelocation');
    redirect(url);
    this.setState({ isFetching: false });
  }

  @mixpanelEvent(editedVideoDownloadEvent)
  downloadEditedVideo(url) {
    redirect(url);
  }

  downloadVideoHandler = () => {
    const { recordingVersionDetails, assetKey, latestRecordingUrl } = this.props;

    if (this.state.originalSelected) {
      this.downloadOriginalVideo(recordingVersionDetails, assetKey);
    } else if (latestRecordingUrl) {
      this.downloadEditedVideo(latestRecordingUrl);
    }
  }

  render() {
    const { t } = this.props;
    const { isFetching, editedSelected, originalSelected } = this.state;

    const primaryActionButton = {
      text: t('cards.recordings.menu.download'),
      disabled: !editedSelected && !originalSelected,
      onClick: this.downloadVideoHandler
    };

    const secondaryActionButton = {
      text: t('button.cancel'),
      disabled: false,
      onClick: this.props.close
    };

    let content = (
      <React.Fragment>
        <span>{t('videos.selectVersion')}</span>
        <div className={styles.radioButtons}>
          <FormGroup check className={styles.radioBtn}>
            <Label check>
              <Input
                type='radio'
                name='video-download-option-edited'
                checked={editedSelected}
                onChange={this.selectEditedVideo}
              />
              {t('videos.editedVersion')}
            </Label>
          </FormGroup>
          <FormGroup check className={styles.radioBtn}>
            <Label check>
              <Input
                type='radio'
                name='video-download-option-original'
                checked={originalSelected}
                onChange={this.selectOriginalVideo}
              />
              {t('videos.originalVersion')}
            </Label>
          </FormGroup>
        </div>
      </React.Fragment>
    );

    if (isFetching) {
      content = <ChameleonLoader color='blue' />;
    }

    return (
      <ResponsiveModal
        isOpen
        onClose={this.props.close}
        title={t('videos.downloadVideo')}
        primaryActionButton={primaryActionButton}
        secondaryActionButton={secondaryActionButton}
      >
        <div className={styles.contentWrapper}>
          {content}
        </div>
      </ResponsiveModal>
    );
  }
}

export default withTranslation()(VideoDownloadOption);
