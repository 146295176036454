import { fetchCopyEventsAction } from './actions';

const defaultState = {
  copyEventList: [],
  isFetching: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    // to get all events including webinars and virtual events

    case fetchCopyEventsAction.toString(): {
      return {
        ...state,
        copyEventList: [],
        isFetching: true
      };
    }
    case fetchCopyEventsAction.complete.toString(): {
      return {
        ...state,
        copyEventList: action.data,
        isFetching: false
      };
    }
    case fetchCopyEventsAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        isFetching: false
      };
    }
    default: {
      return state;
    }
  }
};
