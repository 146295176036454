import { createAsyncAction, createAction } from 'redux-promise-middleware-actions';
import * as messageCenterService from 'Api/messageCenterService';
import { TAG_IDENTIFIER } from 'Components/Card/MessageCenterNotifications/constants';
import { createNotificationPreferenceMessage } from './messageCenterHelper';

export const getMessages = createAsyncAction('GET_MESSAGES', messageCenterService.getMessages);
export const getMessagesByTag = createAsyncAction('GET_MESSAGES_BY_TAG', messageCenterService.getMessagesByTag);
export const updateMessage = createAsyncAction('UPDATE_MESSAGE', async (message) => {
  if (message.identifier === TAG_IDENTIFIER.NOTIFICATION_PREFERENCE) {
    return { meta: createNotificationPreferenceMessage(true) };
  }
  await messageCenterService.updateMessage(message);
  return message;
});
export const deleteMessage = createAsyncAction('DELETE_MESSAGE', messageCenterService.deleteMessage, (id) => id);
export const getMessagesInfo = createAsyncAction('GET_MESSAGES_INFO', messageCenterService.getMessagesInfo);

export const addNotificationPreferenceMessage = createAction('ADD_NOTIFICATION_PREFERENCE_MESSAGE', (read) => read);

export const removeNotificationPreferenceMessage = createAction('REMOVE_NOTIFICATION_PREFERENCE_MESSAGE');

export const updateNotificationPreferenceMessage = createAction('UPDATE__NOTIFICATION_PREFERENCE_MESSAGE', (read) => read);

