import { addToRecordingMap } from 'Api/Assets/assetsHelper';
import capitalize from 'Utils/stringUtils';
import * as constants from '../../../Components/Card/Events/Details/Registration/constants';

export function removeAssociations(state, action) {
  const { REGISTRATION_ASSOCIATION, OBJ } = constants.ASSET_ASSOCIATIONS;
  const assetAssociationsObj = action.isSeriesRegistrationAsssociation ? REGISTRATION_ASSOCIATION : OBJ;
  const assetAssociations = { ...state.assetAssociations };
  assetAssociations.isFetching = false;

  const associatedRecordings = { ...state.associatedRecordings };
  const associationResources = assetAssociations[assetAssociationsObj]._embedded.associationResources;
  const matchingIndex = associationResources.findIndex(
    (element) => element.associationType.toLowerCase().includes(action.attendeeType)
  );
  associationResources.splice(matchingIndex, 1);
  associatedRecordings.isFetching = false;
  const arr = associatedRecordings.arr;
  const matchingElement = arr.find((element) => element.recordingKey === action.recordingKey);
  if (matchingElement) {
    matchingElement.recordingSettings[`linkedTo${capitalize(action.attendeeType)}${action.recordingLinkType}`] = false;
  }
  const recordingMetadata = { ...state.recordingMetadata };
  recordingMetadata.isFetching = false;
  return {
    assetAssociations,
    recordingMetadata,
    associatedRecordings
  };
}

export function updateAssociations(state, action, recordingKey) {
  const assetAssociations = { ...state.assetAssociations };
  assetAssociations.isFetching = false;

  const associatedRecordings = { ...state.associatedRecordings };
  associatedRecordings.isFetching = false;
  const arr = associatedRecordings.arr;
  const matchingElement = arr.find((element) => element.recordingKey === recordingKey);
  if (matchingElement) {
    matchingElement.recordingSettings[`linkedTo${capitalize(action.associationType)}${action.recordingLinkType}`] = true;
  } else {
    const recording = { recordingKey, recordingSettings: {} };
    recording.recordingSettings[`linkedTo${capitalize(action.associationType)}${action.recordingLinkType}`] = true;
    arr.push(recording);
  }
  const previousRecording = arr.find((element) => element.recordingKey === action.previousRecordingKey);
  if (previousRecording) {
    previousRecording.recordingSettings[`linkedTo${capitalize(action.associationType)}${action.recordingLinkType}`] = false;
  }
  const recordingMetadata = { ...state.recordingMetadata };
  recordingMetadata.isFetching = false;
  const recordingMetadataMap = recordingMetadata.map;
  addToRecordingMap(recordingMetadataMap, action.recordingObj);
  return {
    assetAssociations,
    recordingMetadata,
    associatedRecordings
  };
}

export function createAssociations(state, action) {
  const recordingKey = action.recordingObj ? action.recordingObj.recordingKey : null;
  const { REGISTRATION_ASSOCIATION, OBJ } = constants.ASSET_ASSOCIATIONS;
  const assetAssociationsObj = action.isSeriesRegistrationAsssociation ? REGISTRATION_ASSOCIATION : OBJ;
  const assetAssociations = { ...state.assetAssociations };
  const associationResource = {
    associationType: action.associationType,
    assetKey: action.assetKey,
    associationKey: action.data.associationKeys[0],
    seriesAssociationKeys: action.data.associationKeys,
    assetType: 'recording',
    entityKey: recordingKey
  };
  if (assetAssociations[assetAssociationsObj]._embedded) {
    const associationResources = assetAssociations[assetAssociationsObj]._embedded.associationResources;
    associationResources.push(associationResource);
  } else {
    assetAssociations[assetAssociationsObj]._embedded = {
      associationResources: [associationResource]
    };
  }
  assetAssociations.isFetching = false;
  const associatedRecordings = { ...state.associatedRecordings };
  associatedRecordings.isFetching = false;
  const arr = associatedRecordings.arr;
  const matchingElement = arr.find((element) => element.recordingKey === recordingKey);
  const attendeeType = action.associationType.replace('emailrecordinglink', '');
  if (matchingElement) {
    matchingElement.recordingSettings[`linkedTo${capitalize(attendeeType)}${action.recordingLinkType}`] = true;
  } else {
    const recording = { recordingKey, recordingSettings: {} };
    recording.recordingSettings[`linkedTo${capitalize(attendeeType)}${action.recordingLinkType}`] = true;
    arr.push(recording);
  }
  const recordingMetadata = { ...state.recordingMetadata };
  recordingMetadata.isFetching = false;
  const recordingMetadataMap = recordingMetadata.map;
  addToRecordingMap(recordingMetadataMap, action.recordingObj);
  return {
    assetAssociations,
    recordingMetadata,
    associatedRecordings
  };
}
