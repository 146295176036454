import { combineReducers } from 'redux';

import dateFilters from './DateFilters/reducer';
import paginations from './Paginations/reducer';
import surveyChoices from './SurveyChoices/reducer';
import surveyQuestions from './SurveyQuestions/reducer';
import webinars from './Webinars/reducer';

const entitiesReducer = combineReducers({
  dateFilters,
  paginations,
  surveyChoices,
  surveyQuestions,
  webinars
});

export default entitiesReducer;
