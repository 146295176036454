const splitTextWithMatches = (text, phrase, id) => {
  const re = new RegExp(`(${phrase})`, 'gi');
  const parts = text.split(re);
  const partsWithMatches = parts.reduce((prev, part) => {
    let result = [];
    if (part.toLowerCase() === phrase.toLowerCase()) {
      const match = { id, index: prev.lastIndex, text: part };
      result = [...prev.result, match];
    } else {
      result = [...prev.result, part];
    }

    const final = {
      result,
      lastIndex: prev.lastIndex + part.length
    };

    return final;
  }, { result: [], lastIndex: 0 });

  return partsWithMatches.result;
};

export const getMatches = (phrase, searchContents = []) => {
  if (!phrase || !Array.isArray(searchContents)) {
    return [];
  }

  const matches = searchContents.reduce((result, { text, id }) => {
    const textWithMatches = splitTextWithMatches(text, phrase, id);
    const matchesOnly = textWithMatches.filter((item) => typeof item === 'object');
    return [...result, ...matchesOnly];
  }, []);

  return matches;
};
