/**
 * Returns the cookie value from the decoded string of cookies if it exists, blank otherwise.
 * @param cookieName - Name of cookie to be found.
 * @returns {string}
 */
export function getCookie(cookieName) {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  const matchingKeyValue = cookies.find((element) => cookieName === element.split('=')[0].trim());
  return (matchingKeyValue) ? matchingKeyValue.split('=')[1] : '';
}

/**
 * Sets a cookie in the browser.
 * @param cookieName - Name of cookie to be set.
 * @param cookieValue - Value of the cookie.
 * @param expiryInMilliseconds - The expiry time to be set for the cookie. Set to 100 years if not defined.
 */
export function setCookie(cookieName, cookieValue, expiryInMilliseconds = 3.154e+12) {
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + expiryInMilliseconds);
  const expiresStr = `expires=${expiryDate.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expiresStr};path=/`;
}
