import config from 'appConfig';
import getHeaders from 'Api/Helpers/getHeaders';
import { fetchJson } from 'Api/Helpers/fetch';
import { POST } from 'Api/Helpers/httpMethods';
import { PRODUCT_ID } from '../../Notifications/constants';

const NOTIFICATION_SUBSCRIPTION_SERVICE_URL = `${config.featureService.url}/notificationSubscriptions`;
const V1 = '/v1';
export const REGISTER_URL = `${NOTIFICATION_SUBSCRIPTION_SERVICE_URL}${V1}/register/${PRODUCT_ID}`;
export const GET_NOTIFICATION_PREFERENCES_URL = `${NOTIFICATION_SUBSCRIPTION_SERVICE_URL}${V1}/getNotificationPreferences/${PRODUCT_ID}`;
export const SET_NOTIFICATION_PREFERENCE_URL = `${NOTIFICATION_SUBSCRIPTION_SERVICE_URL}${V1}/setNotificationPreference/${PRODUCT_ID}`;
export const SET_NOTIFICATION_PREFERENCES_URL = `${NOTIFICATION_SUBSCRIPTION_SERVICE_URL}${V1}/setNotificationPreferences/${PRODUCT_ID}`;
export const SET_NOTIFICATIONS_URL = `${NOTIFICATION_SUBSCRIPTION_SERVICE_URL}${V1}/setNotifications/${PRODUCT_ID}`;

export function subscribeUserForNotifications(userKey, notificationPreference = {}) {
  const authHeaders = getHeaders(true, 'Bearer');
  const url = `${REGISTER_URL}/${userKey}`;
  const options = {
    headers: authHeaders,
    method: POST,
    body: JSON.stringify(notificationPreference)
  };
  return fetchJson(url, options, 'json');
}

export function setNotifications(notifications = []) {
  const authHeaders = getHeaders(true, 'Bearer');
  const options = {
    headers: authHeaders,
    method: POST,
    body: JSON.stringify(notifications)
  };
  return fetchJson(SET_NOTIFICATIONS_URL, options, 'json', false);
}

export function setNotificationPreference(userKey, notificationPreferenceSetting = {}) {
  const authHeaders = getHeaders(true, 'Bearer');
  const url = `${SET_NOTIFICATION_PREFERENCE_URL}/${userKey}`;
  const options = {
    headers: authHeaders,
    method: POST,
    body: JSON.stringify(notificationPreferenceSetting)
  };
  return fetchJson(url, options, 'json', false);
}

export function setNotificationPreferences(userKey, notificationPreferenceSettings = []) {
  const authHeaders = getHeaders(true, 'Bearer');
  const url = `${SET_NOTIFICATION_PREFERENCES_URL}/${userKey}`;
  const options = {
    headers: authHeaders,
    method: POST,
    body: JSON.stringify(notificationPreferenceSettings)
  };
  return fetchJson(url, options, 'json', false);
}

export function getNotificationPreferences(userKey) {
  const authHeaders = getHeaders(true, 'Bearer');
  const url = `${GET_NOTIFICATION_PREFERENCES_URL}/${userKey}`;
  const options = {
    headers: authHeaders
  };
  return fetchJson(url, options);
}
