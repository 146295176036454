const MULTIPLE_CHOICE = 'multipleChoice';
const SINGLE_CHOICE = 'singleChoice';

export default class QuestionType {
  static get MULTIPLE_CHOICE() {
    return MULTIPLE_CHOICE;
  }

  static get SINGLE_CHOICE() {
    return SINGLE_CHOICE;
  }

  static values() {
    return [MULTIPLE_CHOICE, SINGLE_CHOICE];
  }
}
