/* eslint-disable no-multi-spaces */
import React from 'react';
import { array, func, shape, string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Selector from '../../../Components/Card/SharedComponents/Selector';
import QuestionSelection from '../QuestionSelection/QuestionSelection';
import styles from './NewQuestion.scss';
import { createQuestion } from '../../../Reducks/Survey/reducer';

const NewQuestion = ({
  addSurveyChoice,
  choices,
  question,
  removeSurveyChoice,
  updateSurveyChoice,
  updateSurveyQuestion,
  setActiveSurveyQuestion,
  invalidFields
}) => {
  const { t } = useTranslation();

  const questionMap = new Map([
    ['multipleChoice', t('survey.questions.choices.multipleChoiceMultipleAnswers')],
    ['singleChoice',   t('survey.questions.choices.multipleChoiceOneAnswer')],
    ['rating',         t('survey.questions.choices.rateOnScale.title')],
    ['shortAnswer',    t('survey.questions.choices.shortAnswer')]
  ]);

  const createActiveChoices = (newQuestion) => {
    if (newQuestion.type === 'shortAnswer') {
      return {};
    }

    const [firstKey, secondKey] = newQuestion.choices.slice(0, 2);

    if (newQuestion.type === 'rating') {
      return {
        [firstKey]: { answerKey: firstKey, answerText: 'low_rating:1' },
        [secondKey]: { answerKey: secondKey, answerText: 'high_rating:5' }
      };
    }

    return {
      [firstKey]: { answerKey: firstKey, answerText: '' },
      [secondKey]: { answerKey: secondKey, answerText: '' }
    };
  };

  const updateQuestionType = (idx) => {
    const { questionKey, questionText } = question;
    const questionDataKept = { questionKey, questionText };

    let newQuestion = createQuestion({ type: 'multipleChoice', ...questionDataKept });

    if (idx === 1) {
      newQuestion = createQuestion({ type: 'singleChoice', ...questionDataKept });
    } else if (idx === 2) {
      newQuestion = createQuestion({ type: 'rating', ...questionDataKept });
    } else if (idx === 3) {
      newQuestion = createQuestion({ type: 'shortAnswer', choices: [], ...questionDataKept });
    }

    const newChoices = createActiveChoices(newQuestion);
    setActiveSurveyQuestion(question.questionKey, newQuestion, newChoices);
  };

  return (
    <div className={styles.newQuestion}>
      <div className={styles.questionSelector}>
        <Selector
          index={[...questionMap.keys()].indexOf(question.type)}
          label={t('survey.questions.choices.type')}
          onChange={updateQuestionType}
          options={[...questionMap.values()]}
        />
      </div>

      <QuestionSelection
        addSurveyChoice={addSurveyChoice}
        choices={choices}
        question={question}
        removeSurveyChoice={removeSurveyChoice}
        updateSurveyChoice={updateSurveyChoice}
        updateSurveyQuestion={updateSurveyQuestion}
        invalidFields={invalidFields}
      />
    </div>
  );
};

NewQuestion.propTypes = {
  addSurveyChoice: func,
  choices: array.isRequired,
  question: shape({
    questionKey: string.isRequired,
    type: string.isRequired
  }).isRequired,
  removeSurveyChoice: func,
  setActiveSurveyQuestion: func,
  updateSurveyChoice: func,
  updateSurveyQuestion: func,
  invalidFields: object
};

export default NewQuestion;
