export const ADD_VIDEO_TO_CHANNEL = 'ADD_VIDEO_TO_CHANNEL';
export const FEATURE_UNLOCK = 'FEATURE_UNLOCK';
export const GENERATE_AUDIO_WAVEFORM = 'GENERATE_AUDIO_WAVEFORM';
export const VIDEO_DOWNLOAD_OPTION = 'VIDEO_DOWNLOAD_OPTION';
export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const TRANSCRIPT_PROCESSING = 'TRANSCRIPT_PROCESSING';
export const SURVEY = 'SURVEY';
export const RESTRICT_DOMAINS = 'RESTRICT_DOMAINS';
export const ADD_SESSION_SURVEY = 'ADD_SESSION_SURVEY';
export const ADD_SESSION_HANDOUT = 'ADD_SESSION_HANDOUT';
export const ADD_SESSION_POLL = 'ADD_SESSION_POLL';
