import { getNumericUUID } from 'Utils/randomUuidGenerator';
import { createAction } from 'redux-promise-middleware-actions';

export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';

export const sendNotification = (message, messageKey, isError = false, messageKeyParams, timeOut, isInfo = false) => {
  const rand = getNumericUUID();

  return {
    type: SEND_NOTIFICATION,
    state: rand,
    message,
    messageKey,
    messageKeyParams,
    isError,
    isInfo,
    timeOut
  };
};

export const showAlert = createAction('SHOW_ALERT', (title, message, closeButtonTitle, secondButton, type = 'info') => ({
  alertTitle: title,
  alertMessage: message,
  alertType: type,
  closeButtonTitle,
  secondButton
}));
