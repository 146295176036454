import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import * as virtualEventDetailsService from 'Api/VirtualEvent/VirtualEventDetails/VirtualEventDetailsService';
import { fetchVirtualEventsAction } from './actions';
// to get the virtual events
export function* fetchVirtualEvents({ params, reqBody }) {
  try {
    const data = yield call(virtualEventDetailsService.getVirtualEvents, params, reqBody);

    yield put({
      type: fetchVirtualEventsAction.complete.toString(),
      data
    });
  } catch (e) {
    yield put({
      type: fetchVirtualEventsAction.failed.toString(),
      message: e.message
    });
  }
}

export function* watchFetchVirtualEvents() {
  yield takeEvery(fetchVirtualEventsAction.toString(), fetchVirtualEvents);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchVirtualEvents)
  ]);
}
