export const fifteenMB = 15 * 1024 * 1024;
export const defaultThemes = [
  {
    backgroundColor: '#eeeee7',
    borderColor: '#cccccc',
    headerColor: '#114c7f',
    themeType: 'Default'
  },
  {
    backgroundColor: '#636363',
    borderColor: '#393939',
    headerColor: '#393939',
    themeType: 'Coal'
  },
  {
    backgroundColor: '#6B9570',
    borderColor: '#42632F',
    headerColor: '#41632E',
    themeType: 'Forest'
  },
  {
    backgroundColor: '#5b7eaf',
    borderColor: '#314868',
    headerColor: '#314668',
    themeType: 'Ocean'
  },
  {
    backgroundColor: '#652b2b',
    borderColor: '#400000',
    headerColor: '#3d2524',
    themeType: 'Ruby'
  }
];
export const defaultCustomTheme = {
  backgroundColor: '#2ac8f6',
  borderColor: '#1397e9',
  headerColor: '#1397e9',
  themeType: 'Custom'
};
export const CUSTOM_THEME = 'Custom';
export const FILE_TYPE = { LOGO: 'logo', FEATURE_IMAGE: 'feature_image' };
export const COLOR_TYPE = { BACKGROUND: 'BACKGROUND', HEADER: 'HEADER', BORDER: 'BORDER' };

export function checkColorHex(color) {
  return color.includes('#') ? color : `#${color}`;
}
