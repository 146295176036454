import config from 'appConfig';
import getHeaders from 'Api/Helpers/getHeaders';
import * as httpMethods from 'Api/Helpers/httpMethods';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from 'Api/Helpers/fetch';

const URL_WEBINAR_PREFIX = '/api/webinars/';
const URL_HANDOUTS_PREFIX = '/api/handouts';

export function getHandoutsConstraints() {
  const url = `${config.g2w.url}${URL_HANDOUTS_PREFIX}/constraints`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getAllHandouts(webinarKey) {
  const url = `${config.g2w.url}${URL_WEBINAR_PREFIX}${webinarKey}/handouts`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getHandout(webinarKey, handoutKey) {
  const url = `${config.g2w.url}${URL_WEBINAR_PREFIX}${webinarKey}/handouts/${handoutKey}`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function createUploadPolicy(webinarKey, fileMetaData) {
  const url = `${config.g2w.url}${URL_WEBINAR_PREFIX}${webinarKey}/handouts/uploadpolicy`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.POST,
    body: JSON.stringify(fileMetaData)
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function uploadToS3(url, method, formData) {
  const options = {
    method,
    body: formData
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function createHandout(webinarKey, payload) {
  const url = `${config.g2w.url}${URL_WEBINAR_PREFIX}${webinarKey}/handouts`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.POST,
    body: JSON.stringify(payload)
  };
  return fetchJson(url, options, JSON_MIME_TYPE, false);
}

export function deleteHandout(webinarKey, handoutKey) {
  const url = `${config.g2w.url}${URL_WEBINAR_PREFIX}${webinarKey}/handouts/${handoutKey}`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.DELETE
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}
