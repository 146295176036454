import * as Constants from './constants';
import { SURVEY, ADD_SESSION_SURVEY } from '../../Components/Modals/constants';
import { createShortId } from '../../Utils/randomUuidGenerator';
import { uniq } from '../../Utils/arrayHelpers';
import { toggleModal } from '../Ui/actions';
import { updateObject } from '../Utilities';

import { addNewSurveyQuestion,
  addQuestionToSurvey,
  addSurveyChoice,
  dismissActiveQuestion,
  invalidateQuestion,
  getWebinarSurvey,
  removeSurveyChoice,
  setActiveSurveyQuestion,
  updateSurveyChoice,
  updateSurveyQuestion,
  updateSurveySettings,
  updateSurveyTitle } from './actions';

export const createQuestion = ({
  choices = [createShortId(), createShortId()],
  questionKey = createShortId(),
  type = 'multipleChoice',
  questionText = ''
}) => ({
  choices,
  questionKey,
  questionText,
  type
});

export const initialState = {
  activeQuestionKey: '',
  activeQuestion: {
    choices: []
  },
  activeChoices: {},
  isFetching: undefined,
  title: '',
  settings: {},
  invalidFields: {},
  status: {}
};

const toggleModalTransformer = (state, action) => {
  const { id, webinarKey } = action.payload;
  let newValues = {};
  if ((id === SURVEY || id === ADD_SESSION_SURVEY) && webinarKey) {
    newValues = {
      webinarKey
    };
  }
  return updateObject(state, newValues);
};

const updateSurveyTitleTransformer = (state, action) => updateObject(state, { title: action.payload });

const removeSurveyChoiceTransformer = (state, action) => {
  const { answerKey } = action.payload;
  const choices = state.activeQuestion.choices.filter(
    (choice) => choice !== answerKey
  );

  return updateObject(state, {
    activeQuestion: {
      ...state.activeQuestion,
      choices
    },
    activeChoices: {
      ...state.activeChoices,
      [answerKey]: undefined
    }
  });
};

const addNewSurveyQuestionTransformer = (state, action) => updateObject(state, {
  activeChoices: {},
  activeQuestion: createQuestion(action.payload)
});

const addQuestionToSurveyTransformer = (state) => updateObject(state, {
  activeQuestionKey: '',
  activeQuestion: {
    choices: []
  },
  activeChoices: {},
  invalidFields: {}
});

const invalidateQuestionTransformer = (state, action) => updateObject(state, {
  invalidFields: action.payload
});

const dismissActiveQuestionTransformer = (state) => {
  if (state.status.code === 404) {
    return initialState;
  }

  return updateObject(state, {
    activeQuestionKey: '',
    activeQuestion: {
      choices: []
    },
    activeChoices: {},
    invalidFields: {}
  });
};

const setActiveSurveyQuestionTransformer = (state, action) => updateObject(state, {
  activeQuestion: {
    ...state.activeQuestion,
    ...action.payload.question
  },
  activeQuestionKey: action.payload.questionKey,
  activeChoices: action.payload.choices,
  invalidFields: {}
});

const updateSurveyQuestionTransformer = (state, action) => {
  const { question } = action.payload;
  return updateObject(state, {
    activeQuestion: {
      ...state.activeQuestion,
      ...question
    }
  });
};

const addSurveyChoiceTransformer = (state) => {
  const newAnswerKey = createShortId();
  return updateObject(state, {
    activeQuestion: {
      ...state.activeQuestion,
      choices: [
        ...state.activeQuestion.choices, newAnswerKey
      ]
    },
    activeChoices: {
      ...state.activeChoices,
      [newAnswerKey]: {
        answerKey: newAnswerKey,
        answerText: ''
      }
    }
  });
};

const updateSurveyChoiceTransformer = (state, action) => {
  const { answerKey, choice } = action.payload;
  const choices = uniq([...state.activeQuestion.choices, answerKey]);

  return updateObject(state, {
    activeQuestion: {
      ...state.activeQuestion,
      choices
    },
    activeChoices: {
      ...state.activeChoices,
      [answerKey]: {
        answerText: choice,
        answerKey
      }
    }
  });
};

const updateSurveySettingsTransformer = (state, action) => updateObject(state, {
  settings: {
    ...action.payload
  }
});

const createWebinarSurveyStartTransformer = (state) => updateObject(state, {
  isFetching: true
});

const createWebinarSurveyCompleteTransformer = (state) => updateObject(state, {
  isFetching: undefined,
  status: {
    code: 200,
    text: ''
  },
  surveyUpdated: true
});

const createWebinarSurveyFailureTransformer = (state) => {
  const initialStateCopy = updateObject(initialState);
  if (state.status.code === 404) {
    return updateObject(initialStateCopy, {
      status: state.status
    });
  }

  return initialStateCopy;
};

const deleteWebinarSurveyCompleteTransformer = (state) => updateObject(state, {
  ...initialState
});

const deleteWebinarSurveyFailureTransformer = (state) => updateObject(state, {
  isFetching: undefined
});

const getWebinarSurveyPendingTransform = (state) => updateObject(state, {
  isFetching: true,
  status: {},
  surveyUpdated: false
});

const getWebinarSurveyFulfilledTransform = (state, action) => {
  const { settings, title } = action.payload;
  return updateObject(state, {
    title,
    settings,
    isFetching: undefined,
    status: {
      code: 200,
      text: ''
    }
  });
};

const getWebinarSurveyRejectedTransformer = (state, action) => {
  const { statusText, status } = action.payload;
  return updateObject(state, {
    title: '',
    settings: {},
    isFetching: undefined,
    status: {
      code: status,
      text: statusText
    }
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case toggleModal.toString():
      return toggleModalTransformer(state, action);
    case updateSurveyTitle.toString():
      return updateSurveyTitleTransformer(state, action);
    case removeSurveyChoice.toString():
      return removeSurveyChoiceTransformer(state, action);
    case addNewSurveyQuestion.toString():
      return addNewSurveyQuestionTransformer(state, action);
    case addQuestionToSurvey.toString():
      return addQuestionToSurveyTransformer(state);
    case invalidateQuestion.toString():
      return invalidateQuestionTransformer(state, action);
    case dismissActiveQuestion.toString():
      return dismissActiveQuestionTransformer(state);
    case setActiveSurveyQuestion.toString():
      return setActiveSurveyQuestionTransformer(state, action);
    case updateSurveyQuestion.toString():
      return updateSurveyQuestionTransformer(state, action);
    case addSurveyChoice.toString():
      return addSurveyChoiceTransformer(state);
    case updateSurveyChoice.toString():
      return updateSurveyChoiceTransformer(state, action);
    case updateSurveySettings.toString():
      return updateSurveySettingsTransformer(state, action);
    case Constants.CREATE_WEBINAR_SURVEY_START:
      return createWebinarSurveyStartTransformer(state);
    case Constants.CREATE_WEBINAR_SURVEY_COMPLETE:
      return createWebinarSurveyCompleteTransformer(state);
    case Constants.CREATE_WEBINAR_SURVEY_FAILURE:
      return createWebinarSurveyFailureTransformer(state);
    case Constants.DELETE_WEBINAR_SURVEY_COMPLETE:
      return deleteWebinarSurveyCompleteTransformer(state);
    case Constants.DELETE_WEBINAR_SURVEY_FAILURE:
      return deleteWebinarSurveyFailureTransformer(state);
    case getWebinarSurvey.pending.toString():
      return getWebinarSurveyPendingTransform(state);
    case getWebinarSurvey.fulfilled.toString():
      return getWebinarSurveyFulfilledTransform(state, action);
    case getWebinarSurvey.rejected.toString():
      return getWebinarSurveyRejectedTransformer(state, action);
    case Constants.RESET_SURVEY: {
      return { ...initialState, settings: { shownAfterWebinarSession: true, shownInFollowUpEmail: false, shownInAbsenteeFollowUpEmail: false } };
    }
    default: return state;
  }
};
