import MixpanelTracker from '../../mixpanelTracker';
import { VIRTUAL_EVENT_CREATED, VE_TAB_CHANGE_ON_HOME_PAGE } from '../../mixpanelConstants';
import Action from '../../Model/action';

const mixpanelTracker = new MixpanelTracker();

export function trackVirtualEventCreated(webinarDetails, newExperience = false) {
  const properties = {
    Action: Action.CREATE,
    ownerKey: webinarDetails.organizerKey,
    title: webinarDetails.webinarTitle,
    start: webinarDetails.start,
    end: webinarDetails.end,
    timeZone: webinarDetails.timeZone,
    locale: webinarDetails.locale,
    noOfDays: webinarDetails.noOfDays,
    newExperience,
    isExperienceTypeCopied: webinarDetails.isExperienceTypeCopied
  };

  mixpanelTracker.setEventName(VIRTUAL_EVENT_CREATED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function trackVirtualEventTabSelectionOnHomePage(eventType) {
  const properties = {
    Action: Action.CLICK,
    eventType
  };

  mixpanelTracker.setEventName(VE_TAB_CHANGE_ON_HOME_PAGE);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}
