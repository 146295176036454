import MixpanelTracker from '../../mixpanelTracker';
import { VIRTUAL_EVENT_ON_REGISTRATION_SECTION_COLLAPSE_STATUS,
  VIRTUAL_EVENT_ON_EDIT_ABOUT_EVENT,
  VIRTUAL_EVENT_ON_EDIT_IMAGE,
  VIRTUAL_EVENT_ON_OPEN_IMAGE_PICKER,
  VIRTUAL_EVENT_ON_DELETE_IMAGE,
  VIRTUAL_EVENT_ON_EDIT_REGISTRATION_QUESTION,
  VIRTUAL_EVENT_ON_SAVE_REGISTRATION_QUESTION_API_STATUS,
  VIRTUAL_EVENT_ON_SAVE_REGISTRATION_QUESTION,
  VIRTUAL_EVENT_ON_ADD_CUSTOM_QUESTION,
  VIRTUAL_EVENT_ON_UPDATE_APPROVAL_TYPE } from '../../mixpanelConstants';
import Action from '../../Model/action';

const mixpanelTracker = new MixpanelTracker();

export function onCollapseVirtualEventRegistrationSectionTracker(eventKey, collapsed) {
  const properties = {
    Action: Action.COLLAPSE_SECTION,
    eventKey,
    collapsed
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_REGISTRATION_SECTION_COLLAPSE_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onUpdateVirtualEventDescriptionTracker(eventKey, description, status) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    description,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_EDIT_ABOUT_EVENT);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onClickVirtualEventImageEditIconTracker(eventKey, status) {
  const properties = {
    Action: Action.CLICK,
    eventKey,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_OPEN_IMAGE_PICKER);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onUpdateVirtualEventImageTracker(eventKey, imageDetails, status) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    imageDetails,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_EDIT_IMAGE);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onClickVirtualEventImageDeleteIconTracker(eventKey, status) {
  const properties = {
    Action: Action.CLICK,
    eventKey,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_DELETE_IMAGE);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onDeleteVirtualEventImageTracker(eventKey, imageDetails, status) {
  const properties = {
    Action: Action.REMOVE,
    eventKey,
    imageDetails,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_DELETE_IMAGE);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onEditVirtualEventRegistrationQuestionTracker(eventKey) {
  const properties = {
    Action: Action.CLICK,
    eventKey
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_EDIT_REGISTRATION_QUESTION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onSaveVirtualEventRegistrationQuestionTracker(eventKey, questionDetails, noOfQuestion, noOfMandatoryQuestion) {
  const customQuestions = questionDetails.questions.filter((question) => !question.readOnly);
  const templateQuestions = questionDetails.questions.filter((question) => question.readOnly).filter((question) => question.visible);
  const noOfCustomQuestions = customQuestions.length;
  const noOfTemplateQuestion = templateQuestions.length;
  const noOfCustomMandatoryQuestion = customQuestions.filter((question) => question.required).length;
  const noOfTemplateMandatoryQuestion = templateQuestions.filter((question) => question.required).length;
  const properties = {
    Action: Action.SAVE,
    eventKey,
    questionDetails,
    noOfQuestion,
    questions: questionDetails.questions.filter((question) => question.visible),
    customQuestions,
    templateQuestions,
    noOfCustomQuestions,
    noOfTemplateQuestion,
    noOfMandatoryQuestion,
    noOfCustomMandatoryQuestion,
    noOfTemplateMandatoryQuestion
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_SAVE_REGISTRATION_QUESTION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onSaveApiStatusVirtualEventRegistrationQuestionTracker(eventKey, questionDetails, status) {
  const properties = {
    Action: Action.SAVE,
    eventKey,
    questionDetails,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_SAVE_REGISTRATION_QUESTION_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onAddVirtualEventCustomQuestionTracker(eventKey, questionType) {
  const properties = {
    Action: Action.ADD,
    eventKey,
    questionType
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_ADD_CUSTOM_QUESTION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onUpdateApprovalTypeTracker(eventKey, approvalType) {
  const properties = {
    Action: Action.SAVE,
    eventKey,
    approvalType
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_UPDATE_APPROVAL_TYPE);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}
