import { createAsyncAction } from 'Reducks/reduxActions';

export const getRegistrantsAction = createAsyncAction('GET_WEBINAR_REGISTRANTS');
export const approvePendingRegistrationsAction = createAsyncAction('UPDATE_PENDING_WEBINAR_REGISTRANTS');
export const approveDeniedRegistrationsAction = createAsyncAction('UPDATE_DENIED_WEBINAR_REGISTRANTS');
export const cancelRegistrationsAction = createAsyncAction('CANCEL_WEBINAR_REGISTRANTS');
export const denyPendingRegistrationsAction = createAsyncAction('DELETE_PENDING_WEBINAR_REGISTRANTS');
export const resendConfirmationEmailAction = createAsyncAction('RESEND_CONFIRMATION_EMAIL');
export const getRegistrationReportAction = createAsyncAction('GET_REGISTRATION_REPORT');
export const getFilteredRegistrantsAction = createAsyncAction('GET_FILTERED_REGISTRANTS');
export const clearRegistrantsAction = createAsyncAction('CLEAR_WEBINAR_REGISTRANTS');
export const uploadRegistrantsListFileAction = createAsyncAction('UPLOAD_REGISTRANTS_LIST');
export const uploadFileToS3Action = createAsyncAction('UPLOAD_FILE_TO_S3');
export const createUploadPolicyAction = createAsyncAction('CREATE_UPLOAD_POLICY');

export function fetchWebinarRegistrants(webinarKey, includePaymentInfo) {
  return { type: getRegistrantsAction.toString(), webinarKey, includePaymentInfo };
}

export function fetchFilteredRegistrants(filter) {
  return { type: getFilteredRegistrantsAction.toString(), filter };
}

export function approvePendingRegistrations(webinarKey, body, includePaymentInfo) {
  return { type: approvePendingRegistrationsAction.toString(), webinarKey, body, includePaymentInfo };
}

export function approveDeniedRegistrations(webinarKey, body, includePaymentInfo) {
  return { type: approveDeniedRegistrationsAction.toString(), webinarKey, body, includePaymentInfo };
}

export function denyPendingRegistrations(webinarKey, body, includePaymentInfo) {
  return { type: denyPendingRegistrationsAction.toString(), webinarKey, body, includePaymentInfo };
}

export function cancelRegistrations(webinarKey, body, paymentInfo) {
  return { type: cancelRegistrationsAction.toString(), webinarKey, body, paymentInfo };
}

export function resendConfirmationEmail(webinarKey, body) {
  return { type: resendConfirmationEmailAction.toString(), webinarKey, body };
}

export function getRegistrationReport(webinarLocale, webinarKey) {
  return { type: getRegistrationReportAction.toString(), webinarLocale, webinarKey };
}

export function clearWebinarRegistrants(webinarKey) {
  return { type: clearRegistrantsAction.toString(), webinarKey };
}

export function uploadRegistrantsListFile(webinarKey, file, isVirtualEvent = false) {
  return { type: uploadRegistrantsListFileAction.toString(), webinarKey, file, isVirtualEvent };
}

// virtual event registrants actions
export const getVERegistrantsAction = createAsyncAction('GET_VIRTUAL_EVENT_REGISTRANTS');
export function fetchVirtualEventRegistrants(eventKey, includePaymentInfo = false) {
  return { type: getVERegistrantsAction.toString(), eventKey, includePaymentInfo };
}

export const getFilteredVERegistrantsAction = createAsyncAction('GET_FILTERED_VIRTUAL_EVENT_REGISTRANTS');
export function fetchFilteredVERegistrants(filter) {
  return { type: getFilteredVERegistrantsAction.toString(), filter };
}

export const updateVERegistrantsAction = createAsyncAction('UPDATE_VIRTUAL_EVENT_REGISTRANTS');
export function updateVirtualEventRegistrants(eventKey, action, body, includePaymentInfo = false) {
  return { type: updateVERegistrantsAction.toString(), eventKey, action, body, includePaymentInfo };
}

export const sendVEConfirmationEmailAction = createAsyncAction('SEND_VE_REGISTRANTS_CONFIRMATION_EMAIL');
export function sendVEConfirmationEmail(eventKey, body) {
  return { type: sendVEConfirmationEmailAction.toString(), eventKey, body };
}

export const getVERegistrationReportAction = createAsyncAction('GET_VIRTUAL_EVENT_REGISTRATION_REPORT');
export function getVERegistrationReport(eventLocale, eventKey) {
  return { type: getVERegistrationReportAction.toString(), eventLocale, eventKey };
}

export function clearVERegistrants() {
  return { type: clearRegistrantsAction.toString() };
}
