import { MessageBusEventType } from '@getgo/message-bus-js-sdk';
import DashboardClickedEvent from './Events/dashboardClickedEvent';
import PageLoadEvent from './Events/pageLoadEvent';

// Common event constants
const EVENT_NAMESPACE = 'com.logmein.g2w';
const EVENT_SOURCE = 'dashboard';

// Event name constants
const EVENT_NAME_CLICKED = 'dashboardClickedEvent';
const EVENT_NAME_LOADED = 'pageLoadEvent';

// Event Version constants
const EVENT_VERSION_CLICKED = '1-0-1';
const EVENT_VERSION_LOADED = '1-0-0';

let organizerKey;
let accountKey;
let anonymousUserKey;
let userType;
let userFrontEndSessionId;
let appVersion;

export function setUserType(type) {
  userType = type;
}

export function setOrganizerKey(key) {
  organizerKey = key;
}

export function setAccountKey(key) {
  accountKey = key;
}

export function setAnonymousUserKey(key) {
  anonymousUserKey = key;
}

export function setUserFrontendSessionId(frontendSessionId) {
  userFrontEndSessionId = frontendSessionId;
}

export function setAppVersion(version) {
  appVersion = version;
}

/**
 * Adds common event properties like namespace, user type, user email hash etc to an event object.
 * @param {Object} event
 */
function addCommonEventProperties(event) {
  if (!event) {
    return;
  }
  event.withNamespace(EVENT_NAMESPACE)
    .withEventSource(EVENT_SOURCE)
    .withUserType(userType)
    .withUserFrontEndSessionId(userFrontEndSessionId)
    .withOrganizerKey(organizerKey)
    .withAccountKey(accountKey)
    .withAppVersion(appVersion)
    .withAnonymousUserKey(anonymousUserKey);
}

/**
 * Creates a {DashboardClickedEvent} event from the provided parameters.
 * @param {*} event - An object with event properties.
 */
export function createClickedEvent(event) {
  if (!event) {
    throw new Error('Cannot create clicked event without event object');
  }
  const clickedEvent = new DashboardClickedEvent();
  clickedEvent.withEventName(EVENT_NAME_CLICKED)
    .withEventVersion(EVENT_VERSION_CLICKED)
    .withEventType(MessageBusEventType.INFO)
    .withWebpageName(event.webpageName)
    .withObjectClicked(event.objectClicked)
    .withObjectName(event.objectName)
    .withObjectResource(event.objectResource)
    .withObjectMetadata(event.objectMetadata);

  addCommonEventProperties(clickedEvent);
  return clickedEvent.build();
}

export function createPageLoadEvent(event) {
  if (!event) {
    throw new Error('Cannot create load event without event object');
  }
  const loadEvent = new PageLoadEvent();
  loadEvent.withEventName(EVENT_NAME_LOADED)
    .withEventVersion(EVENT_VERSION_LOADED)
    .withEventType(MessageBusEventType.INFO)
    .withWebpageName(event.webpageName)
    .withCustomPayload(event.customPayload)
    .withReferrerUrl(event.referrerUrl)
    .withTitle(event.title)
    .withUrl(event.url);
  addCommonEventProperties(loadEvent);
  return loadEvent.build();
}
