import v4 from 'uuid';

export function getNumericUUID() {
  return Math.floor(Math.random() * 1000000) + 1;
}

export function createShortId() {
  let uid = v4();
  const index = uid.lastIndexOf('-') + 1;
  uid = uid.slice(index, -1);
  return uid;
}

// Returns an alphanumeric string as UUID defined in the v4 spec of uuid library.
export function getAlphanumericUUID() {
  // Use v4
  return v4();
}
