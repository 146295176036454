import config from 'appConfig';
import { getBearerHeaders } from 'Api/Helpers/getHeaders';
import { buildQueries, fetchJson } from 'Api/Helpers/fetch';
import { GET, PUT, POST, DELETE, PATCH } from 'Api/Helpers/httpMethods';
import { SpeakerUpdatePath } from 'Components/Card/VirtualEvent/Details/About/constants';

export function getVirtualEvents(params, requestBody = []) {
  const queries = buildQueries(params);
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    redirect: 'follow',
    body: JSON.stringify(requestBody)
  };
  const url = `${config.g2w.events}/events/list?${queries}`;
  return fetchJson(url, options, 'json');
}

export function getVirtualEventDetails(eventID) {
  const options = {
    headers: getBearerHeaders(),
    method: GET,
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventID}`;
  return fetchJson(url, options, 'json');
}

export function updateVirtualEventDetails(eventKey, body) {
  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    redirect: 'follow',
    body: JSON.stringify(body)
  };
  const url = `${config.g2w.events}/events/${eventKey}`;
  return fetchJson(url, options, 'json');
}

export function addOrganizerToVirtualEvent(eventKey, body) {
  const payload = {
    organizerKey: body.organizerKey,
    displayPanelistInfo: false
  };
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    redirect: 'follow',
    body: JSON.stringify(payload)
  };
  const url = `${config.g2w.events}/events/${eventKey}/organizers`;
  return fetchJson(url, options, 'json');
}

export function deleteOrganizerToVirtualEvent(eventKey, organizerKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE,
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/organizers/${organizerKey}`;
  return fetchJson(url, options, 'json');
}

export function updateOrganizerToVirtualEvent(eventKey, organizerKey, fieldName, value, updateType) {
  let requestBody;

  if (fieldName === SpeakerUpdatePath.urls) {
    requestBody = value;
  } else {
    const data = {
      op: updateType,
      path: fieldName,
      value
    };
    requestBody = [data];
  }

  const options = {
    headers: getBearerHeaders('application/json-patch+json'),
    method: PATCH,
    redirect: 'follow',
    body: JSON.stringify(requestBody)
  };
  const url = `${config.g2w.events}/events/${eventKey}/organizers/${organizerKey}`;
  const response = fetchJson(url, options, 'json');
  return response;
}

export function createVirtualEventPanelist(eventKey, body) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    redirect: 'follow',
    body: JSON.stringify(body)
  };
  const url = `${config.g2w.events}/events/${eventKey}/panelists`;
  return fetchJson(url, options, 'json');
}

export function updateVirtualEventPanelist(eventKey, panelistKey, fieldName, value, updateType) {
  let requestBody;

  if (fieldName === SpeakerUpdatePath.urls) {
    requestBody = value;
  } else {
    requestBody = [{
      op: updateType,
      path: fieldName,
      value
    }];
  }
  const options = {
    headers: getBearerHeaders(),
    method: PATCH,
    redirect: 'follow',
    body: JSON.stringify(requestBody)
  };
  const url = `${config.g2w.events}/events/${eventKey}/panelists/${panelistKey}`;
  return fetchJson(url, options, 'json');
}

export function deleteVirtualEventPanelist(eventKey, panelistKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE,
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/panelists/${panelistKey}`;
  return fetchJson(url, options, 'json');
}

export function deleteVirtualEvent(eventKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE,
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}`;
  return fetchJson(url, options, 'json');
}

export function getVirtualEventRegistrationQuestions(questionnaireKey, accountKey, userLocale) {
  const options = {
    headers: getBearerHeaders('application/json', accountKey, userLocale.split('_').join('-')),
    method: GET,
    redirect: 'follow'
  };
  const url = `${config.g2w.question}/questionnaires/${questionnaireKey}`;
  return fetchJson(url, options, 'json');
}

export function updateVERegistrationQuestions(questions, questionnaireKey, accountKey, userLocale) {
  const options = {
    headers: getBearerHeaders('application/json', accountKey, userLocale.split('_').join('-')),
    method: PATCH,
    body: JSON.stringify(questions),
    redirect: 'follow'
  };
  const url = `${config.g2w.question}/questionnaires/${questionnaireKey}`;
  return fetchJson(url, options, 'json');
}

export function getRegistrationCount(eventKey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET,
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/registrations/counts`;
  return fetchJson(url, options, 'json');
}

export function updateRegistrationSettings(eventKey, approvalType) {
  // approvalType accepts 2 values, 'MANUAL' or 'AUTOMATIC'
  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify({ approvalType })
  };

  const url = `${config.g2w.events}/events/${eventKey}/registration/settings`;
  return fetchJson(url, options, 'json');
}

export function getAllRegistrants(eventKey, includePaymentInfo) {
  const options = {
    headers: getBearerHeaders(),
    method: GET,
    redirect: 'follow'
  };

  const url = `${config.g2w.events}/events/${eventKey}/registrations?includePaymentInfo=${includePaymentInfo}`;
  return fetchJson(url, options, 'json');
}

export function updateRegistrantsStatus(eventKey, action, body) {
  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify(body)
  };

  const url = `${config.g2w.events}/events/${eventKey}/registrations?action=${action}`;
  return fetchJson(url, options, 'json');
}

export function sendConfirmationEmail(eventKey, body) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };

  const url = `${config.g2w.events}/events/${eventKey}/registrations/email?type=CONFIRMATION`;
  return fetchJson(url, options, 'json');
}

export function createUploadPolicy(eventKey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET,
    redirect: 'follow'
  };

  const url = `${config.g2w.events}/events/${eventKey}/file-upload-ticket`;
  return fetchJson(url, options, 'json');
}

export function createPreSignedUrl(eventKey, imageUrl) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    redirect: 'follow',
    body: JSON.stringify({ s3ObjectUrl: imageUrl })
  };
  const url = `${config.g2w.events}/events/${eventKey}/logo/pre-sign-url`;
  return fetchJson(url, options, 'json');
}
