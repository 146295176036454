import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from '../Helpers/fetch';
import { POST } from '../Helpers/httpMethods';

export function uploadFileToS3(url, formData) {
  const options = {
    method: POST,
    body: formData
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}
