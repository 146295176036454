import { scheduleVirtualEventAction } from './actions';

const defaultState = {
  errorMessage: null,
  webinarKey: null,
  isLoading: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case scheduleVirtualEventAction.toString(): {
      return {
        ...state,
        eventKey: null,
        errorMessage: null,
        isLoading: true
      };
    }
    case scheduleVirtualEventAction.complete.toString(): {
      return {
        ...state,
        eventKey: action.webinarKey,
        errorMessage: null,
        isLoading: false
      };
    }
    case scheduleVirtualEventAction.failed.toString(): {
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
