import { schema } from 'normalizr';
import { paginationWebinarsSchema } from '../Paginations/schema';

const webinar = new schema.Entity('webinars', {}, {
  idAttribute: 'webinarKey'
});

const webinarsSchema = {
  webinars: [webinar],
  pagination: paginationWebinarsSchema
};

export default webinarsSchema;
