import { fetchWebinars } from '../../Webinar/actions';

const pagination = {
  total: 0,
  limit: 0,
  page: 0,
  pageSize: 8
};

export const initialState = {
  byId: {
    webinars: pagination
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fetchWebinars.fulfilled.toString():
    {
      const { entities } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          webinars: entities.pagination.webinars
        }
      };
    }

    default:
    {
      return state;
    }
  }
};
