import config from 'appConfig';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from '../Helpers/fetch';
import { GET, POST } from '../Helpers/httpMethods';
import { getBearerHeaders } from '../Helpers/getHeaders';

const baseUrl = `${config.settingsService.url}/products/G2W/identifiers`;

export function getWebinarSettings(webinarKey) {
  const options = {
    method: GET
  };
  return fetchJson(`${baseUrl}/WEBINAR-${webinarKey}`, options, JSON_MIME_TYPE);
}

export function setVideoControlsForRecoredWebinar(webinarKey, showVideoControls) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify({ showVideoControls })
  };

  return fetchJson(`${baseUrl}/WEBINAR-${webinarKey}`, options, JSON_MIME_TYPE, false);
}
