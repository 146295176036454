import { get } from 'Utils/objectHelpers';
import { VIDEO_EDIT, PAGE_VISIT, VIDEO } from '../../mixpanelConstants';
import { AUDIO_WAVEFORM } from './videoConstants';
import Action from '../../Model/action';

export function videoEditPageVisit(props) {
  const assetKey = get(props, (p) => p.match.params.assetKey);
  const assetVersion = get(props, (p) => p.match.params.assetVersion);

  if (!assetKey || !assetVersion) {
    return {};
  }

  return {
    name: VIDEO_EDIT,
    properties: {
      Type: PAGE_VISIT,
      Action: Action.VIEW,
      assetKey,
      assetVersion
    }
  };
}

export function videoEditSaveEvent(props) {
  const assetKey = get(props, (p) => p.video.assetKey);
  const assetVersion = get(props, (p) => p.video.assetVersion);

  if (!assetKey || !assetVersion) {
    return {};
  }

  return {
    name: VIDEO_EDIT,
    properties: {
      Type: VIDEO,
      Action: Action.SAVE,
      assetKey,
      assetVersion
    }
  };
}

export function originalVideoDownloadEvent() {
  return {
    name: VIDEO_EDIT,
    properties: {
      Type: VIDEO,
      Action: Action.DOWNLOAD,
      version: 'original'
    }
  };
}

export function editedVideoDownloadEvent() {
  return {
    name: VIDEO_EDIT,
    properties: {
      Type: VIDEO,
      Action: Action.DOWNLOAD,
      version: 'edited'
    }
  };
}

export function audioWaveformGenerated() {
  return {
    name: VIDEO_EDIT,
    properties: {
      Type: AUDIO_WAVEFORM,
      Action: Action.CREATE
    }
  };
}
