import i18n from 'i18next';
import moment from 'moment';

import { formatAsDate } from '../../../Utils/dateHelpers';
import { webinarDayDateFormat, webinarTimeDateFormat } from '../../../Utils/formats';

export function getDateTimeFormattedString(date, format) {
  return `${formatAsDate(date, format)}`;
}

export function parseDate(startDate, endDate, timezone) {
  const start = moment(startDate).tz(timezone);
  const end = moment(endDate).tz(timezone);

  const day = getDateTimeFormattedString(start, webinarDayDateFormat);
  const time = `${getDateTimeFormattedString(start, webinarTimeDateFormat)} - ${getDateTimeFormattedString(end, webinarTimeDateFormat)} ${start.tz(timezone).zoneAbbr()}`;

  return {
    day,
    time
  };
}

export function addTimeToDate(startDate, hours, minutes) {
  const sDate = new Date(startDate);
  const newStart = new Date(sDate.setHours(hours)).setMinutes(minutes);

  return newStart;
}

export function isEndDateTheNextDayFromStartDate(webinar, timezone) {
  const startDate = moment(webinar.startTime).tz(timezone);
  const endDate = moment(webinar.endTime).tz(timezone);
  return startDate.date() !== endDate.date();
}

export function formatDateAndTimeForTimeZone(
  time,
  timeZone,
  dayFormat = i18n.t('dateFormat.monthAndDayAsStringFormat'),
  timeFormat = i18n.t('dateFormat.webinarTimeDateFormat')
) {
  const startDate = moment(time.startTime).tz(timeZone);
  const endDate = moment(time.endTime).tz(timeZone);
  return {
    day: startDate.format(dayFormat),
    time: `${startDate.format(timeFormat)} - ${endDate.format(timeFormat)} ${startDate.tz(timeZone).format('z')}`
  };
}

export function formatDateAndTimeForOnDemand(
  time,
  timeZone,
  dayFormat = i18n.t('dateFormat.monthAndDayAsStringFormat'),
  timeFormat = i18n.t('dateFormat.webinarTimeDateFormat'),
) {
  const startDate = moment(time.startTime).tz(timeZone);
  const endDate = moment(time.endTime).tz(timeZone);
  return {
    startDateTime: `${startDate.format(dayFormat)} ${startDate.format(timeFormat)} - `,
    endDateTime: `${endDate.format(dayFormat)} ${endDate.format(timeFormat)} `,
    onDemandTimeZone: ` (${startDate.tz(timeZone).format('z')})`
  };
}

export function formatDateForTimeZone(date, timeZone) {
  const momentDate = moment(date).tz(timeZone);
  return momentDate.format(i18n.t('dateFormat.monthAndDayAsStringFormat'));
}

export function isLocalTimeZoneDifferentFromWebinarTimeZone(webinar) {
  return moment.tz.guess(true) !== webinar.timeZone;
}

export function extractNextUpcomingSessionDateTimeForWebinar(webinar) {
  if (webinar.type !== 'sequence') {
    return webinar.times[0];
  }
  const webinarTimes = webinar.times;
  const now = new Date().getTime();
  let closestEndTime = webinarTimes[webinarTimes.length - 1];
  for (let i = 0; i < webinarTimes.length; i++) {
    const webinarTime = webinarTimes[i];
    const endTime = new Date(webinarTime.endTime).getTime();
    if (endTime >= now) {
      closestEndTime = webinarTime;
      break;
    }
  }
  return closestEndTime;
}
