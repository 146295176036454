import { PAGE_NAMES, PAGE_TYPES } from './pageConstants';

const platformData = {
  platform_product: 'g2w',
  platform_type: 'product'
};

export function setGtmPlatformData(language = 'en_US', countryForUser, environment) {
  platformData.platform_environment = `g2w-${environment}`;
  platformData.platform_locale = `${language}_${countryForUser}`;
  platformData.platform_region = undefined;
  platformData.platform_country = countryForUser;
  platformData.platform_language = language;
}

function pushToDataLayer(gtmData) {
  if (window.dataLayer) {
    window.dataLayer.push({ ...gtmData });
  }
}

export function reportGtmPageView(page) {
  const pageData = {
    event: 'view_content',
    section: page.section,
    sub_section: page.subSection,
    template: page.template,
    content_type: page.contentType,
    content_number: page.contentNumber,
    funnel_name: page.funnelName,
    form_name: page.formName,
    login_status: true,
    ...platformData
  };

  switch (page.name) {
    case PAGE_NAMES.DASHBOARD:
      pageData.page_type = PAGE_TYPES.HOME;
      pageData.page_name = PAGE_NAMES.DASHBOARD;
      break;
    case PAGE_NAMES.EVENT_DETAILS:
      pageData.page_type = PAGE_TYPES.CONTENT;
      pageData.page_name = PAGE_NAMES.EVENT_DETAILS;
      break;
    case PAGE_NAMES.SETTINGS:
      pageData.page_type = PAGE_TYPES.SETTINGS;
      pageData.page_name = PAGE_NAMES.SETTINGS;
      break;
    case PAGE_NAMES.SCHEDULE_WEBINAR:
      pageData.page_type = PAGE_TYPES.CONTENT;
      pageData.page_name = PAGE_NAMES.SCHEDULE_WEBINAR;
      break;
    case PAGE_NAMES.VIDEO_LIBRARY:
      pageData.page_type = PAGE_TYPES.CONTENT;
      pageData.page_name = PAGE_NAMES.VIDEO_LIBRARY;
      break;
    case PAGE_NAMES.MY_CHANNELS:
      pageData.page_type = PAGE_TYPES.CONTENT;
      pageData.page_name = PAGE_NAMES.MY_CHANNELS;
      break;
    default:
      break;
  }
  pushToDataLayer(pageData);
}

export function reportGtmUserData(user, metadata) {
  const userData = {
    event: 'user_information',
    account_id: metadata.accountKey,
    account_type: 'plan',
    user_id: user.id
  };
  pushToDataLayer(userData);
}
