export const MAX_MARKETING_MESSAGE_LENGTH = 2048;
export const MAX_SOCIAL_MEDIA_LENGTH = 2000;
export const MAX_BIO_MESSAGE_LENGTH = 2048;
export const MAX_JOB_TITLE_LENGTH = 256;
export const MAX_ORGANIZATION_LENGTH = 256;
export const MAX_SUMMARY_LENGTH = 10000;

export const socialMediaLinkKeys = {
  website: '',
  twitter: '',
  facebook: '',
  linkedin: ''
};

const add = 'add';
const remove = 'remove';
const replace = 'replace';

export class SpeakerUpdateType {
  static get add() {
    return add;
  }

  static get remove() {
    return remove;
  }

  static get replace() {
    return replace;
  }

  static values() {
    return [add, remove, replace];
  }
}

const displayPanelistInfo = '/displayPanelistInfo';
const jobTitle = '/panelistInfo/jobTitle';
const organization = '/panelistInfo/organization';
const bio = '/panelistInfo/bio';
const avatar = '/panelistInfo/avatar/url';
const urls = '/panelistInfo/urls';
const website = '/panelistInfo/urls/website';
const twitter = '/panelistInfo/urls/twitter';
const facebook = '/panelistInfo/urls/facebook';
const linkedin = '/panelistInfo/urls/linkedin';

export class SpeakerUpdatePath {
  static get displayPanelistInfo() {
    return displayPanelistInfo;
  }

  static get jobTitle() {
    return jobTitle;
  }

  static get organization() {
    return organization;
  }

  static get bio() {
    return bio;
  }

  static get avatar() {
    return avatar;
  }

  static get urls() {
    return urls;
  }

  static get website() {
    return website;
  }

  static get twitter() {
    return twitter;
  }

  static get facebook() {
    return facebook;
  }

  static get linkedin() {
    return linkedin;
  }

  static values() {
    return [displayPanelistInfo, jobTitle, organization, bio, avatar, website, twitter, facebook, linkedin];
  }
}
