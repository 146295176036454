import { createAsyncAction } from 'redux-promise-middleware-actions';
import * as qAndAService from '../../Api/Webinar/QAndAService/qAndAService';

export const getQAndASettings = createAsyncAction('GET_Q_AND_A_SETTINGS', qAndAService.getQAndASettings);
export const updateQAndASettings = createAsyncAction('UPDATE_Q_AND_A_SETTINGS', qAndAService.updateQAndASettings);
export const resetQAndASettingsAction = 'RESET_POLLS';

export function resetQAndASettings() {
  return { type: resetQAndASettingsAction };
}
