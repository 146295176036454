/* eslint-disable camelcase, no-multi-spaces */
import React, { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { array, func, object, string, shape } from 'prop-types';

import Selector from '../../../../Components/Card/SharedComponents/Selector';
import { createShortId } from '../../../../Utils/randomUuidGenerator';
import { extractKeyValuePairs, ratingFields } from '../../../../Reducks/Survey/selectors';
import styles from './RatingScale.scss';

const { LOW_RATING, HIGH_RATING, LOW_LABEL, MID_LABEL, HIGH_LABEL } = ratingFields;

const startOptions = [0, 1];

const endOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10];

const labelCharMax = 32;

const disablePaste = (evt) => {
  evt.preventDefault();
};

const RatingScale = ({
  choices,
  invalidFields,
  question,
  removeSurveyChoice,
  updateSurveyChoice
}) => {
  const { t } = useTranslation();

  const { low_label, mid_label, high_label, low_rating, high_rating } = extractKeyValuePairs(choices);

  const [minRating, setMinRating] = useState(low_rating ? parseInt(low_rating.value, 10) : 1);
  const [maxRating, setMaxRating] = useState(high_rating ? (parseInt(high_rating.value, 10) - 2) : 3);

  const updateLowRating = (idx, rating) => {
    setMinRating(idx);
    updateSurveyChoice(low_rating.answerKey, `${LOW_RATING}:${rating}`);
  };

  const updateHighRating = (idx, rating) => {
    setMaxRating(idx);
    updateSurveyChoice(high_rating.answerKey, `${HIGH_RATING}:${rating}`);
  };

  const updateLabel = ({ target }) => {
    const label = target.getAttribute('data-label');
    const answerKey = {
      [LOW_LABEL]: low_label && low_label.answerKey,
      [MID_LABEL]: mid_label && mid_label.answerKey,
      [HIGH_LABEL]: high_label && high_label.answerKey
    }[label] || createShortId();

    if (!target.value) {
      removeSurveyChoice(answerKey, question.questionKey);
    } else if (target.value.length <= labelCharMax) {
      updateSurveyChoice(answerKey, `${label}:${target.value}`);
    }
  };

  let rightPlaceHolder = `${t('survey.questions.choices.rateOnScale.rightLabel')} (${t('fragments.optional')})`;

  if (invalidFields.highLabel || (low_label && !high_label) || invalidFields.midLabel) {
    rightPlaceHolder = t('survey.questions.choices.rateOnScale.rightLabel');
  }

  let leftPlaceHolder = `${t('survey.questions.choices.rateOnScale.leftLabel')} (${t('fragments.optional')})`;

  if (invalidFields.lowLabel || (!low_label && high_label) || invalidFields.midLabel) {
    leftPlaceHolder = t('survey.questions.choices.rateOnScale.leftLabel');
  }

  return (
    <div className={styles.ratingScale}>
      <div className={classnames(styles.selectorRow, styles.halfWidth)}>
        <Selector
          className={styles.selector}
          index={minRating}
          label={t('survey.questions.choices.rateOnScale.start')}
          onChange={updateLowRating}
          options={startOptions}
        />

        <Selector
          className={styles.selector}
          index={maxRating}
          label={t('survey.questions.choices.rateOnScale.end')}
          onChange={updateHighRating}
          options={endOptions}
        />
      </div>

      <div className={styles.labelRow} onChange={updateLabel}>
        <div className={styles.label}>
          <input
            className={classnames(styles.input, {
              [styles.error]: invalidFields.lowLabel || invalidFields.midLabel
            })}
            data-label={LOW_LABEL}
            onPaste={disablePaste}
            placeholder={leftPlaceHolder}
            type="text"
            value={low_label && low_label.value}
          />
          {(invalidFields.lowLabel || invalidFields.midLabel) && (
            <i className="togo-icon togo-icon-alert" />
          )}
        </div>

        <div className={styles.label}>
          <input
            className={styles.input}
            data-label={MID_LABEL}
            onPaste={disablePaste}
            placeholder={`${t('survey.questions.choices.rateOnScale.centerLabel')} (${t('fragments.optional')})`}
            type="text"
            value={mid_label && mid_label.value}
          />
        </div>

        <div className={styles.label}>
          <input
            className={classnames(styles.input, {
              [styles.error]: invalidFields.highLabel || invalidFields.midLabel
            })}
            data-label={HIGH_LABEL}
            onPaste={disablePaste}
            placeholder={rightPlaceHolder}
            type="text"
            value={high_label && high_label.value}
          />
          {(invalidFields.highLabel || invalidFields.midLabel) && (
            <i className="togo-icon togo-icon-alert" />
          )}
        </div>
      </div>
    </div>
  );
};

RatingScale.propTypes = {
  choices: array,
  invalidFields: object,
  question: shape({
    questionKey: string.isRequired,
    questionText: string.isRequired,
    type: string.isRequired
  }).isRequired,
  removeSurveyChoice: func,
  updateSurveyChoice: func
};

export default RatingScale;
