import React, { Component } from 'react';
import classNames from 'classnames';
import { array, bool, func, object, number, string } from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import FloatingLabelBackground from '../FloatingLabelBackground';
import styles from './styles.scss';

export default class Selector extends Component {
  static propTypes = {
    className: string,
    containerClassname: string,
    disabled: bool,
    id: string,
    index: number,
    isLoading: bool,
    label: string.isRequired,
    menuStyles: string,
    onChange: func,
    optionStyle: object,
    optionProps: object,
    options: array.isRequired
  };

  static defaultProps = {
    index: 0,
    onChange: () => {},
    options: [],
    optionStyle: {}
  };

  state = {
    dropdownOpen: false
  };

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  onOptionSelected = (index, option) => {
    this.props.onChange(index, option);
  };

  renderOptions = () => {
    const indents = [];
    const { options, optionStyle, optionProps = {} } = this.props;

    options.forEach((option, index) => {
      const itemStyle = optionStyle[option];
      const dropdownItemProp = optionProps[index];
      indents.push(
        <DropdownItem
          className={classNames(styles.ellipsisContainer, itemStyle || '')}
          key={`${option}${index}`}
          onClick={() => this.onOptionSelected(index, option)}
          {...dropdownItemProp}>
          {option}
        </DropdownItem>
      );
    });

    return indents;
  };

  render() {
    const { dropdownOpen } = this.state;
    const {
      className,
      containerClassname,
      disabled,
      id,
      index,
      isLoading,
      label,
      menuStyles,
      options
    } = this.props;

    const showPlaceholder = index < 0;

    return (
      <Dropdown className={containerClassname} isOpen={dropdownOpen} disabled={disabled} toggle={this.toggle} id={id}>
        <DropdownToggle className={styles.dropdownToggle} disabled={disabled} aria-expanded={dropdownOpen} role='button' tabIndex='-1' tag='div'>
          <FloatingLabelBackground
            className={classNames(styles.label, className)}
            expanded={dropdownOpen}
            disabled={disabled}
            label={showPlaceholder ? null : label}
            isSelected={dropdownOpen}
          >
            <div className={classNames(styles.ellipsisContainer, { [styles.placeholder]: showPlaceholder })}>
              {showPlaceholder ? label : options[index] }
            </div>

            <i className="togo-icon togo-icon-chevron" />
          </FloatingLabelBackground>
        </DropdownToggle>

        <DropdownMenu className={classNames(styles.menu, menuStyles)}>
          {isLoading ? (
            <div>{'loading'}</div>
          ) : (
            <div className={styles.optionsMenu}>
              {this.renderOptions()}
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
