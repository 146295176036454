const getPrevious = ({ page }) => (
  page - 1 > -1 ? page - 1 : undefined
);

const getNext = ({ page, limit, total }) => (
  (page + 1) * limit >= total ? undefined : (page + 1)
);

const getPageCount = ({ limit, total }) => (
  Math.ceil(total / limit)
);

const createPagination = ({
  total,
  limit,
  page,
  pageSize
}) => ({
  previous: getPrevious({ page }),
  next: getNext({ page, limit, total }),
  pageCount: getPageCount({ limit, total }),
  total,
  limit,
  page,
  pageSize
});

const sortVirtualPastEvents = (first, second) => {
  const firstEndDate = new Date(first.end);
  const secondEndDate = new Date(second.end);
  return secondEndDate - firstEndDate;
};

export {
  createPagination,
  sortVirtualPastEvents
};
