import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import FullScreenLoader from 'Components/Common/FullScreenLoader';
import Authenticated from 'Containers/Auth';
import { hot } from 'react-hot-loader';

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */'Containers/Dashboard'),
  loading: FullScreenLoader,
  delay: 300
});

const Root = hot(module)(Dashboard);

const MainRoute = () => (
  <Route path='/' component={Authenticated(Root)} />
);

export default MainRoute;
