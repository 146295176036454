/* eslint-disable camelcase */
import { extractKeyValuePairs } from '../../Reducks/Survey/selectors';
import { isEmpty } from '../../Utils/objectHelpers';

const validateMulipleChoiceQuestion = (choices) => {
  if (choices.filter((c) => c.answerText) > 1) {
    return undefined;
  }

  const validAnswerKeys = choices
    .slice(0, 2)
    .reduce((acc, { answerKey, answerText }) => {
      if (!answerText) {
        acc[answerKey] = true;
      }
      return acc;
    }, {});

  return validAnswerKeys;
};

const validateRatingQuestion = (choices) => {
  const errors = {};

  const { low_label, high_label, mid_label } = extractKeyValuePairs(choices);

  if (!low_label && high_label) {
    errors.lowLabel = true;
  }

  if (low_label && !high_label) {
    errors.highLabel = true;
  }

  if (mid_label && !low_label && !high_label) {
    errors.midLabel = true;
  }

  return errors;
};

export default (question, choices) => {
  let errors;

  if (!question.questionText) {
    errors = { title: true };
  }

  if (question.type === 'multipleChoice' || question.type === 'singleChoice') {
    errors = { ...errors, ...validateMulipleChoiceQuestion(choices) };
  }

  if (question.type === 'rating') {
    errors = { ...errors, ...validateRatingQuestion(choices) };
  }

  if (isEmpty(errors)) {
    return undefined;
  }

  return errors;
};
