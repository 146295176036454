export const TAG_TYPE = {
  RECORDING_PROCESSING: 'RecordingProcessingComplete',
  REGISTRATION_LIMIT: 'RegistrationLimitReached',
  NOTIFICATION_PREFERENCE: 'NotificationPreference',
  NEW_FEATURES_AVAILABLE: 'NewFeaturesAvailable'
};

export const TAG_IDENTIFIER = {
  NOTIFICATION_PREFERENCE: 'NotificationPreferenceId'
};
