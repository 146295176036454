import { fetchVirtualEventsAction } from './actions';
import { createPagination } from './operations';

const defaultState = {
  virtualEventsList: [],
  isFetching: false,
  isUpcoming: false,
  allVirtualEventKeys: [],
  pagination: {}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    // to get virtual events

    case fetchVirtualEventsAction.toString(): {
      return {
        ...state,
        isFetching: true,
        isUpcoming: action.params.upcoming
      };
    }
    case fetchVirtualEventsAction.complete.toString(): {
      const { events, totalEventKeys, page, limit } = action.data;
      let newVirtualEventsList = events;
      if (state.isUpcoming) {
        // to display inprogress status
        const now = new Date().getTime();
        newVirtualEventsList = newVirtualEventsList.filter((eventObj) => now <= new Date(eventObj.end).getTime()).map((item) => ({
          ...item, isOngoing: now >= new Date(item.start).getTime()
        }));
      }
      return {
        ...state,
        virtualEventsList: newVirtualEventsList,
        allVirtualEventKeys: totalEventKeys,
        pagination: createPagination({ page: page - 1, total: totalEventKeys.length, limit, pageSize: events.length }),
        isFetching: false
      };
    }
    case fetchVirtualEventsAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        isFetching: false
      };
    }
    default: {
      return state;
    }
  }
};
