/* eslint-disable no-continue */
import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { SEND_NOTIFICATION } from 'Reducks/Notification/actions';
import { delay } from 'redux-saga';
import { redirect } from 'Utils/redirector';
import ReportStatus from '../../Reporting/status';
import * as virtualEventDetailsService from '../../Api/VirtualEvent/VirtualEventDetails/VirtualEventDetailsService';
import * as webinarService from '../../Api/Webinar/webinarService';
import * as reportingService from '../../Api/reportingService';
import { createfileUploadPayload } from '../../Api/Helpers/upload';
import { uploadFileToS3 } from '../../Api/AWS/awsService';

import { getRegistrantsAction,
  getRegistrationReportAction,
  approvePendingRegistrationsAction,
  approveDeniedRegistrationsAction,
  denyPendingRegistrationsAction,
  cancelRegistrationsAction,
  resendConfirmationEmailAction,
  getFilteredRegistrantsAction,
  clearRegistrantsAction,
  uploadRegistrantsListFileAction,
  uploadFileToS3Action,
  createUploadPolicyAction,
  getVERegistrantsAction,
  getFilteredVERegistrantsAction,
  updateVERegistrantsAction,
  sendVEConfirmationEmailAction,
  getVERegistrationReportAction } from './actions';

function* getRegistrants({ webinarKey, includePaymentInfo }) {
  try {
    const data = yield call(webinarService.getAllRegistrants, webinarKey, includePaymentInfo);
    yield put({ type: getRegistrantsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getRegistrantsAction.failed.toString(),
      message: e.message
    });
  }
}

function* getFilteredRegistrants({ filter }) {
  try {
    yield put({ type: getFilteredRegistrantsAction.complete.toString(), filter });
  } catch (e) {
    yield put({
      type: getFilteredRegistrantsAction.failed.toString(),
      message: e.message
    });
  }
}

function* reSendConfirmation({ webinarKey, body }) {
  try {
    const data = yield call(webinarService.resendConfirmationEmail, webinarKey, body);
    yield put({ type: resendConfirmationEmailAction.complete.toString(), data });
    yield put({
      type: SEND_NOTIFICATION,
      messageKey: body.registrants.length === 1 ? 'registrantList.notification.confirmationEmailSentSingular' : 'registrantList.notification.confirmationEmailSent',
      isError: false,
      messageKeyParams: [{ count: body.registrants.length }]
    });
  } catch (e) {
    yield put({
      type: resendConfirmationEmailAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.failed', isError: true });
  }
}

function* approvePendingRegistrations({ webinarKey, body, includePaymentInfo }) {
  try {
    let data = yield call(webinarService.updatePendingRegistrations, webinarKey, body);

    yield put({ type: approvePendingRegistrationsAction.complete.toString(), data });
    yield put({
      type: SEND_NOTIFICATION,
      messageKey: body.registrants.length === 1 ? 'registrantList.notification.approvedRegistrationSingular' : 'registrantList.notification.approvedRegistrationPlural',
      messageKeyParams: [{ count: body.registrants.length }],
      isError: false
    });

    data = yield call(webinarService.getAllRegistrants, webinarKey, includePaymentInfo);
    yield put({ type: getRegistrantsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: approvePendingRegistrationsAction.failed.toString(),
      error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.failed', isError: true });
  }
}

function* denyPendingRegistrations({ webinarKey, body, includePaymentInfo }) {
  try {
    let data = yield call(webinarService.updatePendingRegistrations, webinarKey, body);
    const count = data.count;
    if (!count) {
      throw new Error('0 count returned when denying registrants.');
    }
    yield put({ type: denyPendingRegistrationsAction.complete.toString(), data });
    yield put({
      type: SEND_NOTIFICATION,
      messageKey: count === 1 ? 'registrantList.notification.denyPendingRegistrationSingular' : 'registrantList.notification.denyPendingRegistrationsPlural',
      messageKeyParams: [{ count }],
      isError: false
    });
    data = yield call(webinarService.getAllRegistrants, webinarKey, includePaymentInfo);
    yield put({ type: getRegistrantsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: denyPendingRegistrationsAction.failed.toString(),
      error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.failed', isError: true });
  }
}

function* approveDeniedRegistrations({ webinarKey, body, includePaymentInfo }) {
  try {
    let data = yield call(webinarService.approveDeniedRegistrations, webinarKey, body);

    yield put({ type: approveDeniedRegistrationsAction.complete.toString(), data });
    yield put({
      type: SEND_NOTIFICATION,
      messageKey: body.registrants.length === 1 ? 'registrantList.notification.approvedRegistrationSingular' : 'registrantList.notification.approvedRegistrationPlural',
      messageKeyParams: [{ count: body.registrants.length }],
      isError: false
    });
    data = yield call(webinarService.getAllRegistrants, webinarKey, includePaymentInfo);
    yield put({ type: getRegistrantsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: approveDeniedRegistrationsAction.failed.toString(),
      error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.failed', isError: true });
  }
}

function* cancelRegistrations({ webinarKey, body, paymentInfo }) {
  try {
    let data = yield call(webinarService.cancelRegistrations, webinarKey, body, paymentInfo && paymentInfo.issueRefund);

    yield put({ type: cancelRegistrationsAction.complete.toString(), data });
    yield put({
      type: SEND_NOTIFICATION,
      messageKey: body.registrants.length === 1 ? 'registrantList.notification.canceledRegistrationSingular' : 'registrantList.notification.canceledRegistrationPlural',
      messageKeyParams: [{ count: body.registrants.length }],
      isError: false
    });

    data = yield call(webinarService.getAllRegistrants, webinarKey, !!(paymentInfo && paymentInfo.includePaymentInfo));
    yield put({ type: getRegistrantsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: cancelRegistrationsAction.failed.toString(),
      error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.failed', isError: true });
  }
}

function* getRegistrationReport({ webinarLocale, webinarKey }) {
  try {
    const data = yield call(reportingService.getRegistrationReport, webinarLocale, webinarKey);
    const jobId = data.id;

    const retryCount = 5;
    let sendEvent = false;

    for (let i = 0; i < retryCount; i++) {
      if (i === retryCount - 1) {
        sendEvent = true;
      }
      if (i !== 0) {
        yield call(delay, 1000);
      }

      const jobResponse = yield call(reportingService.checkJobStatus, jobId, sendEvent);
      if (jobResponse.status === ReportStatus.STARTED || jobResponse.status === ReportStatus.SUBMITTED) {
        continue;
      } else if (jobResponse.status === ReportStatus.COMPLETED) {
        yield put({
          type: SEND_NOTIFICATION,
          messageKey: 'registrantList.notification.downloadReportSuccessFull',
          isError: false
        });
        redirect(JSON.parse(jobResponse.output).url);
        break;
      } else if (jobResponse.status === ReportStatus.FAILED) {
        yield put({
          type: getRegistrationReportAction.failed.toString()
        });
        yield put({
          type: SEND_NOTIFICATION,
          messageKey: 'registrantList.notification.downloadReportFailed',
          isError: true
        });
        break;
      }
    }

    yield put({ type: getRegistrationReportAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: getRegistrationReportAction.failed.toString(),
      error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.downloadReportFailed', isError: true });
  }
}

function* clearRegistrants() {
  try {
    yield put({ type: clearRegistrantsAction.complete.toString() });
  } catch (error) {
    yield put({
      type: clearRegistrantsAction.failed.toString(),
      error
    });
  }
}

function* uploadRegistrantsFile(action) {
  try {
    const webinarKey = action.webinarKey ?? null;
    let uploadPolicy = {};
    // Call to get upload policy for s3 upload
    if (action.isVirtualEvent) {
      uploadPolicy = yield call(virtualEventDetailsService.createUploadPolicy, webinarKey);
    } else {
      uploadPolicy = yield call(webinarService.createUploadPolicy, webinarKey);
    }
    yield put({ type: createUploadPolicyAction.complete.toString() });

    // Call to upload the file to s3 with upload policy
    const newFile = new File([action.file], uploadPolicy.formData['x-amz-meta-__filename__'], { type: action.file.type });
    const payload = createfileUploadPayload(uploadPolicy.formData, newFile);
    yield call(uploadFileToS3, uploadPolicy.uploadUrl, payload);
    yield put({ type: uploadFileToS3Action.complete.toString() });

    yield put({ type: uploadRegistrantsListFileAction.complete.toString() });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantsBulkUpload.uploadSuccessMessage', isError: false });
  } catch (error) {
    yield put({ type: uploadRegistrantsListFileAction.failed.toString(), error });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantsBulkUpload.uploadFailureMessage', isError: true });
  }
}

function* getVERegistrants({ eventKey, includePaymentInfo }) {
  try {
    const data = yield call(virtualEventDetailsService.getAllRegistrants, eventKey, includePaymentInfo);
    yield put({ type: getVERegistrantsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getVERegistrantsAction.failed.toString(),
      message: e.message
    });
  }
}

function* sendVEConfirmation({ eventKey, body }) {
  try {
    const data = yield call(virtualEventDetailsService.sendConfirmationEmail, eventKey, body);
    yield put({ type: sendVEConfirmationEmailAction.complete.toString(), data });
    yield put({
      type: SEND_NOTIFICATION,
      messageKey: body.registrants.length === 1 ? 'registrantList.notification.confirmationEmailSentSingular' : 'registrantList.notification.confirmationEmailSent',
      isError: false,
      messageKeyParams: [{ count: body.registrants.length }]
    });
  } catch (e) {
    yield put({
      type: sendVEConfirmationEmailAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.failed', isError: true });
  }
}

function* getFilteredVERegistrants({ filter }) {
  try {
    yield put({ type: getFilteredVERegistrantsAction.complete.toString(), filter });
  } catch (e) {
    yield put({
      type: getFilteredVERegistrantsAction.failed.toString(),
      message: e.message
    });
  }
}

function* updateVERegistrationsStatus({ eventKey, action, body, includePaymentInfo }) {
  try {
    const data = yield call(virtualEventDetailsService.updateRegistrantsStatus, eventKey, action, body);

    yield put({ type: updateVERegistrantsAction.complete.toString(), data });
    let msgKey = '';
    const isSingular = body.registrants.length === 1;
    if (action === 'APPROVE') {
      msgKey = isSingular ? 'registrantList.notification.approvedRegistrationSingular' : 'registrantList.notification.approvedRegistrationPlural';
    } else if (action === 'CANCEL') {
      msgKey = isSingular ? 'registrantList.notification.canceledRegistrationSingular' : 'registrantList.notification.canceledRegistrationPlural';
    } else {
      msgKey = isSingular ? 'registrantList.notification.denyPendingRegistrationSingular' : 'registrantList.notification.denyPendingRegistrationsPlural';
    }
    yield put({
      type: SEND_NOTIFICATION,
      messageKey: msgKey,
      messageKeyParams: [{ count: body.registrants.length }],
      isError: false
    });
    yield put({ type: getVERegistrantsAction.toString(), eventKey, includePaymentInfo });
  } catch (error) {
    yield put({
      type: updateVERegistrantsAction.failed.toString(),
      error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.failed', isError: true });
  }
}

function* getVERegistrationReport({ eventLocale, eventKey }) {
  try {
    const data = yield call(reportingService.getVirtualEventRegistrationReport, eventKey, eventLocale);
    const jobId = data.id;

    const retryCount = 5;
    let sendEvent = false;

    for (let i = 0; i < retryCount; i++) {
      if (i === retryCount - 1) {
        sendEvent = true;
      }
      if (i !== 0) {
        yield call(delay, 1000);
      }

      const jobResponse = yield call(reportingService.checkVEReportJobStatus, jobId, sendEvent);
      if (jobResponse.status === ReportStatus.STARTED || jobResponse.status === ReportStatus.SUBMITTED) {
        continue;
      } else if (jobResponse.status === ReportStatus.COMPLETED) {
        yield put({
          type: SEND_NOTIFICATION,
          messageKey: 'registrantList.notification.downloadReportSuccessFull',
          isError: false
        });
        redirect(jobResponse.output);
        break;
      } else if (jobResponse.status === ReportStatus.FAILED) {
        yield put({
          type: getVERegistrationReportAction.failed.toString()
        });
        yield put({
          type: SEND_NOTIFICATION,
          messageKey: 'registrantList.notification.downloadReportFailed',
          isError: true
        });
        break;
      }
    }

    yield put({ type: getVERegistrationReportAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: getVERegistrationReportAction.failed.toString(),
      error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'registrantList.notification.downloadReportFailed', isError: true });
  }
}

export function* watchFetchVERegistrantsList() {
  yield takeLatest(getVERegistrantsAction.toString(), getVERegistrants);
}

export function* watchFetchFilteredVERegistrants() {
  yield takeLatest(getFilteredVERegistrantsAction.toString(), getFilteredVERegistrants);
}

export function* watchGetVERegistrationReport() {
  yield takeLatest(getVERegistrationReportAction.toString(), getVERegistrationReport);
}

export function* watchUpdateVERegistrationsStatus() {
  yield takeLatest(updateVERegistrantsAction.toString(), updateVERegistrationsStatus);
}

export function* watchSendVEConfirmation() {
  yield takeLatest(sendVEConfirmationEmailAction.toString(), sendVEConfirmation);
}

export function* watchFetchRegistrantsList() {
  yield takeLatest(getRegistrantsAction.toString(), getRegistrants);
}

export function* watchFetchFilteredRegistrants() {
  yield takeLatest(getFilteredRegistrantsAction.toString(), getFilteredRegistrants);
}

export function* watchGetRegistrationReport() {
  yield takeLatest(getRegistrationReportAction.toString(), getRegistrationReport);
}

export function* watchUpdatePendingRegistrations() {
  yield takeLatest(approvePendingRegistrationsAction.toString(), approvePendingRegistrations);
}

export function* watchDenyPendingRegistrations() {
  yield takeLatest(denyPendingRegistrationsAction.toString(), denyPendingRegistrations);
}

export function* watchUpdateDeniedRegistrations() {
  yield takeLatest(approveDeniedRegistrationsAction.toString(), approveDeniedRegistrations);
}

export function* watchCancelRegistrations() {
  yield takeLatest(cancelRegistrationsAction.toString(), cancelRegistrations);
}

export function* watchResendConfirmationEmail() {
  yield takeLatest(resendConfirmationEmailAction.toString(), reSendConfirmation);
}

export function* watchClearRegistrants() {
  yield takeLatest(clearRegistrantsAction.toString(), clearRegistrants);
}

export function* watchUploadRegistrantsList() {
  yield takeLatest(uploadRegistrantsListFileAction.toString(), uploadRegistrantsFile);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchRegistrantsList),
    fork(watchFetchFilteredRegistrants),
    fork(watchGetRegistrationReport),
    fork(watchUpdatePendingRegistrations),
    fork(watchDenyPendingRegistrations),
    fork(watchUpdateDeniedRegistrations),
    fork(watchCancelRegistrations),
    fork(watchResendConfirmationEmail),
    fork(watchClearRegistrants),
    fork(watchUploadRegistrantsList),
    fork(watchFetchVERegistrantsList),
    fork(watchFetchFilteredVERegistrants),
    fork(watchGetVERegistrationReport),
    fork(watchUpdateVERegistrationsStatus),
    fork(watchSendVEConfirmation)
  ]);
}
