import React, { PureComponent } from 'react';
import ResponsiveModal from '@getgo/chameleon-react/dist/ResponsiveModal';
import { func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import config from 'appConfig';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import styles from './styles.scss';
import transcriptEditing from './assets/transcript_editing.png';
import videoEditing from './assets/video_editing.png';
import unlockFeature from '../../Card/ScheduleWebinarV2/Assets/unlock-feature.svg';
import { getUpgradeUrl } from '../../../Notifications/Helpers/notificationsHelper';
import { redirectToNewTab } from '../../../Utils/redirector';
import { trackUpgradeUrlClicked } from '../../../Mixpanel/Features/Upgrade/featureUpgradeTracker';

const FEATURES = {
  default: unlockFeature,
  transcriptEditing,
  videoEditing
};

@connect(
  (state) => ({
    metadata: state.user.metadata
  }),
)

class FeatureUnlock extends PureComponent {
  static propTypes = {
    close: func,
    unlockFeature: string,
    metadata: object,
    t: func
  }

  static defaultProps = {
    unlockFeature: 'default'
  }

  renderFeatures(iconName, title, isLink = false, url) {
    return (
      <span className={styles.feature}>
        <i className={`togo-icon ${iconName}`} />
        { !isLink && <label id='feature_unlock_feature_label'>{title}</label> }
        { isLink && <a id ='feature_unlock_feature_link' rel='noopener noreferrer' target="_blank" href={url}>{title}</a> }
      </span>
    );
  }

  upgradeClicked = () => {
    trackUpgradeUrlClicked();
    const url = getUpgradeUrl(this.props.metadata);
    if (url) {
      redirectToNewTab(url);
    }
    this.props.close();
  }

  render() {
    const { t } = this.props;
    const placeholderImage = FEATURES[this.props.unlockFeature];

    return (
      <ResponsiveModal isOpen onClose={this.props.close}>
        <div id='feature_unlock_modal_container' className={styles.container}>
          <img
            alt=''
            className={styles.featureUnlockImage}
            role='presentation'
            src={placeholderImage}
          />

          <h1 id='feature_unlock_modal_title'>
            {t('featureUnlock.title')}
          </h1>

          <p id='feature_unlock_modal_description'>
            {t('featureUnlock.description', { plan: this.props.metadata.description })}
          </p>

          <div className="row">
            <div className="col-sm">
              {this.renderFeatures('togo-icon-video-pen', t('featureUnlock.videoEditor'))}
            </div>
            <div className="col-sm">
              {this.renderFeatures('togo-icon-video', t('featureUnlock.recordedEvent'))}
            </div>
            <div className="col-sm">
              {this.renderFeatures('togo-icon-share', t('featureUnlock.videoSharing'))}
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              {this.renderFeatures('togo-icon-badge', t('featureUnlock.Certificates'))}
            </div>
            <div className="col-sm">
              {this.renderFeatures('togo-icon-link', t('featureUnlock.customChannelURL'))}
            </div>
            <div className="col-sm">
              {this.renderFeatures('togo-icon-three-dots', t('featureUnlock.andMore'), true, config.webinarPricingUrl)}
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            <Button color='link' onClick={this.upgradeClicked}>
              {t('featureUnlock.upgrade')}
            </Button>
            <Button color='primary' onClick={this.props.close}>
              {t('button.cancel')}
            </Button>
          </div>
        </div>
      </ResponsiveModal>
    );
  }
}

export default withTranslation()(FeatureUnlock);
