import { clearLocalStorage } from 'Utils/localStorage';
import * as Constants from '../Survey/constants';
import * as SurveyActions from '../Survey/actions';
import * as PollsActions from '../Poll/actions';
import * as HandoutActions from '../Handouts/actionTypes';
import * as VideoActions from '../Videos/actionTypes';

import { createVirtualEventSessionAction,
  setCreateSesssionErrorStateAction,
  saveVirtualEventSessionAction,
  deleteVirtualEventSessionAction,
  clearErrorFieldsAction,
  addPanelistToVirtualEventSessionAction,
  setSaveSessionCallStateAction,
  setDeleteSessionCallStateAction,
  fetchVirtualConferenceAction,
  resetVirtualEventSessionAction,
  deleteSpeakerAction,
  replaceSpeakerAction,
  updateDeleteSpeakerFlagAction,
  setFetchSessionCallStateAction,
  updateRecordingAction } from './actions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case createVirtualEventSessionAction.toString(): {
      return {
        ...state,
        errorInSessionCreation: false,
        isCreateSessionInProgress: true
      };
    }
    case createVirtualEventSessionAction.complete.toString(): {
      return {
        ...state,
        ...action.data,
        errorInSessionCreation: false,
        isCreateSessionInProgress: false
      };
    }
    case createVirtualEventSessionAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        errorInSessionCreation: true,
        isCreateSessionInProgress: false
      };
    }
    case saveVirtualEventSessionAction.toString(): {
      return {
        ...state,
        sessionUpdated: false
      };
    }
    case saveVirtualEventSessionAction.complete.toString(): {
      const errorFields = { ...state.errorFields };
      action.data.field.forEach((field) => delete errorFields[field]);
      return {
        ...state,
        ...action.data,
        sessionUpdated: true,
        errorInSave: false,
        errorFields
      };
    }
    case saveVirtualEventSessionAction.failed.toString(): {
      const errorFields = { ...state.errorFields };
      action.data.errorField.forEach((errorField) => { errorFields[errorField] = true; });
      return {
        ...state,
        ...action.data,
        sessionUpdated: false,
        errorFields,
        errorInSave: true
      };
    }
    case addPanelistToVirtualEventSessionAction.complete.toString(): {
      return {
        ...state,
        ...action.data,
        sessionUpdated: true
      };
    }
    case addPanelistToVirtualEventSessionAction.failed.toString(): {
      return {
        ...state,
        error: action.message
      };
    }
    case fetchVirtualConferenceAction.toString(): {
      return {
        ...state,
        inSessionConferenceKey: ''
      };
    }
    case fetchVirtualConferenceAction.complete.toString(): {
      let confKey = '';
      if (action.data.inSession) {
        confKey = action.data.webinarKey;
      } else {
        clearLocalStorage(action.data.webinarKey);
      }
      return {
        ...state,
        inSessionConferenceKey: confKey
      };
    }
    case fetchVirtualConferenceAction.failed.toString(): {
      return {
        ...state,
        inSessionConferenceKey: ''
      };
    }
    case updateRecordingAction.toString(): {
      return {
        ...state,
        sessionUpdated: false
      };
    }
    case updateRecordingAction.complete.toString(): {
      return {
        ...state,
        sessionUpdated: true
      };
    }
    case updateRecordingAction.failed.toString(): {
      return {
        ...state,
        sessionUpdated: false
      };
    }
    case setSaveSessionCallStateAction: {
      return {
        ...state,
        sessionUpdated: action.data
      };
    }
    case Constants.CREATE_WEBINAR_SURVEY_COMPLETE:
    case Constants.DELETE_WEBINAR_SURVEY_COMPLETE:
    case SurveyActions.updateSurveyTitle.toString():
    case SurveyActions.updateSurveyQuestion.toString():
    case SurveyActions.addNewSurveyQuestion.toString():
    case SurveyActions.addQuestionToSurvey.toString():
    case PollsActions.updateWebinarPollAction.complete.toString():
    case PollsActions.deleteWebinarPollAction.complete.toString():
    case PollsActions.createWebinarPollAction.complete.toString():
    case HandoutActions.deleteHandoutAction.complete.toString():
    case HandoutActions.uploadToS3Action.complete.toString():
    case VideoActions.uploadVideoAction.complete.toString():
    case VideoActions.updateVideoAction.complete.toString():
    case VideoActions.deleteVideoAction.complete.toString():
    case VideoActions.uploadYouTubeLinkAction.complete.toString(): {
      return {
        ...state,
        sessionUpdated: true
      };
    }
    case setDeleteSessionCallStateAction: {
      return {
        ...state,
        deleteSessionCompleted: action.data
      };
    }

    case setFetchSessionCallStateAction: {
      return {
        ...state,
        inSessionConferenceKey: ''
      };
    }
    case deleteVirtualEventSessionAction.toString(): {
      return {
        ...state,
        deleteSessionCompleted: false
      };
    }

    case replaceSpeakerAction.complete.toString():
    case deleteSpeakerAction.complete.toString(): {
      return {
        ...state,
        speakerUpdated: true,
        sessionUpdated: true
      };
    }

    case updateDeleteSpeakerFlagAction: {
      return {
        ...state,
        speakerUpdated: action.data
      };
    }

    case deleteVirtualEventSessionAction.complete.toString(): {
      return {
        ...state,
        ...action.data,
        deleteSessionCompleted: true
      };
    }
    case deleteVirtualEventSessionAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        deleteSessionCompleted: false
      };
    }
    case setCreateSesssionErrorStateAction: {
      return {
        ...state,
        errorInSessionCreation: action.data
      };
    }
    case clearErrorFieldsAction: {
      return {
        ...state,
        errorFields: {},
        speakerDeleted: false
      };
    }
    case resetVirtualEventSessionAction: {
      const updatedState = { ...state };
      delete updatedState.conferenceKey;
      return updatedState;
    }
    default: {
      return state;
    }
  }
};
