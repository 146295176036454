import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { SEND_NOTIFICATION } from '../Notification/actions';
import * as webinarService from '../../Api/Webinar/webinarService';
import { updateOrganizerWebinarSettingsAction, updateOrganizerAudioSettingsAction, getOrganizerAudioSettingsAction } from './actions';

function* updateOrganizerWebinarSettings({ accountKey, userKey, dataToBeUpdated }) {
  try {
    yield call(webinarService.updateOrganizerWebinarSettings, accountKey, userKey, dataToBeUpdated);
    yield put({ type: updateOrganizerWebinarSettingsAction.complete.toString(), data: dataToBeUpdated });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.settings.updatedSuccessfully' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: updateOrganizerWebinarSettingsAction.failed.toString(),
      message: e.message
    });
  }
}

function* updateOrganizerAudioSettings({ audioDetails }) {
  try {
    yield call(webinarService.updateOrganizerAudioSettings, audioDetails);
    yield put({ type: updateOrganizerAudioSettingsAction.complete.toString(), audioDetails });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.settings.updatedSuccessfully' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: updateOrganizerAudioSettingsAction.failed.toString(),
      message: e.message
    });
  }
}

function* getOrganizerAudioSettings() {
  try {
    const data = yield call(webinarService.getOrganizerAudioSettings);
    yield put({ type: getOrganizerAudioSettingsAction.complete.toString(), data });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: getOrganizerAudioSettingsAction.failed.toString(),
      message: e.message
    });
  }
}

export function *watchUpdateOrganizerWebinarSettings() {
  yield takeLatest(updateOrganizerWebinarSettingsAction.toString(), updateOrganizerWebinarSettings);
}

export function *watchUpdateOrganizerAudioSettings() {
  yield takeLatest(updateOrganizerAudioSettingsAction.toString(), updateOrganizerAudioSettings);
}

export function* watchGetOrganizerAudioSettings() {
  yield takeLatest(getOrganizerAudioSettingsAction.toString(), getOrganizerAudioSettings);
}

export default function* rootSaga() {
  yield all([
    fork(watchUpdateOrganizerWebinarSettings),
    fork(watchUpdateOrganizerAudioSettings),
    fork(watchGetOrganizerAudioSettings)
  ]);
}
