let _webinarKey = null;
let _webinarName = null;
export default class Webinar {
  static set webinarKey(webinarKey) {
    _webinarKey = webinarKey;
  }

  static set webinarName(webinarName) {
    _webinarName = webinarName;
  }

  static get webinarKey() {
    return _webinarKey;
  }

  static get webinarName() {
    return _webinarName;
  }
}
