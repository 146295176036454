import { createAsyncAction } from '../reduxActions';

export const createVirtualEventSessionAction = createAsyncAction('CREATE_VIRTUAL_EVENT_SESSION');
export const saveVirtualEventSessionAction = createAsyncAction('UPDATE_VIRTUAL_EVENT_SESSION');
export const setCreateSesssionErrorStateAction = 'SET_CREATE_SESSION_ERROR_STATE';
export const deleteVirtualEventSessionAction = createAsyncAction('DELETE_VIRTUAL_EVENT_SESSION');
export const clearErrorFieldsAction = 'CLEAR_ERROR_FIELDS';
export const addPanelistToVirtualEventSessionAction = createAsyncAction('ADD_PANELIST_VIRTUAL_EVENT_SESSION');
export const deleteSpeakerAction = createAsyncAction('DELETE_SPEAKER_ACTION');
export const replaceSpeakerAction = createAsyncAction('REPLACE_SPEAKER_ACTION');
export const updateDeleteSpeakerFlagAction = 'UPDATE_DELETE_SPEAKER_FLAG';
export const setSaveSessionCallStateAction = 'SET_SAVE_SESSION_CALL_STATE';
export const setDeleteSessionCallStateAction = 'SET_DELETE_SESSION_CALL_STATE';
export const fetchVirtualConferenceAction = createAsyncAction('FETCH_CONFERENCE');
export const setFetchSessionCallStateAction = 'SET_FETCH_SESSION_CALL_STATE';
export const resetVirtualEventSessionAction = 'RESET_VIRTUAL_EVENT_SESSION';
export const updateRecordingAction = createAsyncAction('UPDATE_RECORDING');
export const resendSpeakerInvitationAction = createAsyncAction('RESEND_SPEAKER_INVITATION');

export function createVirtualEventSession(eventKey, sessionDetails) {
  return { type: createVirtualEventSessionAction.toString(), eventKey, sessionDetails };
}

export function saveVirtualEventSession(eventKey, conferenceKey, sessionDetails) {
  return { type: saveVirtualEventSessionAction.toString(), eventKey, conferenceKey, sessionDetails };
}

export function deleteVirtualEventSession(eventKey, conferenceKey) {
  return { type: deleteVirtualEventSessionAction.toString(), eventKey, conferenceKey };
}

export function addPanelistToVirtualEventSession(eventKey, conferenceKey, panelistId, addJoinLink) {
  return { type: addPanelistToVirtualEventSessionAction.toString(), eventKey, conferenceKey, panelistId, addJoinLink };
}

export function deleteSpeaker(eventKey, conferenceKey, speakerId) {
  return { type: deleteSpeakerAction.toString(), eventKey, conferenceKey, speakerId };
}

export function replaceSpeaker(eventKey, conferenceKey, speakerId, panelistId) {
  return { type: replaceSpeakerAction.toString(), eventKey, conferenceKey, speakerId, panelistId };
}

export function updateRecording(eventKey, conferenceKey, sessionDetails) {
  return { type: updateRecordingAction.toString(), eventKey, conferenceKey, sessionDetails };
}

export function updateDeleteSpeakerFlag(data) {
  return { type: updateDeleteSpeakerFlagAction, data };
}

export function fetchVirtualConference(conferenceKey) {
  return { type: fetchVirtualConferenceAction.toString(), conferenceKey };
}

export function setCreateSessionErrorState(state) {
  return { type: setCreateSesssionErrorStateAction, state };
}

export function setSaveSessionCallState(data) {
  return { type: setSaveSessionCallStateAction, data };
}

export function setDeleteSessionCallState(state) {
  return { type: setDeleteSessionCallStateAction, state };
}

export function setFetchSessionCallState(state) {
  return { type: setFetchSessionCallStateAction, state };
}

export function clearErrorFields() {
  return { type: clearErrorFieldsAction };
}

export function resetVirtualEventSession() {
  return { type: resetVirtualEventSessionAction };
}

export const showSessionUpdateNotificationAction = 'VIRTUAL_EVENT_SESSION_UPDATE_NOTIFICATION';
export function showSessionUpdateNotification(message) {
  return { type: showSessionUpdateNotificationAction, message };
}

export function resendSpeakerInvitation(eventKey, speakerId, speakerName, conferenceKey) {
  return { type: resendSpeakerInvitationAction.toString(), eventKey, speakerId, speakerName, conferenceKey };
}
