import MixpanelTracker from 'Mixpanel/mixpanelTracker';
import { EVENT_DETAILS, ENGAGEMENT } from 'Mixpanel/mixpanelConstants';
import QuestionType from 'Api/Webinar/Survey/Model/questionType';
import Action from '../../../Model/action';

const mixpanelTracker = new MixpanelTracker();

const mixpanelQuestionTypeByQuestionType = new Map();
mixpanelQuestionTypeByQuestionType.set(QuestionType.SINGLE_CHOICE, 'Multiple Choice One Answer');
mixpanelQuestionTypeByQuestionType.set(QuestionType.MULTIPLE_CHOICE, 'Multiple Choice Multiple Answer');
mixpanelQuestionTypeByQuestionType.set(QuestionType.RATING, 'Rate');
mixpanelQuestionTypeByQuestionType.set(QuestionType.SHORT_ANSWER, 'Short Answer');

export default function trackEngagement(feature = '', attribute = '', action, questionType) {
  const properties = {
    Type: ENGAGEMENT,
    Feature: feature
  };
  if (attribute) {
    properties.Attribute = attribute;
  }
  if (Action.values().includes(action)) {
    properties.Action = action;
  }
  const mixpanelQuestionType = mixpanelQuestionTypeByQuestionType.get(questionType);
  if (mixpanelQuestionType) {
    properties['Question Type'] = mixpanelQuestionType;
  }
  mixpanelTracker.setEventName(EVENT_DETAILS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}
