import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';

class AddButton extends Component {
  static propTypes = {
    className: string,
    featureLocked: bool,
    hidden: bool,
    id: string.isRequired,
    isDisabled: bool,
    onClick: func,
    showIcon: bool,
    title: string.isRequired
  };

  static defaultProps = {
    className: '',
    isDisabled: false,
    showIcon: true
  };

  render() {
    const {
      className,
      featureLocked,
      hidden,
      id,
      isDisabled,
      onClick,
      showIcon,
      title
    } = this.props;

    return (
      <button
        className={classNames(
          { [styles.addButtonLocked]: featureLocked || isDisabled },
          { [styles.addButton]: !featureLocked },
          className
        )}
        disabled={isDisabled}
        hidden={hidden}
        id={id}slot
        onClick={onClick}
      >
        {showIcon && (
          <i className={classNames('togo-icon', 'togo-icon-add', styles.addIcon)} />
        )}

        <span className={styles.title}>
          {title}
        </span>

        {featureLocked && (
          <i id="add_button_lock_icon" className={classNames(styles.closedIcon, 'togo-icon togo-icon-lock-closed')} />
        )}
      </button>
    );
  }
}

export default AddButton;
