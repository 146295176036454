import { createAsyncAction, createAction } from 'redux-promise-middleware-actions';
import * as stageService from 'Api/GoToStage/contentService';
import * as channelService from 'Api/GoToStage/channelService';

export const getUserChannelsAction = createAsyncAction('GET_USER_CHANNELS', channelService.getChannels);
export const deleteChannelAction = createAsyncAction('DELETE_CHANNEL', channelService.deleteChannel);
export const getChannelUserSettingsAction = createAsyncAction('GET_CHANNEL_USER_SETTINGS', channelService.getUserSettings);
export const getChannelContentsAction = createAsyncAction('GET_CHANNEL_CONTENTS', channelService.getChannelContents, (channelKey) => ({ channelKey }));
export const getChannelForContentsAction = createAsyncAction('GET_CHANNEL_FOR_CONTENT', stageService.getContentChannel);
export const updateShowNewVideoAlertAction = createAction('UPDATE_SHOW_NEW_VIDEO_ALERT', (showAlert) => showAlert);
export const createAndUpdateChannelAction = createAsyncAction('CREATE_AND_UPDATE_CHANNEL', channelService.createAndUpdateChannel, (userKey, title, description, isLimitedAudience) => ({ title, description, isLimitedAudience }));
export const publishVideoToChannelAction = createAsyncAction('PUBLISH_VIDEO_TO_CHANNEL', async (channelKey, recording, attributes) => {
  const result = await channelService.publishVideoToChannel(channelKey, recording, attributes);
  return {
    contents: result.success && result.success.contents,
    recordingKey: recording.recordingKey
  };
});
export const getContentKeyForWebinarAction = createAsyncAction('GET_CONTENT_FOR_WEBINAR', stageService.getContentKeyForWebinar);
export const getChannelForContentKeyAction = createAsyncAction('GET_CHANNEL_FOR_WEBINAR_CONTENT', channelService.getChannelProfile);
export const updateChannelAssociationAction = createAsyncAction('UPDATE_CHANNEL_FOR_WEBINAR', stageService.updateContent, (contentKey, content) => ({ contentKey, content }));
export const addContentToChannelAction = createAsyncAction('PUT_CONTENT_TO_CHANNEL', stageService.addContentToChannel, (contentKey, content) => ({ contentKey, content }));
export const addWebinarToChannelAction = createAsyncAction('POST_WEBINAR_TO_CHANNEL', stageService.addWebinarToChannel, (channelKey, webinarIdentifier) => ({ channelKey, webinarIdentifier }));
