import errors from 'Api/Helpers/apiErrors.json';
import { put, all, fork, takeLatest, call } from 'redux-saga/effects';
import { SEND_NOTIFICATION } from 'Reducks/Notification/actions';
import * as VirtualWebinarService from 'Api/VirtualEvent/Events/ScheduleWebinarService';
import { trackVirtualEventCreated } from 'Mixpanel/Features/VirtualEvents/VirtualEventTracker';
import { getVirtualEventDetails } from '../../Api/VirtualEvent/VirtualEventDetails/VirtualEventDetailsService';
import { scheduleVirtualEventAction } from './actions';
import { updateOrganizerToVirtualEventAction } from '../VirtualEventDetails/actions';

export function* postScheduleVirtualEvent({ organizerKey, eventData, noOfDays, copiedWebinarKey, isOrganizerPanelist }) {
  try {
    let response;
    if (copiedWebinarKey) {
      const copiedData = yield call(getVirtualEventDetails, copiedWebinarKey);

      if (!eventData.organizers) {
        eventData.organizers = [];
      }
      const data = Object.assign(copiedData, eventData);
      delete data.ownerKey;
      delete data.questionnaireKey;
      delete data.eventKey;
      delete data.conferences;
      response = yield call(VirtualWebinarService.copyVirtualWebinar, copiedWebinarKey, data);
    } else {
      response = yield call(VirtualWebinarService.scheduleVirtualWebinar, organizerKey, eventData);
    }
    const eventKey = response.id;
    eventData.type = 'Virtual';
    if (isOrganizerPanelist) {
      yield put({ type: updateOrganizerToVirtualEventAction.toString(), eventKey, organizerKey, fieldName: '/displayPanelistInfo', data: true, updateType: 'replace' });
    }
    yield put({ type: scheduleVirtualEventAction.complete.toString(), eventKey, eventData });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.eventCreated', isError: false });
    trackVirtualEventCreated({ ...eventData, noOfDays, isExperienceTypeCopied: !!copiedWebinarKey });
  } catch (e) {
    yield put({ type: scheduleVirtualEventAction.failed.toString(), errorMessage: e.message });
    if (e.body.errorCode === errors.api.schedule.maintenanceErrorCode) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maintenanceWindowFailure', isError: true, timeOut: 7000 });
      return;
    }
    if (e.status === 400) {
      switch (e.body.message) {
        case errors.api.schedule.recurrenceTimeInPast:
        case errors.api.schedule.timeInPast:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
          break;
        case errors.api.schedule.endBeforeStart:
        case errors.api.schedule.recurrenceEndBeforeStart:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.endTimeAfterStartTimeFail', isError: true });
          break;
        case errors.api.schedule.overYearAhead:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.startTimeYearAheadFail', isError: true });
          break;
        case errors.api.schedule.invalidSubject:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidTitleFail', isError: true });
          break;
        case errors.api.schedule.maximumAllowedEvent:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maximumUpcomingEventsAllowed', isError: true });
          break;
        case errors.api.schedule.invalidCreateEventRequest:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
          break;
        default:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.genericFail', isError: true });
      }
    } else if (e.message === errors.api.audio.customAudioNotAllowed) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.customAudioNotAllowed', isError: true });
    } else if (e.message === errors.api.audio.voipNotAllowed) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.voipNotAllowed', isError: true });
    } else if (e.body.message === errors.api.schedule.invalidEventTimes && e.status === 500) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
    } else {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.genericFail', isError: true });
    }
  }
}

// ------------------------------------ WATCHERS -----------------------------------
export function* watchScheduleVirtualEvent() {
  yield takeLatest(scheduleVirtualEventAction.toString(), postScheduleVirtualEvent);
}

export default function* rootSaga() {
  yield all([fork(watchScheduleVirtualEvent)]);
}
