/* eslint-disable key-spacing */
import React from 'react';
import isEqual from 'lodash.isequal';
import { ResponsiveModal, AlertDialog } from '@getgo/chameleon-react';
import { array, bool, func, object, string } from 'prop-types';

import AddButton from '../../Components/Card/SharedComponents/AddButton';
import NewQuestion from './NewQuestion/NewQuestion';
import Settings from './Settings/Settings';
import SurveyPreview from './Preview/Preview';
import QuestionsList from './QuestionsList/QuestionsList';
import SurveyTitle from './SurveyTitle/SurveyTitle';
import validate from './Validation';
import { createShortId } from '../../Utils/randomUuidGenerator';
import { createQuestion } from '../../Reducks/Survey/reducer';

class Survey extends React.Component {
  static propTypes = {
    activeChoices: object,
    activeQuestionKey: string,
    addNewSurveyQuestion: func.isRequired,
    addQuestionToSurvey: func.isRequired,
    addSurveyChoice: func.isRequired,
    choices: array.isRequired,
    close: func.isRequired,
    createWebinarSurvey: func.isRequired,
    deleteWebinarSurvey: func.isRequired,
    dismissActiveQuestion: func.isRequired,
    doesQuestionExist: bool,
    invalidateQuestion: func.isRequired,
    invalidFields: object,
    isSaveDisabled: bool,
    isSurveyFound: bool,
    moveSurveyQuestion: func.isRequired,
    question: object,
    removeSurveyChoice: func.isRequired,
    removeSurveyQuestion: func.isRequired,
    setActiveSurveyQuestion: func.isRequired,
    settings: object,
    showAddQuestionBtn: bool,
    survey: object.isRequired,
    surveyQuestions: array.isRequired,
    t: func.isRequired,
    title: string,
    updateSurveyChoice: func.isRequired,
    updateSurveyQuestion: func.isRequired,
    updateSurveySettings: func.isRequired,
    updateSurveyTitle: func.isRequired,
    webinarKey: string,
    id: string
  };

  state = {
    showSurveyPreview: false,
    initialSurvey: this.props.survey,
    warningAlert: null
  }

  setCloseWarningAlert = () => {
    const { t, survey } = this.props;
    const { initialSurvey } = this.state;

    if (isEqual(survey, initialSurvey) && !this.props.activeQuestionKey) {
      return this.props.close();
    }

    const warningAlert = {
      title: t('survey.warning.close.title'),
      type: 'warning',
      message: t('survey.warning.close.message'),
      buttons: [
        { title: t('survey.warning.close.closeSurvey'), onClickHandler: this.props.close },
        { title: t('button.cancel'), onClickHandler: this.dismissAlert }
      ]
    };

    return this.setState({ warningAlert });
  }

  setDeleteWarningAlert = () => {
    const { t } = this.props;
    const warningAlert = {
      title: t('survey.warning.delete.title'),
      type: 'warning',
      message: t('survey.warning.delete.message'),
      buttons: [
        { title: t('survey.warning.delete.deleteSurvey'), onClickHandler: this.deleteSurvey },
        { title: t('button.cancel'), onClickHandler: this.dismissAlert }
      ]
    };
    this.setState({ warningAlert });
  }

  createWebinarSurvey = () => {
    this.props.createWebinarSurvey(this.props.webinarKey, this.props.survey);
    this.props.close();
  }

  dismissAlert = () => {
    if (this.state.warningAlert) {
      this.setState({ warningAlert: null });
    }
  }

  deleteSurvey = () => {
    this.props.deleteWebinarSurvey(this.props.webinarKey);
    this.dismissAlert();
    this.props.close();
  }

  onPreviewSurvey = () => {
    this.setState({ showSurveyPreview: !this.state.showSurveyPreview });
  }

  addNewQuestion = () => {
    const questionKey = createShortId();
    const newQuestion = createQuestion({ questionKey });
    const [firstId, secondId] = newQuestion.choices;
    const newChoioces = {
      [firstId]: { answerKey: firstId, answerText: '' },
      [secondId]: { answerKey: secondId, answerText: '' }
    };
    this.props.setActiveSurveyQuestion(questionKey, newQuestion, newChoioces);
  }

  addQuestionToSurvey = () => {
    this.props.addQuestionToSurvey(
      this.props.question,
      this.props.activeChoices,
      this.props.question.questionKey
    );
  }

  maybeAddQuestion = () => {
    const { question, choices } = this.props;
    const errors = validate(question, choices);

    if (errors) {
      this.props.invalidateQuestion(errors);
    } else {
      this.addQuestionToSurvey();
    }
  }

  getPrimaryModalBtn = () => {
    const { doesQuestionExist, isSaveDisabled, activeQuestionKey, t } = this.props;

    if (activeQuestionKey) {
      return {
        id: 'tc_save_question_button',
        onClick: this.maybeAddQuestion,
        text: doesQuestionExist
          ? t('button.update')
          : t('survey.questions.addToSurvey')
      };
    }

    return {
      disabled: isSaveDisabled,
      id: 'tc_save_survey_button',
      onClick: this.createWebinarSurvey,
      text: t('button.save')
    };
  }

  getSecondaryModalBtns = () => {
    const { t, doesQuestionExist, dismissActiveQuestion, activeQuestionKey, isSurveyFound } = this.props;

    if (activeQuestionKey) {
      return {
        onClick: dismissActiveQuestion,
        text: doesQuestionExist ? t('button.cancel') : t('button.remove')
      };
    }

    const previewBtn = {
      id: 'survey_preview',
      onClick: this.onPreviewSurvey,
      text: this.state.showSurveyPreview ? t('survey.preview.closePreview') : t('button.preview')
    };

    const deleteBtn = {
      id: 'survey_delete_survey_trash_button',
      onClick: this.setDeleteWarningAlert,
      text: t('button.delete')
    };

    const surveyBtns = isSurveyFound
      ? [previewBtn, deleteBtn]
      : [previewBtn];

    return surveyBtns;
  }

  render() {
    const { showSurveyPreview, warningAlert } = this.state;
    const { t, activeQuestionKey, showAddQuestionBtn } = this.props;

    const primaryActionButton = this.getPrimaryModalBtn();
    const secondaryActionButton = this.getSecondaryModalBtns();

    let modalTitle = t('survey.title');

    if (showSurveyPreview) {
      modalTitle = t('survey.preview.title');
    }

    if (activeQuestionKey) {
      modalTitle = t('survey.questions.moveQuestions.editQuestion');
    }

    return (
      <ResponsiveModal
        isOpen
        onClose={this.setCloseWarningAlert}
        primaryActionButton={primaryActionButton}
        title={modalTitle}
        titleId="survey_window_title"
        secondaryActionButton={secondaryActionButton}
      >
        <AlertDialog
          alertMessage={warningAlert ? warningAlert.message : ''}
          alertTitle={warningAlert ? warningAlert.title : ''}
          alertType={warningAlert ? warningAlert.type : 'warning'}
          buttons={warningAlert ? warningAlert.buttons : []}
          isOpen={warningAlert}
        />

        <div id="survey_window">
          {showSurveyPreview ? (
            <SurveyPreview
              questions={this.props.survey.questions}
              title={this.props.title}
            />
          ) : (
            <>
              {!activeQuestionKey && (<SurveyTitle
                id={this.props.id}
                title={this.props.title}
                updateSurveyTitle={this.props.updateSurveyTitle}
              />)}

              {!activeQuestionKey && (
                <QuestionsList
                  moveSurveyQuestion={this.props.moveSurveyQuestion}
                  questions={this.props.surveyQuestions}
                  removeSurveyQuestion={this.props.removeSurveyQuestion}
                  setActiveSurveyQuestion={this.props.setActiveSurveyQuestion}
                  updateSurveyQuestion={this.props.updateSurveyQuestion}
                />)}

              {activeQuestionKey && (
                <NewQuestion
                  addSurveyChoice={this.props.addSurveyChoice}
                  choices={this.props.choices}
                  question={this.props.question}
                  removeSurveyChoice={this.props.removeSurveyChoice}
                  updateSurveyChoice={this.props.updateSurveyChoice}
                  updateSurveyQuestion={this.props.updateSurveyQuestion}
                  setActiveSurveyQuestion={this.props.setActiveSurveyQuestion}
                  invalidFields={this.props.invalidFields}
                />
              )}

              {showAddQuestionBtn && (<div>
                <AddButton
                  id="tc_add_new_question"
                  onClick={this.addNewQuestion}
                  title={t('survey.questions.addNewQuestion')}
                />
              </div>
              )}

              {!activeQuestionKey && (
                <Settings
                  onSettingsChange={this.props.updateSurveySettings}
                  surveySettings={this.props.settings}
                />
              )}
            </>
          )}
        </div>
      </ResponsiveModal>
    );
  }
}

export default Survey;
