
import { all, fork, call, put } from 'redux-saga/effects';
import { t } from 'i18next';
import { delay } from 'redux-saga';
import { takeEveryAction } from 'Utils/sagaUtils';
import { SEND_NOTIFICATION } from 'Reducks/Notification/actions';
import * as reportingService from 'Api/reportingService';
import ReportStatus from '../../../Reporting/status';
import { getRecordingReportAction } from './actionTypes';

function* getRecordingReportFailure(error) {
  const emailMsg = error.emailMsg || '';
  yield put({
    type: getRecordingReportAction.failed.toString(),
    error
  });
  yield put({
    type: SEND_NOTIFICATION,
    messageKey: 'cards.videoLibrary.videoReport.notification.downloadReportFailed',
    isError: true,
    messageKeyParams: [{ emailMsg }]
  });
}

function* getFileUrl(jobId, retryCount = 10) {
  if (retryCount < 0) {
    return null;
  }
  yield delay(1000);

  const jobResponse = yield call(reportingService.checkJobStatus, jobId, retryCount === 0);
  switch (jobResponse.status) {
    case ReportStatus.STARTED:
    case ReportStatus.SUBMITTED:
    case ReportStatus.FAILED: {
      return yield getFileUrl(jobId, retryCount - 1);
    }
    case ReportStatus.COMPLETED:
      return JSON.parse(jobResponse.output).url;
    default:
      return null;
  }
}

function* getRecordingReport({
  recordingKey, format, webinarLocale, timeZone
}) {
  try {
    const data = yield call(reportingService.getRecordingReport, webinarLocale, recordingKey, format, timeZone);
    const jobId = data.id;

    const fileUrl = yield getFileUrl(jobId);
    if (!fileUrl) {
      const error = new Error('Unable to download file. Sending the report by email.');
      error.emailMsg = t('cards.videoLibrary.videoReport.notification.emailMessage');
      throw error;
    }
    yield put({ type: getRecordingReportAction.complete.toString(), redirectUrl: fileUrl });
    yield put({
      type: SEND_NOTIFICATION,
      messageKey: 'cards.videoLibrary.videoReport.notification.downloadReportSuccessFul',
      isError: false
    });
  } catch (error) {
    yield getRecordingReportFailure(error);
  }
}

export default function * rootSaga() {
  yield all([
    fork(takeEveryAction.bind(this, getRecordingReportAction.toString(), getRecordingReport))
  ]);
}
