import config from 'appConfig';
import { getAuth } from 'Api';
import * as httpMethods from 'Api/Helpers/httpMethods';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from 'Api/Helpers/fetch';

const URL_PREFIX = '/api/webinars/';
const URL_POLLS_POSTFIX = '/polls';

function getHeaders(needsAuth) {
  const header = { 'Content-Type': 'application/json' };
  if (needsAuth) {
    header.Authorization = `Token ${getAuth().access_token}`;
  }
  return header;
}

export function retrieveWebinarPoll(webinarKey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_POLLS_POSTFIX}`;
  const options = {
    headers: getHeaders(true)
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function createWebinarPoll(webinarKey, poll) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_POLLS_POSTFIX}`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.POST,
    body: JSON.stringify(poll)
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function deleteWebinarPoll(webinarKey, pollKey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_POLLS_POSTFIX}/${pollKey}`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.DELETE
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function updateWebinarPoll(webinarKey, pollKey, poll) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_POLLS_POSTFIX}/${pollKey}`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.PUT,
    body: JSON.stringify(poll)
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function updateWebinarPollOrder(webinarKey, pollQuestions) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_POLLS_POSTFIX}`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.PUT,
    body: JSON.stringify(pollQuestions)
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}
