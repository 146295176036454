import MixpanelTracker from 'Mixpanel/mixpanelTracker';
import { EVENT_DETAILS, LIVE_STREAMING } from 'Mixpanel/mixpanelConstants';
import Action from 'Mixpanel/Model/action';

const mixpanelTracker = new MixpanelTracker();

export const trackConfigureLiveStreamChannel = (Channel) => {
  const properties = {
    Type: LIVE_STREAMING,
    Action: Action.CONFIGURE_LIVE_STREAM_CHANNEL,
    Channel
  };
  mixpanelTracker.setEventName(EVENT_DETAILS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
};

export const trackStartLiveStreamBroadcast = (Channel) => {
  const properties = {
    Type: LIVE_STREAMING,
    Action: Action.START_LIVE_STREAM_BROADCAST,
    Channel
  };
  mixpanelTracker.setEventName(EVENT_DETAILS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
};
