import React, { useEffect } from 'react';
import { func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PollQuestions from '../../Poll/PollQuestions';
import { retrieveWebinarPoll } from '../../../Reducks/Poll/actions';

const NewSessionPoll = ({ webinarKey, close, ...props }) => {
  const dispatch = useDispatch();
  const polls = useSelector((state) => state.poll?.webinarPoll?.obj ?? []);
  useEffect(() => {
    dispatch(retrieveWebinarPoll(webinarKey));
  }, [dispatch, webinarKey]);

  return <PollQuestions onCancel={close} webinarKey={webinarKey} polls={polls} {...props} />;
};

NewSessionPoll.propTypes = {
  webinarKey: string,
  close: func.isRequired
};

export default NewSessionPoll;
