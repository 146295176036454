import React, { Fragment } from 'react';
import classnames from 'classnames';
import { array, func, object, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import FloatingLabel from '@getgo/chameleon-react/dist/FloatingLabel';

import { anyCharRegex } from '../../../../Utils/formats';
import AddButton from '../../../../Components/Card/SharedComponents/AddButton/index';
import styles from './MultipleChoice.scss';

const CHOICES_MAX = 8;
const CHOICES_MIN = 2;

const MultipleChoice = ({
  addSurveyChoice,
  choices,
  icon,
  question,
  removeSurveyChoice,
  updateSurveyChoice,
  invalidFields
}) => {
  const { t } = useTranslation();

  const showTrashIcon = choices.length > CHOICES_MIN;
  const showAddChoice = choices.length < CHOICES_MAX;

  return (
    <Fragment>
      {choices.map((choice, idx) => {
        const updateChoiceText = (text) => updateSurveyChoice(choice.answerKey, text);
        const removeChoice = () => removeSurveyChoice(choice.answerKey, question.questionKey);
        const isInvalid = invalidFields && invalidFields[choice.answerKey] && (idx < 2);

        return (
          <div className={styles.choiceWrapper} key={idx}>
            <div className={styles.choice}>
              {icon && (
                <img
                  alt=""
                  className={styles.icon}
                  role="presentation"
                  src={icon}
                />
              )}

              <FloatingLabel
                charLimitText={t('survey.charLimit')}
                containerClassNames={classnames(styles.floatingLabel, isInvalid && styles.error)}
                floatLabel={false}
                form
                id={`tc_question_choice_${idx}`}
                maxLength={128}
                onChange={updateChoiceText}
                pattern={anyCharRegex}
                placeholder={`${t('survey.questions.choices.options.placeholder')} ${idx + 1}`}
                showAnimatedBar={!isInvalid}
                value={choice.answerText}
              />

              {showTrashIcon && (
                <i
                  className={classnames(styles.trash, 'togo-icon togo-icon-trash')}
                  onClick={removeChoice}
                  onKeyUp={removeChoice}
                  role="button"
                  tabIndex="0"
                />
              )}
            </div>

            <span className={styles.required}>
              {isInvalid && t('survey.questions.requiredField')}
            </span>
          </div>
        );
      })}

      {showAddChoice && (
        <div className={styles.addBtn}>
          <AddButton
            title={t('survey.questions.choices.options.addOption')}
            onClick={addSurveyChoice}
          />
        </div>
      )}
    </Fragment>
  );
};

MultipleChoice.propTypes = {
  addSurveyChoice: func.isRequired,
  choices: array,
  icon: string,
  invalidFields: object,
  question: shape({
    questionKey: string
  }).isRequired,
  removeSurveyChoice: func.isRequired,
  updateSurveyChoice: func.isRequired
};

export default MultipleChoice;
