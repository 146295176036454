import React from 'react';
import { func } from 'prop-types';
import { ResponsiveModal } from '@getgo/chameleon-react';
import { withTranslation } from 'react-i18next';
import { history } from '../../../../Store/configureStore';
import { toggleModal } from '../../../../Reducks/Ui/actions';
import { bound } from '../../../../Utils/decorators';
import processingGIF from './assets/processing.gif';
import styles from './styles.scss';

class PostActionPage extends React.Component {
  static propTypes = {
    dispatch: func.isRequired,
    t: func
  }

  @bound
  navigateToVideoLibrary() {
    history.push({ pathname: '/contentLibrary', state: { reload: true } });
    this.props.dispatch(toggleModal());
  }

  render() {
    const { t } = this.props;
    const primaryActionButton = {
      text: t('button.okay'),
      disabled: false,
      onClick: this.navigateToVideoLibrary
    };

    return (
      <ResponsiveModal
        isOpen
        onClose={this.navigateToVideoLibrary}
        title={t('modals.generateAudioWaveform.postActionPage.title')}
        primaryActionButton={primaryActionButton}>
        <div className={styles.postActionPage}>
          <span>{t('modals.generateAudioWaveform.postActionPage.body')}</span>
          <img alt='' role='presentation' src={processingGIF} />
        </div>
      </ResponsiveModal>
    );
  }
}

export default withTranslation()(PostActionPage);
