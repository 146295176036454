import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FloatingLabel from '@getgo/chameleon-react/dist/FloatingLabel';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { domainNameRegex } from 'Utils/formats';
import styles from './styles.scss';

class DomainInput extends Component {
  static propTypes = {
    domainName: PropTypes.string,
    domainIndex: PropTypes.number,
    hasError: PropTypes.bool,
    onDomainUpdated: PropTypes.func,
    onDomainDeleted: PropTypes.func,
    t: PropTypes.func
  };

  static defaultProps = {
    domainName: ''
  };

  constructor(props) {
    super(props);
    this.state = { domainName: props.domainName, hasError: false };
  }

  componentDidUpdate(prevProps) {
    const newHasError = this.props.hasError;
    const oldHasError = prevProps.hasError;
    if (newHasError !== oldHasError && newHasError === true) {
      this.setState({ hasError: true });
    }
  }

  onDomainChanged = (domainName) => {
    const hasError = !domainNameRegex.test(domainName);
    this.setState(
      { hasError, domainName },
      () => this.props.onDomainUpdated(this.state.domainName, this.props.domainIndex, this.state.hasError)
    );
  };

  render() {
    const { t, domainIndex, onDomainDeleted } = this.props;
    const { domainName, hasError } = this.state;
    return (
      <div className={classNames(styles.inputContainer, styles.row)} id={`domain_input_domain_count_${domainIndex}`}>
        <FloatingLabel
          value={domainName}
          id='domain_input'
          placeholder={t('cards.webinar.details.registration.restrictDomainsModal.domainInput.placeholder')}
          maxLength={256}
          onChange={this.onDomainChanged}
          containerClassNames={styles.floatingLabel}
          customError={hasError}
        />
        <button id='domain_input_delete' aria-label={t('cards.webinar.details.registration.restrictDomainsModal.domainInput.deleteAriaLabel')} onClick={onDomainDeleted(domainIndex)}>
          <i className={classNames(styles.deleteIcon, 'togo-icon', 'togo-icon-trash')} />
        </button>
      </div>
    );
  }
}

export default withTranslation()(DomainInput);
