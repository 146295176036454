export const graphqlQuery = (userKey, accountKey, recordingAssetKey) => [
  {
    query: `query searchAssets($offset: Int!, $size: Int!) {\r\nsearchAsset(filter: {\r\naccountKey: "${accountKey}"
          \r\nuserKey: "${userKey}"\r\n\r\nassetType: {in: ["SUMMARY"]}\r\nstatus: {in: ["COMPLETED"]}
          \r\nrecordingAssetKey: {in: ["${recordingAssetKey}"]}\r\n}, offset: $offset, size: $size, \r\n
          sort:{fieldName:createTime, order:DESC}\r\n)\r\n {\r\nassets\r\n{\r\n# Unique identifier of an asset(Mandatory)\r\n
            assetKey\r\n# String representing type of the asset(Mandatory)\r\nassetType\r\n# Asset version(Mandatory)\r\n
            assetVersion\r\n# product(Mandatory)\r\nproduct\r\n# accountKey(Mandatory)\r\naccountKey\r\n# userKey(Mandatory)\r\n
            userKey\r\n# Asset name\r\nname\r\n# asset status(Mandatory)\r\nstatus\r\n# Asset creation time in ISO format(Mandatory)\r\n
            createTime\r\nrequestId\r\n# Asset metadata\r\nmetadata\r\n# Asset tags.\r\ntags\r\n# Resources in the asset.\r\n
            resources {\r\n# Unique id of the resource(Mandatory)\r\nresourceKey\r\n# String representing resource type(Mandatory)\r\n
            resourceType\r\n# Resource tags\r\ntags\r\n# Resource metadata\r\nmetadata\r\n# Resource status\r\nstatus\r\n
            # Resource create time\r\ncreateTime\r\nname\r\nmandatory\r\ncontent {\r\nvalue\r\nlocation\r\ntype\r\nprovider\r\n
            cdnLocation\r\ndownloadLocation\r\n}\r\n}\r\n# highlight will be returned if content search is done\r\nhighlight\r\n
            relatedAssets {\r\n# Unique identifier of an asset(Mandatory)\r\nassetKey\r\n# String representing type of the asset(Mandatory)\r\n
            assetType\r\n# Asset version(Mandatory)\r\nassetVersion\r\n# product(Mandatory)\r\nproduct\r\n# accountKey(Mandatory)\r\n
            accountKey\r\nrequestId\r\n# Asset metadata\r\nmetadata\r\n# Asset tags.\r\ntags\r\n# Resources in the asset.\r\n
            resources {\r\n# Unique id of the resource(Mandatory)\r\nresourceKey\r\n# String representing resource type(Mandatory)\r\n
            resourceType\r\n# Resource tags\r\ntags\r\n# Resource metadata\r\nmetadata\r\n# Resource status\r\nstatus\r\n# Resource create time\r\n
            createTime\r\nname\r\nmandatory\r\ncontent {\r\nvalue\r\nlocation\r\ntype\r\nprovider\r\ncdnLocation\r\ndownloadLocation\r\n}\r\n}\r\n
            highlight\r\n\r\n}\r\n\r\n\r\n}\r\npage\r\n{\r\nnumber\r\nsize\r\ntotalPages\r\n}\r\n }\r\n}`,
    variables: {
      offset: 0,
      size: 100
    }
  }
];
