import appLogger from 'Utils/logging';

const logger = appLogger.create('Notification Helper');

export function requestPermission() {
  logger.debug('Requested permission to send push notifications.');
  return Notification.requestPermission();
}

export function isPermissionGranted(permission) {
  return permission === 'granted';
}

export function isPermissionDenied(permission) {
  return permission === 'denied';
}
export function isWebPushSupported() {
  return (('serviceWorker' in navigator) && ('PushManager' in window));
}
