import React, { useState } from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import editIcon from '../../../Components/Questions/Assets/edit.svg';
import moveDownIcon from '../../../Components/Questions/Assets/moveDown.svg';
import moveUpIcon from '../../../Components/Questions/Assets/moveUp.svg';
import removeIcon from '../../../Components/Questions/Assets/delete.svg';
import styles from './QuestionOptions.scss';

const QuestionOptions = ({
  edit,
  moveDown,
  moveUp,
  remove
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Dropdown
      className={styles.questionOptions}
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle
        color="white"
        className={styles.dropdownToggle}
        id="questions_three_dots"
      >
        <i className="togo-icon togo-icon-three-dots togo-icon-rotate-90" />
      </DropdownToggle>

      <DropdownMenu>
        {moveUp && (
          <DropdownItem id="questions_moveup_dropdown_item" onClick={moveUp}>
            <img
              alt=""
              role="presentation"
              className={styles.dropdownIcon}
              src={moveUpIcon}
            />
            <div className={styles.dropdownText}>
              {t('survey.questions.moveQuestions.moveQuestionUp')}
            </div>
          </DropdownItem>
        )}

        {moveDown && (
          <DropdownItem id="questions_movedown_dropdown_item" onClick={moveDown}>
            <img
              alt=""
              role="presentation"
              className={styles.dropdownIcon}
              src={moveDownIcon}
            />
            <div className={styles.dropdownText}>
              {t('survey.questions.moveQuestions.moveQuestionDown')}
            </div>
          </DropdownItem>
        )}

        <DropdownItem id="questions_edit_dropdown_item" onClick={edit}>
          <img
            alt=""
            role="presentation"
            className={styles.dropdownIcon}
            src={editIcon}
          />
          <div className={styles.dropdownText}>
            {t('survey.questions.moveQuestions.editQuestion')}
          </div>
        </DropdownItem>

        <DropdownItem id="questions_remove_dropdown_item" onClick={remove}>
          <img
            alt=""
            role="presentation"
            className={styles.dropdownIcon}
            src={removeIcon}
          />
          <div className={styles.dropdownText}>
            {t('survey.questions.moveQuestions.removeQuestion')}
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

QuestionOptions.propTypes = {
  edit: func,
  moveDown: func,
  moveUp: func,
  remove: func
};

export default QuestionOptions;
