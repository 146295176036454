import React, { Component } from 'react';
import { any, bool, func, string } from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';

export default class FloatingLabelBackground extends Component {
  static propTypes = {
    children: any,
    className: string,
    disabled: bool,
    expanded: bool,
    id: string,
    isSelected: bool,
    label: string,
    labelClassName: string,
    onClick: func
  };

  static defaultProps = {
    isSelected: false
  };

  onClicked = (event) => {
    const { onClick, disabled } = this.props;
    if (!disabled && onClick) {
      onClick(event);
    }
  };

  render() {
    const {
      id, label, children, className, isSelected, disabled, labelClassName, expanded
    } = this.props;

    return (
      <div>
        <div
          aria-haspopup="true"
          role="button"
          tabIndex="0"
          onKeyPress={this.onClicked}
          aria-expanded={expanded}
          className={classNames(styles.background, className, { [styles.selected]: isSelected, [styles.disabled]: disabled })}
          id={id}
          onClick={this.onClicked}
        >
          {children}
        </div>

        {label && (
          <label htmlFor={id} className={classNames(styles.label, labelClassName)}>
            {label}
          </label>
        )}
      </div>
    );
  }
}
