const reminder = 'reminder';
const confirmation = 'confirmation';
const followUp = 'follow-up';
const replyTo = 'reply-to';

export default class EmailType {
  static get REMINDER() {
    return reminder;
  }

  static get CONFIRMATION() {
    return confirmation;
  }

  static get FOLLOW_UP() {
    return followUp;
  }

  static get REPLY_TO() {
    return replyTo;
  }

  static values() {
    return [reminder, confirmation, followUp, replyTo];
  }
}
