import { getAuth } from 'Api';

export const TOKEN_TYPE = { BEARER: 'Bearer', TOKEN: 'Token' };

export default function getHeaders(needsAuth, authTokenName = TOKEN_TYPE.TOKEN, contentType = 'application/json', pragmaNoCache = false, accountKey, userLocale, noCache = false) {
  return () => {
    const header = contentType ? { 'Content-Type': contentType } : {};
    const auth = getAuth();
    if (needsAuth && auth && auth.access_token) {
      header.Authorization = `${authTokenName} ${auth.access_token}`;
    }
    // Using Pragma only for backwards compatibility with HTTP/1.0 clients and ie11. https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Pragma
    if (pragmaNoCache) {
      header.Pragma = 'no-cache';
    }
    if (accountKey) {
      header['X-ACCOUNTKEY'] = accountKey;
    }
    if (userLocale) {
      header['Accept-Language'] = userLocale;
    }
    if (noCache) {
      header['Cache-Control'] = 'no-cache';
    }
    return header;
  };
}

export function getBearerHeaders(contentType, accountKey, userLocale) {
  return getHeaders(true, TOKEN_TYPE.BEARER, contentType, false, accountKey, userLocale);
}
