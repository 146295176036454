import React, { useEffect, useMemo } from 'react';
import { func, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Handouts from '../../Handouts';
import { getHandoutsConstraints } from '../../../Reducks/Handouts/actions';
import Loading from '../../Common/FullScreenLoader';

const SessionHandout = ({ webinarKey, close, ...props }) => {
  const dispatch = useDispatch();
  const handouts = useSelector(({ handouts: handoutsStore }) => handoutsStore);
  const handoutsLoaded = useMemo(
    () => handouts?.handoutsConstraints?.data?.allowedFileSizeInBytes ?? null,
    [handouts.handoutsConstraints.data.allowedFileSizeInBytes]
  );

  useEffect(() => {
    dispatch(getHandoutsConstraints(webinarKey));
  }, [dispatch, webinarKey]);

  if (!handoutsLoaded) return <Loading showBackground={false} color="blue" />;
  return <Handouts onClose={close} webinarKey={webinarKey} handouts={handouts} {...props} />;
};

SessionHandout.propTypes = {
  webinarKey: string,
  close: func.isRequired
};

export default SessionHandout;
