const createAssetDetails = (recording) => {
  let assetDetails;
  if (recording.status === 'INITIATED') {
    assetDetails = {
      assetKey: recording.assetKey,
      assetVersion: recording.assetVersion
    };
  }

  if (recording.assetVersionDetails && recording.assetVersionDetails.length) {
    const [latest] = recording.assetVersionDetails.sort((a, b) => b.assetVersion - a.assetVersion);

    if (latest.status === 'INITIATED') {
      assetDetails = {
        assetKey: recording.assetKey,
        assetVersion: latest.assetVersion
      };
    }
  }

  return assetDetails;
};

export const getInitiatedAssets = ({ recordings }) => {
  const arr = [];
  for (const recording of recordings.values()) {
    const assetDetails = createAssetDetails(recording);
    if (assetDetails) {
      arr.push(assetDetails);
    }
  }
  return arr;
};

export const getRecordingKeyFromResources = (recording) => {
  if (!recording.resources || !recording.resources.length) {
    return null;
  }

  const recordingResource = recording.resources.find((r) => r.resourceType === 'recording');
  const attribute = recordingResource.attributes.find((a) => a.name === 'recordingkey');

  if (attribute && attribute.value) {
    return attribute.value;
  }

  return null;
};
