/* eslint-disable react/prop-types */
import React from 'react';
import { compose } from 'redux';
import { func, string } from 'prop-types';
import AlertDialog from '@getgo/chameleon-react/dist/AlertDialog';
import ChameleonCSSTransition from '@getgo/chameleon-react/dist/ChameleonCSSTransition';
import { connectDispatch } from '../../Utils/decorators';
import { toggleModal } from '../../Reducks/Ui/actions';
import modals from '../../Components/Modals';

export const MODALS = {
  CREATE_CHANNEL: modals.CreateChannel,
  ADD_VIDEO_TO_CHANNEL: modals.AddVideoToChannel,
  FEATURE_UNLOCK: modals.FeatureUnlock,
  GENERATE_AUDIO_WAVEFORM: modals.GenerateAudioWaveform,
  TRANSCRIPT_PROCESSING: modals.TranscriptProcessing,
  VIDEO_DOWNLOAD_OPTION: modals.VideoDownloadOption,
  SURVEY: modals.Survey,
  ADD_SESSION_SURVEY: modals.NewSessionSurvey,
  ADD_SESSION_HANDOUT: modals.newSessionHandout,
  ADD_SESSION_POLL: modals.NewSessionPoll,
  RESTRICT_DOMAINS: modals.RestrictDomains
};

export const TYPES = {
  ALERT: 'alert'
};

const mapStateToProps = (state) => ({
  ...state.ui.modal
});

const idRequired = (Component) => (props) => {
  if (!props.id && !props.type) return null;
  return <Component { ...props } />;
};

const withModal = (Component) => (props) => {
  if (props.type === TYPES.ALERT) {
    return <Component Modal={AlertDialog} {...props} />;
  }

  const Modal = MODALS[props.id];
  return <Component Modal={Modal} {...props} />;
};

@compose(
  connectDispatch(mapStateToProps),
  idRequired,
  withModal
)
export default class ModalRoot extends React.PureComponent {
  static propTypes = {
    dispatch: func,
    id: string,
    Modal: func
  }

  closeModal = () => this.props.dispatch(toggleModal());

  render() {
    const { Modal, ...props } = this.props;
    const defaultProps = {};

    if (props.id) {
      defaultProps.close = this.closeModal;
    } else if (props.type === TYPES.ALERT) {
      defaultProps.onDismiss = this.closeModal;
      defaultProps.alertIsModal = true;
      defaultProps.isDismissible = true;
      defaultProps.isOpen = true;
    }

    return (
      <ChameleonCSSTransition isOpen>
        <Modal {...defaultProps} {...props} />
      </ChameleonCSSTransition>
    );
  }
}
