const ORGANIZER = 'organizer';
const ATTENDEE = 'attendee';
const UNKNOWN = 'unknown';

export default class Role {
  static get ORGANIZER() {
    return ORGANIZER;
  }

  static get ATTENDEE() {
    return ATTENDEE;
  }

  static get UNKNOWN() {
    return UNKNOWN;
  }

  static values() {
    return [ORGANIZER, ATTENDEE, UNKNOWN];
  }
}
