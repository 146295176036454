export default class ReportStatus {
  static get STARTED() {
    return 'STARTED';
  }

  static get SUBMITTED() {
    return 'SUBMITTED';
  }

  static get COMPLETED() {
    return 'COMPLETED';
  }

  static get FAILED() {
    return 'FAILED';
  }
}
