import config from 'appConfig';
import getHeaders from 'Api/Helpers/getHeaders';
import { fetchJson } from 'Api/Helpers/fetch';
import { getUser, getAccountKey } from 'Api/index';
import { GET, PUT } from 'Api/Helpers/httpMethods';

const serviceUrl = `${config.featureService.url}/applicationstateservice/state`;
const productAccessToken = config.appStateService.accessToken;
const productId = config.appStateService.productId;

const HEADER_KEYS = {
  productId: 'Product-Identifier',
  userKey: 'X-User-Key',
  accountKey: 'X-Account-Key',
  productToken: 'Product-Access-Token'
};

export function getAppState() {
  const headers = getHeaders(false)();
  headers[HEADER_KEYS.productId] = productId;
  headers[HEADER_KEYS.userKey] = getUser().id;
  headers[HEADER_KEYS.accountKey] = getAccountKey();
  const options = {
    headers,
    method: GET
  };
  return fetchJson(serviceUrl, options, 'json');
}

export function setAppState(data) {
  const headers = getHeaders(true, 'Bearer')();
  headers[HEADER_KEYS.productId] = productId;
  headers[HEADER_KEYS.userKey] = getUser().id;
  headers[HEADER_KEYS.accountKey] = getAccountKey();
  headers[HEADER_KEYS.productToken] = productAccessToken;
  const options = {
    headers,
    method: PUT,
    body: JSON.stringify({ data })
  };
  return fetchJson(`${serviceUrl}?merge=true`, options, 'json', false);
}
