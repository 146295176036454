import AddVideoToChannel from './AddVideoToChannel';
import FeatureUnlock from './FeatureUnlock';
import CreateChannel from './CreateChannel';
import GenerateAudioWaveform from './GenerateAudioWaveform';
import TranscriptProcessing from './TranscriptProcessing';
import VideoDownloadOption from './VideoDownloadOption';
import Survey from './Survey/Survey';
import NewSessionSurvey from './Survey/NewSessionSurvey';
import newSessionHandout from './Handout/NewSessionHandout';
import RestrictDomains from './RestrictDomains/restrictDomains.container';
import NewSessionPoll from './Poll/NewSessionPoll';

const modals = {
  CreateChannel,
  AddVideoToChannel,
  FeatureUnlock,
  GenerateAudioWaveform,
  Survey,
  NewSessionSurvey,
  newSessionHandout,
  NewSessionPoll,
  TranscriptProcessing,
  VideoDownloadOption,
  RestrictDomains
};

export default modals;
