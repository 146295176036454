import MixpanelTracker from '../../mixpanelTracker';
import { REGISTRATION, EVENT_DETAILS } from '../../mixpanelConstants';

const mixpanelTracker = new MixpanelTracker();

export function trackRegistrationUpdated(subType, action) {
  const properties = {
    Type: REGISTRATION,
    SubType: subType,
    Action: action
  };
  mixpanelTracker.setEventName(EVENT_DETAILS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}

export function trackRegistrationQuestion(subType, questionType, action) {
  const properties = {
    Type: REGISTRATION,
    SubType: subType,
    QuestionType: questionType,
    Action: action
  };
  mixpanelTracker.setEventName(EVENT_DETAILS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}

