export const generateSummaryAndTopics = (summary) => {
  let summaryText = '';
  let keyTopics = '';
  if (summary) {
    if (summary.summary) {
      summaryText = summary.summary;
    }
    if (summary.topics && summary.topics.length) {
      keyTopics = summary.topics.reduce((result, topic, index) => {
        if (index === 0) {
          return `${result}${topic.topic}:\n${topic.description}`;
        }
        return `${result}\n\n${topic.topic}:\n${topic.description}`;
      }, '');
    }
    return { summaryText, keyTopics };
  }
  return { summaryText, keyTopics };
};

export const getSummaryAndTopics = (summary) => {
  let summaryText = '';
  let keyTopics = '';
  if (summary) {
    const summaryIndex = summary.indexOf('SUMMARY:');
    let keyTopicsIndex = summary.indexOf('KEY TOPICS:');
    if (keyTopicsIndex < 0) {
      keyTopicsIndex = summary.length;
    } else {
      keyTopics = summary && summary.substring(keyTopicsIndex)?.split('KEY TOPICS:')[1]?.trim();
    }
    if (summaryIndex >= 0) {
      summaryText = summary
        && summary.substring(summaryIndex, keyTopicsIndex)?.split('SUMMARY:')[1]?.trim();
    }
    return { summaryText, keyTopics };
  }
  return { summaryText, keyTopics };
};

export const formatCopiedSummaryAndTopics = (summary) => {
  let summaryText = '';
  let keyTopics = '';
  let copiedText = '';
  if (summary) {
    const summaryIndex = summary.indexOf('SUMMARY:');
    let keyTopicsIndex = summary.indexOf('KEY TOPICS:');
    if (keyTopicsIndex < 0) {
      keyTopicsIndex = summary.length;
    } else {
      keyTopics = summary && summary.substring(keyTopicsIndex)?.split('KEY TOPICS:')[1]?.trim();
    }
    if (summaryIndex >= 0) {
      summaryText = summary
        && summary.substring(summaryIndex, keyTopicsIndex)?.split('SUMMARY:')[1]?.trim();
    }
    copiedText = `SUMMARY:\n${summaryText}\n\nKEY TOPICS:\n${keyTopics}`;
  }
  return copiedText;
};
