const classic = 'CLASSIC';
const webcast = 'BROADCAST';
const simulive = 'SIMULIVE';

export default class WebinarType {
  static get CLASSIC() {
    return classic;
  }

  static get WEBCAST() {
    return webcast;
  }

  static get SIMULIVE() {
    return simulive;
  }

  static values() {
    return [classic, webcast, simulive];
  }
}
