
let auth;
let user;
let accountKey;

export function getAuth() { return auth; }
export function getUser() { return user; }
export function getAccountKey() { return accountKey; }

export default Object.assign({
  setAuth: (token) => {
    auth = token;
  },
  setAuthJSON: (authJSON) => {
    auth = JSON.parse(authJSON);
  },
  setUser: (userProps) => {
    user = userProps;
  },
  setAccountKey: (key) => {
    accountKey = key;
  }
});
