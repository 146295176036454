// Validates the
export function validateEvent(event, eventName, mandatoryEventProperties) {
  const missingEventProperties = [];
  mandatoryEventProperties.forEach((mandatoryEvent) => {
    if (!Object.keys(event).includes(mandatoryEvent) || event[mandatoryEvent] === null || event[mandatoryEvent] === undefined) {
      missingEventProperties.push(mandatoryEvent);
    }
  });
  if (missingEventProperties.length === 0) {
    return;
  }
  throw new Error(`Cannot build ${eventName} without: ${missingEventProperties}`);
}
