import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import FloatingLabel from '@getgo/chameleon-react/dist/FloatingLabel';
import commonPollStyles from 'Containers/Poll/styles.scss';
import { anyCharRegex } from 'Utils/formats';
import singleChoiceInactive from 'Components/Common/Assets/singleChoiceGray.svg';
import multipleChoiceInactive from 'Components/Common/Assets/checkBoxGrey.svg';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import AddButton from '../../Card/SharedComponents/AddButton/index';
import styles from './styles.scss';
import QuestionType from '../../../Api/Webinar/Survey/Model/questionType';

const MIN_NUM_OF_CHOICES = 2;

class InputOption extends Component {
  static propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    choices: PropTypes.array,
    optionWrapperClassname: PropTypes.string,
    maxNumberOfChoices: PropTypes.number,
    maxLengthForAnswerOption: PropTypes.number,
    floatingLabelContainerClassname: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    type: QuestionType.SINGLE_CHOICE,
    maxNumberOfChoices: 5,
    optionWrapperClassname: styles.singleChoice,
    maxLengthForAnswerOption: 128,
    floatingLabelContainerClassname: styles.singleChoiceAnswer
  };

  constructor(props) {
    super(props);
    if (!QuestionType.values().includes(props.type)) {
      throw new Error(`Cannot set a input option type that isnt listed in QuestionType: ${QuestionType.values()}`);
    }
    this.state = {
      choices: props.choices && props.choices.length > 0 ? props.choices : new Array(MIN_NUM_OF_CHOICES),
      autoFocusIndex: -1
    };
    this.createOption = this.createOption.bind(this);
    this.onChoiceChange = this.onChoiceChange.bind(this);
    this.addChoice = this.addChoice.bind(this);
    this.removeChoice = this.removeChoice.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { choices } = this.props;
    const prevChoices = prevProps.choices;
    if (choices && prevChoices && choices.length !== prevChoices.length) {
      const newChoicesState = choices.length > 0 ? choices : new Array(MIN_NUM_OF_CHOICES);
      this.setState({ choices: newChoicesState });
    }
  }

  onChoiceChange(value, index) {
    const choicesCopy = this.state.choices.slice();
    let autoFocusIndex = this.state.autoFocusIndex;
    if (this.state.choices[index]) {
      choicesCopy[index] = ((this.state.choices[index].answerKey !== null)
        ? { answerKey: this.state.choices[index].answerKey, answerText: value }
        : { answerText: value });
    } else {
      choicesCopy[index] = { answerText: value };
    }

    autoFocusIndex = index;
    this.setState({ choices: choicesCopy, autoFocusIndex });
    const { onChange } = this.props;
    if (onChange) {
      onChange(choicesCopy);
    }
  }

  addChoice(index) {
    this.createOption(index);
    this.onChoiceChange('', this.state.choices.length);
  }

  removeChoice(index) {
    const choicesCopy = this.state.choices.slice();
    choicesCopy.splice(index, 1);
    this.setState({ choices: choicesCopy });
    const { onChange } = this.props;
    if (onChange) {
      onChange(choicesCopy);
    }
  }

  renderRemoveButton(index) {
    if (this.state.choices.length <= MIN_NUM_OF_CHOICES) {
      return null;
    }
    return (
      <i
        className={classNames(styles.removeIcon, 'togo-icon togo-icon-trash')}
        onClick={this.removeChoice.bind(this, index)}
        onKeyPress={this.removeChoice.bind(this, index)}
        role='button'
        tabIndex='0'
      />
    );
  }

  renderChoiceIcon(key) {
    const { type } = this.props;
    let imageSrc = null;
    switch (type) {
      case QuestionType.SINGLE_CHOICE:
        imageSrc = singleChoiceInactive;
        break;
      case QuestionType.MULTIPLE_CHOICE:
        imageSrc = multipleChoiceInactive;
        break;
      default:
        break;
    }

    return <img key={key} className={styles.choiceImage} src={imageSrc} alt='' role='presentation' />;
  }

  createOption(num) {
    const { t } = this.props;
    const { choices } = this.state;
    const { optionWrapperClassname, floatingLabelContainerClassname } = this.props;
    const placeholder = `${t('survey.questions.choices.options.placeholder')} ${num + 1}`;
    return (
      <div key={num} className={optionWrapperClassname}>
        {this.renderChoiceIcon()}
        <FloatingLabel
          id={`input_option_${num + 1}`}
          containerClassNames={floatingLabelContainerClassname}
          inputClassName={commonPollStyles.textInputField}
          placeholder={placeholder}
          pattern={anyCharRegex}
          form={true}
          floatLabel={false}
          autoFocus={this.state.autoFocusIndex === num}
          showAnimatedBar={true}
          onChange={(value) => this.onChoiceChange(value, num)}
          errorMessage={t('survey.questions.requiredField')}
          maxLength={this.props.maxLengthForAnswerOption}
          charLimitText={t('survey.charLimit')}
          value = {choices[num] ? choices[num].answerText : ''}
        />
        {this.renderRemoveButton(num)}
      </div>
    );
  }

  renderOptions() {
    const options = [];
    for (let i = 0; i < this.state.choices.length; i++) {
      options.push(this.createOption(i));
    }
    return options;
  }

  renderAddOption() {
    const { choices } = this.state;
    if (choices.length >= this.props.maxNumberOfChoices) {
      return null;
    }
    const { t } = this.props;
    return (
      <AddButton
        id='inputoption_add_another_option_button'
        title={t('survey.questions.choices.options.addOption')}
        className={styles.addAnotherOptionButton}
        onClick={this.addChoice} />
    );
  }

  render() {
    return (<div>
      {this.renderOptions()}
      {this.renderAddOption()}
    </div>);
  }
}

export default withTranslation()(InputOption);
