import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddButton from 'Components/Card/SharedComponents/AddButton';
import QuestionType from 'Api/Webinar/Poll/Model/questionType';
import Questions from 'Components/Questions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Action from 'Mixpanel/Model/action';
import { actions as pollActions } from 'Reducks/Poll';
import isEqual from 'lodash.isequal';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { POLL } from 'Mixpanel/Features/EventDetails/Engagement/engagementConstants';
import trackEngagement from 'Mixpanel/Features/EventDetails/Engagement/engagementTracker';
import { ResponsiveModal } from '@getgo/chameleon-react';
import { onPollsAddedToSessionTracker,
  onPollsDeletedFromSessionTracker } from 'Mixpanel/Features/VirtualEventDetails/engagementDetailsTracker';
import Choices from '../../Common/Choices';
import styles from './styles.scss';

const MAX_NUM_OF_QUESTIONS = 20;
const MAX_NUM_OF_CHOICES = 5;
const MIXPANEL_ATTRIBUTE_QUESTION = 'Question';
const MAX_NUM_FOR_POLL_ANSWER = 60;
const MAX_NUM_FOR_POLL_QUESTION = 128;

class PollQuestions extends Component {
  static propTypes = {
    webinarKey: PropTypes.string,
    questions: PropTypes.array,
    createWebinarPoll: PropTypes.func,
    deleteWebinarPoll: PropTypes.func,
    updateWebinarPoll: PropTypes.func,
    updateWebinarPollOrder: PropTypes.func,
    retrieveWebinarPoll: PropTypes.func,
    onUpdate: PropTypes.func,
    pollQuestions: PropTypes.array,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    t: PropTypes.func,
    eventKey: PropTypes.string
  };

  static defaultProps = {
    pollQuestions: []
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddNewQuestion: this.props.pollQuestions.length <= MAX_NUM_OF_QUESTIONS,
      questionCreationInProgress: false,
      newQuestion: {},
      originalQuestionBeingEdited: {},
      editQuestion: false,
      editQuestionIndex: 0
    };
    this.onChange = this.onChange.bind(this);
    this.onQuestionEdit = this.onQuestionEdit.bind(this);
    this.onQuestionTitleChange = this.onQuestionTitleChange.bind(this);
    this.onAnswersChange = this.onAnswersChange.bind(this);
    this.toggleAddNewQuestion = this.toggleAddNewQuestion.bind(this);
    this.clearNewQuestion = this.clearNewQuestion.bind(this);
    this.addNewPoll = this.addNewPoll.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.cancelUpdateQuestion = this.cancelUpdateQuestion.bind(this);
    this.disableUpdateQuestion = this.disableUpdateQuestion.bind(this);
    this.disableSave = this.disableSave.bind(this);
    this.resetEditQuestionState = this.resetEditQuestionState.bind(this);
    this.createPollQuestion = this.createPollQuestion.bind(this);
  }

  componentDidMount() {
    this.props.retrieveWebinarPoll(this.props.webinarKey);
  }

  reportQuestionsChange(changeType, questionType) {
    if (!Action.values().includes(changeType)) {
      throw new Error('Cannot set a change type on questions change with an invalid type');
    }
    trackEngagement(POLL, MIXPANEL_ATTRIBUTE_QUESTION, changeType, questionType);
  }

  onQuestionTitleChange(value) {
    const questionCopy = { ...this.state.newQuestion };
    questionCopy.questionText = value;
    this.setState({ newQuestion: questionCopy });
  }

  disableSave() {
    const { newQuestion } = this.state;
    if (!newQuestion) {
      return true;
    }
    if (!newQuestion.questionText) {
      return true;
    }
    if (!newQuestion.type) {
      return true;
    }
    if (!newQuestion.choices || newQuestion.choices.length === 0) {
      return true;
    }
    if (newQuestion.choices && newQuestion.choices.findIndex((choice) => !choice || !choice.answerText) > -1) {
      return true;
    }
    return false;
  }

  onAnswersChange(value) {
    const questionCopy = { ...this.state.newQuestion };
    questionCopy.choices = value;
    this.setState({ newQuestion: questionCopy });
  }

  onQuestionTypeChange = (value, clearChoices = true) => {
    const questionCopy = { ...this.state.newQuestion };
    if (clearChoices) {
      questionCopy.choices = [];
    }
    questionCopy.type = value;
    this.setState({ newQuestion: questionCopy });
  }

  onChange(questions, changeType, questionType, question) {
    this.setState({ newQuestion: questions });
    if (changeType === Action.MOVE) {
      const { updateWebinarPollOrder } = this.props;
      updateWebinarPollOrder(this.props.webinarKey, questions);
    } else if (changeType === Action.REMOVE) {
      const { deleteWebinarPoll } = this.props;
      deleteWebinarPoll(this.props.webinarKey, question.pollKey);
      onPollsDeletedFromSessionTracker(this.props.eventKey, this.props.webinarKey, question.pollKey, 'Deleting Poll');
    }

    this.reportQuestionsChange(changeType, questionType);
    this.setState({ questions });
  }

  onQuestionEdit(question, index) {
    this.setState({
      editQuestion: true,
      newQuestion: question,
      editQuestionIndex: index,
      originalQuestionBeingEdited: question
    });
  }

  clearNewQuestion() {
    this.setState({ newQuestion: {} });
  }

  resetEditQuestionState() {
    this.setState({ editQuestion: false, editQuestionIndex: 0 });
  }

  updateQuestion() {
    const { newQuestion } = this.state;
    const { updateWebinarPoll } = this.props;

    for (let i = 0; i < newQuestion.choices.length; i++) {
      delete newQuestion.choices[i].answerKey;
    }

    updateWebinarPoll(this.props.webinarKey, newQuestion);

    this.reportQuestionsChange(Action.EDIT, newQuestion.type);
    this.setState({ newQuestion: {}, editQuestion: false });
  }

  cancelUpdateQuestion() {
    this.setState({ editQuestion: false, newQuestion: {} });
  }

  disableUpdateQuestion() {
    const { newQuestion, originalQuestionBeingEdited } = this.state;
    if (!newQuestion.questionText) {
      return true;
    }

    if (!newQuestion.type) {
      return true;
    }

    if (!newQuestion.choices || newQuestion.choices.length === 0) {
      return true;
    }

    if (newQuestion.choices && newQuestion.choices.findIndex((choice) => !choice || !choice.answerText) > -1) {
      return true;
    }

    return isEqual(newQuestion, originalQuestionBeingEdited);
  }

  renderQuestionAndChoices(show = true) {
    if (!show) {
      return null;
    }
    const { t } = this.props;
    const { newQuestion, editQuestion } = this.state;
    const questionChoices = [QuestionType.SINGLE_CHOICE, QuestionType.MULTIPLE_CHOICE];
    return (
      <div>
        <Choices
          maxNumberOfChoices={MAX_NUM_OF_CHOICES}
          options={questionChoices}
          onQuestionTypeChange={this.onQuestionTypeChange}
          onAnswersChange={this.onAnswersChange}
          questionType={newQuestion.type}
          questionText={newQuestion.questionText}
          maxQuestionLength={MAX_NUM_FOR_POLL_QUESTION}
          maxLengthForAnswerOption={MAX_NUM_FOR_POLL_ANSWER}
          onQuestionTitleChange={this.onQuestionTitleChange}
          choices={newQuestion.choices} />
        {editQuestion
          ? <div>
            <Button
              id='poll_questions_update_poll'
              className={styles.updateButton}
              color='primary'
              disabled={this.disableUpdateQuestion()}
              onClick={this.updateQuestion}>{t('button.update')}
            </Button>
            <button
              className={styles.cancel}
              onClick={this.cancelUpdateQuestion}>
              {t('button.cancel')}
            </button>
          </div>
          : null
        }
      </div>
    );
  }

  toggleAddNewQuestion() {
    this.setState({ showAddNewQuestion: !this.state.showAddNewQuestion });
  }

  addNewPoll() {
    this.toggleAddNewQuestion();
    this.setState({ editQuestion: false });
  }

  createPollQuestion() {
    const { createWebinarPoll, eventKey } = this.props;
    this.toggleAddNewQuestion();
    createWebinarPoll(this.props.webinarKey, this.state.newQuestion);
    onPollsAddedToSessionTracker(eventKey, this.props.webinarKey, this.state.newQuestion, 'Creating Poll');
    this.reportQuestionsChange(Action.ADD, this.state.newQuestion.type);
    this.setState({ newQuestion: {} });
  }

  renderAddPoll() {
    const { pollQuestions } = this.props;
    if (!this.state.showAddNewQuestion || pollQuestions.length >= 20) {
      return null;
    }
    const { t } = this.props;
    return (
      <AddButton
        id='pollquestions_add_new_poll'
        title={t('poll.questions.addNewQuestion')}
        className={styles.addNewPoll}
        onClick={this.addNewPoll} />
    );
  }

  renderQuestions(questions) {
    const pollQuestions = questions;
    const { t } = this.props;
    const props = {
      questions: pollQuestions,
      title: t('poll.questions.title'),
      subtitle: `${pollQuestions.length} ${t('poll.questions.numOfQuestions.of')} ${MAX_NUM_OF_QUESTIONS} ${t('poll.questions.numOfQuestions.questionsAdded')}`,
      onChange: this.onChange,
      editQuestionTitle: t('poll.questions.editQuestion'),
      removeQuestionTitle: t('poll.questions.removeQuestion'),
      onQuestionEdit: this.onQuestionEdit
    };
    return (<Questions {...props} />);
  }

  renderExistingQuestions(questions) {
    const pollQuestions = questions;
    const { t } = this.props;
    const isEditing = !this.state.showAddNewQuestion && pollQuestions.length <= MAX_NUM_OF_QUESTIONS;
    return (
      <div className={styles.questions}>
        {this.renderQuestions(pollQuestions)}
        {this.renderQuestionAndChoices(isEditing)}
        {this.renderAddPoll()}
        <span className={styles.info}> {t('poll.generateAttendeeReport')} </span>
        {!isEditing && <Button
          id='poll_questions_done_adding_polls'
          className={styles.updateButton}
          color='primary'
          onClick={this.props.onCancel}>
          {t('poll.doneAddingPolls')}
        </Button> }
      </div>
    );
  }

  renderEditQuestion() {
    return (
      <div>
        {this.renderQuestionAndChoices(this.state.editQuestion)}
      </div>);
  }

  renderCreateQuestions() {
    return (<div>
      {this.renderQuestionAndChoices()}
    </div>);
  }

  render() {
    const { t } = this.props;
    const { editQuestion } = this.state;
    const { pollQuestions, title, onCancel } = this.props;
    let content;
    if (editQuestion) {
      content = this.renderEditQuestion();
    } else if (pollQuestions && pollQuestions.length > 0) {
      content = this.renderExistingQuestions(pollQuestions);
    } else {
      content = this.renderCreateQuestions();
    }
    return (
      <ResponsiveModal
        isOpen={true}
        onClose={onCancel}
        title={title}
        primaryActionButton={{
          id: 'polls_questions_save',
          disabled: this.disableSave(),
          onClick: this.createPollQuestion,
          text: t('poll.savePoll')
        }}
      >
        {content}
      </ResponsiveModal>
    );
  }
}

export default connect(
  (state) => ({
    pollQuestions: state.poll.webinarPoll.obj
  }),
  (dispatch) => bindActionCreators(Object.assign({}, pollActions), dispatch)
)(withTranslation()(PollQuestions));
