import { retrieveReplyToAction,
  updateReplyToAction,
  retrieveFollowUpAction,
  updateFollowUpAction,
  retrieveReminderEmailAction,
  updateReminderEmailAction,
  retrieveConfirmationEmailAction,
  updateConfirmationEmailAction,
  getCustomEmailDisclaimerAction,
  updateEmailDisclaimerAction,
  getCertificateInfoAction,
  createSignatureUploadPolicyAction,
  updateCertificateInfoAction,
  deleteCertificateInfoAction,
  getCertificateUrlAction } from './actionTypes';

export function retrieveReplyTo(webinarKey) {
  return { type: retrieveReplyToAction.toString(), webinarKey };
}

export function updateReplyTo(webinarKey, replyToObj) {
  return { type: updateReplyToAction.toString(), webinarKey, replyToObj };
}

export function retrieveFollowUpEmail(webinarKey, attendeeType) {
  return { type: retrieveFollowUpAction.toString(), webinarKey, attendeeType };
}

export function updateFollowUpEmail(webinarKey, followUpEmailObj, attendeeType, includeCertificate = false) {
  return {
    type: updateFollowUpAction.toString(), webinarKey, attendeeType, followUpEmailObj, includeCertificate
  };
}

export function retrieveReminderEmail(webinarKey) {
  return { type: retrieveReminderEmailAction.toString(), webinarKey };
}

export function updateReminderEmail(webinarKey, reminderEmailObj) {
  return { type: updateReminderEmailAction.toString(), webinarKey, reminderEmailObj };
}

export function retrieveConfirmationEmail(webinarKey) {
  return { type: retrieveConfirmationEmailAction.toString(), webinarKey };
}

export function updateConfirmationEmail(webinarKey, confirmationEmailObj) {
  return { type: updateConfirmationEmailAction.toString(), webinarKey, confirmationEmailObj };
}

export function getCustomEmailDisclaimer(webinarKey) {
  return { type: getCustomEmailDisclaimerAction.toString(), webinarKey };
}

export function updateEmailDisclaimer(webinarKey, customEmailDisclaimer) {
  return { type: updateEmailDisclaimerAction.toString(), webinarKey, customEmailDisclaimer };
}

export function getCertificateInfo(webinarKey, showCertificateModal) {
  return { type: getCertificateInfoAction.toString(), webinarKey, showCertificateModal };
}

export function createSignatureUploadPolicy(webinarKey, fileMetaData, file) {
  return { type: createSignatureUploadPolicyAction.toString(), webinarKey, fileMetaData, file };
}

export function updateCertificateInfo(webinarKey, certificateInfo, fileMetaData, file, signatureType) {
  return { type: updateCertificateInfoAction.toString(), webinarKey, certificateInfo, fileMetaData, file, signatureType };
}

export function deleteCertificateInfo(webinarKey) {
  return { type: deleteCertificateInfoAction.toString(), webinarKey };
}

export function getCertificateUrl(webinarKey) {
  return { type: getCertificateUrlAction.toString(), webinarKey };
}
