import config from 'appConfig';
import moment from 'moment';
import { GET, POST } from './Helpers/httpMethods';
import getHeader from './Helpers/getHeaders';
import { JSON_MIME_TYPE } from './Helpers/requestProperties';
import { fetchJson, fetchJsonForVEReport } from './Helpers/fetch';
import { NO_OF_RECORDINGS } from './Assets/assetsService';

export function getReportingDetails() {
  const dataLimit = 5;
  let toTime = moment().toDate().toISOString();
  let fromTime = moment().subtract(1, 'years').toDate().toISOString();
  toTime = `${toTime.slice(0, -5)}Z`;
  fromTime = `${fromTime.slice(0, -5)}Z`;
  const url = `${config.g2wReporting.url}/reporting/api/V2/sessions?fromTime=${fromTime}&toTime=${toTime}&page=0&limit=${dataLimit}&client=g2w-dashboard`;
  const options = {
    headers: getHeader(true),
    method: GET
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

function getBody(recordingKeys, startingIndex, bodies) {
  const count = recordingKeys.length;
  if (startingIndex >= count) {
    return bodies;
  }

  const endingIndex = startingIndex + NO_OF_RECORDINGS;
  if (endingIndex < count) {
    const keys = recordingKeys.slice(startingIndex, endingIndex);
    bodies.push(JSON.stringify(keys));
    return getBody(recordingKeys, endingIndex, bodies);
  }

  const keys = recordingKeys.slice(startingIndex, count);
  bodies.push(JSON.stringify(keys));
  return bodies;
}

export function getViews(recordingKeys) {
  const bodies = getBody(recordingKeys, 0, []);
  const url = `${config.g2wReporting.url}/reporting/api/recordings/views?client=g2w-dashboard`;
  const options = bodies.map((body) => ({
    headers: getHeader(true),
    method: POST,
    body
  }));

  return Promise.all(options.map((option) => fetchJson(url, option, JSON_MIME_TYPE))).then((response) => {
    let merged = {};
    response.forEach((r) => {
      merged = Object.assign(merged, r);
    });
    return merged;
  });
}

export function getAttendanceStats(webinarKey) {
  const url = `${config.g2wReporting.url}/reporting/api/webinars/${webinarKey}/attendancestatistics?client=g2w-dashboard`;
  const options = {
    headers: getHeader(true, 'Bearer'),
    method: GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE).catch((error) => {
    if (error.status === 404) {
      return Promise.resolve({
        sessionCount: 0,
        attendeeCount: 0,
        absenteeCount: 0
      });
    }
    throw Error(error.statusText, error.status);
  });
}

export function getRegistrationReport(webinarLocale, webinarKey) {
  const url = `${config.g2wReporting.url}/reporting/jobs?client=g2w-dashboard`;
  let inputObj;
  if (webinarLocale) {
    inputObj = `{"product": "g2w", "timeZone": "${moment.tz.guess(true)}", "locale":"${webinarLocale}", "format":"xlsx", "webinarKey":"${webinarKey}"}}`;
  } else {
    inputObj = `{"product": "g2w", "timeZone": "${moment.tz.guess(true)}", "format":"xlsx", "webinarKey":"${webinarKey}"}}`;
  }
  const body = {
    type: 'WEBINAR_REGISTRATION_REPORT',
    input: inputObj
  };

  const options = {
    headers: getHeader(true, 'Bearer'),
    method: POST,
    body: JSON.stringify(body)
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getVirtualEventRegistrationReport(
  virtualEventKey,
  eventLocale = 'en_US',
  format = 'XLSX',
  timezone = `${moment.tz.guess(true)}`
) {
  const url = `${config.g2wVirtualEventReporting.url}/v1/jobs?sendEmail=false&client=g2w-dashboard`;
  const inputObj = {
    product: 'G2W',
    locale: eventLocale,
    reportType: 'VIRTUAL_EVENT_REGISTRATION_REPORT',
    timezone,
    format,
    virtualEventKey
  };
  const body = {
    productType: 'G2W',
    reportType: 'VIRTUAL_EVENT_REGISTRATION_REPORT',
    input: JSON.stringify(inputObj)
  };

  const options = {
    headers: getHeader(true, 'Bearer'),
    method: POST,
    body: JSON.stringify(body)
  };

  return fetchJsonForVEReport(url, options, JSON_MIME_TYPE);
}

export function checkVEReportJobStatus(JobId, sendEvent) {
  const url = `${config.g2wVirtualEventReporting.url}/v1/jobs/${JobId}?sendEvent=${sendEvent}&client=g2w-dashboard`;
  const options = {
    headers: getHeader(true, 'Bearer'),
    method: GET
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function checkJobStatus(jobId, sendEvent = false) {
  const url = `${config.g2wReporting.url}/reporting/jobs/${jobId}?sendEvent=${sendEvent}&client=g2w-dashboard`;
  const options = {
    headers: getHeader(true, 'Bearer')
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getRecordingReport(webinarLocale, recordingKey, format = 'csv', timeZone = `${moment.tz.guess(true)}`) {
  const url = `${config.g2wReporting.url}/reporting/jobs?client=g2w-dashboard`;
  const jsonBody = {
    product: 'g2w',
    timeZone,
    locale: `${webinarLocale}`,
    format,
    recordingKey
  };
  const body = {
    type: 'WEBINAR_RECORDING_REPORT',
    input: JSON.stringify(jsonBody)
  };

  const options = {
    headers: getHeader(true, 'Bearer'),
    method: POST,
    body: JSON.stringify(body)
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}
