import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Backdrop from '@getgo/chameleon-react/dist/Backdrop';
import ChameleonLoader from 'Components/Common/ChameleonLoader';
import styles from './styles.scss';

class ContentViewer extends React.PureComponent {
  static propTypes = {
    canDownloadMedia: PropTypes.bool,
    children: PropTypes.any,
    isOnlyAudio: PropTypes.bool,
    isOnlyImage: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    previewUrl: PropTypes.string,
    title: PropTypes.string,
    views: PropTypes.number,
    isLoading: PropTypes.bool,
    t: PropTypes.func
  }

  static defaultProps = {
    canDownloadMedia: false,
    isOnlyAudio: false,
    isOnlyImage: false,
    isOpen: true,
    views: 0
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  displayContentViewer() {
    const { previewUrl, isOnlyAudio, canDownloadMedia, isOnlyImage, t } = this.props;

    const downloadOption = canDownloadMedia ? 'download' : 'nodownload';

    if (!previewUrl) {
      return null;
    }

    if (isOnlyImage) {
      return (
        <img className={styles.imageViewer} src={previewUrl} alt='Handout'/>
      );
    }

    if (isOnlyAudio) {
      return (
        <audio controls autoPlay controlsList={downloadOption}>
          <source src={previewUrl} type="audio/mpeg" />
          {t('video.noHtml5')}
        </audio>
      );
    }

    return (
      <video controls autoPlay controlsList={downloadOption}>
        <source src={previewUrl} type="video/mp4" />
        {t('video.noHtml5')}
      </video>
    );
  }

  renderLoading() {
    return (
      <Backdrop className={styles.backdrop} isOpen={this.props.isOpen}>
        <ChameleonLoader />
      </Backdrop>
    );
  }

  render() {
    const { isLoading } = this.props;
    if (isLoading) { return this.renderLoading(); }
    const { title, views, isOpen, isOnlyAudio, t } = this.props;

    const mediaTopStyle = classNames({
      [styles.audioTop]: isOnlyAudio,
      [styles.videoTop]: !isOnlyAudio
    });

    return (
      <Backdrop onClickHandler={this.props.onClose} isOpen={isOpen}>
        {/* eslint-disable-next-line */}
        <div className={classNames(styles.main, mediaTopStyle)} onClick={this.stopPropagation}>
          <button onClick={this.props.onClose}>
            <span className={styles.closeButton}>{t('button.close')}</span><i className="togo-icon togo-icon-closes"/>
          </button>
          {this.props.children || this.displayContentViewer()}
          <div className={styles.contentDetails}>
            <span className={styles.contentTitle}>{title}</span>
            <span>{ views && views >= 0 ? `${views} ${t('cards.recordings.views')}` : null}</span>
          </div>
        </div>
      </Backdrop>
    );
  }
}

export default withTranslation()(ContentViewer);
