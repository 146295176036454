import { all, call, put, fork, takeLatest } from 'redux-saga/effects';
import { getExternalOrganizers, getAccountUsers } from 'Api/Webinar/webinarService';
import { getAccountUsersAction } from './actions';

export function* getUsers({ organizerKey }) {
  try {
    const [internal, external] = yield all([call(getAccountUsers, organizerKey), call(getExternalOrganizers)]);
    yield put({ type: getAccountUsersAction.complete.toString(), internal, external });
  } catch (error) {
    yield put({ type: getAccountUsersAction.failed.toString(), error });
  }
}

export function* watchGetAccountUsers() {
  yield takeLatest(getAccountUsersAction.toString(), getUsers);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAccountUsers)
  ]);
}
