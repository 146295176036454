import { fetchWebinars, getWebinarAction, updateWebinarEntitiesAction } from '../../Webinar/actions';

export const initialState = {
  sort: 'desc',
  search: '',
  byId: {},
  ids: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fetchWebinars.pending.toString():
    {
      return {
        ...state,
        isFetching: true
      };
    }

    case fetchWebinars.fulfilled.toString():
    {
      const { entities, result } = action.payload;
      return {
        ...state,
        byId: entities.webinars || {},
        ids: result.webinars || [],
        isFetching: undefined
      };
    }

    case fetchWebinars.rejected.toString():
    {
      return {
        ...state,
        isFetching: undefined
      };
    }

    case getWebinarAction.complete.toString():
    {
      return {
        ...state,
        byId: action.data,
        ids: [action.webinarKey]
      };
    }

    case updateWebinarEntitiesAction:
    {
      const { details, webinarKey } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [webinarKey]: {
            ...state.byId[webinarKey],
            ...details
          }
        }
      };
    }

    default:
    {
      return state;
    }
  }
};
