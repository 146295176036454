import config from 'appConfig';
import getHeaders from 'Api/Helpers/getHeaders';
import { GET, PUT } from '../../Helpers/httpMethods';
import { fetchJson } from '../../Helpers/fetch';

export function getInterprefyToken(webinarKey) {
  const url = `${config.g2w.url}/api/webinars/${webinarKey}/featureSettings`;
  const options = {
    headers: getHeaders(true),
    method: GET
  };
  return fetchJson(url, options, 'json');
}

export function updateInterprefyToken(webinarKey, data) {
  const url = `${config.g2w.url}/api/webinars/${webinarKey}/featureSettings`;
  const dataUpdated = { interprefyToken: data };
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(dataUpdated)
  };
  return fetchJson(url, options, 'json');
}
