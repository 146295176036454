import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveModal, ToggleSwitch } from '@getgo/chameleon-react';
import { connectDispatch } from 'Utils/decorators';
import { getUserChannelsAction, publishVideoToChannelAction, createAndUpdateChannelAction, addContentToChannelAction,
  addWebinarToChannelAction } from 'Reducks/Channel/actions';
import WithLoading from 'Components/Common/WithLoading';
import CreateChannel from 'Components/Channel/CreateChannel';
import { sendNotification } from 'Reducks/Notification/actions';
import { trackAddVideoToChannel, trackCreateChannel } from 'Mixpanel/Features/Channel/channelTracker';
import config from 'appConfig';
import appLogger from 'Utils/logging';
import classNames from 'classnames';
import { CREATE_SOURCES } from 'Mixpanel/Features/Channel/constants';
import { withTranslation } from 'react-i18next';
import { getContentAttributes } from 'Api/GoToStage/contentService';
import { get } from 'Utils/objectHelpers';
import emptyChannelLogo from '../../Channel/Assets/emptyChannelLogo.png';
import styles from '../../Channel/Common/styles.scss';
import localStyles from './styles.scss';

@connectDispatch(
  (state, props) => ({
    userKey: state.user.metadata.userKey,
    userName: state.user.user.displayName,
    channels: state.channel.channels.data,
    channelsLoading: state.channel.channels.pending,
    channelError: state.channel.channels.error,
    associatedChannel: state.channel.contentChannels[props.updateFlow && props.recording.content.id] || {},
    numAllowedChannels: state.channel.userSettings.data.g2sNumberOfChannelsAllowed
  })
)
class AddVideoToChannelModal extends PureComponent {
  static propTypes = {
    associatedChannel: PropTypes.object,
    recording: PropTypes.object,
    close: PropTypes.func,
    dispatch: PropTypes.func,
    onVideoPublished: PropTypes.func,
    channels: PropTypes.array,
    channelsLoading: PropTypes.bool,
    channelError: PropTypes.object,
    numAllowedChannels: PropTypes.number,
    userKey: PropTypes.string,
    updateFlow: PropTypes.bool,
    primaryActionButtonTitle: PropTypes.string,
    title: PropTypes.string,
    userName: PropTypes.string,
    webinarContentKey: PropTypes.string,
    webinarKey: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    channels: [],
    channelsLoading: true
  };

  state = {
    selectedChannel: this.props.associatedChannel,
    loading: false,
    attributesLoading: true
  };

  async componentDidMount() {
    const { dispatch, recording } = this.props;

    dispatch(getUserChannelsAction());

    if (recording && recording.transcript) {
      try {
        const { showtranscript } = await getContentAttributes(recording && recording.content && recording.content.id);
        this.setState({ showTranscript: showtranscript !== 'false', attributesLoading: false });
      } catch (e) {
        this.setState({ showTranscript: true, attributesLoading: false });
      }
    } else {
      this.setState({ attributesLoading: false });
    }
  }

  onAddToChannel = async () => {
    const { selectedChannel, showTranscript } = this.state;
    const { onVideoPublished, recording, userKey, dispatch } = this.props;
    try {
      this.setState({ loading: true });
      let channelKey = selectedChannel.channelKey;
      if (selectedChannel.isNewChannel) {
        const { value } = await dispatch(createAndUpdateChannelAction(userKey, selectedChannel.channelTitle,
          selectedChannel.channelDescription, selectedChannel.isLimitedAudience, selectedChannel.channelLogo));
        channelKey = value;
        trackCreateChannel(selectedChannel.isLimitedAudience, CREATE_SOURCES.ADD_VIDEO);
      }

      let attributes;
      if (typeof this.state.showTranscript === 'boolean') {
        attributes = { showtranscript: showTranscript };
      }

      await dispatch(publishVideoToChannelAction(channelKey, recording, attributes));
      this.setState({ loading: false });
      dispatch(sendNotification(null, 'channel.recordingAdded', false,
        [{ recordingName: recording.name, channelName: selectedChannel.channelTitle || '' }]));
      trackAddVideoToChannel(channelKey, recording.assetKey, selectedChannel.isNewChannel, selectedChannel.isLimitedAudience);
      if (onVideoPublished) {
        onVideoPublished(recording, channelKey);
      }
      this.props.close();
    } catch (error) {
      appLogger.error(error);
      this.setState({ loading: false });
      dispatch(sendNotification(null, 'error.genericError', true));
    }
  };

  onAddContentToChannel = async () => {
    const { webinarContentKey, dispatch, webinarKey, userKey, userName } = this.props;
    const { selectedChannel } = this.state;
    if (selectedChannel.isNewChannel) {
      if (selectedChannel.channelTitle === undefined) {
        selectedChannel.channelTitle = userName;
      }
      const { value } = await dispatch(createAndUpdateChannelAction(userKey, selectedChannel.channelTitle,
        selectedChannel.channelDescription, selectedChannel.isLimitedAudience, selectedChannel.channelLogo));
      selectedChannel.channelKey = value;
      trackCreateChannel(selectedChannel.isLimitedAudience, CREATE_SOURCES.ADD_VIDEO);
    }
    this.setState({ loading: true });
    const contentKeys = [webinarContentKey];
    if (webinarContentKey) {
      dispatch(addContentToChannelAction({ contentKeys }, selectedChannel.channelKey)).then(() => {
        dispatch(sendNotification(null, 'cards.webinar.details.shareEvent.eventAddedToChannel', false));
      }).catch(() => {
        dispatch(sendNotification(null, 'cards.webinar.details.shareEvent.failedToAddEventToChannel', true));
      }).finally(() => {
        this.setState({ loading: false });
        this.props.onVideoPublished(selectedChannel.channelTitle, selectedChannel.channelKey);
      });
    } else {
      dispatch(addWebinarToChannelAction(selectedChannel.channelKey, webinarKey))
        .then(() => {
          dispatch(sendNotification(null, 'cards.webinar.details.shareEvent.eventAddedToChannel', false));
        }).catch(() => {
          dispatch(sendNotification(null, 'cards.webinar.details.shareEvent.failedToAddEventToChannel', true));
        }).finally(() => {
          this.setState({ loading: false });
          this.props.onVideoPublished(selectedChannel.channelTitle, selectedChannel.channelKey);
        });
    }
  };

  onChannelSelected = (channel) => {
    this.setState({ selectedChannel: channel });
  };

  renderChannel = (channel) => {
    const { selectedChannel } = this.state;
    const { t } = this.props;
    const boxStyle = channel.channelKey === selectedChannel.channelKey ? styles.selectedChannelBox : styles.channelBox;
    const visibilityModes = [t('channel.private'), t('channel.public')];
    return (
      <button key={channel.channelKey} className={boxStyle} onClick={() => this.onChannelSelected(channel)}>
        <img className={styles.channelImage} src={channel.channelLogo || emptyChannelLogo} alt={t('channel.logoAltText')}/>
        <div className={styles.infoContainer}>
          <div className={styles.channelTitle}>{channel.channelTitle}</div>
          <div className={styles.visibilityContainer}>
            <i className={classNames(channel.isLimitedAudience ? 'togo-icon togo-icon-lock-closed' : 'togo-icon togo-icon-globe', styles.icon)}/>
            <span className={styles.dropdownText}>{visibilityModes[channel.isLimitedAudience ? 0 : 1]}</span>
          </div>
          <div className={styles.channelDescription}>{channel.channelDescription}</div>
        </div>
      </button>
    );
  };

  renderChannels = (channels) => {
    const { selectedChannel } = this.state;
    const { associatedChannel, numAllowedChannels, updateFlow } = this.props;
    const { t } = this.props;
    const content = [];
    if (channels.length > 0) {
      if (updateFlow) {
        content.push(<div key="note" className={localStyles.channelNote}>{t('channel.note')}</div>);
        content.push(<div key="channelheading"><b>{t('channel.updateHeading')}</b></div>);
        content.push(this.renderChannel(channels.find(({ channelKey }) => associatedChannel.channelKey === channelKey)));
      } else {
        content.push(<div key="channelheading">{t('channel.heading1')}</div>);
      }

      channels
        .filter((channel) => channel.channelKey !== associatedChannel.channelKey)
        .forEach((channel) => content.push(this.renderChannel(channel)));

      if (numAllowedChannels > channels.length) {
        content.push(
          <CreateChannel
            key='create_new_channel_container'
            onClick={this.onChannelSelected}
            selected={selectedChannel.isNewChannel}
            onChannelUpdated={this.onChannelSelected}
            disableLogo={true}
          />
        );
      }
    } else {
      content.push(
        <div key='create_new_channel_heading_2'>
          {t('channel.heading2')}
          <span>
            <a className={styles.learnMoreLink} href={config.support.g2s} target='_blank' rel='noopener noreferrer'>
              {t('schedule.learnMore')}
            </a>
          </span>
        </div>
      );
      content.push(
        <CreateChannel
          key='create_new_channel_container'
          onClick={this.onChannelSelected}
          selected={selectedChannel.isNewChannel}
          onChannelUpdated={this.onChannelSelected}
          disableLogo={true}
        />
      );
    }
    return content;
  };

  render() {
    const { channels, close, channelsLoading, channelError, title, primaryActionButtonTitle, recording, updateFlow } = this.props;
    const { selectedChannel, loading } = this.state;
    const { t } = this.props;
    const hasSelectedChannel = selectedChannel.channelKey !== undefined || selectedChannel.isNewChannel;
    const attributesLoading = get(this.props, (p) => p.recording.transcript) && this.state.attributesLoading;

    let titleText;
    let addButtonText;
    if (updateFlow) {
      titleText = t('channel.modalUpdateTitle');
      addButtonText = t('button.update');
    } else {
      titleText = t('channel.modalTitle');
      addButtonText = t('button.add', { text: '' });
    }

    if (!recording) {
      titleText = title;
      addButtonText = primaryActionButtonTitle;
    }

    const showTranscriptToggle = typeof this.state.showTranscript === 'boolean' && (
      <div className={localStyles.showTranscript}>
        <span>{t('channel.showTranscript')}</span>
        <ToggleSwitch
          id='show-transcript-checkbox'
          checked={this.state.showTranscript}
          onChange={() => {
            this.setState({ showTranscript: !this.state.showTranscript });
          }}
          size='sm'
          showHint={false}
        />
      </div>
    );

    return (
      <ResponsiveModal
        isOpen={true}
        title={titleText}
        onClose={close}
        primaryActionButton={channelError || loading ? undefined : {
          text: addButtonText,
          id: 'add_video_to_channel_primary_button',
          onClick: recording ? this.onAddToChannel : this.onAddContentToChannel,
          disabled: !hasSelectedChannel
        }}
        secondaryActionButton={loading ? undefined : {
          text: t('button.cancel'),
          onClick: close
        }}
      >
        <WithLoading loading={loading || attributesLoading || channelsLoading}>
          {channelError ? t('error.genericError') : (
            <>
              {showTranscriptToggle}
              {this.renderChannels(channels)}
            </>
          )}
        </WithLoading>
      </ResponsiveModal>
    );
  }
}

export default withTranslation()(AddVideoToChannelModal);
