import { getWebinarSettingsAction, setSimuliveVideoControlAction } from './actions';

const defaultState = {
  showVideoControls: false,
  hasPrice: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case getWebinarSettingsAction.pending.toString(): {
      return {
        ...state,
        getSettingsError: null,
        isFetching: true
      };
    }

    case getWebinarSettingsAction.fulfilled.toString(): {
      return {
        ...state,
        ...action.payload,
        isFetching: undefined
      };
    }

    case getWebinarSettingsAction.rejected.toString(): {
      return {
        ...state,
        ...defaultState,
        getSettingsError: action.payload.message,
        isFetching: undefined
      };
    }

    case setSimuliveVideoControlAction.pending.toString(): {
      return {
        ...state,
        setSettingsError: null
      };
    }

    case setSimuliveVideoControlAction.fulfilled.toString(): {
      return {
        ...state,
        showVideoControls: action.meta.showVideoControls
      };
    }

    case setSimuliveVideoControlAction.rejected.toString(): {
      return {
        ...state,
        setSettingsError: action.payload.message
      };
    }

    default: {
      return state;
    }
  }
};
