import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import removeIcon from './Assets/delete.svg';
import editIcon from './Assets/edit.svg';
import moveUpIcon from './Assets/moveUp.svg';
import moveDownIcon from './Assets/moveDown.svg';
import styles from './styles.scss';

class Question extends Component {
  static propTypes = {
    question: PropTypes.object,
    isFirstQuestionInList: PropTypes.bool,
    isLastQuestionInList: PropTypes.bool,
    moveUpOnClick: PropTypes.func,
    moveDownOnClick: PropTypes.func,
    removeOnClick: PropTypes.func,
    editOnClick: PropTypes.func,
    editQuestionTitle: PropTypes.string,
    removeQuestionTitle: PropTypes.string,
    showRequired: PropTypes.bool,
    toggleRequired: PropTypes.func,
    t: PropTypes.func
  };

  static defaultProps = {
    isFirstQuestionInList: false,
    isLastQuestionInList: false,
    showRequired: false,
    editOnClick: Function.prototype
  };

  constructor(props) {
    super(props);
    this.state = {
      showQuestionDropdown: false
    };
  }

  toggleQuestionDropDown() {
    this.setState({ showQuestionDropdown: !this.state.showQuestionDropdown });
  }

  createDropdownMenu() {
    const { t } = this.props;
    const {
      editOnClick, removeOnClick, moveDownOnClick, editQuestionTitle, removeQuestionTitle, moveUpOnClick,
      isFirstQuestionInList, isLastQuestionInList
    } = this.props;
    if (isFirstQuestionInList && isLastQuestionInList) {
      return (<DropdownMenu>
        <DropdownItem id='questions_edit_dropdown_item' onClick={editOnClick}>
          <img alt='' role='presentation' className={styles.dropdownIcon} src={editIcon} />
          <div>{editQuestionTitle}</div>
        </DropdownItem>
        <DropdownItem id='questions_remove_dropdown_item' onClick={removeOnClick}>
          <img alt='' role='presentation' className={styles.dropdownIcon} src={removeIcon} />
          <div>{removeQuestionTitle}</div>
        </DropdownItem>
      </DropdownMenu>);
    } if (isFirstQuestionInList) {
      return (<DropdownMenu>
        <DropdownItem id='questions_movedown_dropdown_item' onClick={moveDownOnClick}>
          <img alt='' role='presentation' className={styles.dropdownIcon} src={moveDownIcon} />
          <div >{t('questions.moveQuestions.moveQuestionDown')}</div>
        </DropdownItem>
        <DropdownItem id='questions_edit_dropdown_item' onClick={editOnClick}>
          <img alt='' role='presentation' className={styles.dropdownIcon} src={editIcon} />
          <div>{editQuestionTitle}</div>
        </DropdownItem>
        <DropdownItem id='questions_remove_dropdown_item' onClick={removeOnClick}>
          <img alt='' role='presentation' className={styles.dropdownIcon} src={removeIcon} />
          <div>{removeQuestionTitle}</div>
        </DropdownItem>
      </DropdownMenu>);
    } if (isLastQuestionInList) {
      return (<DropdownMenu>
        <DropdownItem id='questions_moveup_dropdown_item' onClick={moveUpOnClick}>
          <img alt='' role='presentation' className={styles.dropdownIcon} src={moveUpIcon} />
          <div>{t('questions.moveQuestions.moveQuestionUp')}</div>
        </DropdownItem>
        <DropdownItem id='questions_edit_dropdown_item' onClick={editOnClick}>
          <img alt='' role='presentation' className={styles.dropdownIcon} src={editIcon} />
          <div>{editQuestionTitle}</div>
        </DropdownItem>
        <DropdownItem id='questions_remove_dropdown_item' onClick={removeOnClick}>
          <img alt='' role='presentation' className={styles.dropdownIcon} src={removeIcon} />
          <div>{removeQuestionTitle}</div>
        </DropdownItem>
      </DropdownMenu>);
    }
    return (<DropdownMenu>
      <DropdownItem id='questions_moveup_dropdown_item' onClick={moveUpOnClick}>
        <img alt='' role='presentation' className={styles.dropdownIcon} src={moveUpIcon} />
        <div>{t('questions.moveQuestions.moveQuestionUp')}</div>
      </DropdownItem>
      <DropdownItem id='questions_movedown_dropdown_item' onClick={moveDownOnClick}>
        <img alt='' role='presentation' className={styles.dropdownIcon} src={moveDownIcon} />
        <div>{t('questions.moveQuestions.moveQuestionDown')}</div>
      </DropdownItem>
      <DropdownItem id='questions_edit_dropdown_item' onClick={editOnClick}>
        <img alt='' role='presentation' className={styles.dropdownIcon} src={editIcon} />
        <div>{editQuestionTitle}</div>
      </DropdownItem>
      <DropdownItem id='questions_remove_dropdown_item' onClick={removeOnClick}>
        <img alt='' role='presentation' className={styles.dropdownIcon} src={removeIcon} />
        <div>{removeQuestionTitle}</div>
      </DropdownItem>
    </DropdownMenu>);
  }

  createQuestionDropDown() {
    return (
      <Dropdown className={styles.dropdown} isOpen={this.state.showQuestionDropdown} toggle={this.toggleQuestionDropDown.bind(this)}>
        <DropdownToggle color='white' id='questions_three_dots' className={styles.createQuestionDropDown}>
          <i className="togo-icon togo-icon-three-dots togo-icon-rotate-90" />
        </DropdownToggle>
        {this.createDropdownMenu()}
      </Dropdown>
    );
  }

  showRequired() {
    const { question, showRequired, toggleRequired } = this.props;
    if (!showRequired) return null;
    const preferredStarColor = question.required ? '#00c2ff' : '#8b8d99';
    return (
      <button className={styles.requiredButton} onClick={() => toggleRequired(question)}>
        <i
          style={{ color: preferredStarColor }}
          className={`togo-icon ${question.required
            ? 'togo-icon-star-filled' : 'togo-icon-star'} ${styles.requiredIcon}`} />
      </button>
    );
  }

  render() {
    const { questionText, title, questionKey } = this.props.question;
    return (
      <div id={questionKey} className={styles.question}>
        <div id='question_text' className={styles.questionText}>
          {questionText || title}
        </div>
        <div className={styles.questionAndDropdownContainer}>
          {this.createQuestionDropDown()}
          {this.showRequired()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Question);
