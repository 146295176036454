import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const ChameleonLoader = (props) => <div id='chameleon-loader' className={styles.container}>
  <div className="chameleon-loader">
    <div className={`chameleon-loader-dots-${props.color} e2e-chameleon-loader size-${props.size}`}/>
  </div>
</div>;

ChameleonLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string
};

ChameleonLoader.defaultProps = {
  color: 'white',
  size: 'xl'
};

export default ChameleonLoader;
