import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import styles from './styles.scss';

class EmptyState extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClickButton: PropTypes.func,
    id: PropTypes.string,
    containerId: PropTypes.string,
    mainMsgId: PropTypes.string,
    subMsgId: PropTypes.string
  };

  static defaultProps = { id: 'empty_state', mainMsgId: 'emptystate_main_message', subMsgId: 'emptystate_sub_message' };

  render() {
    return (
      <div id={this.props.containerId} className={styles.container}>
        <img src={this.props.data.image} alt="No upcoming events"/>
        <h1 id={this.props.mainMsgId} className={styles.mainMessage}> {this.props.data.title} </h1>
        <p id={this.props.subMsgId} className={styles.subMessage}> {this.props.data.description} </p>
        {this.props.data.buttonText && (
          <Button
            id={this.props.id}
            color='link'
            onClick={this.props.onClickButton}
          >
            {this.props.data.buttonText}
          </Button>
        )}
      </div>
    );
  }
}

export default EmptyState;
