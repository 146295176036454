import { deleteSummaryAction, discardSummaryAction, generateSummaryAction, getSummaryAction, saveSummaryAction } from './actions';
import { generateSummaryAndTopics, getSummaryAndTopics } from './summaryHelper';

const initialState = {
  pending: false,
  data: {},
  summaryText: '',
  keyTopics: '',
  summary: {},
  isEditSummaryOpen: false,
  assetKey: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case generateSummaryAction.toString(): {
      return {
        ...state,
        pending: true,
        isEditSummaryOpen: true
      };
    }

    case generateSummaryAction.complete.toString(): {
      const summaryData = JSON.parse(action?.payload?.generatedText);
      const { summaryText, keyTopics } = generateSummaryAndTopics(summaryData);
      return {
        ...state,
        summaryText,
        keyTopics,
        pending: false,
        isEditSummaryOpen: true,
        summary: { ...action.payload }
      };
    }
    case generateSummaryAction.failed.toString(): {
      return {
        ...state,
        pending: false,
        isEditSummaryOpen: false
      };
    }
    case getSummaryAction.toString():
    case saveSummaryAction.toString():
    case deleteSummaryAction.toString(): {
      return {
        ...state,
        pending: true,
        isEditSummaryOpen: false
      };
    }
    case saveSummaryAction.complete.toString(): {
      const { summaryText, keyTopics } = getSummaryAndTopics(action?.summaryData?.generatedText);
      return {
        ...state,
        pending: false,
        summaryText,
        keyTopics,
        summary: { ...action.summaryData },
        assetKey: action.assetKey,
        isEditSummaryOpen: false
      };
    }
    case saveSummaryAction.failed.toString(): {
      return {
        ...state,
        pending: false,
        isEditSummaryOpen: true
      };
    }
    case getSummaryAction.complete.toString(): {
      const { summaryText, keyTopics } = getSummaryAndTopics(action?.payload?.generatedText);
      return {
        ...state,
        summaryText,
        keyTopics,
        pending: false,
        summary: { ...action.payload },
        assetKey: action.assetKey
      };
    }
    case deleteSummaryAction.complete.toString():
    case getSummaryAction.failed.toString():
    case discardSummaryAction.toString(): {
      return {
        ...initialState
      };
    }
    case deleteSummaryAction.failed.toString(): {
      return {
        ...state,
        pending: false
      };
    }
    default: {
      return state;
    }
  }
};
