import * as Constants from 'Containers/StartWebinar/constants';
import * as oAuthService from 'Api/oAuthService';
import { setWebinarDetailsNeedToStartSession } from 'Containers/StartWebinar/sessionStorageHelper';
import { setWebinarLocaleStorageDetails } from 'Containers/StartWebinar/localeStorageHelper';
import { trackWebinarStarted } from 'Mixpanel/Features/Webinar/webinarTracker';

export const startMode = {
  PRACTICE: 'practice',
  LIVE: 'start'
};

export default function startWebinar(mode, webinar, startUrl) {
  const isPractice = mode === startMode.PRACTICE;
  trackWebinarStarted(webinar, isPractice);
  setWebinarLocaleStorageDetails(webinar.webinarKey, mode);
  setWebinarDetailsNeedToStartSession(webinar.webinarKey, startUrl || window.location.href, mode);
  oAuthService.authenticateWithGoToOpener(Constants.REDIRECT_URI_FOR_GOTO_OPENER_AUTH_CODE);
}
