export const APPLICATION_ID = 'com.logmein.gotowebinar';
export const PRODUCT_ID = 'g2w';

export const NOTIFICATION_ID_RECORDING_PROCESSING_COMPLETE = 'recordingProcessingComplete';
export const NOTIFICATION_ID_REGISTRATION_CLOSE_TO_LIMIT = 'registrationsCloseToLimit';

export const NOTIFICATION_ID_NEW_FEATURES_AVAILABLE = 'newFeaturesAvailable';

export const NOTIFICATION_DESCRIPTION_RECORDING_PROCESSING_COMPLETE = 'Recording Processiong Complete Mock Description';
export const NOTIFICATION_DESCRIPTION_REGISTRATION_CLOSE_TO_LIMIT = 'Registration Close to Limit Mock Description';

export const NOTIFICATION_TYPE = 'web';
export const NOTIFICATION_AUTH = 'auth';
export const NOTIFICATION_P256DH = 'p256dh';

export const SET_USER = 'Set User';
