import config from 'appConfig';
import { redirect } from 'Utils/redirector';
import { redirectToNewTab } from '../Utils/redirector';

export function authenticateWithGoToOpener(redirectUri) {
  const url = `${config.oauth.url}/oauth/authorize?response_type=code&client_id=${config.oauth.goToOpenerClientId}&redirect_uri=${redirectUri}`;
  redirectToNewTab(url);
}

export function authenticateWithLinkedinOpener(redirectUri) {
  const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.liveStream.linkedInClientId}&redirect_uri=${redirectUri}&state=rtywqps&scope=r_organization_admin,w_organization_live,r_organization_live,r_member_live,r_liteprofile,w_member_live,r_emailaddress`;
  redirect(url);
}

