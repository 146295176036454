import { createAsyncAction } from 'Reducks/reduxActions';

export const retrieveReplyToAction = createAsyncAction('RETRIEVE_REPLY_TO');
export const updateReplyToAction = createAsyncAction('UPDATE_REPLY_TO');
export const retrieveFollowUpAction = createAsyncAction('RETRIEVE_FOLLOW_UP_EMAIL');
export const updateFollowUpAction = createAsyncAction('UPDATE_FOLLOW_UP_EMAIL');
export const retrieveReminderEmailAction = createAsyncAction('RETRIEVE_REMINDER_EMAIL');
export const updateReminderEmailAction = createAsyncAction('UPDATE_REMINDER_EMAIL');
export const retrieveConfirmationEmailAction = createAsyncAction('RETRIEVE_CONFIRMATION_EMAIL');
export const updateConfirmationEmailAction = createAsyncAction('UPDATE_CONFIRMATION_EMAIL');
export const getCustomEmailDisclaimerAction = createAsyncAction('GET_CUSTOM_EMAIL_DISCLAIMER');
export const updateEmailDisclaimerAction = createAsyncAction('UPDATE_EMAIL_DISCLAIMER');
export const getCertificateInfoAction = createAsyncAction('GET_CERTIFICATE_INFO');
export const createSignatureUploadPolicyAction = createAsyncAction('CREATE_SIGNATURE_UPLOAD_POLICY');
export const uploadFileToS3Action = createAsyncAction('UPLOAD_FILE_TO_S3');
export const updateCertificateInfoAction = createAsyncAction('UPDATE_CERTIFICATE_INFO');
export const deleteCertificateInfoAction = createAsyncAction('DELETE_CERTIFICATE_INFO');
export const getCertificateUrlAction = createAsyncAction('GET_CERTIFICATE_URL');
