import { toggleModal } from '../../Ui/actions';
import { SURVEY, ADD_SESSION_SURVEY } from '../../../Components/Modals/constants';
import { CREATE_WEBINAR_SURVEY_COMPLETE, DELETE_WEBINAR_SURVEY_COMPLETE, RESET_SURVEY } from '../../Survey/constants';

import { addQuestionToSurvey,
  getWebinarSurvey,
  moveSurveyQuestion,
  removeSurveyQuestion } from '../../Survey/actions';

export const initialState = {
  byId: {},
  ids: []
};

const swap = (arr, oldPos, newPos) => {
  if (!arr[newPos]) { return arr; }
  return arr.map((val, index) => {
    switch (index) {
      case oldPos:
        return arr[newPos];
      case newPos:
        return arr[oldPos];
      default:
        return val;
    }
  });
};

const insert = (arr, id) => {
  const index = arr.indexOf(id);
  if (index > -1) {
    arr.splice(index, 1, id);
    return arr;
  }
  return [...arr, id];
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case getWebinarSurvey.fulfilled.toString(): {
      const { entities, result } = action.payload;
      return {
        ...state,
        byId: {
          ...entities.questions
        },
        ids: result.questions || []
      };
    }

    case getWebinarSurvey.rejected.toString(): {
      return {
        ...state,
        byId: {},
        ids: []
      };
    }

    case addQuestionToSurvey.toString(): {
      const { question, questionKey } = action.payload;
      const ids = [...state.copy.ids];
      const newIds = insert(ids, questionKey);
      const entity = { [questionKey]: question };
      return {
        ...state,
        copy: {
          byId: {
            ...state.copy.byId,
            ...entity
          },
          ids: [...newIds]
        }
      };
    }

    case moveSurveyQuestion.toString(): {
      const oldPos = state.copy.ids.indexOf(action.payload.id);
      const newPos = oldPos + action.payload.position;
      const swapped = swap([...state.copy.ids], oldPos, newPos);

      return {
        ...state,
        copy: {
          ...state.copy,
          ids: swapped
        }
      };
    }

    case removeSurveyQuestion.toString(): {
      const ids = [...state.copy.ids].filter((id) => id !== action.payload);
      const questions = { ...state.copy.byId };
      delete questions[action.payload];
      return {
        ...state,
        copy: {
          byId: { ...questions },
          ids
        }
      };
    }

    case toggleModal.toString(): {
      if (action.payload.id === SURVEY || action.payload.id === ADD_SESSION_SURVEY) {
        return {
          ...state,
          copy: {
            byId: state.byId,
            ids: state.ids
          }
        };
      }

      return state;
    }

    case CREATE_WEBINAR_SURVEY_COMPLETE: {
      return {
        ...state,
        byId: state.copy.byId,
        ids: state.copy.ids,
        copy: undefined
      };
    }

    case DELETE_WEBINAR_SURVEY_COMPLETE: {
      return initialState;
    }

    case RESET_SURVEY: {
      return initialState;
    }

    default: return state;
  }
};

