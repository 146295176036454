import * as actions from './actions';
import { toggleTranscriptMode } from '../Transcript/actions';

const initialSearch = {
  isActive: false,
  phrase: '',
  index: -1,
  currentMatch: {
    id: null
  },
  matches: []
};

const initialState = {
  transcript: initialSearch
};

export default (state = initialState, action) => {
  switch (action.type) {
    case String(actions.updateMatches): {
      const { target, phrase, matches } = action.payload;
      const targetState = state[target];

      if (!phrase) {
        return {
          ...state,
          [target]: {
            ...initialSearch,
            isActive: true
          }
        };
      }

      return {
        ...state,
        [target]: {
          ...targetState,
          index: matches.length ? 0 : -1,
          currentMatch: matches.length ? matches[0] : {},
          phrase,
          matches
        }
      };
    }

    case String(actions.updateSearchPhrase): {
      const { target, phrase } = action.payload;
      const targetState = state[target];

      return {
        ...state,
        [target]: {
          ...targetState,
          phrase
        }
      };
    }

    case String(actions.nextMatch): {
      const { target, num } = action.payload;
      const targetState = state[target];
      const newIndex = targetState.index + num;

      return {
        ...state,
        [target]: {
          ...targetState,
          index: newIndex,
          currentMatch: targetState.matches[newIndex] || targetState.matches.slice(-1)[0]
        }
      };
    }

    case String(actions.toggleSearchBar): {
      const { target, bool } = action.payload;
      const targetState = state[target];

      if (!bool) {
        return {
          ...state,
          [target]: initialSearch
        };
      }

      return {
        ...state,
        [target]: {
          ...targetState,
          isActive: bool
        }
      };
    }

    case String(toggleTranscriptMode): {
      return {
        ...state,
        transcript: {
          ...state.transcript,
          isActive: action.payload.mode
        }
      };
    }

    case String(actions.discardMatches): {
      return {
        ...state,
        [action.payload.target]: initialSearch
      };
    }

    default: {
      return state;
    }
  }
};
