import { toggleModal } from '../../Ui/actions';
import { CREATE_WEBINAR_SURVEY_COMPLETE, DELETE_WEBINAR_SURVEY_COMPLETE, RESET_SURVEY } from '../../Survey/constants';
import { addQuestionToSurvey, getWebinarSurvey, removeSurveyChoice } from '../../Survey/actions';

export const initialState = {
  byId: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case getWebinarSurvey.fulfilled.toString(): {
      return {
        ...state,
        byId: {
          ...action.payload.entities.choices
        }
      };
    }

    case getWebinarSurvey.rejected.toString(): {
      return {
        ...state,
        byId: {}
      };
    }

    case removeSurveyChoice.toString(): {
      const newChoices = { ...state.copy.byId };
      delete newChoices[action.payload.answerKey];

      return {
        ...state,
        copy: {
          byId: {
            ...newChoices
          }
        }
      };
    }

    case addQuestionToSurvey.toString(): {
      return {
        ...state,
        copy: {
          byId: {
            ...state.copy.byId,
            ...action.payload.choices
          }
        }
      };
    }

    case toggleModal.toString(): {
      if (action.payload.id === 'SURVEY' || action.payload.id === 'ADD_SESSION_SURVEY') {
        return {
          ...state,
          copy: {
            byId: state.byId

          }
        };
      }

      return state;
    }

    case CREATE_WEBINAR_SURVEY_COMPLETE: {
      return {
        ...state,
        byId: state.copy.byId,
        copy: undefined
      };
    }

    case DELETE_WEBINAR_SURVEY_COMPLETE: {
      return initialState;
    }

    case RESET_SURVEY: {
      return initialState;
    }

    default: return state;
  }
};
