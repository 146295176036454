import { getNotificationPreferencesForUser,
  setNotificationPreferencesForUser, setNotificationPreferenceForUser,
  setPermission, setPushNotificationsSupported } from './actions';
import { changeAllNotificationPreferences, changeNotificationPreference } from './helper';

const initialState = {
  notificationPreferences: {
    notificationSettings: []
  },
  permission: false,
  pushNotificationsSupported: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case getNotificationPreferencesForUser.fulfilled.toString(): {
      return {
        ...state,
        notificationPreferences: action.payload
      };
    }

    case setNotificationPreferenceForUser.fulfilled.toString(): {
      return changeNotificationPreference(state, action.payload);
    }

    case setNotificationPreferencesForUser.fulfilled.toString(): {
      return changeAllNotificationPreferences(state, action.payload);
    }

    case String(setPermission): {
      return {
        ...state,
        permission: action.payload.permission
      };
    }

    case String(setPushNotificationsSupported): {
      return {
        ...state,
        pushNotificationsSupported: action.payload.isSupported
      };
    }

    default: {
      return state;
    }
  }
};
