import CommonFragment from './Fragments/commonFragment';
import { validateEvent } from '../Helpers/messageBusEventValidator';

export default class DashboardClickedEvent extends CommonFragment {
  static get TYPE() {
    return {
      BUTTON: 'BUTTON',
      LINK: 'LINK',
      INPUT: 'INPUT',
      TOGGLESWITCH: 'TOGGLESWITCH',
      SEGMENTEDCONTROL: 'SEGMENTED_CONTROL',
      CHECKBOX: 'CHECKBOX'
    };
  }

  static get EVENTS() {
    return {
      saveVideoEdit: 'saveVideoEditButtonClicked',
      saveRegistrationQuestions: 'saveRegistrationQuestionsButtonClicked',
      updateWebinarRecording: 'updateWebinarRecordingButtonClicked',
      scheduleWebinar: 'scheduleWebinarButtonClicked',
      uploadHandout: 'uploadHandoutButtonClicked',
      publishVideo: 'publishVideoButtonClicked',
      createChannel: 'createChannelButtonClicked',
      createPollQuestions: 'createPollQuestionsButtonClicked',
      createSurvey: 'createSurveyButtonClicked'
    };
  }

  withObjectClicked(objectClicked) {
    this.objectClicked = objectClicked;
    return this;
  }

  withObjectName(objectName) {
    this.objectName = objectName;
    return this;
  }

  withObjectResource(objectResource) {
    this.objectResource = objectResource;
    return this;
  }

  withObjectMetadata(objectMetadata) {
    this.objectMetadata = objectMetadata;
    return this;
  }

  /**
   * @returns an object of type {ClickedEvent} containing all properties set with the 'with' functions.
   * @throws Error if mandatory properties for {ClickedEvent}, {MessageBusEvent} or {CommonFragment} are not set.
   */
  build() {
    const event = super.build();
    validateEvent(this, 'ClickedEvent', ['objectClicked', 'objectName']);
    const clickedEvent = {
      objectClicked: this.objectClicked,
      objectName: this.objectName
    };

    if (this.objectResource) {
      clickedEvent.objectResource = this.objectResource;
    }

    if (this.objectMetadata) {
      clickedEvent.objectMetadata = this.objectMetadata;
    }

    const payloadCopy = Object.assign(event.payload, clickedEvent);
    return Object.freeze({
      ...event,
      payload: payloadCopy
    });
  }
}
