import { addQuestionToSurvey } from '../Reducks/Survey/actions';
import * as transcriptActions from '../Reducks/Transcript/actions';
import Action from '../Mixpanel/Model/action';
import MixpanelTracker from '../Mixpanel/mixpanelTracker';
import { CREATE_WEBINAR_SURVEY_COMPLETE, DELETE_WEBINAR_SURVEY_COMPLETE } from '../Reducks/Survey/constants';
import { get } from '../Utils/objectHelpers';
import { setEventCompactView } from '../Reducks/Ui/actions';
import { updateOrganizerWebinarSettingsAction, trackPaymentTosClickedAction, trackPaymentConnectToStripeClickedAction } from '../Reducks/AccountSettings/actions';

import { EVENT_DETAILS,
  ENGAGEMENT,
  PAYMENT_CONNECT_TO_STRIPE,
  PAYMENT_TOS,
  SETTINGS,
  SURVEY,
  TRANSCRIPT,
  WEBINAR,
  G2W_SHARE_RECORDING_ORG,
  WEBINAR_SUMMARY } from '../Mixpanel/mixpanelConstants';
import { deleteSummaryAction, discardSummaryAction, generateSummaryAction, saveSummaryAction } from '../Reducks/WebinarSummary/actions';

const mixpanelTracker = new MixpanelTracker();

const track = (name, properties) => {
  mixpanelTracker.setEventName(name);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
};

const mixpanelMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case String(addQuestionToSurvey): {
      track(
        EVENT_DETAILS,
        {
          Action: Action.ADD,
          Type: ENGAGEMENT,
          Feature: SURVEY,
          'Question Type': get(action, (a) => a.payload.question.type)
        }
      );
      break;
    }
    case CREATE_WEBINAR_SURVEY_COMPLETE: {
      track(
        EVENT_DETAILS,
        {
          Action: Action.SAVE,
          Type: ENGAGEMENT,
          Feature: SURVEY
        }
      );
      break;
    }
    case DELETE_WEBINAR_SURVEY_COMPLETE: {
      track(
        EVENT_DETAILS,
        {
          Action: Action.REMOVE,
          Type: ENGAGEMENT,
          Feature: SURVEY
        }
      );
      break;
    }
    case String(setEventCompactView.fulfilled): {
      track(
        WEBINAR,
        {
          Action: Action.VIEW_MODE_CHANGE,
          'Compact View': action.payload.isCompactView
        }
      );
      break;
    }
    case String(transcriptActions.createTranscript.fulfilled): {
      track(
        TRANSCRIPT,
        {
          Action: 'Transcript Created',
          assetKey: get(action, (a) => a.payload.assetKey)
        }
      );
      break;
    }
    case String(updateOrganizerWebinarSettingsAction.complete): {
      if (Object.prototype.hasOwnProperty.call(action.data, 'autogenerateTranscriptsEnabled')) {
        track(
          TRANSCRIPT,
          {
            Action: 'Update Settings',
            autoGenerate: get(action, (a) => a.data.autogenerateTranscriptsEnabled)
          }
        );
      } else if (Object.prototype.hasOwnProperty.call(action.data, 'coorgRecordingSharingEnabled')) {
        track(
          G2W_SHARE_RECORDING_ORG,
          {
            Action: 'Update Settings',
            shareRecording: get(action, (a) => a.data.coorgRecordingSharingEnabled)
          }
        );
      }
      break;
    }
    case String(trackPaymentTosClickedAction): {
      track(
        SETTINGS,
        {
          Action: Action.CLICK,
          Type: `${PAYMENT_TOS}`
        }
      );
      break;
    }
    case String(trackPaymentConnectToStripeClickedAction): {
      track(
        SETTINGS,
        {
          Action: Action.CLICK,
          Type: `${PAYMENT_CONNECT_TO_STRIPE}`
        }
      );
      break;
    }
    case String(generateSummaryAction.complete): {
      track(
        WEBINAR_SUMMARY,
        {
          Action: 'Webinar Summary Generated',
          recordingAssetKey: get(action, (a) => a.recordingAssetKey)
        }
      );
      break;
    }
    case String(saveSummaryAction.complete): {
      track(
        WEBINAR_SUMMARY,
        {
          Action: 'Webinar Summary Saved',
          recordingAssetKey: get(action, (a) => a.recordingAssetKey)
        }
      );
      break;
    }
    case String(deleteSummaryAction.complete): {
      track(
        WEBINAR_SUMMARY,
        {
          Action: 'Webinar Summary Deleted',
          assetKey: get(action, (a) => a.assetKey)
        }
      );
      break;
    }
    case String(discardSummaryAction): {
      track(
        WEBINAR_SUMMARY,
        {
          Action: 'Webinar Summary Discarded'
        }
      );
      break;
    }
    default: return next(action);
  }

  return next(action);
};

export default mixpanelMiddleware;
