import React from 'react';
import { bool, func, string, shape } from 'prop-types';

import QuestionOptions from '../QuestionOptions/QuestionOptions';
import styles from './Question.scss';

const Question = ({
  isFirst,
  isLast,
  moveSurveyQuestion,
  question,
  removeSurveyQuestion,
  setActiveSurveyQuestion
}) => {
  const { questionKey, questionText } = question;

  const edit = () => setActiveSurveyQuestion(questionKey, question, {});
  const remove = () => removeSurveyQuestion(questionKey);

  const moveDown = isLast ? null : () => moveSurveyQuestion(questionKey, 1);
  const moveUp = isFirst ? null : () => moveSurveyQuestion(questionKey, -1);

  return (
    <div className={styles.question}>
      <div className={styles.text}>
        {questionText}
      </div>

      <QuestionOptions
        edit={edit}
        moveDown={moveDown}
        moveUp={moveUp}
        remove={remove}
      />
    </div>
  );
};

Question.propTypes = {
  isFirst: bool,
  isLast: bool,
  moveSurveyQuestion: func,
  removeSurveyQuestion: func,
  setActiveSurveyQuestion: func,
  question: shape({
    questionKey: string.isRequired,
    questionText: string.isRequired,
    type: string.isRequired
  }).isRequired
};

export default Question;
