const NEW = 'new';
const CLASSIC = 'classic';
const UNKNOWN = 'unknown';

export default class UserType {
  static get NEW() {
    return NEW;
  }

  static get CLASSIC() {
    return CLASSIC;
  }

  static get UNKNOWN() {
    return UNKNOWN;
  }

  static values() {
    return [NEW, CLASSIC, UNKNOWN];
  }
}
