import { updateSeriesRecurrenceAction,
  deleteWebinarAction,
  startWebinarAction,
  getCalendarDetailsAction } from 'Reducks/Webinar/actions';
import { scheduleWebinarAction } from 'Reducks/ScheduleWebinar/actions';
import { scheduleVirtualEventAction } from 'Reducks/VirtualEventsSchedule/actions';
import { deleteVirtualEventAction } from 'Reducks/VirtualEventDetails/actions';

import { redirect } from 'Utils/redirector';
import { history } from 'Store/configureStore';
import { getRecordingReportAction } from 'Reducks/Recording/Reporting/actionTypes';
import { onVirtualEventSelection } from 'Mixpanel/Features/VirtualEventDetails/VirtualEventDetailsTracker';

const redirectMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case updateSeriesRecurrenceAction.complete.toString(): {
      const deleted = action.deleted;
      if (deleted && deleted.filter((w) => w.webinarKey === action.webinarKey).length > 0) {
        history.push('/');
      }
      break;
    }

    case deleteWebinarAction.complete.toString(): {
      const redirectToDashboard = action.redirectToDashboard;
      if (redirectToDashboard) {
        history.push('/');
      }
      break;
    }

    case deleteVirtualEventAction.complete.toString(): {
      const redirectToDashboard = action.redirectToDashboard;
      if (redirectToDashboard) {
        history.push('/');
      }
      break;
    }

    case startWebinarAction.complete.toString(): {
      const data = action.data;
      if (data) {
        redirect(`${data.redirectUrl}?locale=${data.locale}&sessionTrackingId=${data.sessionTrackingId}&token=${data.token}&code=${data.authCode}`);
      }
      break;
    }
    case scheduleWebinarAction.complete.toString(): {
      const webinarKey = action.webinarKey;
      if (!webinarKey) {
        break;
      }
      history.replace(`/webinar/${webinarKey}`);
      break;
    }

    case scheduleVirtualEventAction.complete.toString(): {
      const eventKey = action.eventKey;
      if (!eventKey) {
        break;
      }
      onVirtualEventSelection(eventKey);
      history.replace(`/event/${eventKey}`, { eventType: 'Virtual', eventId: eventKey });
      break;
    }

    case getRecordingReportAction.complete.toString(): {
      const redirectUrl = action.redirectUrl;
      if (!redirectUrl) {
        break;
      }
      redirect(redirectUrl);
      break;
    }

    case getCalendarDetailsAction.complete.toString(): {
      const downloadUrl = action.downloadUrl;
      if (!downloadUrl) {
        break;
      }
      redirect(downloadUrl);
      break;
    }

    default: {
      break;
    }
  }

  return next(action);
};

export default redirectMiddleware;
