import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import appLogger from 'Utils/logging';

@connect(
  (state) => ({
    darkMode: state.appState.darkMode
  })
)

export default class BodyStyles extends PureComponent {
  static propTypes = {
    darkMode: PropTypes.bool
  }

  static defaultProps = {
    isDark: false
  }

  componentDidCatch(error) {
    const logger = appLogger.create('bodyStyles');
    logger.log(error);
  }

  setUsingMouse = () => {
    document.body.classList.add('using-mouse');
  }

  removeUsingMouse = () => {
    document.body.classList.remove('using-mouse');
  }

  componentDidMount() {
    document.body.classList.add(this.props.darkMode ? 'theme--dark' : 'theme--default');

    document.body.addEventListener('mousedown', this.setUsingMouse);
    document.body.addEventListener('keydown', this.removeUsingMouse);
  }

  componentDidUpdate() {
    /* ie doesnt support replace. */
    if (this.props.darkMode) {
      document.body.classList.remove('theme--default');
      document.body.classList.add('theme--dark');
    } else {
      document.body.classList.remove('theme--dark');
      document.body.classList.add('theme--default');
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.setUsingMouse);
    document.body.removeEventListener('keydown', this.removeUsingMouse);
  }

  render() {
    return null;
  }
}
