import React, { PureComponent } from 'react';
import { any, bool, func, PropTypes } from 'prop-types';
import ChameleonLoader from 'Components/Common/ChameleonLoader';

export default class WithLoading extends PureComponent {
  static propTypes = {
    loading: bool.isRequired,
    Loader: func,
    children: any,
    loaderProps: PropTypes.object
  }

  static defaultProps = {
    loaderProps: {},
    Loader: () => <ChameleonLoader color='blue'/>
  };

  render() {
    const { loading, Loader, loaderProps } = this.props;
    const loaderPropsCopy = { ...loaderProps };
    loaderPropsCopy.isOpen = loading;
    return (
      loading ? <Loader {...loaderPropsCopy} /> : this.props.children
    );
  }
}
