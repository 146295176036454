/* eslint-disable */
import { I18nextProvider } from 'react-i18next';
import React, { Suspense } from 'react';
import configureStore, { history } from 'Store/configureStore';
import { ConnectedRouter } from 'connected-react-router';
import appLogger from 'Utils/logging';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { Route } from 'react-router-dom';
import App from 'Containers/App';
import config from 'appConfig';
import Spinner from 'Components/Common/FullScreenLoader';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { getAuth } from 'Api';
import moment from 'moment';
import { setNumberLocale } from 'Utils/numberExtention';
import { redirect } from 'Utils/redirector';
import { init, configureScope } from '@sentry/browser';
import i18n, { getShortLocale, getCountryFromLocale } from './Utils/i18n';
import { getUserAction, NO_ENTITLEMENTS } from './Reducks/User/actions';
import { getOrganizerWebinarSettingsAction } from './Reducks/AccountSettings/actions';
import { fetchSupportedWebinarTypes } from './Reducks/Webinar/actions';
import 'react-table/react-table.css';
import './Styles/react-table-chameleon.scss';
import './Styles/virtual-event-responsive-modal.scss';
import './Styles/event-calender.scss';
import { initializeMixpanel } from './Mixpanel/mixpanelClient';
import { setGtmPlatformData, reportGtmUserData } from './GoogleTagManager/gtmTracker';
import { setUpPendo } from './Pendo/pendo';

const logger = appLogger.create('App Root');

export const setLanguageFromUser = async (store) => {
  const auth = getAuth();
  if (!auth) return { store };

  try {
    const response = await store.dispatch(getUserAction());
    const { user } = response.action.payload;
    const { metadata } = response.action.payload;
    const language = user && user.locale && getShortLocale(user.locale);
    const timezone = user && user.timezone;
    const countryForUser = user && user.locale && getCountryFromLocale(user.locale);

    // report GTM user and platform data.
    reportGtmUserData(user || {}, metadata);
    setGtmPlatformData(user && user.locale, countryForUser, process.env.ENVIRONMENT);

    moment.tz.setDefault(timezone);
    // sets the locale on Number prototype
    setNumberLocale(language);
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language, () => {
        let lang = language;
        // moment doesn't support simplified chinese
        if (lang === 'zh') {
          lang = 'zh-cn';
        }
        moment.locale(lang);
        document.documentElement.lang = lang;
      });
    }

    return { store, user, metadata };
  } catch (err) {
    if (err.message === NO_ENTITLEMENTS) {
      redirect(config.urls.myAccount);
    }
  }
  return { store };
};

export const getUserSettings = async (store, user, metadata) => {
  if (!user || !metadata) {
    return { store };
  }
  const { accountKey, userKey } = metadata;
  try {
    await store.dispatch(getOrganizerWebinarSettingsAction(accountKey, userKey));
    await store.dispatch(fetchSupportedWebinarTypes(accountKey, userKey));
  } catch (e) {
    return { store, user };
  }

  return { store, user };
};

export const setUpWootric = (store, user) => {
  if (process.env.ENVIRONMENT === 'live' && user) {
    let createdTime;
    if (user.meta && user.meta.created) {
      createdTime = Date.parse(user.meta.created);
    } else {
      const now = new Date();
      now.setDate(now.getDate() - 8); // subtract 8 days from current day as done on broker
      createdTime = Date.parse(now);
    }
    try {
      window.wootricSettings = {
        email: user.userName,
        account_token: config.wootric.key,
        product_name: 'GoToWebinar',
        created_at: createdTime,
        properties: { environment: 'E2E_Live' }
      };
      window.wootric('run');
    } catch (error) {
      logger.error('Error loading wootrics', error);
    }
  }
  return store;
};

const setUpSentry = (user) => {
  init({ dsn: config.sentry.url });
  if (!user) {
    return;
  }
  configureScope((scope) => scope.setUser({ userKey: user.id }));
};

const initialize = async () => {
  const store = await configureStore();
  const { user, metadata } = await setLanguageFromUser(store);
  await getUserSettings(store, user, metadata);
  await render(
    <Suspense fallback={<Spinner/>}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <PersistGate loading={<Spinner />} persistor={persistStore(store)}>
            <ConnectedRouter history={history}>
              <Route component={App} />
            </ConnectedRouter>
          </PersistGate>
        </I18nextProvider>
      </Provider>
    </Suspense>,
    document.getElementById('root')
  );
  setUpPendo(user, metadata);
  setUpWootric(store, user);
  initializeMixpanel();
  setUpSentry(user);
};

initialize();
