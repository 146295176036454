import { createAction, createAsyncAction } from 'redux-promise-middleware-actions';
import { getAssetResource, createAssetVersion } from '../../Api/Assets/assetsService';
import { updateTranscriptFromEdits } from '../../Api/Transcript/transcriptEditingService';

const isInvalid = ({ text }) => (
  ['&lt;', '&gt;', '&amp;'].some((entity) => text.includes(entity))
);

export const saveTranscriptEdit = createAction(
  'SAVE_TRANSCRIPT_EDIT',
  (edit, original) => ({
    edit: { ...edit, error: isInvalid(edit) },
    original
  })
);

export const fetchTranscript = createAsyncAction(
  'FETCH_TRANSCRIPT',
  getAssetResource
);

export const createTranscript = createAsyncAction(
  'CREATE_TRANSCRIPT',
  createAssetVersion
);

export const updateTranscript = createAsyncAction(
  'UPDATE_TRANSCRIPT',
  updateTranscriptFromEdits
);

export const selectUtterance = createAction(
  'SELECT_UTTERANCE',
  (blockId, utteranceId) => ({ blockId, utteranceId })
);

export const toggleTranscriptMode = createAction(
  'TOGGLE_TRANSCRIPT_MODE',
  (mode) => ({ mode })
);

export const discardTranscript = createAction(
  'DISCARD_TRANSCRIPT',
);
