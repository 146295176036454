import config from 'appConfig';
import * as httpMethods from 'Api/Helpers/httpMethods';
import getHeaders from 'Api/Helpers/getHeaders';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from 'Api/Helpers/fetch';

const URL_FEATURE_SERVICE_PREFIX = '/videopush/accounts';

export function getAllVideos(webinarKey, accountKey, userKey) {
  const url = `${config.featureService.url}${URL_FEATURE_SERVICE_PREFIX}/${accountKey}/organizers/${userKey}/events/${webinarKey}/videos`;
  const options = {
    headers: getHeaders(true, 'Bearer'),
    method: httpMethods.GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getVideo(webinarKey, accountKey, userKey, videoKey) {
  const url = `${config.featureService.url}${URL_FEATURE_SERVICE_PREFIX}/${accountKey}/organizers/${userKey}/events/${webinarKey}/videos/${videoKey}`;
  const options = {
    headers: getHeaders(true, 'Bearer'),
    method: httpMethods.GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function createUploadPolicy(webinarKey, accountKey, userKey, fileMetaData) {
  const url = `${config.featureService.url}/fileupload/tenants/videopush/path/accounts/${accountKey}/organizers/${userKey}/events/${webinarKey}/videos`;
  const options = {
    headers: getHeaders(true, 'Bearer'),
    method: httpMethods.POST,
    body: JSON.stringify(fileMetaData)
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function uploadToS3(url, formData) {
  const options = {
    method: httpMethods.POST,
    body: formData
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function uploadYouTubeLink(webinarKey, accountKey, userKey, youTubeLinkData) {
  const url = `${config.featureService.url}${URL_FEATURE_SERVICE_PREFIX}/${accountKey}/organizers/${userKey}/events/${webinarKey}/videos`;
  const options = {
    headers: getHeaders(true, 'Bearer'),
    method: httpMethods.POST,
    body: JSON.stringify(youTubeLinkData)
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function updateVideo(webinarKey, accountKey, userKey, videoKey, videoUpdate) {
  const url = `${config.featureService.url}${URL_FEATURE_SERVICE_PREFIX}/${accountKey}/organizers/${userKey}/events/${webinarKey}/videos/${videoKey}`;
  const options = {
    headers: getHeaders(true, 'Bearer'),
    method: httpMethods.PUT,
    body: JSON.stringify(videoUpdate)
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function deleteVideo(webinarKey, accountKey, userKey, videoKey) {
  const url = `${config.featureService.url}${URL_FEATURE_SERVICE_PREFIX}/${accountKey}/organizers/${userKey}/events/${webinarKey}/videos/${videoKey}`;
  const options = {
    headers: getHeaders(true, 'Bearer'),
    method: httpMethods.DELETE
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function copyVideos(webinarKey, accountKey, userKey, srcWebinarKey) {
  const url = `${config.featureService.url}${URL_FEATURE_SERVICE_PREFIX}/${accountKey}/organizers/${userKey}/events/${webinarKey}/videos?srcEvent=${srcWebinarKey}`;
  const options = {
    headers: getHeaders(true, 'Bearer'),
    method: httpMethods.POST
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}
