import { createAndUpdateChannelAction, publishVideoToChannelAction } from '../Reducks/Channel/actions';
import { setApplicationState } from '../Reducks/AppState/actions';
import { getMessagesByTag } from '../Reducks/MessageCenterNotification/actions';
import { getOrganizerWebinarSettingsAction } from '../Reducks/AccountSettings/actions';
import { scheduleWebinarAction } from '../Reducks/ScheduleWebinar/actions';
import { scheduleVirtualEventAction } from '../Reducks/VirtualEventsSchedule/actions';
import { startMode } from '../Components/Card/Events/startWebinarHelper';
import { startWebinarAction, getWebinarAttendanceAction } from '../Reducks/Webinar/actions';
import { pendoUpdateOptions } from '../Pendo/pendo';
import { getWebinars } from '../Api/Webinar/webinarService';

async function getWebinarCount() {
  return getWebinars({ experienceType: 'all', includes: 'activesessionsinfo' });
}

export const getAppcues = (state) => {
  if (state && state.appState && state.appState.appcues) {
    return state.appState.appcues;
  }
  return {};
};

export const setAppcues = (state, data) => {
  if (state && state.appState && state.appState.appcues) {
    return {
      appcues: {
        ...state.appState.appcues,
        ...data
      }
    };
  }
  return {
    appcues: {
      ...data
    }
  };
};

/* eslint-disable default-case */
const appcuesMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  switch (action.type) {
    case 'SET_FLAGS': {
      if (action.data && action.data.isLDReady) {
        setTimeout(() => {
          pendoUpdateOptions('g2w_schedledV2Enabled', true);
        }, 5000);
      }

      break;
    }

    case startWebinarAction.complete.toString(): {
      const state = getState();
      const isPractice = action.mode === startMode.PRACTICE;
      let data = null;
      if (getAppcues(state).hasRunPracticeSession !== true && isPractice) {
        data = setAppcues(state, { hasRunPracticeSession: true });
        setTimeout(() => {
          pendoUpdateOptions('g2w_hasRunPracticeSession', true);
        }, 5000);
      }
      if (getAppcues(state).hasRunLiveSession !== true && !isPractice) {
        data = setAppcues(state, { hasRunLiveSession: true });
        setTimeout(() => {
          pendoUpdateOptions('g2w_hasRunLiveSession', true);
        }, 5000);
      }
      if (data) {
        dispatch(setApplicationState(data));
      }

      break;
    }

    case scheduleWebinarAction.complete.toString(): {
      const state = getState();
      getWebinarCount().then((res) => {
        const totalWebinars = res && res.total ? res.total : 0;
        setTimeout(() => {
          pendoUpdateOptions('g2w_upcomingWebinarsCount', totalWebinars);
        }, 5000);
      });
      if (getAppcues(state).hasScheduledWebinar !== true) {
        const data = setAppcues(state, { hasScheduledWebinar: true });
        setTimeout(() => {
          pendoUpdateOptions('g2w_hasScheduledWebinar', true);
        }, 5000);
        dispatch(setApplicationState(data));
      }

      break;
    }

    case scheduleVirtualEventAction.complete.toString(): {
      const state = getState();
      if (getAppcues(state).hasScheduledWebinar !== true) {
        const data = setAppcues(state, { hasScheduledWebinar: true });
        setTimeout(() => {
          pendoUpdateOptions('g2w_hasScheduledWebinar', true);
        }, 5000);
        dispatch(setApplicationState(data));
      }

      break;
    }

    case createAndUpdateChannelAction.fulfilled.toString(): {
      const state = getState();
      if (getAppcues(state).hasCreatedChannel !== true) {
        const data = setAppcues(state, { hasCreatedChannel: true });
        setTimeout(() => {
          pendoUpdateOptions('g2w_hasCreatedChannel', true);
        }, 5000);
        dispatch(setApplicationState(data));
      }

      break;
    }

    case publishVideoToChannelAction.fulfilled.toString(): {
      const state = getState();
      if (getAppcues(state).hasPublishedVideo !== true) {
        const data = setAppcues(state, { hasPublishedVideo: true });
        setTimeout(() => {
          pendoUpdateOptions('g2w_hasPublishedVideo', true);
        }, 5000);
        dispatch(setApplicationState(data));
      }
      break;
    }
    case getOrganizerWebinarSettingsAction.fulfilled.toString(): {
      if (action.payload) {
        setTimeout(() => {
          pendoUpdateOptions('g2w_isTranscriptsEnabled', action.payload.transcriptsEnabled || false);
        }, 5000);
      }
      break;
    }

    case getMessagesByTag.fulfilled.toString(): {
      const state = getState();
      const tag = action.payload.tag;
      const messages = action.payload.data;
      if (tag === 'RegistrationLimitReached' && messages.length > 0) {
        const data = setAppcues(state, { registrationLimitReached: true });
        setTimeout(() => {
          pendoUpdateOptions('g2w_registrationLimitReached', true);
        }, 5000);
        dispatch(setApplicationState(data));
      }
      break;
    }

    case getWebinarAttendanceAction.complete.toString(): {
      const state = getState();
      const { attendanceStats } = action;
      const { user } = state;
      const { metadata } = user || {};
      const { maxAttendees } = metadata || {};
      const attendeeCount = (attendanceStats && attendanceStats.attendeeCount) || 0;
      if (maxAttendees) {
        const ratio = (attendeeCount / maxAttendees) * 100;
        if (ratio >= 75) {
          const data = setAppcues(state, { attendeeLimitReached: true });
          setTimeout(() => {
            pendoUpdateOptions('g2w_attendeeLimitReached', true);
          }, 4000);
          dispatch(setApplicationState(data));
        }
      }
      break;
    }
  }
  return next(action);
};

export default appcuesMiddleware;
