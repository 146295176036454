import MixpanelTracker from '../../mixpanelTracker';
import { VIRTUAL_EVENT_TITLE_UPDATE, VIRTUAL_EVENT_DETAILS, VIRTUAL_EVENT_ON_COMPANY_INFO_CHANGED, VIRTUAL_EVENT_ON_COMPANY_SOCIAL_MEDIA_CHANGED,
  VIRTUAL_EVENT_ON_NO_OF_ORGANIZERS_CHANGED, VIRTUAL_EVENT_ON_NO_OF_PANELISTS_CHANGED, VIRTUAL_EVENT_ON_NO_OF_SPEAKERS_CHANGED, ON_EDIT_VIRTUAL_EVENT_DETAILS_API_STATUS } from '../../mixpanelConstants';
import Action from '../../Model/action';

const mixpanelTracker = new MixpanelTracker();

export function onVirtualEventSelection(eventKey) {
  const properties = {
    Action: Action.SELECT,
    eventKey
  };

  mixpanelTracker.setEventName(VIRTUAL_EVENT_DETAILS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onVirtualEventNameChange(title, eventKey) {
  const properties = {
    Action: Action.EDIT,
    title,
    eventKey
  };

  mixpanelTracker.setEventName(VIRTUAL_EVENT_TITLE_UPDATE);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onChangeOfCompanyInfo(eventKey, companyInfo) {
  const properties = {
    Action: Action.EDIT,
    companyInfo,
    eventKey
  };

  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_COMPANY_INFO_CHANGED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onChangeOfCompanySocialMediaUrls(eventKey, urls) {
  const properties = {
    Action: Action.EDIT,
    companySocialMediaUrls: urls,
    eventKey
  };

  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_COMPANY_SOCIAL_MEDIA_CHANGED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onChangeOfNoOfPanelistsTracker(eventData) {
  const properties = {
    Action: Action.EDIT,
    eventData
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_NO_OF_PANELISTS_CHANGED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onChangeOfNoOfOrganizersTracker(eventData) {
  const properties = {
    Action: Action.EDIT,
    eventData
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_NO_OF_ORGANIZERS_CHANGED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onChangeOfNoOfSpeakersTracker(eventData) {
  const properties = {
    Action: Action.EDIT,
    eventData
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_NO_OF_SPEAKERS_CHANGED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onUpdateVirtualEventTracker(eventKey, eventData, status) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    eventData,
    status
  };
  mixpanelTracker.setEventName(ON_EDIT_VIRTUAL_EVENT_DETAILS_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}
