import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import QuestionType from 'Api/Webinar/Survey/Model/questionType';
import InputOption from './inputOption';

class Options extends Component {
  static propTypes = {
    questionType: PropTypes.string,
    onChange: PropTypes.func,
    maxNumberOfChoices: PropTypes.number,
    maxLengthForAnswerOption: PropTypes.number,
    choices: PropTypes.array,
    t: PropTypes.func
  };

  static defaultProps = {
    questionType: QuestionType.SINGLE_CHOICE
  };

  constructor(props) {
    super(props);
    if (!QuestionType.values().includes(this.props.questionType)) {
      throw new Error('Cannot set a question type as property of incorrect type');
    }
  }

  render() {
    const { onChange, questionType, choices } = this.props;
    switch (questionType) {
      case QuestionType.MULTIPLE_CHOICE:
      {
        const multipleChoiceProps = {
          type: QuestionType.MULTIPLE_CHOICE,
          onChange,
          choices,
          maxNumberOfChoices: this.props.maxNumberOfChoices,
          maxLengthForAnswerOption: this.props.maxLengthForAnswerOption
        };
        return <InputOption {...multipleChoiceProps} />;
      }
      case QuestionType.SINGLE_CHOICE:
      {
        const singleChoiceProps = {
          onChange,
          choices,
          maxNumberOfChoices: this.props.maxNumberOfChoices,
          maxLengthForAnswerOption: this.props.maxLengthForAnswerOption
        };
        return <InputOption {...singleChoiceProps} />;
      }
      // TODO: Uncomment when there is a support for ratings 1-10
      // case QuestionType.RATING:
      // {
      //   return <RateOnScale onChange = {onChange} />;
      // }
      default:
      {
        return null;
      }
    }
  }
}
export default withTranslation()(Options);
