import config from 'appConfig';
import getHeaders from 'Api/Helpers/getHeaders';
import * as httpMethods from 'Api/Helpers/httpMethods';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from 'Api/Helpers/fetch';

/**
 * https://confluence.ops.expertcity.com/display/Broker/Q+and+A+microservice
 */

export function getQAndASettings(webinarKey) {
  const url = `${config.qAndAService.url}/events/${webinarKey}/settings/effective`;
  const options = {
    headers: getHeaders(true),
    method: httpMethods.GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function updateQAndASettings(eventKeyList, enableQandA, autoResponseText, isRecurring, webinarKey) {
  let url = `${config.qAndAService.url}/events/${webinarKey}/settings`;
  const body = { enableQandA };
  if (autoResponseText) {
    body.autoResponseText = autoResponseText;
  }
  if (eventKeyList && isRecurring) {
    body.eventKeyList = eventKeyList;
    url = `${config.qAndAService.url}/events/bulk/settings`;
  }
  const options = {
    headers: getHeaders(true),
    method: httpMethods.POST,
    body: JSON.stringify(body)
  };
  return fetchJson(url, options, JSON_MIME_TYPE).then(() => ({ enableQandA, autoResponseText }));
}

