import { call, put, all, fork } from 'redux-saga/effects';
import { takeLatestAction } from 'Utils/sagaUtils';
import { uploadFileToS3 } from '../../Api/AWS/awsService';
import { createfileUploadPayload } from '../../Api/Helpers/upload';
import * as summaryService from '../../Api/WebinarSummary/webinarSummaryService';
import { SEND_NOTIFICATION } from '../Notification/actions';
import { uploadFileToS3Action } from '../RegistrantsList/actions';
import { generateSummaryAction, saveSummaryAction, getSummaryAction, deleteSummaryAction } from './actions';

export function* generateSummary({ userKey, accountKey, recordingAssetKey, showContentFilteringAlert }) {
  try {
    const payload = yield call(summaryService.generateSummary, userKey, accountKey, recordingAssetKey);
    yield put({ type: generateSummaryAction.complete.toString(), payload, recordingAssetKey });
  } catch (error) {
    switch (error?.body?.exception) {
      case 'token_subceed': {
        yield put({ type: SEND_NOTIFICATION, messageKey: 'summary.notifications.generateSummaryError.tokenLimitSubceeded', isError: true });
        break;
      }
      case 'token_exceed': {
        yield put({ type: SEND_NOTIFICATION, messageKey: 'summary.notifications.generateSummaryError.tokenLimitExceeded', isError: true });
        break;
      }
      case 'max_request_exceed': {
        yield put({
          type: SEND_NOTIFICATION,
          messageKey: 'summary.notifications.generateSummaryError.maximumLimitReached',
          isError: true,
          messageKeyParams: [{ count: error?.body?.details[0]?.details }]
        });
        break;
      }
      case 'content_filtered': {
        if (typeof showContentFilteringAlert === 'function') {
          showContentFilteringAlert();
        }
        break;
      }
      default: {
        yield put({ type: SEND_NOTIFICATION, messageKey: 'summary.notifications.generateSummaryError.genericError', isError: true });
        break;
      }
    }
    yield put({ type: generateSummaryAction.failed.toString(), error });
  }
}

export function* getSummary({ userKey, accountKey, recordingAssetKey }) {
  try {
    const data = yield call(summaryService.getSummaryUrl, userKey, accountKey, recordingAssetKey);
    if (data[0]?.data.searchAsset?.assets[0]?.resources[0]?.content?.cdnLocation) {
      const url = data[0].data.searchAsset.assets[0].resources[0].content.cdnLocation;
      const assetKey = data[0].data.searchAsset.assets[0].assetKey;
      const payload = yield call(summaryService.getSummary, url);
      yield put({ type: getSummaryAction.complete.toString(), payload, assetKey });
    } else {
      yield put({ type: getSummaryAction.failed.toString() });
    }
  } catch (error) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'summary.notifications.getSummaryError', isError: true });
    yield put({ type: getSummaryAction.failed.toString(), error });
  }
}

export function* saveSummary({ userKey, accountKey, recordingAssetKey, summaryData }) {
  try {
    const data = yield call(summaryService.saveSummary, userKey, accountKey, recordingAssetKey);
    if (data[0]?.resources[0]?.uploadDetail?.uploadUrl) {
      const assetKey = data[0].assetKey;
      const uploadUrl = data[0].resources[0].uploadDetail.uploadUrl;
      const formMetaData = data[0].resources[0].uploadDetail.formData;
      const file = new Blob([JSON.stringify(summaryData)], { type: 'application/txt' });
      const payload = createfileUploadPayload(formMetaData, file);
      yield call(uploadFileToS3, uploadUrl, payload);
      yield put({ type: uploadFileToS3Action.complete.toString() });
      yield put({ type: saveSummaryAction.complete.toString(), assetKey, summaryData, recordingAssetKey });
    }
  } catch (error) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'summary.notifications.saveSummaryError', isError: true });
    yield put({ type: saveSummaryAction.failed.toString(), error });
  }
}

export function* deleteSummary({ assetKey }) {
  try {
    yield call(summaryService.deleteSummary, assetKey);
    yield put({ type: deleteSummaryAction.complete.toString(), assetKey });
  } catch (error) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'summary.notifications.deleteSummaryError', isError: true });
    yield put({ type: deleteSummaryAction.failed.toString(), error });
  }
}

export default function* rootSaga() {
  yield all([
    fork(takeLatestAction.bind(this, generateSummaryAction.toString(), generateSummary)),
    fork(takeLatestAction.bind(this, getSummaryAction.toString(), getSummary)),
    fork(takeLatestAction.bind(this, saveSummaryAction.toString(), saveSummary)),
    fork(takeLatestAction.bind(this, deleteSummaryAction.toString(), deleteSummary))
  ]);
}
