export function clearSessionStorage(sessionStorageKey) {
  sessionStorage.removeItem(sessionStorageKey);
}

export function getSessionStorage(sessionStorageKey) {
  return sessionStorage.getItem(sessionStorageKey);
}

export function setSessionStorage(sessionStorageKey, sessionStorageValue) {
  try {
    sessionStorage.setItem(sessionStorageKey, sessionStorageValue);
  } catch (e) {
    console.log('session storage is not supported by browser');
  }
}
