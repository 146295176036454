import { createAction } from 'redux-promise-middleware-actions';
import { getMatches } from './operations';

export const discardMatches = createAction(
  'DISCARD_MATCHES',
  (target) => ({ target })
);

export const updateMatches = createAction(
  'UPDATE_MATCHES',
  (target, phrase, utterances) => ({ target, phrase, matches: getMatches(phrase, utterances) })
);

export const updateSearchPhrase = createAction(
  'UPDATE_SEARCH_PHRASE',
  (target, phrase) => ({ target, phrase })
);

export const nextMatch = createAction(
  'NEXT_MATCH',
  (target, num) => ({ target, num })
);

export const toggleSearchBar = createAction(
  'TOGGLE_SEARCH_BAR',
  (target, bool) => ({ target, bool })
);
