import * as Sentry from '@sentry/browser';

const sentryMiddleware = (store) => {
  Sentry.addGlobalEventProcessor((event) => {
    const { ...state } = store.getState();
    return {
      ...event,
      extra: {
        ...event.extra,
        'redux:state': state
      }
    };
  });

  return (next) => (action) => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type
    });

    return next(action);
  };
};

export default sentryMiddleware;
