import config from 'appConfig';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from 'Api/Helpers/fetch';
import { PUT } from 'Api/Helpers/httpMethods';
import getHeaders from '../../Helpers/getHeaders';

const URL_PREFIX = '/api/V2/webinars/';
const URL_RECORDINGS_POSTFIX = '/recordings';

export function getAssociatedRecordings(webinarId, includeOnlineRecordingAssociation = true) {
  let url = `${config.g2w.url}${URL_PREFIX}${webinarId}${URL_RECORDINGS_POSTFIX}`;
  if (includeOnlineRecordingAssociation) {
    url = `${url}?includeOnlineRecordingAssociation=${includeOnlineRecordingAssociation}`;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function createRecordingAssociation(webinarKey = '', recordingKey = '', recordingSettings = {}) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_RECORDINGS_POSTFIX}/${recordingKey}`;
  const body = { recordingKey, recordingSettings };
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(body)
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getAssociatedWebinars(webinarKeys) {
  const options = {
    headers: getHeaders(true)
  };
  const requests = webinarKeys.map((webinarId) => {
    const url = `${config.g2w.url}/api/webinars/${webinarId}`;
    return fetchJson(url, options, 'json');
  });

  return Promise.all(requests);
}
