import { asyncReducer } from 'redux-promise-middleware-actions';
import { undo, redo } from '../UndoHistory/actions';
import * as actions from './actions';
import { toggleSearchBar } from '../Search/actions';

export const TRANSCRIPT_PATH = 'transcript';

const initialState = {
  editMode: true,
  activeBlockId: '',
  activeUtteranceId: '',
  editedUtterances: {},
  data: {},
  invalidUtteranceIds: []
};

const fetchTranscriptReducer = asyncReducer(actions.fetchTranscript);

export default (state = initialState, action) => {
  switch (action.type) {
    case String(actions.fetchTranscript.pending):
    case String(actions.fetchTranscript.fulfilled):
    case String(actions.fetchTranscript.rejected): {
      return {
        ...state,
        ...fetchTranscriptReducer(state, action)
      };
    }

    case String(actions.updateTranscript.pending): {
      return {
        ...state,
        pending: true
      };
    }

    case String(actions.toggleTranscriptMode): {
      return {
        ...state,
        editMode: action.payload.mode,
        activeBlockId: '',
        activeUtteranceId: ''
      };
    }

    case String(toggleSearchBar): {
      return {
        ...state,
        editMode: false
      };
    }

    case String(actions.selectUtterance): {
      return {
        ...state,
        activeBlockId: action.payload.blockId,
        activeUtteranceId: action.payload.utteranceId
      };
    }

    case String(actions.saveTranscriptEdit): {
      const { edit } = action.payload;
      const filtered = state.invalidUtteranceIds.filter((id) => edit.id !== id);

      return {
        ...state,
        invalidUtteranceIds: edit.error ? [...filtered, edit.id] : [...filtered],
        editedUtterances: {
          ...state.editedUtterances,
          [edit.id]: edit
        }
      };
    }

    case String(redo):
    case String(undo): {
      if (action.payload.target !== TRANSCRIPT_PATH) {
        return state;
      }

      const { utterances } = state.data;
      const { item } = action.payload;

      const editedUtterances = {
        ...state.editedUtterances
      };

      if (item.text === utterances[item.id].text) {
        delete editedUtterances[item.id];
        return {
          ...state,
          editedUtterances: { ...editedUtterances }
        };
      }

      return {
        ...state,
        editedUtterances: { ...editedUtterances, [item.id]: item }
      };
    }

    case String(actions.discardTranscript): {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
