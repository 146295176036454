import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { getInterprefyTokenAction, updateInterprefyTokenAction } from './actions';
import * as interprefyService from '../../Api/Webinar/Interprefy/interprefyService';
import { SEND_NOTIFICATION } from '../Notification/actions';

export function* updateInterprefyToken({ webinarKey, data, isSaving }) {
  try {
    yield call(interprefyService.updateInterprefyToken, webinarKey, data);
    yield put({ type: updateInterprefyTokenAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: isSaving ? 'cards.webinar.details.interprefyEvent.tokenSavedSuccessMsg' : 'cards.webinar.details.interprefyEvent.tokenDeletedMsg' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: isSaving ? 'error.interprefyTokenError' : 'error.interprefyDeleteTokenError', isError: true });
    yield put({ type: updateInterprefyTokenAction.failed.toString(), message: e.message });
  }
}

export function* getInterprefyToken({ webinarKey }) {
  try {
    const data = yield call(interprefyService.getInterprefyToken, webinarKey);
    yield put({ type: getInterprefyTokenAction.complete.toString(), data });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({ type: getInterprefyTokenAction.failed.toString(), message: e.message });
  }
}

export function* watchUpdateInterprefyToken() {
  yield takeLatest(updateInterprefyTokenAction.toString(), updateInterprefyToken);
}

export function* watchGetInterprefyToken() {
  yield takeLatest(getInterprefyTokenAction.toString(), getInterprefyToken);
}

export default function* rootSaga() {
  yield all([
    fork(watchUpdateInterprefyToken),
    fork(watchGetInterprefyToken)
  ]);
}
