import errors from 'Api/Helpers/apiErrors.json';
import { call, put, all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { history } from 'Store/configureStore';
import { getRegistrationSettingsAction } from 'Reducks/RegistrationSettings/actions';
import { trackConfigureLiveStreamChannel, trackStartLiveStreamBroadcast } from 'Mixpanel/Features/EventDetails/LiveStreaming/liveStreamingTracker';
import * as webinarService from '../../Api/Webinar/webinarService';
import * as Ops from './operations';
import { constructTimeAndTimeZone } from './webinarDetailsHelper';
import { getAttendanceStats } from '../../Api/reportingService';

import { getPastWebinarsAction,
  clearPastFilteredWebinarsAction,
  clearUpcomingFilteredWebinarsAction,
  deleteWebinarAction,
  getPanelistsAction,
  getWebinarDetailsAction,
  updateWebinarDetailsAction,
  updateWebinarEntitiesAction,
  postWebinarAudioSettingsAction,
  getAutoRecordedAction,
  editPanelistsAction,
  editOrganizersAction,
  getSupportedWebinarTypes,
  getWebinarAttendanceAction,
  startWebinarAction,
  getSeriesRecurrenceAction,
  getFutureSeriesRecurrenceAction,
  updateSeriesRecurrenceAction,
  updateWebinarBrandingAction,
  updateSimuliveRecording,
  getSimuliveRecording,
  getWebinarAction,
  getCalendarDetailsAction,
  createBroadcastSessionAction,
  getActiveTargetsStatusAction,
  stopBroadcastSessionAction,
  getBroadcastStatusAction,
  setLiveStreamingConfigAction,
  getLiveStreamingConfigAction,
  getLinkedinConfigAction,
  getAllLiveStreamingConfigurationAction,
  updateLiveStreamingChannelConfigAction,
  reassignWebinarAction,
  linkedInReloginAction,
  getThemesAction, saveBrandingThemeAction, deleteBrandingThemeAction, updateBrandingThemeAction } from './actions';
import { SEND_NOTIFICATION } from '../Notification/actions';

function* fetchPastWebinars({ page, pageSize }) {
  try {
    const data = yield call(webinarService.getPastWebinars, page, pageSize);
    yield put({ type: getPastWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getPastWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* createBroadcastSession({ config, channel }) {
  try {
    const response = yield call(webinarService.createBroadcastSession, config, channel);
    if (response) {
      yield put({ type: createBroadcastSessionAction.complete.toString(), data: response, channel });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.streamingInitiatedSuccess' });
      trackStartLiveStreamBroadcast(channel);
    }
  } catch (error) {
    switch (error.body.errorList[0].errorCode) {
      case errors.api.livestream.linkedInTokenExpiredError:
        yield put({
          type: createBroadcastSessionAction.failed.toString(),
          message: error.message,
          relogin: true,
          config
        });
        break;
      default:
        yield put({
          type: createBroadcastSessionAction.failed.toString(),
          message: error.message
        });
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.streamingInitiatedFailure', isError: true });
        break;
    }
  }
}

function* linkedInRelogin({ config }) {
  try {
    const response = yield call(webinarService.linkedInRelogin, config);
    if (response) {
      yield put({ type: linkedInReloginAction.complete.toString(), data: response });
    }
  } catch (e) {
    yield put({
      type: linkedInReloginAction.failed.toString(),
      message: e.message
    });
  }
}

function* getActiveTargetsStatus({ config }) {
  try {
    const response = yield call(webinarService.getActiveTargetsStatus, config);
    if (response) {
      yield put({ type: getActiveTargetsStatusAction.complete.toString(), data: response });
    }
  } catch (e) {
    yield put({
      type: getActiveTargetsStatusAction.failed.toString(),
      message: e.message
    });
  }
}

function* stopBroadcastSession({ config, channel }) {
  try {
    const response = yield call(webinarService.stopBroadcastSession, config, channel);
    if (response) {
      yield put({ type: stopBroadcastSessionAction.complete.toString(), data: response, channel });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.streamingStopSuccess' });
    }
  } catch (e) {
    yield put({
      type: stopBroadcastSessionAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.streamingStopFailure', isError: true });
  }
}

function* getBroadcastStatus({ config, channel }) {
  try {
    const response = yield call(webinarService.getBroadcastStatus, config, channel);
    if (response) {
      yield put({ type: getBroadcastStatusAction.complete.toString(), data: response, channel });
    }
  } catch (e) {
    switch (e.body.errorList[0].errorCode) {
      case errors.api.livestream.invalidTarget:
        yield put({
          type: getBroadcastStatusAction.failed.toString(),
          message: e.message,
          channel
        });
        break;
      default:
        yield put({
          type: getBroadcastStatusAction.failed.toString(),
          message: e.message
        });
        break;
    }
  }
}

function* getLinkedinConfig({ config }) {
  try {
    const response = yield call(webinarService.getLinkedinConfig, config);
    if (response) {
      yield put({ type: getLinkedinConfigAction.complete.toString(), data: response });
    }
  } catch (e) {
    yield put({
      type: getLinkedinConfigAction.failed.toString(),
      message: e.message
    });
  }
}

function* getLiveStreamingConfig({ config, channel }) {
  try {
    const response = yield call(webinarService.getLiveStreamingConfig, config, channel);
    if (response) {
      yield put({ type: getLiveStreamingConfigAction.complete.toString(), data: response, channel });
    }
  } catch (e) {
    yield put({
      type: getLiveStreamingConfigAction.failed.toString(),
      message: e.message
    });
  }
}

function* getAllLiveStreamingConfiguration({ config }) {
  try {
    const response = yield call(webinarService.getAllLiveStreamingConfiguration, config);
    if (response) {
      yield put({ type: getAllLiveStreamingConfigurationAction.complete.toString(), data: response });
    }
  } catch (e) {
    yield put({
      type: getAllLiveStreamingConfigurationAction.failed.toString(),
      message: e.message
    });
  }
}

function* setLiveStreamingConfig({ config, channel }) {
  const linkedInAccountType = {
    memberAccount: 'member',
    organisationAccount: 'organisation'
  };
  try {
    const response = yield call(webinarService.setLiveStreamingConfig, config, channel);
    if (response) {
      yield put({ type: setLiveStreamingConfigAction.complete.toString(), data: response, channel });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.successMessage' });
      trackConfigureLiveStreamChannel(channel);
    }
  } catch (e) {
    yield put({
      type: setLiveStreamingConfigAction.failed.toString(),
      message: e.message
    });
    switch (e.body.errorList[0].errorCode) {
      case errors.api.livestream.serverError:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.serverErrorMessage', isError: true });
        break;
      case errors.api.livestream.staleObjectError:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.staleObjectError', isError: true });
        break;
      case errors.api.livestream.unauthorizedUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedUserErrorMessage', isError: true });
        break;
      case errors.api.livestream.invalidLinkedInCode:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.invalidLinkedInCode', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedinUser', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinBroadcasterMember:
        yield put({
          type: setLiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: linkedInAccountType.memberAccount
        });
        break;
      case errors.api.livestream.unauthorizedLinkedinBroadcasterOrganisation:
        yield put({
          type: setLiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: linkedInAccountType.organisationAccount
        });
        break;
      case errors.api.livestream.companyPageNotFound:
        yield put({
          type: setLiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: linkedInAccountType.organisationAccount
        });
        break;
      case errors.api.livestream.unauthorizedLinkedUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedUser', isError: true });
        break;
      case errors.api.livestream.linkedinMultiCompanyPage:
        yield put({
          type: setLiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showMultiCompanyPageMessage: true
        });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.serverErrorMessage', isError: true });
        break;
    }
  }
}

function* updateLiveStreamingChannelConfig({ config, channel }) {
  try {
    const response = yield call(webinarService.updateLiveStreamingChannelConfig, config, channel);
    if (response) {
      yield put({ type: updateLiveStreamingChannelConfigAction.complete.toString(), data: response, channel });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.successMessage' });
      trackConfigureLiveStreamChannel(channel);
    }
  } catch (e) {
    yield put({
      type: updateLiveStreamingChannelConfigAction.failed.toString(),
      message: e.message
    });
    switch (e.body.errorList[0].errorCode) {
      case errors.api.livestream.serverError:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.serverErrorMessage', isError: true });
        break;
      case errors.api.livestream.staleObjectError:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.staleObjectError', isError: true });
        break;
      case errors.api.livestream.unauthorizedUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedUserErrorMessage', isError: true });
        break;
      case errors.api.livestream.invalidLinkedInCode:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.invalidLinkedInCode', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedinUser', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinBroadcasterMember:
        yield put({
          type: setLiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: 'member'
        });
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedinBroadcasterUser', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinBroadcasterOrganisation:
        yield put({
          type: setLiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: 'organisation'
        });
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedinBroadcasterUser', isError: true });
        break;
      case errors.api.livestream.companyPageNotFound:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.companyPageNotFound', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedUser', isError: true });
        break;
      case errors.api.livestream.linkedinMultiCompanyPage:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.linkedinMultiCompanyPageNotSupported', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.serverErrorMessage', isError: true });
        break;
    }
  }
}

function* clearPastFilteredWebinars() {
  try {
    const data = {};
    yield put({ type: clearPastFilteredWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: clearPastFilteredWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* clearUpcomingFilteredWebinars() {
  try {
    const data = {};
    yield put({ type: clearUpcomingFilteredWebinarsAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: clearUpcomingFilteredWebinarsAction.failed.toString(),
      message: e.message
    });
  }
}

function* deleteWebinar({
  webinarKey, sendEmails, body, recurrenceKey, redirectToDashboard
}) {
  try {
    const data = yield call(webinarService.deleteWebinarWithEmail, webinarKey, sendEmails, body);
    if (sendEmails) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.deleteWebinarSuccessWithEmailSent' });
    } else {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.deleteWebinarSuccessWithNoEmailSent' });
    }
    yield put({
      type: deleteWebinarAction.complete.toString(), webinarKey, data, recurrenceKey, redirectToDashboard
    });
  } catch (e) {
    yield put({ type: deleteWebinarAction.failed.toString(), error: e });
    switch (e.body.description) {
      case errors.api.schedule.inSession:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.deleteWebinarInSessionFail', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.deleteWebinarFail', isError: true });
    }
  }
}

function* fetchPanelists({ webinarKey }) {
  try {
    const data = yield call(webinarService.getWebinarDetails, webinarKey, ['panelists']);
    yield put({ type: getPanelistsAction.complete.toString(), data, webinarKey });
  } catch (e) {
    yield put({
      type: getPanelistsAction.failed.toString(),
      message: e.message
    });
  }
}

function* reassignWebinar({ webinarKey }) {
  try {
    yield call(webinarService.reassignWebinar, webinarKey);
    yield put({ type: reassignWebinarAction.complete.toString() });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'inactiveOrganizer.successMessage' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'inactiveOrganizer.failed', isError: true });
    yield put({
      type: reassignWebinarAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchWebinarDetails({ webinarKey, details }) {
  try {
    const data = yield call(webinarService.getWebinarDetails, webinarKey, details);
    yield put({ type: getWebinarDetailsAction.complete.toString(), data, webinarKey });
  } catch (e) {
    yield put({
      type: getWebinarDetailsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchThemes({ webinarKey, organizerKeys }) {
  try {
    const data = yield call(webinarService.getThemes, webinarKey, organizerKeys);
    yield put({ type: getThemesAction.complete.toString(), data, webinarKey });
  } catch (e) {
    yield put({
      type: getThemesAction.failed.toString(),
      message: e.message
    });
  }
}

function* updateWebinarDetails({ webinarKey, details, notifyParticipants }) {
  try {
    const data = yield call(webinarService.updateWebinarDetails, webinarKey, details, notifyParticipants);
    yield put({
      type: updateWebinarDetailsAction.complete.toString(), data, details, webinarKey
    });

    yield put({
      type: updateWebinarEntitiesAction, details, webinarKey
    });
    if (notifyParticipants) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.updateSuccessWithEmailSent' });
    } else {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.updateSuccessWithoutEmail' });
    }
  } catch (e) {
    yield put({ type: updateWebinarDetailsAction.failed.toString(), message: e.message });
    if (e.body.errorCode === errors.api.schedule.maintenanceErrorCode) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maintenanceWindowFailure', isError: true, timeOut: 7000 });
      return;
    }
    switch (e.body.Details) {
      case errors.api.schedule.timeInPast:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
        break;
      case errors.api.schedule.endBeforeStart:
      case errors.api.schedule.recurrenceEndBeforeStart:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.endTimeAfterStartTimeFail', isError: true });
        break;
      case errors.api.schedule.overYearAhead:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.startTimeYearAheadFail', isError: true });
        break;
      case errors.api.schedule.invalidSubject:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidTitleFail', isError: true });
        break;
      case errors.api.schedule.invalidDescription:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidTitleFail', isError: true });
        break;
      case errors.api.schedule.maximumAllowedEvent:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maximumUpcomingEventsAllowed', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.updateFail', isError: true });
    }
  }
}

function* editPanelists({ webinarKey, panelists, deleteKeys }) {
  try {
    const deleteRequest = deleteKeys && deleteKeys.length > 0 ? yield call(webinarService.deletePanelist, webinarKey, deleteKeys) : null;
    const addRequest = panelists && panelists.length > 0 ? yield call(webinarService.createPanelists, webinarKey, panelists) : null;
    const data = [addRequest].concat(deleteRequest);
    yield put({
      type: editPanelistsAction.complete.toString(), data, deleteKeys, webinarKey
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.panelistsUpdateSuccess' });
  } catch (e) {
    yield put({ type: editPanelistsAction.failed.toString(), message: e.message });

    switch (e.body.Details) {
      case errors.api.schedule.invalidEmail:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidEmailFail', isError: true });
        break;
      case errors.api.schedule.maxPanelistsCount:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.panelistsMaxLimitReached', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.panelistsUpdateFail', isError: true });
    }
  }
}

function* editOrganizers({ webinarKey, organizers, deleteKeys }) {
  try {
    const data = yield call(webinarService.editOrganizer, webinarKey, organizers, deleteKeys);
    yield put({
      type: editOrganizersAction.complete.toString(), data, deleteKeys, webinarKey
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.organizersUpdateSuccess' });
  } catch (e) {
    yield put({ type: editOrganizersAction.failed.toString(), message: e.message });

    switch (e.body.Details) {
      case errors.api.schedule.invalidEmail:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidEmailFail', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.organizersUpdateFail', isError: true });
    }
  }
}

function* updateWebinarAudioSettings({ webinarKey, audioDetails, notifyParticipants }) {
  try {
    yield call(webinarService.updateWebinarAudioSettings, webinarKey, audioDetails, notifyParticipants);
    const data = yield call(webinarService.getWebinarDetails, webinarKey, ['audio']);
    yield put({ type: postWebinarAudioSettingsAction.complete.toString(), data, webinarKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'audioSettings.audioSaved' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
    yield put({
      type: postWebinarAudioSettingsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchWebinarAutoRecorded({ organizerKey, webinarKey }) {
  try {
    const isAutoRecorded = yield call(webinarService.getWebinarAutoRecorded, organizerKey, webinarKey);
    yield put({ type: getAutoRecordedAction.complete.toString(), payload: { autoRecordEnabled: isAutoRecorded, webinarKey } });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: getAutoRecordedAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchSupportedWebinarTypes({ accountKey, userKey }) {
  try {
    const supportedTypes = yield call(webinarService.getSupportedWebinarTypes, accountKey, userKey);
    yield put({ type: getSupportedWebinarTypes.complete.toString(), supportedTypes });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: getSupportedWebinarTypes.failed.toString(),
      message: e.message
    });
  }
}

export function* getCalendarDetails({ webinarKey, userKey, sourceUrl }) {
  try {
    const downloadUrl = yield call(webinarService.getCalendarDetails, webinarKey, userKey, sourceUrl);
    yield put({ type: getCalendarDetailsAction.complete.toString(), downloadUrl });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
    yield put({
      type: getCalendarDetailsAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchWebinarAttendance({ webinarKey }) {
  try {
    const attendanceStats = yield call(getAttendanceStats, webinarKey);
    yield put({ type: getWebinarAttendanceAction.complete.toString(), webinarKey, attendanceStats });
  } catch (e) {
    yield put({
      type: getWebinarAttendanceAction.failed.toString(),
      message: e.message,
      webinarKey
    });
  }
}

function* startWebinar({
  webinarKey, successUrl, mode, authCode, redirectUrl
}) {
  try {
    const data = yield call(webinarService.startWebinar, webinarKey, successUrl, mode, authCode, redirectUrl);
    data.authCode = authCode;
    yield put({ type: startWebinarAction.complete.toString(), data, mode });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.startWebinarFail', isError: true });
    yield put({
      type: startWebinarAction.failed.toString(),
      message: e.message
    });
  }
}

function* fetchSeriesRecurrence({ recurrenceKey }) {
  try {
    const data = yield call(webinarService.getSeriesRecurrence, recurrenceKey);
    yield put({ type: getSeriesRecurrenceAction.complete.toString(), data, recurrenceKey });
  } catch (e) {
    yield put({ type: getSeriesRecurrenceAction.failed.toString(), message: e.message });
  }
}

function* fetchFutureSeriesRecurrence({ recurrenceKey }) {
  try {
    const data = yield call(webinarService.getFutureSeriesRecurrence, recurrenceKey);
    yield put({ type: getFutureSeriesRecurrenceAction.complete.toString(), data, recurrenceKey });
  } catch (e) {
    yield put({ type: getFutureSeriesRecurrenceAction.failed.toString(), message: e.message });
  }
}

export function* updateSeriesRecurrence({
  webinarKey, recurrenceKey, added, modified, notifyParticipants
}) {
  try {
    const [modifiedData, addedData] = yield all([
      modified && modified.length > 0 ? modified.map((details) => call(webinarService.updateWebinarDetails, details.webinarKey,
        constructTimeAndTimeZone(details), notifyParticipants)) : null,
      added && added.length > 0 ? added.map((details) => call(webinarService.updateSeriesRecurrence, recurrenceKey,
        { times: [details], timeZone: details.timeZone }, notifyParticipants)) : null
    ]);
    yield put({
      type: updateSeriesRecurrenceAction.complete.toString(), webinarKey, addedData, modifiedData, added, modified, recurrenceKey
    });
    if (notifyParticipants) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.editDateAndTime.updateSuccessWithEmailSent', isError: false });
    } else {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.editDateAndTime.updateSuccessWithoutEmail', isError: false });
    }
  } catch (e) {
    if (e.status === 400) {
      switch (e.body.Details) {
        case errors.api.schedule.timeInPast:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
          break;
        case errors.api.schedule.overlapingWebinarTimes:
        case errors.api.schedule.overlapingWebinarSeriesTime:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.invalidTimeWebinarsOverlap', isError: true });
          break;
        case errors.api.schedule.overYearAhead:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.startTimeYearAheadFail', isError: true });
          break;
        case errors.api.schedule.maximumAllowedEvent:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maximumUpcomingEventsAllowed', isError: true });
          break;
        default:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.editDateAndTime.updateFailed', isError: true });
          break;
      }
    }
    yield put({ type: updateSeriesRecurrenceAction.failed.toString(), message: e.message });
  }
}

function* updateWebinarBranding({ webinarKey, brandingData }) {
  try {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'branding.brandingUpdateInProgress', isInfo: true });
    yield call(webinarService.updateBranding, webinarKey, brandingData);
    const data = yield call(webinarService.getWebinarDetails, webinarKey, ['branding']);
    yield put({ type: updateWebinarBrandingAction.complete.toString(), webinarKey, data: data[0] });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.brandingUpdated' });
  } catch (e) {
    yield put({ type: updateWebinarBrandingAction.failed.toString(), webinarKey, message: e.message });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
  }
}

function* saveBrandingTheme({ webinarKey, brandingDataWithTitle }) {
  try {
    const data = yield call(webinarService.saveBrandingTheme, brandingDataWithTitle);
    yield put({ type: saveBrandingThemeAction.complete.toString(), webinarKey, data });
    yield call(fetchThemes, { webinarKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'branding.brandingThemeSaved' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
  }
}

function* updateBrandingTheme({ webinarKey, brandingDataWithTitleAndId }) {
  try {
    const data = yield call(webinarService.updateBrandingTheme, brandingDataWithTitleAndId);
    yield put({ type: updateBrandingThemeAction.complete.toString(), webinarKey, data });
    yield call(fetchThemes, { webinarKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'branding.brandingThemeUpdated' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
  }
}

function* deleteBrandingTheme({ webinarKey, themeId }) {
  try {
    yield call(webinarService.deleteBrandingTheme, themeId);
    yield call(fetchThemes, { webinarKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'branding.brandingThemeDeleted' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
  }
}

export function* updateSimuliveWebinarRecording({ webinarKey, selectedRecording }) {
  try {
    yield call(webinarService.updateRecordingForSimuliveWebinar, webinarKey, selectedRecording.assetKey);
    yield put({ type: updateSimuliveRecording.complete.toString() });
    yield put({ type: getSimuliveRecording.fulfilled.toString(), payload: selectedRecording });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.recordingEvent.recordingUpdated' });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.genericError', isError: true });
  }
}

function* fetchWebinar({ webinarKey, organizerKey, shouldLoadWebinar }) {
  let mappedInternalOrganizerKeysWithoutMe = [];
  if (shouldLoadWebinar) {
    try {
      const data = yield call(webinarService.getWebinar, webinarKey);
      const coOrganizers = yield call(webinarService.getWebinarCoOrganizers, webinarKey);
      const internalOrganizerKeys = coOrganizers.filter((o) => (o.external !== 'true'));
      const mappedInternalOrganizerKeys = internalOrganizerKeys.map((o) => (o.organizerKey));
      mappedInternalOrganizerKeysWithoutMe = mappedInternalOrganizerKeys.filter((o) => (o !== organizerKey));
      if (organizerKey === data.organizerKey) {
        yield put({ type: getWebinarAction.complete.toString(), data: Ops.formatWebinarsById([data]), webinarKey });
      } else {
        try {
          if (mappedInternalOrganizerKeys) {
            if (mappedInternalOrganizerKeys.includes(organizerKey)) {
              yield put({ type: getWebinarAction.complete.toString(), data: Ops.formatWebinarsById([data]), webinarKey });
            }

            try {
              yield put({ type: getPanelistsAction.toString(), webinarKey });
              yield put({ type: getWebinarDetailsAction.toString(), webinarKey, details: ['audio', 'coorganizers', 'branding'] });
              yield put({ type: getThemesAction.toString(), webinarKey, organizerKeys: [...mappedInternalOrganizerKeysWithoutMe, data.organizerKey] });
              yield put({ type: getAutoRecordedAction.toString(), organizerKey, webinarKey });
              yield put({ type: getRegistrationSettingsAction.toString(), webinarKey });
            } catch (e) {
              yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
            }
            return;
          }

          history.replace({ pathname: '/error', state: { titleKey: 'cards.webinar.details.unauthorizedWebinar' } });
          yield put({ type: getWebinarAction.failed.toString(), webinarKey, message: 'unauthorized' });
          return;
        } catch (e) {
          history.replace({ pathname: '/error', state: { titleKey: 'cards.webinar.details.unauthorizedWebinar' } });
          yield put({ type: getWebinarAction.failed.toString(), webinarKey, message: 'unauthorized' });
          return;
        }
      }
    } catch (e) {
      history.replace({ pathname: '/error', state: { titleKey: 'cards.webinar.details.notFound' } });
      yield put({ type: getWebinarAction.failed.toString(), webinarKey, message: e.message });
      return;
    }
  }

  try {
    yield put({ type: getPanelistsAction.toString(), webinarKey });
    yield put({ type: getWebinarDetailsAction.toString(), webinarKey, details: ['audio', 'coorganizers', 'branding'] });
    yield put({ type: getThemesAction.toString(), webinarKey, organizerKeys: mappedInternalOrganizerKeysWithoutMe });
    yield put({ type: getAutoRecordedAction.toString(), organizerKey, webinarKey });
    yield put({ type: getRegistrationSettingsAction.toString(), webinarKey });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
  }
}

// ------------------------------------ WATCHERS -----------------------------------
export function* watchFetchPastWebinar() {
  yield takeLatest(getPastWebinarsAction.toString(), fetchPastWebinars);
}
export function* watchcreateBroadcastSession() {
  yield takeLatest(createBroadcastSessionAction.toString(), createBroadcastSession);
}
export function* watchLinkedInRelogin() {
  yield takeLatest(linkedInReloginAction.toString(), linkedInRelogin);
}
export function* watchGetActiveTargetsStatus() {
  yield takeLatest(getActiveTargetsStatusAction.toString(), getActiveTargetsStatus);
}
export function* watchstopBroadcastSession() {
  yield takeLatest(stopBroadcastSessionAction.toString(), stopBroadcastSession);
}
export function* watchgetBroadcastStatus() {
  yield takeLatest(getBroadcastStatusAction.toString(), getBroadcastStatus);
}
export function* watchgetLiveStreamingConfig() {
  yield takeLatest(getLiveStreamingConfigAction.toString(), getLiveStreamingConfig);
}
export function* watchgetLinkedinConfig() {
  yield takeLatest(getLinkedinConfigAction.toString(), getLinkedinConfig);
}
export function* watchgetAllLiveStreamingConfiguration() {
  yield takeLatest(getAllLiveStreamingConfigurationAction.toString(), getAllLiveStreamingConfiguration);
}
export function* watchsetLiveStreamingConfig() {
  yield takeLatest(setLiveStreamingConfigAction.toString(), setLiveStreamingConfig);
}
export function* watchupdateLiveStreamingChannelConfig() {
  yield takeLatest(updateLiveStreamingChannelConfigAction.toString(), updateLiveStreamingChannelConfig);
}
export function* watchClearPastFilteredWebinars() {
  yield takeLatest(clearPastFilteredWebinarsAction.toString(), clearPastFilteredWebinars);
}

export function* watchClearUpcomingFilteredWebinars() {
  yield takeLatest(clearUpcomingFilteredWebinarsAction.toString(), clearUpcomingFilteredWebinars);
}

export function* watchFetchSupportedWebinarTypes() {
  yield takeLatest(getSupportedWebinarTypes.toString(), fetchSupportedWebinarTypes);
}

export function* watchDeleteWebinar() {
  yield takeLatest(deleteWebinarAction.toString(), deleteWebinar);
}

export function* watchFetchPanelists() {
  yield takeEvery(getPanelistsAction.toString(), fetchPanelists);
}

export function* watchReassignWebinar() {
  yield takeEvery(reassignWebinarAction.toString(), reassignWebinar);
}

export function* watchFetchWebinarDetails() {
  yield takeLatest(getWebinarDetailsAction.toString(), fetchWebinarDetails);
}

export function* watchFetchThemes() {
  yield takeLatest(getThemesAction.toString(), fetchThemes);
}

export function* watchUpdateWebinarDetails() {
  yield takeLatest(updateWebinarDetailsAction.toString(), updateWebinarDetails);
}

export function* watchUpdateWebinarAudioSettings() {
  yield takeLatest(postWebinarAudioSettingsAction.toString(), updateWebinarAudioSettings);
}

export function* watchFetchWebinarAutoRecorded() {
  yield takeLatest(getAutoRecordedAction.toString(), fetchWebinarAutoRecorded);
}

export function* watchEditPanelists() {
  yield takeLatest(editPanelistsAction.toString(), editPanelists);
}

export function* watchEditOrganizers() {
  yield takeLatest(editOrganizersAction.toString(), editOrganizers);
}

export function* watchFetchWebinarAttendance() {
  yield takeEvery(getWebinarAttendanceAction.toString(), fetchWebinarAttendance);
}

export function* watchStartWebinar() {
  yield takeLatest(startWebinarAction.toString(), startWebinar);
}

export function* watchSeriesRecurrence() {
  yield takeLatest(getSeriesRecurrenceAction.toString(), fetchSeriesRecurrence);
}

export function* watchFutureSeriesRecurrence() {
  yield takeLatest(getFutureSeriesRecurrenceAction.toString(), fetchFutureSeriesRecurrence);
}

export function* watchUpdateSeriesRecurrence() {
  yield takeLatest(updateSeriesRecurrenceAction.toString(), updateSeriesRecurrence);
}

export function* watchUpdateWebinarBranding() {
  yield takeLatest(updateWebinarBrandingAction.toString(), updateWebinarBranding);
}

export function* watchSaveBrandingThemeAction() {
  yield takeLatest(saveBrandingThemeAction.toString(), saveBrandingTheme);
}

export function* watchUpdateBrandingThemeAction() {
  yield takeLatest(updateBrandingThemeAction.toString(), updateBrandingTheme);
}

export function* watchDeleteBrandingThemeAction() {
  yield takeLatest(deleteBrandingThemeAction.toString(), deleteBrandingTheme);
}

export function* watchFetchWebinar() {
  yield takeLatest(getWebinarAction.toString(), fetchWebinar);
}

export function* watchGetCalendarDetailsForWebinar() {
  yield takeLatest(getCalendarDetailsAction.toString(), getCalendarDetails);
}

export function* watchUpdateRecordingForSimulive() {
  yield takeLatest(updateSimuliveRecording.toString(), updateSimuliveWebinarRecording);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchPastWebinar),
    fork(watchcreateBroadcastSession),
    fork(watchLinkedInRelogin),
    fork(watchGetActiveTargetsStatus),
    fork(watchstopBroadcastSession),
    fork(watchgetBroadcastStatus),
    fork(watchsetLiveStreamingConfig),
    fork(watchupdateLiveStreamingChannelConfig),
    fork(watchgetLiveStreamingConfig),
    fork(watchgetLinkedinConfig),
    fork(watchgetAllLiveStreamingConfiguration),
    fork(watchClearPastFilteredWebinars),
    fork(watchClearUpcomingFilteredWebinars),
    fork(watchDeleteWebinar),
    fork(watchFetchPanelists),
    fork(watchReassignWebinar),
    fork(watchFetchWebinarDetails),
    fork(watchFetchThemes),
    fork(watchUpdateWebinarDetails),
    fork(watchUpdateWebinarAudioSettings),
    fork(watchEditPanelists),
    fork(watchEditOrganizers),
    fork(watchFetchWebinarAutoRecorded),
    fork(watchFetchSupportedWebinarTypes),
    fork(watchFetchWebinarAttendance),
    fork(watchStartWebinar),
    fork(watchGetCalendarDetailsForWebinar),
    fork(watchSeriesRecurrence),
    fork(watchFutureSeriesRecurrence),
    fork(watchUpdateSeriesRecurrence),
    fork(watchUpdateWebinarBranding),
    fork(watchSaveBrandingThemeAction),
    fork(watchUpdateBrandingThemeAction),
    fork(watchDeleteBrandingThemeAction),
    fork(watchUpdateRecordingForSimulive),
    fork(watchFetchWebinar)
  ]);
}
