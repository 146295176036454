import { createAction, createAsyncAction } from 'redux-promise-middleware-actions';
import { createAssetVersion, getRecordingByAssetKeySearchAPI } from '../../Api/Assets/assetsService';
import { createVideoFromEdits } from '../../Api/Video/videoEditingService';
import { getContents } from '../../Api/GoToStage/contentService';
import { getRecordingKey } from './selectors';
import { get } from '../../Utils/objectHelpers';

export const closePreview = createAction('PREVIEW_MODE_OFF');
export const discardVideo = createAction('DISCARD_VIDEO');
export const disableVideoEditor = createAction('DISABLE_VIDEO_EDITOR');
export const enableVideoEditor = createAction('ENABLE_VIDEO_EDITOR');
export const openPreview = createAction('PREVIEW_MODE_ON');

export const updateVideo = createAction(
  'UPDATE_VIDEO_META_DATA',
  (updates) => updates
);

export const saveEdit = createAction(
  'SAVE_EDIT',
  (edit) => ({ id: edit.id, edit })
);

export const deleteEdit = createAction(
  'DELETE_EDIT',
  (id) => ({ id })
);

export const fetchAsset = createAsyncAction('FETCH_ASSET', async (assetKey, assetVersion, accountKey) => {
  const assets = await getRecordingByAssetKeySearchAPI(assetKey, accountKey);
  const asset = get(assets, (a) => a._embedded.assets[0], {});
  const recordingKey = getRecordingKey(asset);
  const [content] = await getContents([recordingKey]);
  return {
    ...asset,
    content
  };
});

export const saveVideo = createAsyncAction(
  'SAVE_EDITED_VIDEO',
  createVideoFromEdits
);

export const generateAudioWaveform = createAsyncAction(
  'GENERATE_AUDIO_WAVEFORM',
  createAssetVersion
);

