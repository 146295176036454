import errors from 'Api/Helpers/apiErrors.json';
import { call, put, all, fork } from 'redux-saga/effects';
import { takeLatestAction } from 'Utils/sagaUtils';
import * as surveyService from 'Api/Webinar/Survey/surveyService';
import { onSurveyAddedToSessionSagaTracker, onSurveyDeletedFromSessionSagaTracker } from 'Mixpanel/Features/VirtualEventDetails/engagementDetailsTracker';
import * as Actions from './constants';
import { SEND_NOTIFICATION } from '../Notification/actions';

function* createWebinarSurvey({ webinarKey, survey }) {
  try {
    const { key } = yield call(surveyService.createWebinarSurvey, webinarKey, survey);
    onSurveyAddedToSessionSagaTracker(webinarKey, survey, 'Success');
    yield put({ type: Actions.CREATE_WEBINAR_SURVEY_COMPLETE, payload: { key } });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'survey.notification.saved', isError: false });
  } catch (error) {
    yield put({
      type: Actions.CREATE_WEBINAR_SURVEY_FAILURE,
      error
    });
    onSurveyAddedToSessionSagaTracker(webinarKey, survey, 'Failure');
    switch (error.body.Details) {
      case errors.api.survey.invalidTitle:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'survey.notification.invalidTitleFail', isError: true });
        break;
      case errors.api.survey.invalidQuestionText:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'survey.notification.invalidQuestionTextFail', isError: true });
        break;
      case errors.api.survey.invalidAnswerText:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'survey.notification.invalidAnswerTextFail', isError: true });
        break;
      case errors.api.survey.invalidLabelText:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'survey.notification.invalidLabelTextFail', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'survey.notification.failed', isError: true });
    }
  }
}

function* deleteWebinarSurvey(webinar) {
  try {
    yield call(surveyService.deleteWebinarSurvey, webinar.webinarKey);
    onSurveyDeletedFromSessionSagaTracker(webinar.webinarKey, 'Success');
    yield put({ type: Actions.DELETE_WEBINAR_SURVEY_COMPLETE });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'survey.notification.deleted', isError: false });
  } catch (error) {
    yield put({
      type: Actions.DELETE_WEBINAR_SURVEY_FAILURE,
      error
    });
    onSurveyDeletedFromSessionSagaTracker(webinar.webinarKey, 'Failure');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'survey.notification.deleteFailed', isError: true });
  }
}

// TODO: Delete these sagas if not needed
function* retrieveWebinarSurveyInfo(webinarKey) {
  try {
    const data = yield call(surveyService.retrieveWebinarSurveyInfo, webinarKey);
    yield put({ type: Actions.RETRIEVE_WEBINAR_SURVEY_INFO_COMPLETE, data });
  } catch (error) {
    yield put({
      type: Actions.RETRIEVE_WEBINAR_SURVEY_INFO_FAILURE,
      error
    });
  }
}

function* retrieveWebinarSurveyResponses(webinarKey, sessionKey) {
  try {
    const data = yield call(surveyService.retrieveWebinarSurveyResponses, webinarKey, sessionKey);
    yield put({ type: Actions.RETRIEVE_WEBINAR_SURVEY_RESPONSES_COMPLETE, data });
  } catch (error) {
    yield put({
      type: Actions.RETRIEVE_WEBINAR_SURVEY_RESPONSES_FAILURE,
      error
    });
  }
}

function* retrieveWebinarSurveyResponsesForAttendee(webinarKey) {
  try {
    const data = yield call(surveyService.retrieveWebinarSurveyInfo, webinarKey);
    yield put({ type: Actions.RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_COMPLETE, data });
  } catch (error) {
    yield put({
      type: Actions.RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_FAILURE,
      error
    });
  }
}

export default function* rootSaga() {
  yield all([
    fork(takeLatestAction.bind(this, Actions.CREATE_WEBINAR_SURVEY_START, createWebinarSurvey)),
    fork(takeLatestAction.bind(this, Actions.DELETE_WEBINAR_SURVEY_START, deleteWebinarSurvey)),
    fork(takeLatestAction.bind(this, Actions.RETRIEVE_WEBINAR_SURVEY_INFO_START, retrieveWebinarSurveyInfo)),
    fork(takeLatestAction.bind(this, Actions.RETRIEVE_WEBINAR_SURVEY_RESPONSES_START, retrieveWebinarSurveyResponses)),
    fork(takeLatestAction.bind(this, Actions.RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_START, retrieveWebinarSurveyResponsesForAttendee))
  ]);
}
