import { normalize } from 'normalizr';
import { createAction, createAsyncAction } from 'redux-promise-middleware-actions';

import * as Constants from './constants';
import { retrieveWebinarSurvey } from '../../Api/Webinar/Survey/surveyService';
import surveyQuestionsSchema from '../_Entities/SurveyQuestions/schema';
import { uniq } from '../../Utils/arrayHelpers';

const filterRatingAnswers = (questions) => questions.map((question) => {
  if (question.type === 'rating') {
    const filtered = question.choices.filter(({ answerText }) => answerText.includes(':'));
    return { ...question, choices: filtered };
  }
  return question;
});

export const getWebinarSurvey = createAsyncAction(
  'GET_WEBINAR_SURVEY',
  async (webinarKey) => {
    const { questions, ...rest } = await retrieveWebinarSurvey(webinarKey);
    const questionsFormatted = filterRatingAnswers(questions);
    const normalized = normalize({ questions: questionsFormatted, webinar: webinarKey }, surveyQuestionsSchema);
    return { ...normalized, ...rest };
  }
);

export const saveSurveyQuestion = createAction(
  'SAVE_SURVEY_QUESTION',
);

export const setActiveSurveyQuestion = createAction(
  'SET_ACTIVE_SURVEY_QUESTION',
  (questionKey, question, choices = {}) => ({ questionKey, question, choices })
);

export const dismissActiveQuestion = createAction(
  'DISMISS_ACTIVE_QUESTION',
);

export const removeSurveyQuestion = createAction(
  'REMOVE_SURVEY_QUESTION',
  (id) => id
);

export const moveSurveyQuestion = createAction(
  'MOVE_SURVEY_QUESTION',
  (id, position) => ({ id, position })
);

export const addNewSurveyQuestion = createAction(
  'ADD_NEW_SURVEY_QUESTION',
  (questionType) => questionType
);

export const updateSurveyQuestion = createAction(
  'UPDATE_SURVEY_QUESTION',
  (question, questionKey) => ({ question, questionKey })
);

export const addQuestionToSurvey = createAction(
  'ADD_QUESTION_TO_SURVEY',
  (question, choices, questionKey) => {
    const questionKeys = uniq([...question.choices, ...Object.keys(choices)]);
    return {
      question: {
        ...question,
        choices: questionKeys
      },
      choices,
      questionKey
    };
  }
);

export const invalidateQuestion = createAction(
  'INVALIDATE_SURVEY_QUESTION',
  (errors) => errors
);

export const addSurveyChoice = createAction(
  'ADD_NEW_SURVEY_CHOICE',
);

export const removeSurveyChoice = createAction(
  'REMOVE_SURVEY_CHOICE',
  (answerKey, questionKey) => ({ answerKey, questionKey })
);

export const updateSurveyChoice = createAction(
  'UPDATE_SURVEY_CHOICE',
  (answerKey, choice) => ({ answerKey, choice })
);

export const updateSurveyTitle = createAction(
  'UPDATE_SURVEY_TITLE',
  (title) => title
);

export const updateSurveySettings = createAction(
  'UPDATE_SURVEY_SETTINGS',
  (settings) => settings
);

export function createWebinarSurvey(webinarKey, survey) {
  return { type: Constants.CREATE_WEBINAR_SURVEY_START, webinarKey, survey };
}

export function deleteWebinarSurvey(webinarKey) {
  return { type: Constants.DELETE_WEBINAR_SURVEY_START, webinarKey };
}

// TODO: Delete these actions if not needed
export function retrieveWebinarSurveyInfo(webinarKey) {
  return { type: Constants.RETRIEVE_WEBINAR_SURVEY_INFO_START, webinarKey };
}

export function retrieveWebinarSurveyResponses(webinarKey, sessionKey) {
  return { type: Constants.RETRIEVE_WEBINAR_SURVEY_RESPONSES_START, webinarKey, sessionKey };
}

export function retrieveWebinarSurveyResponsesForAttendees(webinarKey, sessionKey, registrantKey) {
  return {
    type: Constants.RETRIEVE_WEBINAR_SURVEY_RESPONSES_FOR_ATTENDEES_START, webinarKey, sessionKey, registrantKey
  };
}

export function resetSurvey() {
  return { type: Constants.RESET_SURVEY };
}
