import { formatAsDate } from 'Utils/dateHelpers';
import { getEventsForDatesAction, getVirtualEventsForDatesAction } from './actions';

export default (state = {}, action) => {
  switch (action.type) {
    case getEventsForDatesAction.complete.toString(): {
      const dateGroups = { ...state.dateGroups };
      if (action.data.data) {
        const webinars = action.data.data;
        webinars.forEach((webinar) => {
          webinar.times.forEach((eventTime) => {
            const dayKey = formatAsDate(eventTime.startTime);
            const now = new Date().getTime();
            const endTime = new Date(eventTime.endTime).getTime();
            if (endTime < now) {
              return;
            }
            if (dateGroups[dayKey]) {
              const index = dateGroups[dayKey].findIndex((w) => w.webinarKey === webinar.webinarKey);
              if (index >= 0) {
                dateGroups[dayKey][index] = webinar;
              } else {
                dateGroups[dayKey].push(webinar);
              }
            } else {
              dateGroups[dayKey] = [webinar];
            }
          });
        });
      }

      return {
        ...state,
        dateGroups
      };
    }

    case getVirtualEventsForDatesAction.complete.toString(): {
      const dateGroups = { ...state.dateGroups };
      if (action.data) {
        const virtualEvents = action.data;

        virtualEvents.forEach((virtualEvent) => {
          const start = virtualEvent.start;
          const dayKey = formatAsDate(start);
          const now = new Date().getTime();
          const endTime = new Date(virtualEvent.end).getTime();
          if (endTime < now) {
            return;
          }
          if (dateGroups[dayKey]) {
            const index = dateGroups[dayKey].findIndex((w) => w.eventKey === virtualEvent.eventKey);
            if (index >= 0) {
              dateGroups[dayKey][index] = virtualEvent;
            } else {
              dateGroups[dayKey].push(virtualEvent);
            }
          } else {
            dateGroups[dayKey] = [virtualEvent];
          }
        });
      }

      return {
        ...state,
        dateGroups
      };
    }

    default: {
      return state;
    }
  }
};
