import MixpanelTracker from '../../mixpanelTracker';
import { CHANNEL } from '../../mixpanelConstants';

const mixpanelTracker = new MixpanelTracker();

function sendEvent(properties, sendToCrossProduct = false) {
  mixpanelTracker.setEventName(CHANNEL);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(sendToCrossProduct);
}

export function trackAddVideoToChannel(channelKey, assetKey, isNewChannel, isPrivate) {
  sendEvent({
    Type: 'Add Video',
    Action: 'Publish',
    channelKey,
    assetKey,
    isNewChannel,
    isPrivate
  }, true);
}

export function trackCreateChannel(isPrivate, source) {
  sendEvent({ Type: 'Create', Action: 'Create', isPrivate, source }, true);
}

export function trackSelectChannel() {
  sendEvent({ Type: 'Selected' });
}
