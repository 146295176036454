import config from 'appConfig';
import moment from 'moment';
import { getUser } from 'Api';
import getHeaders, { getBearerHeaders, TOKEN_TYPE } from 'Api/Helpers/getHeaders';
import { mapRecordingsWithContent, getAttributeValue, getLastCompletedVersion, createRecordingMap } from './assetsHelper';
import { getContents, getContent } from '../GoToStage/contentService';
import { fetchJson } from '../Helpers/fetch';
import { POST, DELETE, GET, PUT } from '../Helpers/httpMethods';
import { JSON_MIME_TYPE } from '../Helpers/requestProperties';

export const NO_OF_RECORDINGS = 45;
export const SERIESWEBINAR = 'series';
export function getRecordings(accountKey, page = 0, search = '', size = NO_OF_RECORDINGS) {
  let toTime = moment().toDate().toISOString();
  toTime = `${toTime.slice(0, -5)}Z`;

  const postBodyObj = {
    accountKey,
    product: 'g2w',
    sortOrder: 'DESC',
    sortField: 'CREATETIME',
    status: ['INITIATED', 'COMPLETED', 'FAILED'],
    toTime
  };

  if (search) {
    postBodyObj.name = search;
  }

  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(postBodyObj)
  };

  const url = `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets/search?page=${page}&size=${size}&includes=assetVersionDetails,storagelocation,latestVersion,cdnlocation`;

  return fetchJson(url, options, JSON_MIME_TYPE).then((res) => {
    const recordingKeys = [];

    if (res._embedded && res._embedded.assets) {
      const recordingsMap = new Map(res._embedded.assets.map((asset) => {
        const recordingKey = getAttributeValue(asset, 'recordingkey');
        asset.recordingKey = recordingKey;

        if (asset.status !== 'COMPLETED') {
          const lastCompletedVersion = getLastCompletedVersion(asset);
          asset.assetVersion = lastCompletedVersion;
        }

        recordingKeys.push(recordingKey);
        return [asset.assetKey, asset];
      }));

      return getContents(recordingKeys)
        .then((publishedRecordings) => ({
          recordings: mapRecordingsWithContent(recordingsMap, publishedRecordings),
          pagination: res.page,
          search
        }));
    }

    return { recordings: new Map(), pagination: res.page, search };
  });
}

export function deleteRecording(assetKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE
  };
  const url = `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets/${assetKey}`;
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getFileUploadTicket(asset, fileExt) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  const url = `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets/${asset.assetKey}/uploaddetail?resourceType=RAW_RECORDING&assetVersion=0&fileType=${fileExt}`;
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function initiateAsset(asset) {
  const options = {
    headers: getBearerHeaders(),
    method: PUT
  };
  const url = `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets/${asset.assetKey}/initiate?assetVersion=0`;
  return fetchJson(url, options, JSON_MIME_TYPE, false);
}

export function createRecording(file, accountKey, resources = [], sendnotification = true) {
  const recordingAttributes = [
    { name: 'sendnotification', value: sendnotification },
    { name: 'type', value: 'UPLOADED' }
  ];

  const recordingResources = [
    { name: `${file.title}.${file.ext}`, resourceType: 'raw_recording', provider: 's3', external: true },
    { name: `${file.title}.${file.ext}`, resourceType: 'recording', provider: 's3', attributes: recordingAttributes },
    ...resources
  ];

  const body = {
    name: file.title,
    accountKey,
    userKey: getUser().id,
    productName: 'g2w',
    resources: recordingResources
  };

  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };

  const url = `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets?upload=true`;
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getRecordingByRecordingKey(accountKey, recordingKey) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify({
      accountKey,
      product: 'g2w',
      resourceType: 'recording',
      resourceAttributes: [
        {
          name: 'recordingKey',
          value: recordingKey
        }
      ]
    })
  };

  const url = `${config.assetsService.url}/assetfleet/recording/als/api/v1/assets/search`;
  return fetchJson(url, options, JSON_MIME_TYPE).then((res) => {
    const recordingsMap = new Map(res._embedded.assets.map((obj) => [getAttributeValue(obj, 'recordingkey'), obj]));
    const resourceKey = Array.from(recordingsMap.keys())[0];
    return getContent(resourceKey).then((recording) => createRecordingMap(recordingsMap, recording));
  });
}

export function getRecordingByAssetKey(assetKey, assetVersion) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };

  const url = typeof assetVersion === 'number'
    ? `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets/${assetKey}?assetVersion=${assetVersion}&includes=assetVersions`
    : `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets/${assetKey}?includes=assetVersions`;

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getRecordingByAssetKeySearchAPI(assetKey, accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify({
      assetKeys: [assetKey],
      accountKey,
      status: ['COMPLETED']
    })
  };

  const url = `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets/search?page=0&size=1&includes=assetVersionDetails,cdnlocation,storagelocation,latestVersion`;
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getRecordingAssociations(entityType, entityKeys = [], assetKeys = [], associationTypes = [], userKey = getUser().id, recurrenceKey = '', webinarType = '', size = 100) {
  const url = `${config.assetsService.url}/assetfleet/recording/pas/api/v1/associations/search?size=${size}`;
  let body = {
    assetKeys,
    associationTypes,
    entityType,
    userKey,
    sortOrder: 'desc',
    sortField: 'CREATETIME'
  };
  if (webinarType === SERIESWEBINAR) body.entityKeys = [recurrenceKey];
  else {
    body.entityKeys = entityKeys;
  }
  body = JSON.stringify(body);
  const options = {
    headers: getHeaders(true, TOKEN_TYPE.BEARER),
    method: POST,
    body
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function createRecordingAssociation(entityKeys = [], assetKey = '', associationType = '', accountKey = '', userKey = '', recordingObj, recurrenceKey = '', webinarType = '') {
  const url = `${config.assetsService.url}/assetfleet/recording/pas/api/v1/associations?bulk=true&action=create`;
  let body = {
    assetType: 'RECORDING',
    assetKey,
    associationType,
    accountKey,
    userKey
  };
  if (webinarType === SERIESWEBINAR) {
    body.entityKeys = [recurrenceKey];
    body.entityType = 'recurrencewebinar';
  } else {
    body.entityKeys = entityKeys;
    body.entityType = 'WEBINAR';
  }
  body = JSON.stringify(body);
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function updateRecordingAssociation(associationKeys = [], assetKey = '', accountKey = '', userKey = '') {
  const url = `${config.assetsService.url}/assetfleet/recording/pas/api/v1/associations?bulk=true&action=update`;
  const body = JSON.stringify({
    associationKeys,
    assetKey,
    assetType: 'RECORDING',
    accountKey,
    userKey
  });
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body
  };
  return fetchJson(url, options, JSON_MIME_TYPE, false);
}

export function deleteRecordingAssociation(associationKeys = [], accountKey = '', userKey = '') {
  const url = `${config.assetsService.url}/assetfleet/recording/pas/api/v1/associations?bulk=true&action=delete`;
  const body = JSON.stringify({
    associationKeys,
    accountKey,
    userKey
  });
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body
  };
  return fetchJson(url, options, JSON_MIME_TYPE, false);
}

export function createAssetVersion(assetKey, resources = []) {
  const hasResources = resources.length && resources.every(
    (resource) => resource.provider && resource.resourceType
  );

  if (!assetKey || !hasResources) {
    throw new Error('Invalid parameters given while updating asset resources');
  }

  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify({ resources })
  };

  return fetchJson(
    `${config.assetsService.url}/assetfleet/recording/als/api/v2/assets/${assetKey}?updateAssetAttributesOnly=false`,
    options,
    JSON_MIME_TYPE
  );
}

export function getAssetResource(resourceUrl) {
  const options = {
    method: GET
  };

  return fetchJson(resourceUrl, options, JSON_MIME_TYPE);
}
