import { schema } from 'normalizr';
import surveyChoiceSchema from '../SurveyChoices/schema';

const webinar = new schema.Entity('webinar');

const question = new schema.Entity('questions', {
  choices: [surveyChoiceSchema]
}, {
  idAttribute: 'questionKey'
});

const surveyQuestionsSchema = {
  webinar,
  questions: [question]
};

export default surveyQuestionsSchema;
