import config from 'appConfig';
import { getBearerHeaders } from 'Api/Helpers/getHeaders';
import { fetchJson } from '../../Helpers/fetch';
import { POST } from '../../Helpers/httpMethods';

export function scheduleVirtualWebinar(organizerKey, webinarData) {
  const requestBody = webinarData;
  if (webinarData.locale === 'en_GB') {
    requestBody.locale = 'en_US';
  }
  const options = {
    headers: getBearerHeaders(),
    body: JSON.stringify(requestBody),
    method: POST,
    redirect: 'follow'
  };

  const url = `${config.g2w.events}/events`;

  return fetchJson(url, options, 'json');
}

export function copyVirtualWebinar(eventKey, webinarData) {
  const requestBody = webinarData;
  if (webinarData.locale === 'en_GB') {
    requestBody.locale = 'en_US';
  }
  const options = {
    headers: getBearerHeaders(),
    body: JSON.stringify(requestBody),
    method: POST,
    redirect: 'follow'
  };

  const url = `${config.g2w.events}/events/${eventKey}/copy`;

  return fetchJson(url, options, 'json');
}
