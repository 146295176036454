import moment from 'moment';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { getWebinars } from '../../Api/Webinar/webinarService';

export const searchWebinars = createAsyncAction(
  'COPY_SEARCH_WEBINARS',
  (filter, experienceType = 'ALL') => getWebinars({
    filter,
    fromTime: moment().subtract(10, 'y').utc().format(),
    toTime: moment().add(1, 'y').utc().format(),
    experienceType,
    page: 0
  })
);
