export const PAGE_NAMES = {
  DASHBOARD: 'Dashboard',
  MY_CHANNELS: 'My Channels',
  SETTINGS: 'Settings',
  EVENT_DETAILS: 'Event Details',
  SCHEDULE_WEBINAR: 'Schedule Webinar',
  VIDEO_LIBRARY: 'Video Library'
};

export const PAGE_TYPES = {
  SETTINGS: 'settings',
  CONTENT: 'content',
  HOME: 'home'
};

export const FORM_NAMES = {
  NEW_EVENT: 'New Event'
};

export const PAGE_SECTIONS = {
  ABOUT: 'About',
  MY_CHANNELS: 'MY Channels',
  AUDIO: 'Audio',
  VIDEOS: 'Videos',
  UPCOMING_EVENTS: 'Upcoming events'
};
