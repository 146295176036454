import v4 from 'uuid';
import { getCookie, setCookie } from 'Utils/browserCookies';
import { setSessionStorage, getSessionStorage, clearSessionStorage } from 'Utils/sessionStorage';
import Role from './role';

const ANONYMOUS_ID = 'anonymousId';
const USER_SESSION_ID = 'userSessionId';
const G2W_ENTITLEMENT = 'g2w';

/**
 * Returns the value of ANONYMOUS_ID cookie if it exists. If cookie doesn't exist, it creates the cookie.
 */

export function getAnonymousId() {
  return getCookie(ANONYMOUS_ID);
}

export function setAnonymousId() {
  let anonymousId = getAnonymousId();
  if (!anonymousId) {
    anonymousId = v4();
    setCookie(ANONYMOUS_ID, anonymousId);
  }
}

export function getUserSessionId() {
  return getSessionStorage(USER_SESSION_ID);
}

export function setUserSessionId() {
  let userSessionId = getUserSessionId();
  if (!userSessionId) {
    userSessionId = v4();
    setSessionStorage(USER_SESSION_ID, userSessionId);
  }
}

export function clearUserSessionId() {
  clearSessionStorage(USER_SESSION_ID);
}

export function getRole(entitlements) {
  if (!(Array.isArray(entitlements))) {
    return Role.UNKNOWN;
  } if (entitlements.findIndex((item) => item.toLowerCase() === G2W_ENTITLEMENT) > -1) {
    return Role.ORGANIZER;
  }
  return Role.ATTENDEE;
}
