const attendee = 'attendee';
const absentee = 'absentee';

export default class AttendeeType {
  static get ATTENDEE() {
    return attendee;
  }

  static get ABSENTEE() {
    return absentee;
  }

  static values() {
    return [attendee, absentee];
  }
}
