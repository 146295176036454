import { createAction } from 'redux-promise-middleware-actions';

export const undo = createAction(
  'UNDO',
  (target, item, num) => ({ target, item, num })
);

export const redo = createAction(
  'REDO',
  (target, item, num) => ({ target, item, num })
);
