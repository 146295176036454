import moment from 'moment';
import appLogger from 'Utils/logging';
import { registerUserForWebNotifications } from 'Api/Notifications/notificationService';
import { registerServiceWorker, pushMessage, unregister } from './serviceWorkerManager';
import { subscribeUserForNotifications } from '../../Api/Notifications/notificationSubscriptionService';
import config from '../../appConfig';
import urlBase64ToUint8Array from '../../Utils/encodingUtils';
import { APPLICATION_ID, NOTIFICATION_TYPE, NOTIFICATION_AUTH, NOTIFICATION_P256DH, SET_USER } from '../constants';
import { getUserKey } from '../Helpers/notificationsHelper';

const logger = appLogger.create('Notification Registration Manager');

let registration;

// Push Service
function getExistingPushServiceSubscription() {
  return registration.pushManager.getSubscription();
}

function createNewPushServiceSubscription() {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(
      config.notificationservice.webPushPublicKey
    )
  };
  return registration.pushManager.subscribe(subscribeOptions);
}

// Notification Service
function registerWithNotificationService(userKey, subscription) {
  const subscriptionJSON = subscription.toJSON();
  const pushData = {
    alias: userKey,
    applicationId: APPLICATION_ID,
    type: NOTIFICATION_TYPE,
    subscription: {
      endpoint: subscriptionJSON.endpoint,
      keys: {
        auth: subscriptionJSON.keys[NOTIFICATION_AUTH],
        p256dh: subscriptionJSON.keys[NOTIFICATION_P256DH]
      }
    }
  };
  return registerUserForWebNotifications(userKey, pushData);
}

// Notification Subscription Service
export const registerWithNotificationSubscriptionService = (userKey) => {
  const notificationSubscription = {
    timeZone: moment.tz.guess(),
    pushToken: 'MockWebPushToken'
  };
  logger.debug('Subscribing user with Notification Subscription Service', 'notificationSubscription=', notificationSubscription);
  return subscribeUserForNotifications(userKey, notificationSubscription);
};

export const registerForNotifications = async (user) => {
  const userKey = getUserKey(user);
  if (!userKey) {
    logger.error('Cannot subscribe for notifications without user key.');
    throw new Error('Cannot subscribe for notifications without user key');
  }
  let result;
  try {
    // Unregister any existing service workers before registering for a new one.
    logger.debug('Unregistering existing service workers');
    await unregister(userKey);
    logger.debug('Registering Service Worker');
    registration = await registerServiceWorker();
    const userData = {
      type: SET_USER,
      payload: user
    };
    pushMessage(userData);
    let subscription = await getExistingPushServiceSubscription();
    if (!subscription) {
      logger.debug('No existing push service subscription. Creating a new push service subscription.');
      subscription = await createNewPushServiceSubscription();
    }
    logger.debug('Registering subscription with Notification Service.', 'subscription=', subscription);
    result = await registerWithNotificationService(userKey, subscription);
  } catch (error) {
    logger.error('Failed to subscribe for notifications', 'error=', error);
  }
  return result;
};

