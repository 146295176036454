const MULTIPLE_CHOICE = 'multipleChoice';
const RATING = 'rating';
const SHORT_ANSWER = 'shortAnswer';
const SINGLE_CHOICE = 'singleChoice';

export default class QuestionType {
  static get MULTIPLE_CHOICE() {
    return MULTIPLE_CHOICE;
  }

  static get RATING() {
    return RATING;
  }

  static get SHORT_ANSWER() {
    return SHORT_ANSWER;
  }

  static get SINGLE_CHOICE() {
    return SINGLE_CHOICE;
  }

  static values() {
    return [MULTIPLE_CHOICE, RATING, SHORT_ANSWER, SINGLE_CHOICE];
  }
}
