import config from 'appConfig';
import { getBearerHeaders } from '../Helpers/getHeaders';
import { fetchJson } from '../Helpers/fetch';
import { GET, POST, PUT, DELETE } from '../Helpers/httpMethods';

/**
 * Payment Service
 * https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=collaboration&title=Payments+Service
 */

const baseUrl = config.paymentService.url;

export function getUserStripeId(accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  return fetchJson(`${baseUrl}/associations/products/g2w/accounts/${accountKey}`, options, 'json')
    .catch((res) => {
      if (res.status === 404) {
        return {};
      }
      return Promise.reject(res);
    });
}

export function getVEUserStripeId(accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  return fetchJson(`${baseUrl}/v2/associations/products/g2w/accounts/${accountKey}`, options, 'json')
    .catch((res) => {
      if (res.status === 404) {
        return {};
      }
      return Promise.reject(res);
    });
}

export function getWebinarPayment(webinarkey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  return fetchJson(`${baseUrl}/prices/products/g2w/referenceKeys/${webinarkey}`, options, 'json')
    .catch((res) => {
      if (res.status === 404) {
        return {};
      } return Promise.reject(res);
    });
}

export function createWebinarPayment(body, webinarKey, accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/v2/prices/products/g2w/accounts/${accountKey}/referenceKeys/${webinarKey}`, options, 'json');
}

export function updateWebinarPayment(body, webinarKey) {
  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/prices/products/g2w/referenceKeys/${webinarKey}`, options, 'json');
}

export function deleteWebinarPayment(webinarKey, accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE
  };
  return fetchJson(`${baseUrl}/v2/prices/products/g2w/accounts/${accountKey}/referenceKeys/${webinarKey}`, options, 'json', false);
}

export function getVirtualEventPayment(eventkey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  return fetchJson(`${baseUrl}/prices/products/g2w/referenceKeys/${eventkey}`, options, 'json')
    .catch((res) => {
      if (res.status === 404) {
        return {};
      }
      return Promise.reject(res);
    });
}

export function createVirtualEventPayment(body, eventkey, accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/v3/prices/products/g2w/accounts/${accountKey}/VIRTUALEVENT/${eventkey}`, options, 'json');
}

export function updateVirtualEventPayment(body, eventkey) {
  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/v3/prices/products/g2w/VIRTUALEVENT/${eventkey}`, options, 'json');
}

export function deleteVirtualEventPayment(eventkey, accountKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE
  };
  return fetchJson(`${baseUrl}/v2/prices/products/g2w/accounts/${accountKey}/referenceKeys/${eventkey}`, options, 'json', false);
}
