/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { array, string } from 'prop-types';
import { InputField } from '@getgo/chameleon-react';
import styles from './Preview.scss';

import QuestionType from '../../../Api/Webinar/Survey/Model/questionType';
import { extractKeyValuePairs } from '../../../Reducks/Survey/selectors';

function createShortAnswerDisplay() {
  return (
    <InputField
      isDisabled
      placeholder=""
      inputClassName={styles.shortAnswerTextArea}
    />
  );
}

function createSingleChoiceDisplay(choices) {
  return choices.map((choice, index) => (
    <div key={index} className={styles.answerContainer}>
      <input type="radio" disabled />

      <span className={styles.answer}>
        {choice.answerText}
      </span>
    </div>
  ));
}

function createMultipleChoicesDisplay(choices) {
  return choices.map((choice, index) => (
    <div key={index} className={styles.answerContainer}>
      <input type="checkbox" disabled />

      <span className={styles.answer}>
        {choice.answerText}
      </span>
    </div>
  ));
}

function createRateOnAScaleDisplay(choices) {
  const { low_rating, high_rating, low_label, mid_label, high_label } = extractKeyValuePairs(choices);

  // NOTE: In the case where a user has created a survey using the old APIs,
  // use default values for rating type questions (0 as low, 10 as high)
  const loLimit = low_rating && low_rating.value ? parseInt(low_rating.value, 10) : 0;
  const hiLimit = high_rating && high_rating.value ? parseInt(high_rating.value, 10) : 10;
  const ratings = Array(hiLimit - loLimit + 1).fill().map((_, idx) => loLimit + idx);

  return (
    <Fragment>
      <div className={styles.rowSpread}>
        {ratings.map((rating) => (
          <span key={rating}>
            <input type="radio" disabled />
            <span className={styles.answer}>
              {rating}
            </span>
          </span>
        ))}
      </div>

      <div className={styles.rowSpread}>
        {low_label && low_label.value && (
          <span className={styles.label}>{low_label.value}</span>
        )}
        {mid_label && mid_label.value && (
          <span className={styles.label}>{mid_label.value}</span>
        )}
        {high_label && high_label.value && (
          <span className={styles.label}>{high_label.value}</span>
        )}
      </div>
    </Fragment>
  );
}

function createOptionsDisplay(type, choices) {
  switch (type) {
    case QuestionType.SHORT_ANSWER:
      return createShortAnswerDisplay();

    case QuestionType.SINGLE_CHOICE:
      return createSingleChoiceDisplay(choices);

    case QuestionType.MULTIPLE_CHOICE:
      return createMultipleChoicesDisplay(choices);

    case QuestionType.RATING:
      return createRateOnAScaleDisplay(choices);

    default:
      return '';
  }
}

export default function SurveyPreview(props) {
  const { t } = useTranslation();

  if (!props.questions.length) {
    return (
      <div id="survey_preview_no_questions">
        {t('survey.preview.noQuestions')}
      </div>
    );
  }

  return (
    <div className={styles.previewContainer}>
      <div className={styles.previewLabel}>
        <span>{t('survey.preview.startSurvey')}</span>
      </div>

      <h2>{props.title}</h2>

      <ol>
        {props.questions.map((question, index) => (
          <li key={index}>
            <div className={styles.question}>
              {question.questionText}
            </div>

            {createOptionsDisplay(question.type, question.choices)}
          </li>
        ))}
      </ol>

      <div className={styles.previewLabel}>
        <span>{t('survey.preview.endSurvey')}</span>
      </div>
    </div>
  );
}

SurveyPreview.propTypes = {
  questions: array,
  title: string
};

SurveyPreview.defaultProps = {
  questions: [],
  title: ''
};
