import Action from '../../Model/action';
import MixpanelTracker from '../../mixpanelTracker';
import { SURVEY_ADDED_TO_SESSION,
  SURVEY_DELETED_FROM_SESSION,
  ON_SURVEY_CREATED_API_STATUS,
  ON_SURVEY_DELETED_API_STATUS,
  POLL_ADDED_TO_SESSION,
  POLL_DELETED_FROM_SESSION,
  ON_POLL_CREATED_API_STATUS,
  ON_POLL_DELETED_API_STATUS,
  HANDOUT_ADDED_TO_SESSION,
  HANDOUT_DELETED_FROM_SESSION,
  ON_HANDOUT_CREATED_API_STATUS,
  ON_HANDOUT_DELETED_API_STATUS,
  VIDEO_UPLOAD_TO_SESSION,
  VIDEO_DELETED_FROM_SESSION,
  YOUTUBE_LINK_UPLOAD_TO_SESSION,
  ON_YOUTUBE_LINK_UPLOADED_API_STATUS,
  ON_YOUTUBE_LINK_DELETED_API_STATUS,
  ON_VIDEO_UPLOADED_API_STATUS } from '../../mixpanelConstants';

const mixpanelTracker = new MixpanelTracker();

// Surveys
export function onSurveyAddedToSessionTracker(eventKey, conferenceKey, survey) {
  const properties = {
    Action: Action.CREATE,
    eventKey,
    conferenceKey,
    survey
  };
  mixpanelTracker.setEventName(SURVEY_ADDED_TO_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onSurveyDeletedFromSessionTracker(eventKey, conferenceKey) {
  const properties = {
    Action: Action.REMOVE,
    eventKey,
    conferenceKey
  };
  mixpanelTracker.setEventName(SURVEY_DELETED_FROM_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

// Polls
export function onPollsAddedToSessionTracker(eventKey, conferenceKey, newQuestion) {
  const properties = {
    Action: Action.CREATE,
    eventKey,
    conferenceKey,
    newQuestion
  };
  mixpanelTracker.setEventName(POLL_ADDED_TO_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onPollsDeletedFromSessionTracker(eventKey, conferenceKey, pollKey) {
  const properties = {
    Action: Action.REMOVE,
    eventKey,
    conferenceKey,
    pollKey
  };
  mixpanelTracker.setEventName(POLL_DELETED_FROM_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

// Handouts
export function onHandoutUploadedToSessionTracker(eventKey, conferenceKey, fileMetaData, fileObject, userWebinarInfo) {
  const properties = {
    Action: Action.UPLOAD,
    eventKey,
    conferenceKey,
    fileMetaData,
    fileObject,
    userWebinarInfo
  };
  mixpanelTracker.setEventName(HANDOUT_ADDED_TO_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onHandoutDeletedFromSessionTracker(eventKey, conferenceKey, deleteHandoutKey) {
  const properties = {
    Action: Action.REMOVE,
    eventKey,
    conferenceKey,
    deleteHandoutKey
  };
  mixpanelTracker.setEventName(HANDOUT_DELETED_FROM_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

// Videos
export function onVideoUploadedToSessionTracker(eventkey, conferenceKey, userWebinarInfo, fileMetaData, fileObject) {
  const properties = {
    Action: Action.UPLOAD,
    eventkey,
    conferenceKey,
    userWebinarInfo,
    fileMetaData,
    fileObject
  };
  mixpanelTracker.setEventName(VIDEO_UPLOAD_TO_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onVideoDeletedFromSessionTracker(eventkey, conferenceKey, videoKey) {
  const properties = {
    Action: Action.REMOVE,
    eventkey,
    conferenceKey,
    videoKey
  };
  mixpanelTracker.setEventName(VIDEO_DELETED_FROM_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onYoutubeLinkUploadedToSessionTracker(eventKey, conferenceKey, userWebinarInfo, youTubeLinkData) {
  const properties = {
    Action: Action.UPLOAD,
    eventKey,
    conferenceKey,
    userWebinarInfo,
    youTubeLinkData
  };
  mixpanelTracker.setEventName(YOUTUBE_LINK_UPLOAD_TO_SESSION);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

// saga - Survey
export function onSurveyAddedToSessionSagaTracker(eventKey, survey) {
  const properties = {
    Action: Action.CREATE,
    eventKey,
    survey
  };
  mixpanelTracker.setEventName(ON_SURVEY_CREATED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onSurveyDeletedFromSessionSagaTracker(eventKey) {
  const properties = {
    Action: Action.REMOVE,
    eventKey
  };
  mixpanelTracker.setEventName(ON_SURVEY_DELETED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

// saga - Polls
export function onPollsAddedToSessionSagaTracker(eventKey, poll) {
  const properties = {
    Action: Action.CREATE,
    eventKey,
    poll
  };
  mixpanelTracker.setEventName(ON_POLL_CREATED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onPollsDeletedFromSessionSagaTracker(eventKey, pollKey) {
  const properties = {
    Action: Action.REMOVE,
    eventKey,
    pollKey
  };
  mixpanelTracker.setEventName(ON_POLL_DELETED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

// saga - Handouts
export function onHandoutUploadedToSessionSagaTracker(eventKey, handoutPayload) {
  const properties = {
    Action: Action.UPLOAD,
    eventKey,
    handoutPayload
  };
  mixpanelTracker.setEventName(ON_HANDOUT_CREATED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onHandoutDeletedFromSessionSagaTracker(eventKey, handoutKey) {
  const properties = {
    Action: Action.REMOVE,
    eventKey,
    handoutKey
  };
  mixpanelTracker.setEventName(ON_HANDOUT_DELETED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

// saga - videos
export function onVideoUploadedToSessionSagaTracker(eventKey, accountKey, userKey, fileMetaData) {
  const properties = {
    Action: Action.UPLOAD,
    eventKey,
    accountKey,
    userKey,
    fileMetaData
  };
  mixpanelTracker.setEventName(ON_VIDEO_UPLOADED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

// saga - YouTube Link
export function onYoutubeLinkUploadedToSessionSagaTracker(eventKey, accountKey, userKey, youTubeLinkData) {
  const properties = {
    Action: Action.UPLOAD,
    eventKey,
    accountKey,
    userKey,
    youTubeLinkData
  };
  mixpanelTracker.setEventName(ON_YOUTUBE_LINK_UPLOADED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onVideoDeletedFromSessionSagaTracker(eventKey) {
  const properties = {
    Action: Action.REMOVE,
    eventKey
  };
  mixpanelTracker.setEventName(ON_YOUTUBE_LINK_DELETED_API_STATUS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}
