import MixpanelTracker from 'Mixpanel/mixpanelTracker';
import { RESTRICT_ACCESS_CLICKED, DOMAINS_ADDED } from '../../../mixpanelConstants';
import { ALLOWED_DOMAIN, BLOCKED_DOMAIN } from './restrictAccessConstants';
import Action from '../../../Model/action';

const mixpanelTracker = new MixpanelTracker();

export function trackDomainsAdded(count, isBlockedDomainsSelected) {
  const properties = {
    Type: isBlockedDomainsSelected ? BLOCKED_DOMAIN : ALLOWED_DOMAIN,
    Count: count
  };
  mixpanelTracker.setEventName(DOMAINS_ADDED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}

export function trackRestrictAccessClicked() {
  const properties = {
    Action: Action.CLICK
  };
  mixpanelTracker.setEventName(RESTRICT_ACCESS_CLICKED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}
