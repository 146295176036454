import { t } from 'i18next';
import { getRegistrationSettingsAction,
  updateEventRegistrationSettingsAction,
  updateRegistrationSettingsAction,
  getCustomDisclaimerAction } from './actions';

export default (state = { isUpdatingRegistration: false, registration: {} }, action) => {
  switch (action.type) {
    case getRegistrationSettingsAction.toString(): {
      return {
        ...state,
        isFetchingSettings: true
      };
    }
    case getRegistrationSettingsAction.complete.toString(): {
      const newRegistrationSettings = Object.assign({}, state.registrationSettings);
      newRegistrationSettings[action.webinarKey] = Object.assign(newRegistrationSettings[action.webinarKey] || {}, action.data);
      return {
        ...state,
        registration: newRegistrationSettings,
        isFetchingSettings: false
      };
    }
    case getRegistrationSettingsAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        isFetchingSettings: false
      };
    }
    case updateRegistrationSettingsAction.toString(): {
      return {
        ...state,
        detailsError: null,
        registrationType: action.registrationType,
        isUpdatingRegistration: true,
        isFetchingSettings: false
      };
    }
    case updateRegistrationSettingsAction.complete.toString(): {
      const newRegistrationSettingsMap = Object.assign({}, (state.registration));
      const newRegistrationSettings = Object.assign(newRegistrationSettingsMap[action.webinarKey], action.details);
      newRegistrationSettingsMap[action.webinarKey] = newRegistrationSettings;
      return {
        ...state,
        registration: newRegistrationSettingsMap,
        detailsError: null,
        registrationType: action.registrationType,
        isUpdatingRegistration: false,
        isFetchingSettings: false
      };
    }
    case updateRegistrationSettingsAction.failed.toString(): {
      return {
        ...state,
        detailsError: action.message,
        registrationType: action.registrationType,
        isUpdatingRegistration: false,
        isFetchingSettings: false
      };
    }
    case getCustomDisclaimerAction.complete.toString(): {
      const registrationCopy = Object.assign({}, state.registration);
      const data = action.data;
      if (!data.enabled && !data.text) {
        data.text = t('cards.webinar.details.registration.registrationForm.customRegistrationDisclaimer.defaultText');
      }
      const customDisclaimer = { customDisclaimer: action.data };
      registrationCopy[action.webinarKey] = Object.assign(registrationCopy[action.webinarKey] || {}, customDisclaimer);
      return {
        ...state,
        registration: registrationCopy
      };
    }
    case getCustomDisclaimerAction.failed.toString(): {
      return {
        ...state,
        error: action.error
      };
    }
    case updateEventRegistrationSettingsAction.toString(): {
      return {
        ...state,
        detailsError: null,
        registrationType: action.approvalType,
        isUpdatingRegistration: true,
        isFetchingSettings: false
      };
    }
    case updateEventRegistrationSettingsAction.complete.toString(): {
      const newRegistrationSettingsMap = Object.assign({}, (state.registration));
      const newRegistrationSettings = Object.assign(newRegistrationSettingsMap[action.eventKey], action.approvalType);
      newRegistrationSettingsMap[action.eventKey] = newRegistrationSettings;
      return {
        ...state,
        registration: newRegistrationSettingsMap,
        detailsError: null,
        registrationType: action.approvalType,
        isUpdatingRegistration: false,
        isFetchingSettings: false
      };
    }
    case updateEventRegistrationSettingsAction.failed.toString(): {
      return {
        ...state,
        detailsError: action.message,
        registrationType: action.approvalType,
        isUpdatingRegistration: false,
        isFetchingSettings: false
      };
    }
    default:
      return {
        ...state
      };
  }
};
