import appLogger from 'Utils/logging';
import runtime from 'serviceworker-webpack-plugin/lib/runtime';

const logger = appLogger.create('Service Worker Manager');

export function registerServiceWorker() {
  return runtime.register();
}

const unregisterServiceWorker = async () => {
  const registrations = await navigator.serviceWorker.getRegistrations();
  for (const registration of registrations) {
    registration.unregister();
  }
};

// TODO: Call this when users opt out of notifications. Also call Not. Subs. Svc and Not. Svc as applicable.
export const unregister = async (userKey) => {
  logger.debug('Unregistering service worker', 'userKey=', userKey);
  try {
    await unregisterServiceWorker();
    logger.debug('Unregistered service worker,', 'userKey=', userKey);
  } catch (error) {
    logger.error('Failed to unregister service worker', 'error=', error);
  }
};

export const pushMessage = async (message) => {
  const controller = navigator.serviceWorker && navigator.serviceWorker.controller;
  if (typeof message !== 'object') {
    logger.error('Cannot push message to service worker without a message object');
    return;
  }
  if (!controller) {
    logger.error('Cannot push message to service worker without controller.');
    return;
  }
  controller.postMessage(message);
};

