import { getRecordingReportAction } from './actionTypes';

const initialState = {
  data: {},
  isFetching: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case getRecordingReportAction.toString(): {
      return {
        ...state,
        isFetching: true
      };
    }
    case getRecordingReportAction.complete.toString(): {
      return {
        ...state,
        isFetching: false
      };
    }
    case getRecordingReportAction.failed.toString(): {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    default:
      return {
        ...state
      };
  }
};
