import { createAsyncAction } from 'redux-promise-middleware-actions';
import { getAppState, setAppState } from 'Api/applicationStateService';

export const getApplicationState = createAsyncAction('GET_APPLICATION_STATE', async () => {
  const state = await getAppState();
  return {
    ...state
  };
});

export const setSchedulingPreference = createAsyncAction('SCHEDULING_SET_STATE', async (eventType) => {
  const scheduling = { scheduling: { preferredEvent: eventType } };
  setAppState(scheduling);
  return { preferredEvent: eventType };
});

export const hideNotificationPreferenceMessage = createAsyncAction('HIDE_NOTIFICATION_PREFERENCE_MESSAGE', async (notificationPreferences) => {
  notificationPreferences.showPreferenceMessage = false;
  setAppState({ notificationPreferences });
  return { showPreferenceMessage: false };
});

export const markNotificationPreferenceAsRead = createAsyncAction('MARK_NOTIFICATION_PREFERENCE_READ', async (notificationPreferences) => {
  notificationPreferences.preferenceMessageRead = true;
  setAppState({ notificationPreferences });
  return { preferenceMessageRead: true };
});

export const setDarkMode = createAsyncAction('ENABLE_DARK_MODE', async (enabled) => {
  const data = { darkMode: enabled };
  setAppState(data);
  return data;
});

export const setApplicationState = createAsyncAction('SET_APPLICATION_STATE', async (data) => {
  setAppState(data);
  return data;
});

export const setDefaultCurrency = createAsyncAction('SET_DEFAULT_CURRENCY', async (currency) => {
  setAppState({ defaultCurrency: currency });
  return { defaultCurrency: currency };
});
