export const handoutFileTypes = {
  pdf: 'pdf',
  doc: 'doc',
  docx: 'docx',
  xls: 'xls',
  xlsx: 'xlsx',
  ppt: 'ppt',
  pptx: 'pptx',
  wmv: 'wmv',
  mov: 'mov',
  mp3: 'mp3',
  mp4: 'mp4',
  jpeg: 'jpeg',
  jpg: 'jpg',
  gif: 'gif',
  png: 'png'
};

export function getHandoutFileStyle(fileType, element) {
  let iconName;
  let cardColor;
  switch (fileType) {
    case handoutFileTypes.xls:
      iconName = 'file-xls';
      cardColor = '#20c997';
      break;
    case handoutFileTypes.xlsx:
      iconName = 'file-xls';
      cardColor = '#20c997';
      break;
    case handoutFileTypes.doc:
      iconName = 'file-doc';
      cardColor = '#00c2ff';
      break;
    case handoutFileTypes.docx:
      iconName = 'file-doc';
      cardColor = '#00c2ff';
      break;
    case handoutFileTypes.pdf:
      iconName = 'file-pdf';
      cardColor = '#d657fd';
      break;
    case handoutFileTypes.ppt:
      iconName = 'file-ppt';
      cardColor = '#fd7e14';
      break;
    case handoutFileTypes.pptx:
      iconName = 'file-ppt';
      cardColor = '#fd7e14';
      break;
    case handoutFileTypes.mov:
      iconName = 'video';
      cardColor = '#1397e9';
      break;
    case handoutFileTypes.wmv:
      iconName = 'video';
      cardColor = '#1397e9';
      break;
    case handoutFileTypes.mp4:
      iconName = 'video';
      cardColor = '#1397e9';
      break;
    case handoutFileTypes.png:
      iconName = 'image';
      cardColor = '#8A5BE4';
      break;
    case handoutFileTypes.jpeg:
      iconName = 'image';
      cardColor = '#8A5BE4';
      break;
    case handoutFileTypes.jpg:
      iconName = 'image';
      cardColor = '#8A5BE4';
      break;
    case handoutFileTypes.gif:
      iconName = 'image';
      cardColor = '#8A5BE4';
      break;
    case handoutFileTypes.mp3:
      iconName = 'music';
      cardColor = '#FE0577';
      break;
    default:
      iconName = 'file';
      cardColor = '#C0BEC0';
      break;
  }
  return (element === 'icon' ? iconName : cardColor);
}
