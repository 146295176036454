import moment from 'moment';
import { changeDateFilter, updateEventsTab } from '../../Ui/actions';
import { addDaysToDate } from '../../../Utils/dateHelpers';

const today = new Date();

export const nextYear = addDaysToDate(today, 365);
export const previousYear = addDaysToDate(today, -365);

export const upcomingDateRange = {
  start: moment(today).utc().format(),
  end: moment(nextYear).endOf('day').utc().format()
};

export const pastDateRange = {
  start: moment(previousYear).startOf('day').utc().format(),
  end: moment(today).utc().format()
};

export const initialState = {
  byId: {
    webinars: upcomingDateRange
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case changeDateFilter.toString(): {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: { ...action.payload }
        }
      };
    }

    case updateEventsTab.toString(): {
      return {
        ...state,
        byId: {
          ...state.byId,
          webinars: action.payload === 1 ? pastDateRange : upcomingDateRange
        }
      };
    }

    default: {
      return state;
    }
  }
};
