import isEqual from 'lodash.isequal';
import { getUserStripeIdAction,
  getWebinarPaymentAction,
  createWebinarPaymentAction,
  updateWebinarPaymentAction,
  deleteWebinarPaymentAction,
  getCouponsForWebinarAction,
  createCouponForWebinarAction,
  deleteCouponForWebinarAction,
  getVirtualEventPaymentAction,
  createVirtualEventPaymentAction,
  updateVirtualEventPaymentAction,
  deleteVirtualEventPaymentAction,
  getCouponsForVirtualEventAction,
  createCouponForVirtualEventAction,
  deleteCouponForVirtualEventAction,
  CLEAR_VIRTUAL_EVENT_PAYMENT } from './actions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case getUserStripeIdAction.fulfilled.toString(): {
      if (isEqual(action.payload, {})) {
        return {
          ...state,
          metadata: null
        };
      }
      return {
        ...state,
        metadata: action.payload[0]
      };
    }
    case getUserStripeIdAction.rejected.toString(): {
      return {
        ...state
      };
    }

    case getWebinarPaymentAction.pending.toString(): {
      return {
        ...state,
        chargeSettings: null
      };
    }

    case getWebinarPaymentAction.fulfilled.toString(): {
      if (isEqual(action.payload, {})) {
        return {
          ...state
        };
      }
      return {
        ...state,
        chargeSettings: action.payload
      };
    }

    case getWebinarPaymentAction.rejected.toString(): {
      return {
        ...state,
        error: action.payload.message || action.payload.body.reason
      };
    }

    case createWebinarPaymentAction.fulfilled.toString(): {
      return {
        ...state,
        chargeSettings: action.payload
      };
    }

    case createWebinarPaymentAction.rejected.toString(): {
      return {
        ...state
      };
    }

    case updateWebinarPaymentAction.fulfilled.toString(): {
      return {
        ...state,
        chargeSettings: action.payload
      };
    }

    case updateWebinarPaymentAction.rejected.toString(): {
      return {
        ...state
      };
    }

    case deleteWebinarPaymentAction.fulfilled.toString(): {
      delete state.chargeSettings;
      return {
        ...state
      };
    }

    case deleteWebinarPaymentAction.rejected.toString(): {
      return {
        ...state
      };
    }

    case getCouponsForWebinarAction.pending.toString(): {
      return {
        ...state,
        couponSettings: undefined
      };
    }

    case getCouponsForWebinarAction.fulfilled.toString(): {
      if (isEqual(action.payload, {})) {
        return {
          ...state
        };
      }
      return {
        ...state,
        couponSettings: action.payload
      };
    }
    case getCouponsForWebinarAction.rejected.toString(): {
      return state;
    }

    case createCouponForWebinarAction.fulfilled.toString(): {
      const { couponResponse } = action.payload;
      return {
        ...state,
        couponSettings: state.couponSettings ? [...state.couponSettings, ...couponResponse] : [...couponResponse]
      };
    }

    case createCouponForWebinarAction.rejected.toString(): {
      return state;
    }

    case deleteCouponForWebinarAction.fulfilled.toString(): {
      const deletedKeys = action.meta && action.meta.body && action.meta.body.associationKeys;

      return {
        ...state,
        couponSettings: state.couponSettings.filter((coupon) => !deletedKeys.find((key) => key === coupon.associationKey))
      };
    }

    case deleteCouponForWebinarAction.rejected.toString(): {
      return state;
    }

    case getVirtualEventPaymentAction.pending.toString(): {
      return {
        ...state,
        veChargeSettings: null
      };
    }

    case getVirtualEventPaymentAction.fulfilled.toString(): {
      if (isEqual(action.payload, {})) {
        return {
          ...state
        };
      }
      return {
        ...state,
        veChargeSettings: action.payload
      };
    }

    case getVirtualEventPaymentAction.rejected.toString(): {
      return {
        ...state,
        error: action.payload.message || action.payload.body.reason
      };
    }

    case CLEAR_VIRTUAL_EVENT_PAYMENT: {
      return {
        ...state,
        veChargeSettings: null
      };
    }

    case createVirtualEventPaymentAction.fulfilled.toString(): {
      return {
        ...state,
        veChargeSettings: action.payload
      };
    }

    case createVirtualEventPaymentAction.rejected.toString(): {
      return {
        ...state
      };
    }

    case updateVirtualEventPaymentAction.fulfilled.toString(): {
      return {
        ...state,
        veChargeSettings: action.payload
      };
    }

    case updateVirtualEventPaymentAction.rejected.toString(): {
      return {
        ...state
      };
    }

    case deleteVirtualEventPaymentAction.fulfilled.toString(): {
      delete state.veChargeSettings;
      return {
        ...state
      };
    }

    case deleteVirtualEventPaymentAction.rejected.toString(): {
      return {
        ...state
      };
    }

    case getCouponsForVirtualEventAction.pending.toString(): {
      return {
        ...state,
        veCouponSettings: undefined
      };
    }

    case getCouponsForVirtualEventAction.fulfilled.toString(): {
      if (isEqual(action.payload, {})) {
        return {
          ...state
        };
      }
      return {
        ...state,
        veCouponSettings: action.payload
      };
    }
    case getCouponsForVirtualEventAction.rejected.toString(): {
      return state;
    }

    case createCouponForVirtualEventAction.fulfilled.toString(): {
      const { couponResponse } = action.payload;
      return {
        ...state,
        veCouponSettings: state.veCouponSettings ? [...state.veCouponSettings, ...couponResponse] : [...couponResponse]
      };
    }

    case createCouponForVirtualEventAction.rejected.toString(): {
      return state;
    }

    case deleteCouponForVirtualEventAction.fulfilled.toString(): {
      const deletedKeys = action.meta && action.meta.body && action.meta.body.associationKeys;

      return {
        ...state,
        veCouponSettings: state.veCouponSettings.filter((coupon) => !deletedKeys.find((key) => key === coupon.associationKey))
      };
    }

    case deleteCouponForVirtualEventAction.rejected.toString(): {
      return state;
    }

    default: {
      return state;
    }
  }
};
