export function clearLocalStorage(localStorageKey) {
  localStorage.removeItem(localStorageKey);
}

export function setLocalStorage(localStorageKey, localStorageValue) {
  try {
    localStorage.setItem(localStorageKey, localStorageValue);
  } catch (e) {
    console.log('local storage is not supported by browser');
  }
}

export function getLocalStorage(localStorageKey) {
  return localStorage.getItem(localStorageKey);
}
