import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveModal } from '@getgo/chameleon-react';
import { connectDispatch } from 'Utils/decorators';
import CreateChannel from 'Components/Channel/CreateChannel';
import { sendNotification } from 'Reducks/Notification/actions';
import { createAndUpdateChannelAction } from 'Reducks/Channel/actions';
import config from 'appConfig';
import appLogger from 'Utils/logging';
import WithLoading from 'Components/Common/WithLoading';
import { trackCreateChannel } from 'Mixpanel/Features/Channel/channelTracker';
import { CREATE_SOURCES } from 'Mixpanel/Features/Channel/constants';
import { withTranslation } from 'react-i18next';
import styles from '../../Channel/Common/styles.scss';

@connectDispatch(
  (state) => ({
    userKey: state.user.metadata.userKey,
    displayName: state.user.user.displayName
  })
)
class CreateChannelModal extends PureComponent {
  static propTypes = {
    close: PropTypes.func,
    userKey: PropTypes.string,
    dispatch: PropTypes.func,
    onChannelCreated: PropTypes.func,
    displayName: PropTypes.string,
    t: PropTypes.func
  };

  state = {
    newChannel: {},
    loading: false,
    hasError: false
  };

  onCreateChannel = () => {
    const { newChannel } = this.state;
    const { userKey, dispatch, displayName } = this.props;

    this.setState({ loading: true });
    return this.props.dispatch(createAndUpdateChannelAction(userKey, newChannel.channelTitle || displayName,
      newChannel.channelDescription, newChannel.isLimitedAudience, newChannel.channelLogo)).then((res) => {
      newChannel.channelKey = res.value;
      this.setState({ loading: false });
      trackCreateChannel(newChannel.isLimitedAudience, CREATE_SOURCES.CHANNEL_CARD);
      dispatch(sendNotification(null, 'channelCard.created'));
      this.props.onChannelCreated(newChannel);
    }).catch((error) => {
      appLogger.error(error);
      this.setState({ loading: false });
      if (error.status === 400 && error.body === 'User cannot create any more channels') {
        dispatch(sendNotification(null, 'channelCard.tooManyError', true));
      } else {
        dispatch(sendNotification(null, 'error.genericError', true));
      }
    });
  };

  onChannelUpdated = (channel, hasError) => {
    this.setState({ newChannel: channel, hasError });
  };

  render() {
    const { close } = this.props;
    const { loading, hasError } = this.state;
    const { t } = this.props;
    return (
      <ResponsiveModal
        isOpen={true}
        title={t('channelCard.createChannel')}
        onClose={close}
        primaryActionButton={loading ? undefined : {
          text: t('button.create'),
          disabled: hasError,
          onClick: this.onCreateChannel,
          id: 'create_channel_modal_create_button'
        }}
        secondaryActionButton={loading ? undefined : {
          text: t('button.cancel'),
          onClick: this.props.close,
          id: 'create_channel_modal_cancel_button'
        }}
      >
        <WithLoading loading={loading}>
          {t('channelCard.createBody')}
          <span>
            <a className={styles.learnMoreLink} href={config.support.g2s} target='_blank' rel='noopener noreferrer'>
              {t('schedule.learnMore')}
            </a>
          </span>
          <CreateChannel selected={true} onChannelUpdated={this.onChannelUpdated}/>
        </WithLoading>
      </ResponsiveModal>
    );
  }
}

export default withTranslation()(CreateChannelModal);
