import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Backdrop } from '@getgo/chameleon-react';
import styles from './styles.scss';

class Iframe extends PureComponent {
  static propTypes = {
    previewUrl: PropTypes.string,
    onClose: PropTypes.func,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    containerClassName: PropTypes.string,
    iframeClassName: PropTypes.string,
    shouldBlockClicks: PropTypes.bool,
    t: PropTypes.func
  }

  static defaultProps = {
    isOpen: true
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  render() {
    const {
      title, previewUrl, isOpen, containerClassName, iframeClassName, shouldBlockClicks
    } = this.props;
    const { t } = this.props;
    return (
      <Backdrop onClickHandler={this.props.onClose} isOpen={isOpen}>
        {/* eslint-disable  jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames(styles.iframeContainer, containerClassName)}
          onKeyPress={this.stopPropagation}
          onClick={this.stopPropagation}>
          <button id='iframe_close' onClick={this.props.onClose}>
            <span>{t('button.close')}</span><i className="togo-icon togo-icon-closes"/>
          </button>
          <iframe title={title} id='iframe_container' className={classNames(styles.iframe, iframeClassName)} src={previewUrl}>
            {title}
          </iframe>
          {shouldBlockClicks ? <div className={styles.overlay}/> : null}
          <div className={styles.iframeDetails}>
            <span id='iframe_title' className={styles.iframeTitle}>{title}</span>
          </div>
        </div>
      </Backdrop>
    );
  }
}

export default withTranslation()(Iframe);
