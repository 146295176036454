import { getSourceTrackingInfoAction,
  addSourceTrackingOptionAction } from './actions';

const defaultState = {
  isFetching: true,
  data: [],
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case getSourceTrackingInfoAction.toString(): {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case getSourceTrackingInfoAction.complete.toString(): {
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: null
      };
    }
    case getSourceTrackingInfoAction.failed.toString(): {
      const sourceTrackingDataCopy = state.data;
      return {
        ...state,
        isFetching: false,
        data: sourceTrackingDataCopy,
        error: action.error
      };
    }
    case addSourceTrackingOptionAction.toString(): {
      return {
        ...state,
        isFetching: true,
        error: null
      };
    }
    case addSourceTrackingOptionAction.complete.toString(): {
      const sourceTrackingDataCopy = state.data;
      sourceTrackingDataCopy.push(action.data);
      return {
        ...state,
        isFetching: false,
        data: sourceTrackingDataCopy,
        error: action.error
      };
    }
    case addSourceTrackingOptionAction.failed.toString(): {
      const sourceTrackingDataCopy = state.data;
      return {
        ...state,
        isFetching: false,
        data: sourceTrackingDataCopy,
        error: action.error
      };
    }
    default:
      return {
        ...state
      };
  }
};
