import { history } from 'Store/configureStore';
import { LOCATION_CHANGE } from 'connected-react-router';

// we dont want to reroute all urls with #,
// only the once that we supported before
const hashRouteList = [
  '#/settings',
  '#/webinar',
  '#/contentLibrary',
  '#/schedule',
  '#/startWebinar',
  '#/notifications',
  '#/video',
  '#/manageChannels'
];

const supportHashRoute = () => (next) => (action) => {
  if (action.type === LOCATION_CHANGE && action.payload && action.payload.location) {
    if (action.payload.location.hash && action.payload.location.pathname === '/') {
      const replace = hashRouteList.some((route) => action.payload.location.hash.startsWith(route));
      if (replace) {
        const newURL = action.payload.location.hash.replace('#', '');
        history.replace(newURL);
      }
    }
  }

  return next(action);
};

export default supportHashRoute;
