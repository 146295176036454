import config from 'appConfig';
import getHeaders from 'Api/Helpers/getHeaders';
import { fetchJson } from './Helpers/fetch';

export function getMe() {
  const url = `${config.accountService.url}/identity/v1/Users/me`;
  const options = {
    headers: getHeaders(true, 'Bearer')
  };

  return fetchJson(url, options, 'json');
}
