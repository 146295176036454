import { TAG_TYPE, TAG_IDENTIFIER } from 'Components/Card/MessageCenterNotifications/constants';

export function updateInfoMessageCount(messageId, state) {
  if (messageId && state && state.data && state.data.length > 0) {
    const message = state.data.find((m) => m.identifier === messageId);
    if (message && state.info && state.info.data && state.info.data.unreadMessagesCount > 0) {
      const newInfo = Object.assign({}, state.info);
      const newCount = newInfo.data.unreadMessagesCount - 1;
      newInfo.data.unreadMessagesCount = newCount;
      return newInfo;
    }
  }

  return null;
}

export function createNotificationPreferenceMessage(read = false) {
  return { tag: TAG_TYPE.NOTIFICATION_PREFERENCE, identifier: TAG_IDENTIFIER.NOTIFICATION_PREFERENCE, read };
}
