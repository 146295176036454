import { getStore } from 'Store/configureStore';
import { showAlert } from 'Reducks/Notification/actions';
import appLogger from 'Utils/logging';
import * as authHelper from 'Utils/authHelper';
import i18n from 'i18next';
import isFunction from 'lodash.isfunction';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';

const logger = appLogger.create('E2E Dashboard Service Layer');

const refreshTokenFlow = (sameWindow = false) => new Promise((resolve, reject) => {
  const refreshMechanism = sameWindow
    ? authHelper.reloadAuth
    : async () => {
      await authHelper.refreshToken();
      resolve();
    };
  getStore().dispatch(showAlert(
    i18n.t('authError.title'),
    i18n.t('authError.description'),
    { title: i18n.t('authError.later'), onClickHandler: () => reject(new Error('User suppressed session expiry notification')) },
    { title: i18n.t('authError.reload'), onClickHandler: refreshMechanism },
    'danger'
  ));
});

const getOptionsWithResolvedHeaders = (options) => (isFunction(options.headers)
  ? Object.assign({}, options, { headers: options.headers() })
  : options);

export const fetchJson = async (url, options = {}, type = JSON_MIME_TYPE, parseBody = true) => {
  try {
    const optionsWithResolvedHeader = getOptionsWithResolvedHeaders(options);
    const res = await fetch(url, optionsWithResolvedHeader);
    if (res.status === 401) {
      if (
        options.method && options.method.toUpperCase() !== 'GET'
        && optionsWithResolvedHeader.headers
      ) {
        await refreshTokenFlow();
        return fetchJson(url, options, type, parseBody);
      }
      refreshTokenFlow(true);
    }

    if (res.status < 200 || res.status >= 300) {
      const errResponse = new Error('failure');
      errResponse.statusText = res.statusText;
      errResponse.status = res.status;
      if (res.json) {
        return (res.json().then((body) => {
          errResponse.body = body;
          throw errResponse;
        }).catch(() => { throw errResponse; }));
      }
      throw errResponse;
    }

    if (res.status === 204 || res.status === 202) return res.status;
    return parseBody ? res[type]() : res;
  } catch (error) {
    logger.error('Error on API Request:', 'url =', url, 'RequestMethod =', options.method || '', 'error =', error);
    throw error;
  }
};

// 401 need to be handle in a same way as 400 in case of Livestream calls
export const fetchJsonForLiveStream = async (url, options = {}, type = JSON_MIME_TYPE, parseBody = true) => {
  try {
    const optionsWithResolvedHeader = getOptionsWithResolvedHeaders(options);
    const res = await fetch(url, optionsWithResolvedHeader);

    if (res.status < 200 || res.status >= 300) {
      const errResponse = new Error('failure');
      errResponse.statusText = res.statusText;
      errResponse.status = res.status;
      if (res.json) {
        return (res.json().then((body) => {
          errResponse.body = body;
          throw errResponse;
        }).catch(() => { throw errResponse; }));
      }
      throw errResponse;
    }

    if (res.status === 204 || res.status === 202) return res.status;
    return parseBody ? res[type]() : res;
  } catch (error) {
    logger.error('Error on API Request:', 'url =', url, 'RequestMethod =', options.method || '', 'error =', error);
    throw error;
  }
};

// handle 202 response for ve report
export const fetchJsonForVEReport = async (url, options = {}, type = JSON_MIME_TYPE, parseBody = true) => {
  try {
    const optionsWithResolvedHeader = getOptionsWithResolvedHeaders(options);
    const res = await fetch(url, optionsWithResolvedHeader);
    if (res.status === 401) {
      if (
        options.method && options.method.toUpperCase() !== 'GET'
        && optionsWithResolvedHeader.headers
      ) {
        await refreshTokenFlow();
        return fetchJson(url, options, type, parseBody);
      }
      refreshTokenFlow(true);
    }

    if (res.status < 200 || res.status >= 300) {
      const errResponse = new Error('failure');
      errResponse.statusText = res.statusText;
      errResponse.status = res.status;
      if (res.json) {
        return (res.json().then((body) => {
          errResponse.body = body;
          throw errResponse;
        }).catch(() => { throw errResponse; }));
      }
      throw errResponse;
    }

    if (res.status === 204) return res.status;
    return parseBody ? res[type]() : res;
  } catch (error) {
    logger.error('Error on API Request:', 'url =', url, 'RequestMethod =', options.method || '', 'error =', error);
    throw error;
  }
};

/**
 * Returns a query string
 * @param {object} queries
 * @returns {string} { key1: "one", key2: "two" } -> "key1=one,key2=two"
 */
export const buildQueries = (queries = {}) => (
  Object.keys(queries)
    .reduce((acc, key) => acc.concat(`${key}=${queries[key]}&`), '')
    .slice(0, -1)
);
