
import { createAsyncAction } from '../reduxActions';

export const getEventsForDatesAction = createAsyncAction('GET_EVENTS_FOR_DATES');

export function getEventsForDates(startDate, endDate) {
  return { type: getEventsForDatesAction.toString(), startDate, endDate };
}

export const getVirtualEventsForDatesAction = createAsyncAction('GET_VIRTUAL_EVENTS_FOR_DATES');

export function getVirtualEventsForDates(startDate, endDate) {
  return { type: getVirtualEventsForDatesAction.toString(), startDate, endDate };
}
