import { scheduleWebinarAction } from './actions';

const defaultState = {
  errorMessage: null,
  webinarKey: null,
  isLoading: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case scheduleWebinarAction.toString(): {
      return {
        ...state,
        webinarKey: null,
        errorMessage: null,
        isLoading: true
      };
    }
    case scheduleWebinarAction.complete.toString(): {
      return {
        ...state,
        webinarKey: action.webinarKey,
        errorMessage: null,
        isLoading: false
      };
    }
    case scheduleWebinarAction.failed.toString(): {
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
