import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import LDRedux from 'ld-redux';
import storage from 'redux-persist/lib/storage';
import { reducer as analytics } from './Analytics';
import { reducer as appState } from './AppState';
import { reducer as associatedRecordings } from './Recording/AssociatedRecordings';
import { reducer as auth } from './Auth';
import { reducer as calendar } from './Calendar';
import { reducer as channel } from './Channel';
import { reducer as copyWebinar } from './CopyWebinar';
import { reducer as emailSettings } from './EmailSettings';
import _entities from './_Entities/reducer';
import { reducer as handouts } from './Handouts';
import { reducer as interprefy } from './Interprefy';
import { reducer as ipd } from './IPD';
import { reducer as messageCenter } from './MessageCenterNotification';
import { reducer as notification } from './Notification';
import { reducer as paymentSettings } from './Payment';
import { reducer as poll } from './Poll';
import { reducer as pushNotification } from './PushNotification';
import { reducer as qAndASettings } from './QAndASettings';
import { reducer as recordingReport } from './Recording/Reporting';
import { reducer as recordings } from './Recording';
import { reducer as registrantDetails } from './RegistrantDetails';
import { reducer as registrantsList } from './RegistrantsList';
import { reducer as registrationSettings } from './RegistrationSettings';
import { reducer as scheduleWebinar } from './ScheduleWebinar';
import { reducer as search } from './Search';
import { reducer as settings } from './AccountSettings';
import { reducer as sourceTracking } from './SourceTracking';
import { reducer as survey } from './Survey';
import { reducer as transcript } from './Transcript';
import { reducer as webinarSummary } from './WebinarSummary';
import { reducer as ui } from './Ui';
import { reducer as undoHistory } from './UndoHistory';
import { reducer as user } from './User';
import { reducer as videoEditor } from './VideoEditor';
import { reducer as videos } from './Videos';
import { reducer as webinars } from './Webinar';
import { reducer as webinarSettings } from './WebinarSettings';
import { reducer as scheduleVirtualWebinar } from './VirtualEventsSchedule';
import { reducer as virtualEventDetails } from './VirtualEventDetails';
import { reducer as virtualEventSession } from './VirtualEventSession';
import { reducer as virtualEventsList } from './VirtualEventsList';
import { reducer as copyEventList } from './CopyEvents';

const uiPersistConfig = {
  key: 'ui',
  storage,
  whitelist: ['hideVideoEditingTrimAlert', 'isEventCompactView']
};

const webinarsPersistConfig = {
  key: 'webinars',
  storage,
  whitelist: ['attendance']
};

const ipdPersistConfig = {
  key: 'ipd',
  storage,
  whitelist: ['isCollapsed']
};

const channelPersistConfig = {
  key: 'channel',
  storage,
  whitelist: ['ui']
};

const appStatePersistConfig = {
  key: 'appState',
  storage,
  whitelist: ['darkMode']
};

const rootReducer = (history) => combineReducers(Object.freeze({
  _entities,
  analytics,
  appState: persistReducer(appStatePersistConfig, appState),
  associatedRecordings,
  auth,
  calendar,
  channel: persistReducer(channelPersistConfig, channel),
  copyWebinar,
  emailSettings,
  handouts,
  interprefy,
  ipd: persistReducer(ipdPersistConfig, ipd),
  launchDarkly: LDRedux.reducer(),
  messageCenter,
  notification,
  paymentSettings,
  poll,
  pushNotification,
  qAndASettings,
  recordingReport,
  recordings,
  registrantDetails,
  registrantsList,
  registrationSettings,
  router: connectRouter(history),
  scheduleWebinar,
  search,
  settings,
  sourceTracking,
  survey,
  transcript,
  webinarSummary,
  ui: persistReducer(uiPersistConfig, ui),
  undoHistory,
  user,
  videoEditor,
  videos,
  webinars: persistReducer(webinarsPersistConfig, webinars),
  webinarSettings,
  scheduleVirtualWebinar,
  virtualEventDetails,
  virtualEventSession,
  virtualEventsList,
  copyEventList
}));

export default rootReducer;
