import { put, all, fork, takeLatest, call } from 'redux-saga/effects';
import moment from 'moment';
import { getWebinars } from 'Api/Webinar/webinarService';
import { getVirtualEvents } from 'Api/VirtualEvent/VirtualEventDetails/VirtualEventDetailsService';
import { fetchCopyEventsAction } from './actions';

const compare = (firstObject, secondObject) => {
  let firstObjectTime = null;
  let secondObjectTime = null;
  if (firstObject.eventKey) {
    firstObjectTime = new Date(firstObject.start).getTime();
  } else {
    firstObjectTime = new Date(firstObject.times[0].startTime).getTime();
  }
  if (secondObject.eventKey) {
    secondObjectTime = new Date(secondObject.start).getTime();
  } else {
    secondObjectTime = new Date(secondObject.times[0].startTime).getTime();
  }
  if (firstObjectTime < secondObjectTime) {
    return 1;
  }
  if (firstObjectTime > secondObjectTime) {
    return -1;
  }
  return 0;
};

export function* fetchCopyEventsList({ experienceType, buttonTabIndex, filter }) {
  try {
    let dateFilter = {};
    const defaults = {
      experienceType,
      sortField: 'starttime',
      sortOrder: 'desc',
      page: 0,
      filter
    };
    const fromTime = moment().subtract(10, 'y').utc().format();
    const toTime = moment(new Date()).utc().format();
    dateFilter = { fromTime, toTime };
    let webinarData = [];
    let virtualEventData = [];
    if (buttonTabIndex !== 4) {
      const data = yield call(getWebinars, { ...defaults, ...dateFilter });
      webinarData = [...data.data];
    }
    if ((buttonTabIndex === 0 || buttonTabIndex === 4) && !filter) {
      const paramObj = {
        timeRangeFrom: fromTime,
        timeRangeTo: toTime
      };
      const eventData = yield call(getVirtualEvents, paramObj);
      virtualEventData = eventData && eventData.events ? eventData.events : [];
    }
    const sortedData = [...webinarData, ...virtualEventData].sort(compare);
    yield put({ type: fetchCopyEventsAction.complete.toString(), data: sortedData });
  } catch (e) {
    yield put({ type: fetchCopyEventsAction.failed.toString(), message: e.message });
  }
}

export function* watchFetchCopyEvents() {
  yield takeLatest(fetchCopyEventsAction.toString(), fetchCopyEventsList);
}

export default function* rootSaga() {
  yield all([fork(watchFetchCopyEvents)]);
}
