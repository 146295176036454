import errors from 'Api/Helpers/apiErrors.json';
import { call, put, all, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { clearLocalStorage } from 'Utils/localStorage';
import * as VirtualEventSessionService from '../../Api/VirtualEvent/Sessions/VirtualEventSessionService';
import * as webinarService from '../../Api/Webinar/webinarService';
import { createVirtualEventSessionAction,
  showSessionUpdateNotificationAction,
  saveVirtualEventSessionAction,
  deleteVirtualEventSessionAction,
  addPanelistToVirtualEventSessionAction,
  fetchVirtualConferenceAction,
  replaceSpeakerAction,
  deleteSpeakerAction,
  updateRecordingAction,
  resendSpeakerInvitationAction } from './actions';
import { getVirtualWebinarAction, updateConferenceDetailsAction } from '../VirtualEventDetails/actions';
import { SEND_NOTIFICATION } from '../Notification/actions';

export function* createVirtualEventSession({ eventKey, sessionDetails }) {
  try {
    const data = yield call(VirtualEventSessionService.createVirtualEventSession, eventKey, sessionDetails);
    yield put({ type: createVirtualEventSessionAction.complete.toString(), data });
    yield put({ type: getVirtualWebinarAction.toString(), webinarKey: eventKey });
  } catch (e) {
    yield put({
      type: createVirtualEventSessionAction.failed.toString()
    });
    if (e.body.errorCode === errors.api.schedule.maintenanceErrorCode) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maintenanceWindowFailure', isError: true, timeOut: 7000 });
      return;
    }
    yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.genericFail', isError: true });
  }
}

export function* fetchVirtualConference({ conferenceKey }) {
  try {
    const data = yield call(webinarService.getWebinar, conferenceKey);
    if (data.inSession) {
      // clearLocalStorage(conferenceKey);
      yield put({ type: fetchVirtualConferenceAction.complete.toString(), data });
    } else {
      clearLocalStorage(conferenceKey);
    }
  } catch (e) {
    yield put({ type: fetchVirtualConferenceAction.failed.toString(), conferenceKey, message: e.message });
  }
}

export function* deleteVirtualEventSession({ eventKey, conferenceKey }) {
  try {
    const data = yield call(VirtualEventSessionService.deleteVirtualEventSession, eventKey, conferenceKey);
    yield put({ type: deleteVirtualEventSessionAction.complete.toString(), data });
    yield put({ type: updateConferenceDetailsAction.complete.toString(), conferenceKey });
  } catch (e) {
    yield put({
      type: deleteVirtualEventSessionAction.failed.toString()
    });
    yield put({ type: updateConferenceDetailsAction.failed.toString(), conferenceKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.genericFail', isError: true });
  }
}

export function* saveVirtualEventSession({ eventKey, conferenceKey, sessionDetails }) {
  if (Array.isArray(sessionDetails)) {
    const fields = sessionDetails.map((session) => session.path);
    try {
      const body = sessionDetails.map((session) => ({ ...session, path: `/${session.path}` }));
      const data = yield call(VirtualEventSessionService.saveVirtualEventSession, eventKey, conferenceKey, body);
      const payload = { ...data, field: fields };
      yield put({ type: saveVirtualEventSessionAction.complete.toString(), data: payload });
    } catch (e) {
      yield put({
        type: saveVirtualEventSessionAction.failed.toString(),
        message: e.message,
        data: { errorField: fields }
      });
      if (e.body.errorCode === errors.api.schedule.maintenanceErrorCode) {
        yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maintenanceWindowFailure', isError: true, timeOut: 7000 });
        return;
      }
      yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
    }
  }
}

export function* updateRecording({ eventKey, conferenceKey, sessionDetails }) {
  try {
    const data = yield call(VirtualEventSessionService.updateRecording, eventKey, conferenceKey, sessionDetails);
    yield put({ type: updateRecordingAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: updateRecordingAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
  }
}

export function* addPanelistToVirtualEventSession({ eventKey, conferenceKey, panelistId, addJoinLink }) {
  try {
    const data = yield call(VirtualEventSessionService.addPanelistToVirtualEventSession, eventKey, conferenceKey, panelistId);
    yield put({ type: addPanelistToVirtualEventSessionAction.complete.toString(), data });
    if (typeof addJoinLink === 'function') {
      addJoinLink(panelistId, data.joinUrl);
    }
  } catch (e) {
    yield put({
      type: addPanelistToVirtualEventSessionAction.failed.toString()
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
  }
}

export function* deleteSpeaker({ eventKey, conferenceKey, speakerId }) {
  try {
    const data = yield call(VirtualEventSessionService.deleteSpeaker, eventKey, conferenceKey, speakerId);
    yield put({ type: deleteSpeakerAction.complete.toString(), data });
  } catch (e) {
    yield put({ type: deleteSpeakerAction.failed.toString() });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
  }
}

export function* replaceSpeaker({ eventKey, conferenceKey, speakerId, panelistId }) {
  try {
    yield call(VirtualEventSessionService.deleteSpeaker, eventKey, conferenceKey, speakerId);
    const data = yield call(VirtualEventSessionService.addPanelistToVirtualEventSession, eventKey, conferenceKey, panelistId);
    yield put({ type: replaceSpeakerAction.complete.toString(), data });
  } catch (e) {
    yield put({ type: replaceSpeakerAction.failed.toString() });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.failed', isError: true });
  }
}

export function* showSessionUpdateNotification({ message }) {
  yield put({ type: SEND_NOTIFICATION, message, isError: false });
}

export function* resendSpeakerInvitation({ eventKey, speakerId, speakerName, conferenceKey }) {
  try {
    yield call(VirtualEventSessionService.resendSpeakerInvitation, eventKey, speakerId, conferenceKey);
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.resendInvitation', isError: false, messageKeyParams: [{ name: speakerName }] });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.resendInvitationFailed', isError: true, messageKeyParams: [{ name: speakerName }] });
  }
}

export function* watchCreateVirtualEventSessionAction() {
  yield takeLatest(createVirtualEventSessionAction.toString(), createVirtualEventSession);
}

export function* watchUpdateVirtualEventSession() {
  yield takeEvery(saveVirtualEventSessionAction.toString(), saveVirtualEventSession);
}

export function* watchUpdateRecording() {
  yield takeEvery(updateRecordingAction.toString(), updateRecording);
}

export function* watchShowSessionUpdateNotification() {
  yield takeLatest(showSessionUpdateNotificationAction.toString(), showSessionUpdateNotification);
}

export function* watchDeleteVirtualEventSessionAction() {
  yield takeLatest(deleteVirtualEventSessionAction.toString(), deleteVirtualEventSession);
}

export function* watchAddPanelistToVirtualEventSessionAction() {
  yield takeLatest(addPanelistToVirtualEventSessionAction.toString(), addPanelistToVirtualEventSession);
}

export function* watchFetchVirtualConferenceAction() {
  yield takeLatest(fetchVirtualConferenceAction.toString(), fetchVirtualConference);
}

export function* watchDeleteSpeakerAction() {
  yield takeLatest(deleteSpeakerAction.toString(), deleteSpeaker);
}

export function* watchReplaceSpeakerAction() {
  yield takeLatest(replaceSpeakerAction.toString(), replaceSpeaker);
}

export function* watchResendSpeakerInvitation() {
  yield takeLatest(resendSpeakerInvitationAction.toString(), resendSpeakerInvitation);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateVirtualEventSessionAction),
    fork(watchUpdateVirtualEventSession),
    fork(watchShowSessionUpdateNotification),
    fork(watchDeleteVirtualEventSessionAction),
    fork(watchAddPanelistToVirtualEventSessionAction),
    fork(watchFetchVirtualConferenceAction),
    fork(watchDeleteSpeakerAction),
    fork(watchReplaceSpeakerAction),
    fork(watchUpdateRecording),
    fork(watchResendSpeakerInvitation)
  ]);
}
