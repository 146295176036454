import { MessageBusEventClient } from '@getgo/message-bus-js-sdk';
import * as messageBusEventCreator from './messageBusEventCreator';
import appConfig from '../appConfig';

let messageBusEventClient;

function getMessageBusEventClientInstance() {
  if (!messageBusEventClient) {
    messageBusEventClient = new MessageBusEventClient(appConfig.messageBus.url);
  }
  return messageBusEventClient;
}

/**
 * Dispatches a clicked event to Message Bus.
 * @param {*} event - Raw event object with set properties. Format expected by function is described below:
 *  {
 *
 *    "userType": "ORGANIZER",
 *    "anonymousUserInfo": {anonymousUserKey: "98173987123981273"},
 *    "userFrontEndSessionId": "981273912732933",
 *    "organizerInfo": {organizerKey: "981273912732933"},
 *    "webpageName": "webinarDetails",
 *
 *    "objectName": "AudioSettingsButton",
 *    "objectClicked: "BUTTON",
 *
 *  }
 *  Of these, webpageName, userType, userFrontEndSessionId are mandatory.
 */
export default function dispatchClickedEvent(event) {
  const messageBusEvent = messageBusEventCreator.createClickedEvent(event);
  return getMessageBusEventClientInstance().sendEvent(messageBusEvent)
    .catch((err) => {
      // TODO: Create logger and log errors
      console.error(`Error sending clicked event with id: ${event.eventId} - ${err}`);
    });
}

export function dispatchPageLoadEvent(event) {
  const messageBusEvent = messageBusEventCreator.createPageLoadEvent(event);
  return getMessageBusEventClientInstance().sendEvent(messageBusEvent)
    .catch((err) => {
      console.error(`Error sending load event with id: ${event.eventId} - ${err}`);
    });
}
