import React from 'react';
import { func, string } from 'prop-types';
import { ResponsiveModal } from '@getgo/chameleon-react';
import { withTranslation } from 'react-i18next';
import { bound, mixpanelEvent } from '../../../../Utils/decorators';
import { audioWaveformGenerated } from '../../../../Mixpanel/Features/Video/eventBuilders';
import { generateAudioWaveform } from '../../../../Reducks/VideoEditor/actions';
import nowaves from './assets/nowaves.svg';
import styles from './styles.scss';

class PreActionPage extends React.PureComponent {
  static propTypes = {
    close: func.isRequired,
    dispatch: func.isRequired,
    assetKey: string,
    t: func
  }

  @bound
  @mixpanelEvent(audioWaveformGenerated)
  generateAudioWaveform() {
    const resources = [
      { external: false, provider: 's3', status: 'INITIATED', resourceType: 'audiowaveform' },
      { external: false, provider: 's3', status: 'INITIATED', resourceType: 'audio' }
    ];

    this.props.dispatch(
      generateAudioWaveform(this.props.assetKey, resources)
    );
  }

  render() {
    const { t } = this.props;

    const primaryActionButton = {
      text: t('button.generateWaves'),
      disabled: false,
      onClick: this.generateAudioWaveform
    };

    const secondaryActionButton = {
      text: t('button.cancel'),
      disabled: false,
      onClick: this.props.close
    };

    return (
      <ResponsiveModal
        isOpen
        onClose={this.props.close}
        title={t('modals.generateAudioWaveform.preActionPage.title')}
        primaryActionButton={primaryActionButton}
        secondaryActionButton={secondaryActionButton}>
        <div className={styles.preActionPage}>
          <span className={styles.text}>{t('modals.generateAudioWaveform.preActionPage.body.one')}</span>
          <br />
          <span className={styles.text}>{t('modals.generateAudioWaveform.preActionPage.body.two')}</span>
          <img alt='' role='presentation' src={nowaves} />
        </div>
      </ResponsiveModal>
    );
  }
}

export default withTranslation()(PreActionPage);
