const getNextStartTime = (webinar) => {
  const { times } = webinar;
  const now = Date.now();

  let nextTimes = times[times.length - 1];

  for (let i = 0; i < times.length; i++) {
    const time = times[i];
    const startTime = new Date(time.startTime).getTime();

    if (startTime >= now) {
      nextTimes = time;
      break;
    }
  }

  return new Date(nextTimes.startTime).getTime();
};

const formatWebinarsById = (webinars = []) => {
  const entities = {};

  webinars.forEach((webinar) => {
    entities[webinar.webinarKey] = {
      nextStartTime: getNextStartTime(webinar),
      ...webinar
    };
  });

  return entities;
};

const getPrevious = ({ page }) => (
  page - 1 > -1 ? page - 1 : undefined
);

const getNext = ({ page, limit, total }) => (
  (page + 1) * limit >= total ? undefined : (page + 1)
);

const getPageCount = ({ limit, total }) => (
  Math.ceil(total / limit)
);

const createPagination = ({
  total,
  limit,
  page,
  pageSize
}) => ({
  previous: getPrevious({ page }),
  next: getNext({ page, limit, total }),
  pageCount: getPageCount({ limit, total }),
  total,
  limit,
  page,
  pageSize
});

export {
  createPagination,
  formatWebinarsById
};
