import { createAsyncAction as createAsyncActionV2 } from 'redux-promise-middleware-actions';
import { createAsyncAction } from '../reduxActions';
import { getVirtualEventDetails } from '../../Api/VirtualEvent/VirtualEventDetails/VirtualEventDetailsService';
import { getRecordingByAssetKey } from '../../Api/Assets/assetsService';

export const getVirtualEventAllDetails = createAsyncActionV2('GET_VIRTUAL_EVENT_ALL_DETAILS', getVirtualEventDetails);

export const fetchSimuliveRecording = createAsyncActionV2('GET_SIMULIVE_RECORDING', getRecordingByAssetKey);

// get virtual event details
export const getVirtualWebinarAction = createAsyncAction('GET_VIRTUAL_WEBINAR');
export function fetchVirtualWebinar(webinarKey, organizerKey, shouldLoadWebinar, accountKey, isVECreation, userLocale) {
  return { type: getVirtualWebinarAction.toString(), webinarKey, organizerKey, shouldLoadWebinar, accountKey, isVECreation, userLocale };
}

// to update virtual event
export const updateVirtualEventDetailsAction = createAsyncAction('UPDATE_VIRTUAL_EVENT_DETAILS');
export function updateVirtualEventDetails(eventKey, virtualEventData) {
  return { type: updateVirtualEventDetailsAction.toString(), eventKey, virtualEventData };
}

// add organizer to the event

export const addOrganizerToVirtualEventAction = createAsyncAction('ADD_ORGANIZER_TO_VIRTUAL_EVENT');
export function addOrganizerToVirtualEvent(eventKey, organizerData) {
  return { type: addOrganizerToVirtualEventAction.toString(), eventKey, organizerData };
}

export const deleteOrganizerToVirtualEventAction = createAsyncAction('DELETE_ORGANIZER_TO_VIRTUAL_EVENT');
export function deleteOrganizerToVirtualEvent(eventKey, organizerKey) {
  return { type: deleteOrganizerToVirtualEventAction.toString(), eventKey, organizerKey };
}

export const updateOrganizerToVirtualEventAction = createAsyncAction('UPDATE_ORGANIZER_TO_VIRTUAL_EVENT');
export function updateOrganizerToVirtualEvent(eventKey, organizerKey, fieldName, data, updateType) {
  return { type: updateOrganizerToVirtualEventAction.toString(), eventKey, organizerKey, fieldName, data, updateType };
}

export const createVirtualEventPanelistAction = createAsyncAction('CREATE_VIRTUAL_EVENT_PANELIST');
export function createVirtualEventPanelist(eventKey, panelistData, failureCallback) {
  return { type: createVirtualEventPanelistAction.toString(), eventKey, panelistData, failureCallback };
}

export const updateVirtualEventPanelistAction = createAsyncAction('UPDATE_VIRTUAL_EVENT_PANELIST');
export function updateVirtualEventPanelist(eventKey, panelistKey, fieldName, data, updateType) {
  return { type: updateVirtualEventPanelistAction.toString(), eventKey, panelistKey, fieldName, data, updateType };
}

export const deleteVirtualEventPanelistAction = createAsyncAction('DELETE_VIRTUAL_EVENT_PANELIST');
export function deleteVirtualEventPanelist(eventKey, panelistKey, successCallBack) {
  return { type: deleteVirtualEventPanelistAction.toString(), eventKey, panelistKey, successCallBack };
}

export const showPanelistUpdateNotificationAction = createAsyncAction('VIRTUAL_EVENT_PANELIST_NOTIFICATION');
export function showPanelistUpdateNotification(message) {
  return { type: showPanelistUpdateNotificationAction.toString(), message };
}

export const PANELISTS_UPDATED = 'PANELISTS_UPDATED';
export const CLEAR_ERROR_STATES = 'CLEAR_ERROR_STATES';
export function clearPanelistErrorStates() {
  return { type: CLEAR_ERROR_STATES };
}

export const deleteVirtualEventAction = createAsyncAction('DELETE_VIRTUAL_EVENT');
export function deleteVirtualEvent(eventKey) {
  return { type: deleteVirtualEventAction.toString(), eventKey };
}
export const showOrganizerUpdateNotificationAction = createAsyncAction('VIRTUAL_EVENT_ORGANIZERS_NOTIFICATION');
export function showOrganizerUpdateNotification(message) {
  return { type: showOrganizerUpdateNotificationAction.toString(), message };
}
export const showDateAndTimeNotificationAction = createAsyncAction('VIRTUAL_EVENT_EDITDATEANDTIME_NOTIFICATION');
export function showDateAndTimeNotification(message) {
  return { type: showDateAndTimeNotificationAction.toString(), message };
}

export const getVirtualEventRegistrationQuestionAction = createAsyncAction('GET_VIRTUAL_EVENT_REGISTRATION_QUESTION');
export function getVirtualEventRegistrationQuestion(questionnaireKey, accountKey, userLocale) {
  return { type: getVirtualEventRegistrationQuestionAction.toString(), questionnaireKey, accountKey, userLocale };
}

export const updateVERegistrationQuestionAction = createAsyncAction('UPDATE_VIRTUAL_EVENT_REGISTRATION_QUESTION');
export function updateVERegistrationQuestion(eventKey, questions, questionnaireKey, accountKey, userLocale) {
  return { type: updateVERegistrationQuestionAction.toString(), eventKey, questions, questionnaireKey, accountKey, userLocale };
}

export const setVELiveStreamingConfigAction = createAsyncAction('SET_VIRTUAL_LIVE_STREAMING_CONFIG');
export function setVELiveStreamingConfig(config, channel, conferenceKey) {
  return { type: setVELiveStreamingConfigAction.toString(), config, channel, conferenceKey };
}

export const updateVELiveStreamingChannelConfigAction = createAsyncAction('UPDATE_VIRTUAL_LIVE_STREAM_CHANNEL_CONFIG');
export function updateVELiveStreamingChannelConfig(config, channel, conferenceKey) {
  return { type: updateVELiveStreamingChannelConfigAction.toString(), config, channel, conferenceKey };
}

export const getAllVELiveStreamingConfigurationAction = createAsyncAction('GET_VIRTUAL_LIVE_AVAILABLE_STREAM_CONFIG');
export function getAllVELiveStreamingConfiguration(config) {
  return { type: getAllVELiveStreamingConfigurationAction.toString(), config };
}

export const createVEBroadcastSessionAction = createAsyncAction('CREATE_VIRTUAL_BROADCAST_SESSION');
export function createVEBroadcastSession(config, channel, conferenceKey) {
  return { type: createVEBroadcastSessionAction.toString(), config, channel, conferenceKey };
}

export const linkedInVEReloginAction = createAsyncAction('LINKEDIN_VIRTUAL_RELOGIN');
export function linkedInVERelogin(config) {
  return { type: linkedInVEReloginAction.toString(), config };
}

export const getVEActiveTargetsStatusAction = createAsyncAction('GET_VIRTUAL_ACTIVE_TARGETS');
export function getVEActiveTargetsStatus(config, conferenceKey) {
  return { type: getVEActiveTargetsStatusAction.toString(), config, conferenceKey };
}

export const stopVEBroadcastSessionAction = createAsyncAction('DELETE_VIRTUAL_BROADCAST_SESSION');
export function stopVEBroadcastSession(config, channel, conferenceKey) {
  return { type: stopVEBroadcastSessionAction.toString(), config, channel, conferenceKey };
}

export const getVEBroadcastStatusAction = createAsyncAction('GET_VIRTUAL_BROADCAST_STATUS');
export function getVEBroadcastStatus(config, channel, conferenceKey) {
  return { type: getVEBroadcastStatusAction.toString(), config, channel, conferenceKey };
}

export const getVELinkedinConfigAction = createAsyncAction('GET_VIRTUAL_LINKEDIN_CONFIG');
export function getVELinkedinConfig(config) {
  return { type: getVELinkedinConfigAction.toString(), config };
}

export const getVELiveStreamingConfigAction = createAsyncAction('GET_VIRTUAL_LIVE_STREAM_CONFIG');
export function getVELiveStreamingConfig(config, channel, conferenceKey) {
  return { type: getVELiveStreamingConfigAction.toString(), config, channel, conferenceKey };
}

export const ORGANIZERS_UPDATED = 'ORGANIZERS_UPDATED';

export const updateVERegistrationSettingsAction = createAsyncAction('UPDATE_VIRTUAL_EVENT_REGISTRATION_SETTINGS');
export function updateVERegistrationSettings(eventKey, approvalType) {
  return {
    type: updateVERegistrationSettingsAction.toString(), eventKey, approvalType
  };
}

export const resetlinkedInErrStatusAction = 'RESET_LINKEDIN_ERR_STATUS';
export function resetlinkedInErrStatus(conferenceKey) {
  return { type: resetlinkedInErrStatusAction, conferenceKey };
}

export const updateConferenceDetailsAction = createAsyncAction('UPDATE_CONFERENCES');
export function updateConferenceDetails(conferenceKey) {
  return { type: updateConferenceDetailsAction.toString(), conferenceKey };
}
