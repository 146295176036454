import { asyncReducer } from 'redux-promise-middleware-actions';
import { getQAndASettings, updateQAndASettings, resetQAndASettingsAction } from './actions';

const getSettingsReducer = asyncReducer(getQAndASettings);
const updateSettingsReducer = asyncReducer(updateQAndASettings);

const defaultState = {
  qAndASettings: {
    data: {}
  }
};

export default (newState = defaultState, action) => {
  const state = {
    ...newState,
    ...getSettingsReducer(newState, action),
    ...updateSettingsReducer(newState.qAndASettings.data, action)
  };

  switch (action.type) {
    case resetQAndASettingsAction: {
      return {
        ...defaultState
      };
    }
    default: {
      return state;
    }
  }
};
