import config from 'appConfig';
import Auth from '@getgo/auth-client';

const redirectUrl = `${window.location.protocol}//${window.location.host}/authenticate.html`;
export const USER_LOGOUT = 'USER_LOGOUT';

export const authReset = () => ({ type: 'AUTH_RESET' });

export const authRedirect = (path) => {
  const auth = new Auth({
    client_id: config.oauth.clientId,
    url: config.oauth.url,
    redirect_url: redirectUrl,
    enableTokenRefresh: true,
    onTokenRefresh: () => {
      // do some app logic here, e.g. you should clear the token from cache
      localStorage.removeItem('endToEnd');
      sessionStorage.removeItem('endToEnd');
      return Promise.resolve();
    },
    tokenRefreshTimestamp: (token) => token.expires - 30000,
    state: { path }
  });

  auth.login();

  return { type: 'AUTH_REDIRECT' };
};

export const logout = (path) => {
  const { clientId, url } = config.oauth;
  const fullUrl = `${url}/oauth/logout?client_id=${clientId}&redirect_uri=${path}`;

  const auth = new Auth({
    client_id: config.oauth.clientId,
    url: config.oauth.url,
    redirect_url: path
  });

  auth.logout();

  return {
    type: 'USER_LOGOUT',
    path: fullUrl
  };
};
