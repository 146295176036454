import { retrieveWebinarPollsAction,
  createWebinarPollAction,
  deleteWebinarPollAction,
  resetPollsAction } from './actions';

const initialState = {
  webinarPoll: {
    isFetching: false,
    obj: [],
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case createWebinarPollAction.complete.toString(): {
      const webinarPollObjCopy = Object.assign({}, state.webinarPoll.obj);
      return {
        ...state,
        webinarPoll: {
          isFetching: false,
          obj: webinarPollObjCopy,
          error: null
        }
      };
    }
    case createWebinarPollAction.failed.toString(): {
      const webinarPollCopy = Object.assign({}, state.webinarPoll);
      webinarPollCopy.isFetching = false;
      webinarPollCopy.error = action.error;
      return {
        ...state,
        webinarPoll: webinarPollCopy
      };
    }
    case retrieveWebinarPollsAction.toString(): {
      const webinarPollCopy = Object.assign({}, state.webinarPoll);
      webinarPollCopy.isFetching = true;
      return {
        ...state,
        webinarPoll: webinarPollCopy,
        error: null
      };
    }
    case retrieveWebinarPollsAction.complete.toString(): {
      const webinarPollCopy = Object.assign({}, state.webinarPoll);
      webinarPollCopy.isFetching = false;
      webinarPollCopy.obj = action.data;
      webinarPollCopy.error = null;
      return {
        ...state,
        webinarPoll: webinarPollCopy
      };
    }
    case retrieveWebinarPollsAction.failed.toString(): {
      const webinarPollCopy = Object.assign({}, state.webinarPoll);
      webinarPollCopy.isFetching = false;
      webinarPollCopy.obj = {};
      webinarPollCopy.error = action.error;
      return {
        ...state,
        webinarPoll: webinarPollCopy
      };
    }

    case resetPollsAction: {
      return {
        ...initialState
      };
    }

    case deleteWebinarPollAction.failed.toString(): {
      const webinarPollCopy = Object.assign({}, state.webinarPoll);
      return {
        ...state,
        webinarPoll: {
          isFetching: false,
          obj: webinarPollCopy.obj,
          error: action.error
        }
      };
    }

    default:
      return state;
  }
};
