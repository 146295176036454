import { createAsyncAction } from 'redux-promise-middleware-actions';
import { setVideoControlsForRecoredWebinar, getWebinarSettings } from 'Api/Settings/settingsService';

export const setSimuliveVideoControlAction = createAsyncAction(
  'SET_SIMULIVE_VIDEO_CONTROL',
  (webinarKey, showVideoControls) => setVideoControlsForRecoredWebinar(webinarKey, showVideoControls),
  (_, showVideoControls) => ({ showVideoControls })
);

export const getWebinarSettingsAction = createAsyncAction(
  'GET_WEBINAR_SETTINGS',
  getWebinarSettings
);
