import { init } from '../../Notifications/Push/notificationOrchestrator';

const hasMetadata = (state) => (
  state.user && state.user.metadata
);

const isSetFlags = (action) => action.type === 'SET_FLAGS';

const isLaunchDarklyReady = (action, state) => action.data.isLDReady || state.launchDarkly.isLDReady;

const notificationsMiddleware = ({ getState, dispatch }) => (next) => (action) => {
  const state = getState();
  const containsMetadata = hasMetadata(state);
  if (containsMetadata && isSetFlags(action) && isLaunchDarklyReady(action, state)) {
    init(state.user, dispatch);
  }
  return next(action);
};

export default notificationsMiddleware;
