import { call, put, all, fork } from 'redux-saga/effects';
import { takeLatestAction } from 'Utils/sagaUtils';
import * as handoutService from 'Api/Webinar/handoutService';
import trackEngagement from 'Mixpanel/Features/EventDetails/Engagement/engagementTracker';
import { HANDOUTS } from 'Mixpanel/Features/EventDetails/Engagement/engagementConstants';
import Action from 'Mixpanel/Model/action';
import { onHandoutUploadedToSessionSagaTracker,
  onHandoutDeletedFromSessionSagaTracker } from 'Mixpanel/Features/VirtualEventDetails/engagementDetailsTracker';
import { SEND_NOTIFICATION } from '../Notification/actions';
import { createfileUploadPayload, createHandoutPayload } from './sagaHelper';
import { getHandoutsConstraintsAction,
  getAllHandoutsAction,
  getHandoutAction,
  uploadHandoutAction,
  createUploadPolicyAction,
  uploadToS3Action,
  createHandoutAction,
  deleteHandoutAction } from './actionTypes';

function* getHandoutsConstraints(constraints) {
  try {
    const data = yield call(handoutService.getHandoutsConstraints, constraints);
    yield put({ type: getHandoutsConstraintsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: getHandoutsConstraintsAction.failed.toString(),
      error
    });
  }
}

function* getAllHandouts(action) {
  try {
    const data = yield call(handoutService.getAllHandouts, action.webinarKey);
    yield put({ type: getAllHandoutsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: getAllHandoutsAction.failed.toString(),
      error
    });
  }
}

function* getHandout(action) {
  try {
    const data = yield call(handoutService.getHandout, action.webinarKey, action.handoutKey);
    yield put({ type: getHandoutAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: getHandoutAction.failed.toString(),
      error
    });
  }
}

function* createUploadPolicy(action) {
  try {
    yield put({ type: uploadHandoutAction.toString() });
    // Call to get upload policy for s3 upload
    const uploadPolicy = yield call(handoutService.createUploadPolicy, action.webinarKey, action.fileMetaData);
    yield put({ type: createUploadPolicyAction.complete.toString() });

    // Call to upload the file to s3 with upload policy
    const payload = createfileUploadPayload(uploadPolicy, action.fileMetaData, action.file);
    yield call(handoutService.uploadToS3, uploadPolicy.url, uploadPolicy.method, payload);
    yield put({ type: uploadToS3Action.complete.toString() });

    // Call to create a handout
    const handoutPayload = createHandoutPayload(uploadPolicy, action.fileMetaData, action.userWebinarInfo);
    yield call(handoutService.createHandout, action.webinarKey, handoutPayload);
    trackEngagement(HANDOUTS, null, Action.ADD);
    onHandoutUploadedToSessionSagaTracker(action.webinarKey, handoutPayload, 'Success');
    // get new handout
    const newHandoutKey = handoutPayload && handoutPayload.objectkey ? handoutPayload.objectkey : null;
    const newHandout = yield call(handoutService.getHandout, action.webinarKey, newHandoutKey);
    yield put({ type: getHandoutAction.complete.toString(), newHandout });
    yield put({ type: createHandoutAction.complete.toString(), newHandout });

    yield put({ type: uploadHandoutAction.complete.toString() });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'handouts.notification.handoutUploaded', isError: false });
  } catch (error) {
    yield put({ type: uploadHandoutAction.failed.toString(), error });
    onHandoutUploadedToSessionSagaTracker(action.webinarKey, 'Failure');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'handouts.notification.handoutUploadFailed', isError: true });
  }
}

function* createHandout(action) {
  try {
    const data = yield call(handoutService.createHandout, action.webinarKey, action.handout);
    yield put({ type: createHandoutAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: createHandoutAction.failed.toString(),
      error
    });
  }
}

function* deleteHandout(action) {
  try {
    const data = yield call(handoutService.deleteHandout, action.webinarKey, action.handoutKey);
    onHandoutDeletedFromSessionSagaTracker(action.webinarKey, action.handoutKey, 'Success');
    const handoutKey = action.handoutKey;
    yield put({ type: deleteHandoutAction.complete.toString(), data, handoutKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'handouts.notification.handoutDeleted', isError: false });
  } catch (error) {
    yield put({
      type: deleteHandoutAction.failed.toString(),
      error
    });
    onHandoutDeletedFromSessionSagaTracker(action.webinarKey, action.handoutKey, 'Failure');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'handouts.notification.handoutDeleteFailed', isError: true });
  }
}

export default function* rootSaga() {
  yield all([
    fork(takeLatestAction.bind(this, getHandoutsConstraintsAction.toString(), getHandoutsConstraints)),
    fork(takeLatestAction.bind(this, getAllHandoutsAction.toString(), getAllHandouts)),
    fork(takeLatestAction.bind(this, getHandoutAction.toString(), getHandout)),
    fork(takeLatestAction.bind(this, createUploadPolicyAction.toString(), createUploadPolicy)),
    fork(takeLatestAction.bind(this, createHandoutAction.toString(), createHandout)),
    fork(takeLatestAction.bind(this, deleteHandoutAction.toString(), deleteHandout))
  ]);
}
