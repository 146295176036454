import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moveElementInArray from 'Utils/arrayHelpers';
import { withTranslation } from 'react-i18next';
import Action from 'Mixpanel/Model/action';
import styles from './styles.scss';
import Question from './question';

class Questions extends Component {
  static propTypes = {
    questions: PropTypes.array,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleButtonClassName: PropTypes.string,
    subtitleButtonOnClickHandler: PropTypes.string,
    editQuestionTitle: PropTypes.string,
    removeQuestionTitle: PropTypes.string,
    onChange: PropTypes.func,
    onQuestionEdit: PropTypes.func,
    showRequired: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    questions: [],
    title: '',
    subtitle: '',
    showRequired: false,
    onQuestionEdit: Function.prototype,
    onChange: Function.prototype
  };

  constructor(props) {
    super(props);
    this.state = {
      questions: this.props.questions
    };
    this.editQuestion = this.editQuestion.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.questions.length <= this.props.questions.length
      && this.state.questions.length !== this.props.questions.length) {
      const newQuestions = this.props.questions.filter((question) => !this.props.questions.includes(question));
      const stateQuestionsCopy = this.props.questions.slice().concat(newQuestions);
      this.setState({ questions: stateQuestionsCopy });
    }
  }

  triggerChange(questions, changeType = Action.UNKNOWN, questionType, question = {}) {
    const { onChange } = this.props;
    onChange(questions, changeType, questionType, question);
  }

  moveQuestionUp(question) {
    const updatedQuestions = moveElementInArray(this.props.questions, question, -1);
    this.setState({ questions: updatedQuestions });
    this.triggerChange(updatedQuestions, Action.MOVE, question.type);
  }

  moveQuestionDown(question) {
    const updatedQuestions = moveElementInArray(this.props.questions, question, 1);
    this.setState({ questions: updatedQuestions });
    this.triggerChange(updatedQuestions, Action.MOVE, question.type);
  }

  editQuestion(question, index) {
    const { onQuestionEdit } = this.props;
    onQuestionEdit(question, index);
  }

  removeQuestion(question) {
    const questionsCopy = this.props.questions.slice();
    const index = questionsCopy.indexOf(question);
    if (index < 0 || index >= questionsCopy.length) {
      return;
    }
    questionsCopy.splice(index, 1);
    this.setState({ questions: questionsCopy });
    this.triggerChange(questionsCopy, Action.REMOVE, question.type, question);
  }

  toggleRequired = (question) => {
    const { questions } = this.state;
    const index = questions.indexOf(question);
    if (index >= 0) {
      questions[index].required = !questions[index].required;
    }

    this.setState({ questions });
  }

  createQuestion(question, index) {
    const props = {
      question,
      isFirstQuestionInList: (index === 0),
      isLastQuestionInList: (index === this.props.questions.length - 1),
      moveUpOnClick: this.moveQuestionUp.bind(this, question),
      moveDownOnClick: this.moveQuestionDown.bind(this, question),
      removeOnClick: this.removeQuestion.bind(this, question),
      editOnClick: this.editQuestion.bind(this, question, index),
      showRequired: this.props.showRequired,
      editQuestionTitle: this.props.editQuestionTitle,
      removeQuestionTitle: this.props.removeQuestionTitle,
      toggleRequired: this.toggleRequired
    };
    return (
      <div key={index}>
        <Question {...props} />
        <hr className={styles.separator} />
      </div>);
  }

  createQuestions() {
    return Array.from(this.props.questions, (element, index) => this.createQuestion(element, index));
  }

  addSubtitleButton() {
    if (!this.props.subtitleButtonClassName) {
      return null;
    }
    return (<button onClick={this.props.subtitleButtonOnClickHandler}><i className={this.props.subtitleButtonClassName} /></button>);
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.questions}>
        <div className={styles.header}>
          <h1 id='questions_title' className={styles.questionsTitle}>{this.props.title}</h1>
          {this.props.showRequired && <span className={styles.requiredSubtitle}>{t('cards.webinar.details.registration.required')}
            <i className={`togo-icon togo-icon-star-filled ${styles.requiredIcon}`} />
          </span>}
        </div>
        <div id='questions_sub_title' className={styles.questionsSubtitle}>{this.props.subtitle}{this.addSubtitleButton()}</div>
        <hr className={styles.separator} />
        {this.createQuestions()}
      </div>
    );
  }
}

export default withTranslation('translation', { withRef: true })(Questions);
