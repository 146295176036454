import appLogger from 'Utils/logging';
import { setPermission, setPushNotificationsSupported } from 'Reducks/PushNotification/actions';
import { isWebPushSupported, requestPermission, isPermissionGranted } from './Helpers/pushNotificationHelper';
import { registerForNotifications, registerWithNotificationSubscriptionService } from './notificationRegistrationManager';
import { getUserKey } from '../Helpers/notificationsHelper';

const logger = appLogger.create('Notification Orchestrator');

export const init = async (user, dispatch) => {
  const userKey = getUserKey(user);
  if (!userKey) {
    logger.error('Cannot initialize push notification without user key');
    return null;
  }
  if (typeof dispatch !== 'function') {
    logger.error('Cannot initialize push notification without dispatch function');
    return null;
  }
  await registerWithNotificationSubscriptionService(userKey);
  if (!isWebPushSupported()) {
    logger.error('Web push notifications are not supported by this browser', 'userKey=', userKey);
    dispatch(setPushNotificationsSupported(false));
    return null;
  }
  dispatch(setPushNotificationsSupported(true));
  let result;
  try {
    logger.debug('Requesting permission to send push notifications to user', 'userKey=', userKey);
    const permission = await requestPermission();
    const permissionGranted = isPermissionGranted(permission);
    dispatch(setPermission(permission));
    if (!permissionGranted) {
      logger.debug('Permission to send push notifications not granted by user', 'userKey=', userKey);
      return null;
    }
    logger.debug('Registering for notifications', 'user=', user);
    result = await registerForNotifications(user);
  } catch (error) {
    logger.error('Failed complete init logic', 'error=', error);
  }
  return result;
};
