import { buffers, eventChannel, END } from 'redux-saga';

export function createfileUploadPayload(formData, fileObject) {
  const fData = new FormData();
  if (formData) {
    for (const f in formData) {
      if (formData[f]) {
        fData.append(f, formData[f]);
      }
    }
    fData.append('file', fileObject);
    return fData;
  }
  return null;
}

export function createUploadFileChannel(endpoint, file) {
  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = (e) => {
      emitter({ error: e });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 200 || status === 204) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure(new Error('Upload Failed'));
        }
      }
    };
    xhr.open('POST', endpoint, true);
    xhr.send(file);
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
