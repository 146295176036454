export const OCCURRENCE_TYPE = {
  ONE_SESSION: 'one_session',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  CUSTOM: 'custom',
  ONDEMAND: 'anytime'
};

export const SERIES_TYPE = {
  SINGLE_SESSION: 'single_session',
  SERIES: 'series',
  SEQUENCE: 'sequence'
};

export const REGISTRATION_TYPE = {
  ONCE: 'once',
  INDIVIDUAL: 'individual'
};

export const RECURRENCE_STATUS = {
  FUTURE: 'FUTURE',
  PAST: 'PAST',
  INSESSION: 'INSESSION'
};

export const RECURRENCE_PERIOD = {
  NEVER: 'NEVER',
  ANYTIME: 'ANYTIME', // only for webinarType = simulive & OccurenceType = OnDemand
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY'
};
