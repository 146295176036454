import { call, put, all, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { takeLatestAction } from 'Utils/sagaUtils';
import { createfileUploadPayload } from 'Api/Helpers/upload';
import * as videoService from 'Api/Webinar/videoService';
import * as webinarService from 'Api/Webinar/webinarService';
import * as youTubeService from 'Api/ThirdPartyServices/youtubeService';
import { onYoutubeLinkUploadedToSessionSagaTracker,
  onVideoDeletedFromSessionSagaTracker,
  onVideoUploadedToSessionSagaTracker } from 'Mixpanel/Features/VirtualEventDetails/engagementDetailsTracker';
import { SEND_NOTIFICATION } from '../Notification/actions';
import { getIsVideoPushEnabledAction,
  getAllVideosAction,
  getVideoAction,
  uploadYouTubeLinkAction,
  uploadVideoAction,
  createUploadPolicyAction,
  uploadToS3Action,
  updateVideoAction,
  deleteVideoAction,
  getYouTubeVideoDetailsAction } from './actionTypes';

function* getIsVideoPushEnabled(action) {
  try {
    const data = yield call(webinarService.getIsVideoPushEnabled, action.accountKey, action.userKey);
    yield put({ type: getIsVideoPushEnabledAction.complete.toString(), data });
  } catch (error) {
    yield put({ type: getIsVideoPushEnabledAction.failed.toString(), error });
  }
}

function* getAllVideos(action) {
  try {
    const data = yield call(videoService.getAllVideos, action.webinarKey, action.accountKey, action.userKey);
    yield put({ type: getAllVideosAction.complete.toString(), data });
  } catch (error) {
    yield put({ type: getAllVideosAction.failed.toString(), error });
  }
}

function* getVideo(action) {
  try {
    const data = yield call(videoService.getVideo, action.webinarKey, action.accountKey, action.userKey, action.videoKey);
    yield put({ type: getVideoAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: getVideoAction.failed.toString(),
      error
    });
  }
}

function* uploadVideo(action) {
  const webinarKey = action.userWebinarInfo && action.userWebinarInfo.webinarKey ? action.userWebinarInfo.webinarKey : null;
  const accountKey = action.userWebinarInfo && action.userWebinarInfo.accountKey ? action.userWebinarInfo.accountKey : null;
  const userKey = action.userWebinarInfo && action.userWebinarInfo.userKey ? action.userWebinarInfo.userKey : null;
  try {
    // Call to get upload policy for s3 upload
    const uploadPolicy = yield call(videoService.createUploadPolicy, webinarKey, accountKey, userKey, action.fileMetaData);
    yield put({ type: createUploadPolicyAction.complete.toString() });
    onVideoUploadedToSessionSagaTracker(webinarKey, accountKey, userKey, action.fileMetaData, 'Success');
    // Call to upload the file to s3 with upload policy
    const payload = createfileUploadPayload(uploadPolicy.formData, action.fileObject);
    yield call(videoService.uploadToS3, uploadPolicy.uploadUrl, payload);
    yield put({ type: uploadToS3Action.complete.toString() });

    // TODO: fix the API to return created video Key instead of getting all videos again as broker does.
    // Adding delay here since the API returns old data if called right after the upload.
    yield delay(4000);
    const data = yield call(videoService.getAllVideos, webinarKey, accountKey, userKey);
    yield put({ type: getAllVideosAction.complete.toString(), data });

    yield put({ type: uploadVideoAction.complete.toString() });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.videoUploaded', isError: false });
  } catch (error) {
    yield put({ type: uploadVideoAction.failed.toString(), error });
    onVideoUploadedToSessionSagaTracker(webinarKey, accountKey, userKey, action.fileMetaData, 'Failure');
    if (error.status === 409) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.duplicateVideo', isError: true });
    }
    yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.videoUploadFailed', isError: true });
  }
}

function* uploadYouTubeLink(action) {
  const webinarKey = action.userWebinarInfo && action.userWebinarInfo.webinarKey ? action.userWebinarInfo.webinarKey : null;
  const accountKey = action.userWebinarInfo && action.userWebinarInfo.accountKey ? action.userWebinarInfo.accountKey : null;
  const userKey = action.userWebinarInfo && action.userWebinarInfo.userKey ? action.userWebinarInfo.userKey : null;
  try {
    const data = yield call(videoService.uploadYouTubeLink, webinarKey, accountKey, userKey, action.youTubeLinkData);
    onYoutubeLinkUploadedToSessionSagaTracker(webinarKey, accountKey, userKey, action.youTubeLinkData, 'Success');
    yield put({ type: uploadYouTubeLinkAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.youTubeLinkAdded', isError: false });
  } catch (error) {
    yield put({
      type: uploadYouTubeLinkAction.failed.toString(), error
    });
    onYoutubeLinkUploadedToSessionSagaTracker(webinarKey, accountKey, userKey, action.youTubeLinkData, 'Failure');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.youTubeLinkAddFailed', isError: true });
  }
}

function* updateVideo(action) {
  const webinarKey = action.userWebinarInfo && action.userWebinarInfo.webinarKey ? action.userWebinarInfo.webinarKey : null;
  const accountKey = action.userWebinarInfo && action.userWebinarInfo.accountKey ? action.userWebinarInfo.accountKey : null;
  const userKey = action.userWebinarInfo && action.userWebinarInfo.userKey ? action.userWebinarInfo.userKey : null;
  try {
    const data = yield call(videoService.updateVideo, webinarKey, accountKey, userKey, action.videoKey, action.videoData);
    const videoKey = action.videoKey;
    yield put({ type: updateVideoAction.complete.toString(), data, videoKey });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.videoUpdated', isError: false });
  } catch (error) {
    yield put({
      type: updateVideoAction.failed.toString(), error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.videoUpdateFailed', isError: true });
  }
}

function* deleteVideo(action) {
  try {
    const data = yield call(videoService.deleteVideo, action.webinarKey, action.accountKey, action.userKey, action.videoKey);
    const videoKey = action.videoKey;
    yield put({ type: deleteVideoAction.complete.toString(), data, videoKey });
    onVideoDeletedFromSessionSagaTracker(data, videoKey, 'Success');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.videoDeleted', isError: false });
  } catch (error) {
    yield put({
      type: deleteVideoAction.failed.toString(), error
    });
    onVideoDeletedFromSessionSagaTracker(action.videoKey, 'Failure');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'videos.notification.videoDeleteFailed', isError: true });
  }
}

function* getYoutubeVideoDetails(action) {
  try {
    const data = yield call(youTubeService.getYouTubeVideoDetails, action.youTubeId);
    yield put({ type: getYouTubeVideoDetailsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: getYouTubeVideoDetailsAction.failed.toString(), error
    });
  }
}

export default function* rootSaga() {
  yield all([
    fork(takeLatestAction.bind(this, getIsVideoPushEnabledAction.toString(), getIsVideoPushEnabled)),
    fork(takeLatestAction.bind(this, getAllVideosAction.toString(), getAllVideos)),
    fork(takeLatestAction.bind(this, uploadYouTubeLinkAction.toString(), uploadYouTubeLink)),
    fork(takeLatestAction.bind(this, uploadVideoAction.toString(), uploadVideo)),
    fork(takeLatestAction.bind(this, getVideoAction.toString(), getVideo)),
    fork(takeLatestAction.bind(this, updateVideoAction.toString(), updateVideo)),
    fork(takeLatestAction.bind(this, deleteVideoAction.toString(), deleteVideo)),
    fork(takeLatestAction.bind(this, getYouTubeVideoDetailsAction.toString(), getYoutubeVideoDetails))
  ]);
}
