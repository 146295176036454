import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FloatingLabel } from '@getgo/chameleon-react';

import { anyCharRegex } from '../../../Utils/formats';
import styles from './SurveyTitle.scss';

const SurveyTitle = ({ title, updateSurveyTitle }) => {
  const { t } = useTranslation();

  const saveTitle = (text) => updateSurveyTitle(text);

  return (
    <FloatingLabel
      charLimitText={t('survey.charLimit')}
      errorMessage={t('survey.questions.requiredField')}
      floatLabel={false}
      form
      id="tc_survey_title"
      inputClassName={styles.surveyTitle}
      maxLength={128}
      onChange={saveTitle}
      pattern={anyCharRegex}
      placeholder={t('survey.placeholder')}
      showAnimatedBar
      value={title}
    />
  );
};

SurveyTitle.propTypes = {
  title: string,
  updateSurveyTitle: func.isRequired
};

export default React.memo(
  SurveyTitle,
  (prevProps, nextProps) => prevProps.title === nextProps.title
);
