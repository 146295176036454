import config from 'appConfig';
import { getBearerHeaders } from 'Api/Helpers/getHeaders';
import { fetchJson } from '../../Helpers/fetch';
import { PATCH, POST, DELETE, PUT } from '../../Helpers/httpMethods';

export function createVirtualEventSession(eventKey, body) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body),
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/conferences`;
  return fetchJson(url, options);
}

export function saveVirtualEventSession(eventKey, conferenceKey, sessionDetails) {
  const options = {
    headers: getBearerHeaders(),
    method: PATCH,
    body: JSON.stringify(sessionDetails),
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/conferences/${conferenceKey}`;
  return fetchJson(url, options);
}

export function addPanelistToVirtualEventSession(eventKey, conferenceKey, sessionDetails) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify({ panelistId: sessionDetails }),
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/conferences/${conferenceKey}/panelists`;
  return fetchJson(url, options, 'json');
}

export function deleteSpeaker(eventKey, conferenceKey, speakerId) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE,
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/conferences/${conferenceKey}/panelists/${speakerId}`;
  return fetchJson(url, options, 'json');
}

export function deleteVirtualEventSession(eventKey, conferenceKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE,
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/conferences/${conferenceKey}`;
  return fetchJson(url, options, 'json');
}

export function updateRecording(eventKey, conferenceKey, sessionDetails) {
  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify(sessionDetails),
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/conferences/${conferenceKey}/asset`;
  return fetchJson(url, options);
}

export function resendSpeakerInvitation(eventKey, speakerId, conferenceId) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify({ speakerId, conferenceId }),
    redirect: 'follow'
  };
  const url = `${config.g2w.events}/events/${eventKey}/panelists/invite/mail`;
  return fetchJson(url, options, 'json');
}

