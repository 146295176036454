export const channelStatus = {
  connected: 'connected',
  initiated: 'initiated',
  custom: 'custom'
};

export const product = 'G2W';

export const channelType = {
  linkedIn: 'linkedIn'
};

export const localStorageKey = {
  linkedinAuthCodeReLogin: 'linkedinAuthCodeReLogin',
  redirectEventDetailsPage: 'redirectEventDetailsPage',
  reloginRequired: 'reloginRequired',
  liveStreamingId: 'liveStreamingSettingIdForRelogin'
};

export const statusInterval = [0, 2000, 4000, 6000];
