export function changeNotificationPreference(state, payload) {
  const stateCopy = { ...state };
  const notificationPreferences = stateCopy.notificationPreferences;
  const notificationSettings = notificationPreferences.notificationSettings;
  const matchingIndex = notificationSettings.findIndex((setting) => setting.notificationId === payload.notificationId);
  if (matchingIndex > -1) {
    notificationSettings[matchingIndex] = payload;
  } else {
    notificationSettings.push(payload);
  }
  return stateCopy;
}

export function changeAllNotificationPreferences(state, payload) {
  const stateCopy = { ...state };
  const notificationPreferences = stateCopy.notificationPreferences;
  notificationPreferences.notificationSettings = payload;
  return stateCopy;
}
