import CommonFragment from './Fragments/commonFragment';
import { validateEvent } from '../Helpers/messageBusEventValidator';

export default class pageLoadEvent extends CommonFragment {
  withCustomPayload(customPayload) {
    this.customPayload = customPayload;
    return this;
  }

  withReferrerUrl(referrerUrl) {
    this.referrerUrl = referrerUrl;
    return this;
  }

  withTitle(title) {
    this.title = title;
    return this;
  }

  withUrl(url) {
    this.url = url;
    return this;
  }

  /**
   * @returns an object of type {LoadEvent} containing all properties set with the 'with' functions.
   * @throws Error if mandatory properties for {LoadEvent}, {MessageBusEvent} or {CommonFragment} are not set.
   */
  build() {
    const event = super.build();
    validateEvent(this, 'PageLoadEvent', ['customPayload', 'referrerUrl', 'title', 'url']);
    const loadEvent = {
      customPayload: this.customPayload,
      referrerUrl: this.referrerUrl,
      title: this.title,
      url: this.url
    };

    const payloadCopy = Object.assign(event.payload, loadEvent);
    return Object.freeze({
      ...event,
      payload: payloadCopy
    });
  }
}
