import config from 'appConfig';
import { getBearerHeaders } from 'Api/Helpers/getHeaders';
import { DELETE, POST } from '../Helpers/httpMethods';
import { fetchJson } from '../Helpers/fetch';
import { JSON_MIME_TYPE } from '../Helpers/requestProperties';
import { graphqlQuery } from './graphqlQueryHelper';

export function generateSummary(userKey, accountKey, recordingAssetKey) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify({})
  };

  const url = `${config.g2w.generateSummaryUrl}/accounts/${accountKey}/users/${userKey}/conferenceType/g2w/assetKeys/${recordingAssetKey}/summary`;
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getSummaryUrl(userKey, accountKey, recordingAssetKey) {
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(graphqlQuery(userKey, accountKey, recordingAssetKey))
  };

  const url = `${config.g2w.summaryUrl}/assets/search`;
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function getSummary(url) {
  return fetchJson(url, {}, JSON_MIME_TYPE);
}

export function saveSummary(userKey, accountKey, recordingAssetKey) {
  const body = [
    {
      createAsset: true,
      product: 'g2w',
      accountKey,
      userKey,
      assetType: 'SUMMARY',
      metadata: {
        recordingAssetKey
      },
      resources: [
        {
          content: {
            type: 'PROVIDED'
          },
          resourceType: 'TXT',
          mandatory: true,
          upload: true
        }
      ]
    }
  ];

  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };

  const url = `${config.g2w.summaryUrl}/bulk/assets?isS3Accelerated=true`;
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function deleteSummary(assetKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE
  };

  const url = `${config.g2w.summaryUrl}/bulk/assets?assetKeys=${assetKey}`;
  return fetchJson(url, options, JSON_MIME_TYPE);
}
