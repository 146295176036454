import { normalize } from 'normalizr';
import { createAsyncAction as createAsyncActionV2, createAction } from 'redux-promise-middleware-actions';
import webinarsSchema from '../_Entities/Webinars/schema';
import * as Ops from './operations';
import { fetchSimuliveRecording,
  sendPanelistInviation,
  sendCoOrganizerInviation,
  getTimeZones,
  getWebinar,
  getWebinars,
  updateOrganizers,
  updateWebinarAutoRecorded } from '../../Api/Webinar/webinarService';
import { createAsyncAction } from '../reduxActions';

// WEBINAR
export const deleteWebinarAction = createAsyncAction('DELETE_WEBINAR');
export const getSupportedWebinarTypes = createAsyncAction('GET_SUPPORTED_WEBINAR_TYPES');
export const clearPastFilteredWebinarsAction = createAsyncAction('DELETE_PAST_FILTERED_WEBINARS');
export const clearUpcomingFilteredWebinarsAction = createAsyncAction('DELETE_CURRENT_AND_FUTURE_FILTERED_WEBINARS');
export const getCalendarDetailsAction = createAsyncAction('GET_CALENDAR_DETAILS');
export const getSimuliveRecording = createAsyncActionV2('GET_SIMULIVE_RECORDING', fetchSimuliveRecording);
export const addSimuliveRecordingAction = createAction('ADD_SIMULIVE_RECORDING');
export const updateSimuliveRecording = createAsyncAction('UPDATE_SIMULIVE_RECORDING');
export const getWebinarTimeZones = createAsyncActionV2('GET_TIMEZONES', getTimeZones);
export const getWebinarAllDetails = createAsyncActionV2('GET_WEBINAR_ALL_DETAILS', getWebinar);

export const getWebinarAction = createAsyncAction('GET_WEBINAR');

// Adding this action to update the details for the webinarKey under the _entities redux
export const updateWebinarEntitiesAction = 'UPDATE_WEBINAR_ENTITIES';

export const TOGGLE_BRANDING_CARD = 'TOGGLE_BRANDING_CARD';

export function fetchWebinar(webinarKey, organizerKey, shouldLoadWebinar) {
  return { type: getWebinarAction.toString(), webinarKey, organizerKey, shouldLoadWebinar };
}

export const fetchWebinars = createAsyncActionV2(
  'GET_WEBINARS',
  async ({ id, ...params }) => {
    const { data, ...rest } = await getWebinars(params);
    const denormalized = { webinars: data, pagination: Ops.createPagination(rest) };
    const normalized = normalize(denormalized, webinarsSchema);
    return { ...normalized };
  },
  ({ id }) => id
);

// TODO:REFACTOR use the fetchWebinars action here instead, they do the same thing
export const getPastWebinarsAction = createAsyncAction('GET_PAST_WEBINARS');

export function fetchPastWebinars(page, pageSize) {
  return { type: getPastWebinarsAction.toString(), page, pageSize };
}

// START WEBINAR
export const startWebinarAction = createAsyncAction('START_WEBINAR');
export const getAuthCodeForGoToOpenerAction = createAsyncAction('GET_OAUTH_CODE_FOR_GOTO_LAUNCHER');

// PANELISTS
export const getPanelistsAction = createAsyncAction('GET_PANELISTS');

// INVITATION
export const resendPanelistInvitation = createAsyncActionV2('SEND_PANELISTS_INVITATION', sendPanelistInviation);
export const resendCoOrganizerInvitation = createAsyncActionV2('SEND_COORGANIZER_INVITATION', sendCoOrganizerInviation);

// DETAILS
export const getWebinarDetailsAction = createAsyncAction('GET_WEBINAR_DETAILS');
export const updateWebinarDetailsAction = createAsyncAction('UPDATE_WEBINAR_DETAILS');
export const getWebinarAttendanceAction = createAsyncAction('GET_WEBINAR_ATTENDANCE');
export const updateWebinarBrandingAction = createAsyncAction('UPDATE_WEBINAR_BRANDING');
export const saveBrandingThemeAction = createAsyncAction('SAVE_BRANDING_THEME');
export const updateBrandingThemeAction = createAsyncAction('UPDATE_BRANDING_THEME');
export const deleteBrandingThemeAction = createAsyncAction('DELETE_BRANDING_THEME');
export const reassignWebinarAction = createAsyncAction('REASSIGN_WEBINAR');

export const getThemesAction = createAsyncAction('GET_THEMES');

// AUDIO SETTINGS
export const postWebinarAudioSettingsAction = createAsyncAction('POST_WEBINAR_AUDIO_SETTINGS');
export const getAutoRecordedAction = createAsyncAction('GET_AUTO_RECORDED');
export const getOrganizerAudioSettingsAction = createAsyncAction('GET_ORGANIZER_AUDIO_SETTINGS');
export const updateWebinarAutoRecordedAction = createAsyncActionV2('POST_AUTO_RECORDED', updateWebinarAutoRecorded);

// EDIT PANELIST
export const editPanelistsAction = createAsyncAction('EDIT_PANELISTS');

// EDIT ORGANIZER
export const editOrganizersAction = createAsyncAction('EDIT_ORGANIZERS');
export const updateOrganizersAction = createAsyncActionV2('UPDATE_ORGANIZERS', updateOrganizers);

// SERIES
export const getSeriesRecurrenceAction = createAsyncAction('GET_SERIES_RECURRENCE');
export const getFutureSeriesRecurrenceAction = createAsyncAction('GET_FUTURE_SERIES_RECURRENCE');
export const updateSeriesRecurrenceAction = createAsyncAction('UPDATE_SERIES_RECURRENCE');

// Broadcasting / Streaming webinar
export const createBroadcastSessionAction = createAsyncAction('CREATE_BROADCAST_SESSION');
export const linkedInReloginAction = createAsyncAction('LINKEDIN_RELOGIN');
export const getActiveTargetsStatusAction = createAsyncAction('GET_ACTIVE_TARGETS');
export const stopBroadcastSessionAction = createAsyncAction('DELETE_BROADCAST_SESSION');
export const getBroadcastStatusAction = createAsyncAction('GET_BROADCAST_STATUS');
export const setLiveStreamingConfigAction = createAsyncAction('SET_LIVE_STREAMING_CONFIG');
export const getLiveStreamingConfigAction = createAsyncAction('GET_LIVE_STREAM_CONFIG');
export const getLinkedinConfigAction = createAsyncAction('GET_LINKEDIN_CONFIG');
export const getAllLiveStreamingConfigurationAction = createAsyncAction('GET_LIVE_AVAILABLE_STREAM_CONFIG');
export const updateLiveStreamingChannelConfigAction = createAsyncAction('UPDATE_LIVE_STREAM_CHANNEL_CONFIG');

export function createBroadcastSession(config, channel) {
  return { type: createBroadcastSessionAction.toString(), config, channel };
}

export function linkedInRelogin(config) {
  return { type: linkedInReloginAction.toString(), config };
}

export function getActiveTargetsStatus(config) {
  return { type: getActiveTargetsStatusAction.toString(), config };
}

export function stopBroadcastSession(config, channel) {
  return { type: stopBroadcastSessionAction.toString(), config, channel };
}

export function getBroadcastStatus(config, channel) {
  return { type: getBroadcastStatusAction.toString(), config, channel };
}

export function getAllLiveStreamingConfiguration(config) {
  return { type: getAllLiveStreamingConfigurationAction.toString(), config };
}

export function getLiveStreamingConfig(config, channel) {
  return { type: getLiveStreamingConfigAction.toString(), config, channel };
}

export function getLinkedinConfig(config) {
  return { type: getLinkedinConfigAction.toString(), config };
}
export function setLiveStreamingConfig(config, channel) {
  return { type: setLiveStreamingConfigAction.toString(), config, channel };
}

export function updateLiveStreamingChannelConfig(config, channel) {
  return { type: updateLiveStreamingChannelConfigAction.toString(), config, channel };
}

export function clearPastFilteredWebinars() {
  return { type: clearPastFilteredWebinarsAction.toString() };
}

export function clearUpcomingFilteredWebinars() {
  return { type: clearUpcomingFilteredWebinarsAction.toString() };
}

export function fetchWebinarAttendance(webinarKey) {
  return { type: getWebinarAttendanceAction.toString(), webinarKey };
}

export function fetchSupportedWebinarTypes(accountKey, userKey) {
  return { type: getSupportedWebinarTypes.toString(), accountKey, userKey };
}

export function deleteWebinar(webinarKey, sendEmails, body, recurrenceKey, redirectToDashboard = true) {
  return {
    type: deleteWebinarAction.toString(), webinarKey, sendEmails, body, recurrenceKey, redirectToDashboard
  };
}

export function getCalendarDetails(webinarKey, userKey, sourceUrl) {
  return {
    type: getCalendarDetailsAction.toString(), webinarKey, userKey, sourceUrl
  };
}

export function fetchPanelists(webinarKey) {
  return { type: getPanelistsAction.toString(), webinarKey };
}

export function fetchWebinarDetails(webinarKey, details) {
  return { type: getWebinarDetailsAction.toString(), webinarKey, details };
}

export function fetchThemes(webinarKey, organizerKeys) {
  return { type: getThemesAction.toString(), webinarKey, organizerKeys };
}

export function updateWebinarDetails(webinarKey, details, notifyParticipants) {
  return {
    type: updateWebinarDetailsAction.toString(), webinarKey, details, notifyParticipants
  };
}

export function updateWebinarAudioSettings(webinarKey, audioDetails, notifyParticipants) {
  return {
    type: postWebinarAudioSettingsAction.toString(), webinarKey, audioDetails, notifyParticipants
  };
}

export function fetchWebinarAutoRecorded(organizerKey, webinarKey) {
  return { type: getAutoRecordedAction.toString(), organizerKey, webinarKey };
}

export function editPanelists(webinarKey, panelists, deleteKeys) {
  return {
    type: editPanelistsAction.toString(), webinarKey, panelists, deleteKeys
  };
}

export function editOrganizers(webinarKey, organizers, deleteKeys) {
  return {
    type: editOrganizersAction.toString(), webinarKey, organizers, deleteKeys
  };
}

export function startWebinar(webinarKey, successUrl, mode, authCode, redirectUrl) {
  return {
    type: startWebinarAction.toString(), webinarKey, successUrl, mode, authCode, redirectUrl
  };
}

export function getAuthCodeForGoToOpener(redirectUri) {
  return { type: getAuthCodeForGoToOpenerAction.toString(), redirectUri };
}

export function fetchSeriesRecurrence(recurrenceKey) {
  return { type: getSeriesRecurrenceAction.toString(), recurrenceKey };
}

export function fetchFutureSeriesRecurrence(recurrenceKey) {
  return { type: getFutureSeriesRecurrenceAction.toString(), recurrenceKey };
}

export function updateSeriesRecurrence(webinarKey, recurrenceKey, added, modified, notifyParticipants) {
  return {
    type: updateSeriesRecurrenceAction.toString(), webinarKey, recurrenceKey, added, modified, notifyParticipants
  };
}

export function updateWebinarBranding(webinarKey, brandingData) {
  return { type: updateWebinarBrandingAction.toString(), webinarKey, brandingData };
}

export function saveBrandingTheme(webinarKey, brandingDataWithTitle) {
  return { type: saveBrandingThemeAction.toString(), webinarKey, brandingDataWithTitle };
}

export function updateBrandingTheme(webinarKey, brandingDataWithTitleAndId) {
  return { type: updateBrandingThemeAction.toString(), webinarKey, brandingDataWithTitleAndId };
}

export function deleteBrandingTheme(webinarKey, themeId) {
  return { type: deleteBrandingThemeAction.toString(), webinarKey, themeId };
}

export function updateSimuliveWebinarRecording(webinarKey, selectedRecording) {
  return { type: updateSimuliveRecording.toString(), webinarKey, selectedRecording };
}

export function reassignWebinar(webinarKey) {
  return { type: reassignWebinarAction.toString(), webinarKey };
}

export function toggleBrandingCardAction(isCollapsed) {
  return { type: TOGGLE_BRANDING_CARD, isCollapsed };
}
