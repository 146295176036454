import api, { getUser } from 'Api';
import { getMe } from 'Api/accountService';

export function resetAuth() {
  localStorage.removeItem('endToEnd');
  sessionStorage.removeItem('endToEnd');
  document.location.href = '/';
}

export function reloadAuth() {
  if (window.location.href.includes('/error')) {
    resetAuth();
  } else {
    window.location.reload();
  }
}

export function refreshToken() {
  return new Promise((resolve) => {
    const userBeforeLogin = getUser();
    sessionStorage.removeItem('endToEnd');
    window.open('/', 'TokenRefreshWindow', 'height=500,width=500,toolbar=no,location=no');
    window.resolveRefreshToken = async (tokenJSON) => {
      sessionStorage.setItem('endToEnd', JSON.stringify({ auth: JSON.parse(tokenJSON) }));
      api.setAuthJSON(tokenJSON);

      // Handle case if user logs in with different user id
      const userAfterLogin = await getMe();
      if (userAfterLogin.id !== userBeforeLogin.id) {
        window.location.href = '/';
      }
      resolve();
    };
  });
}
