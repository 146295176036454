import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { SEND_NOTIFICATION } from 'Reducks/Notification/actions';
import * as webinarService from '../../Api/Webinar/webinarService';

import { getSourceTrackingInfoAction,
  addSourceTrackingOptionAction,
  archiveSourceTrackingOptionAction } from './actions';

function* getSourceTrackingInfo({ webinarKey }) {
  try {
    const data = yield call(webinarService.getSourceTrackingInfo, webinarKey);
    yield put({ type: getSourceTrackingInfoAction.complete.toString(), data });
  } catch (e) {
    yield put({
      type: getSourceTrackingInfoAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.trackingRegistrants.sourceTracking.notification.failedRetrievingSources', isError: true });
  }
}

function* addSourceTrackingOption({ webinarKey, sourceName }) {
  try {
    const data = yield call(webinarService.addSourceTrackingOption, webinarKey, sourceName);
    yield put({ type: addSourceTrackingOptionAction.complete.toString(), data });
    yield put({
      type: SEND_NOTIFICATION, messageKeyParams: [{ sourceName }], messageKey: 'cards.webinar.details.registration.trackingRegistrants.sourceTracking.notification.registrationLinkCreatedAndCopied', isError: false
    });
  } catch (e) {
    yield put({
      type: addSourceTrackingOptionAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.trackingRegistrants.sourceTracking.notification.addSourceFailed', isError: true });
  }
}

function* archiveSourceTrackingOption({ webinarKey, sourceKey }) {
  try {
    let data = yield call(webinarService.archiveSourceTrackingOption, webinarKey, sourceKey);
    yield put({ type: archiveSourceTrackingOptionAction.complete.toString(), data });

    data = yield call(webinarService.getSourceTrackingInfo, webinarKey);
    yield put({ type: getSourceTrackingInfoAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.trackingRegistrants.sourceTracking.notification.sourceArchived', isError: false });
  } catch (e) {
    yield put({
      type: archiveSourceTrackingOptionAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.trackingRegistrants.sourceTracking.notification.sourceArchiveFailed', isError: true });
  }
}

export function* watchGetRegistrantDetails() {
  yield takeLatest(getSourceTrackingInfoAction.toString(), getSourceTrackingInfo);
}

export function* watchAddSourceTrackingOption() {
  yield takeLatest(addSourceTrackingOptionAction.toString(), addSourceTrackingOption);
}

export function* watchArchiveSourceTrackingOption() {
  yield takeLatest(archiveSourceTrackingOptionAction.toString(), archiveSourceTrackingOption);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetRegistrantDetails),
    fork(watchAddSourceTrackingOption),
    fork(watchArchiveSourceTrackingOption)
  ]);
}
