import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ChameleonCSSTransition from '@getgo/chameleon-react/dist/ChameleonCSSTransition';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { AlertDialog } from '@getgo/chameleon-react';
import styles from './styles.scss';

export const NOTIFICATION_STYLE = { NORMAL: 'NORMAL', ALERT: 'ALERT' };

@connect(
  (state) => ({
    notification: state.notification
  })
)
class NotificationContainer extends PureComponent {
  static propTypes = {
    notification: PropTypes.object,
    t: PropTypes.func
  };

  state = {
    style: NOTIFICATION_STYLE.NORMAL,
    show: false,
    messageKey: null,
    messageKeyParams: null,
    message: '',
    isError: false,
    isInfo: false,
    timeOut: null,
    timerHandle: null
  };

  renderNotification() {
    const { isError, isInfo, message, messageKey, messageKeyParams } = this.state;
    const { t } = this.props;
    const containerStyle = classnames(styles.container, { [styles.error]: isError }, { [styles.info]: isInfo });
    const iconStyle = classnames({ 'togo-icon togo-icon-alert': isError, 'togo-icon togo-icon-clock': isInfo, 'togo-icon togo-icon-check': !isError && !isInfo });
    let messageStr = '';
    if (messageKey && messageKeyParams) {
      messageStr = t(messageKey, ...messageKeyParams);
    } else if (messageKey && !messageKeyParams) {
      messageStr = t(messageKey);
    } else {
      messageStr = message;
    }
    return (
      <div role="alert" aria-live="assertive" className={containerStyle}>
        <p id="notification_banner">
          <i className={iconStyle} id='notification_banner_image' />
          {messageStr}
        </p>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    const interruptInfoForNextNotification = this.state.show && this.props.notification.isInfo && !nextProps.notification.isInfo;
    if (interruptInfoForNextNotification) {
      clearTimeout(this.state.timerHandle);
    }

    if ((!this.state.show || interruptInfoForNextNotification) && nextProps.notification && nextProps.notification.style) {
      this.setState({ ...nextProps.notification, show: true });
      if (nextProps.notification.style === NOTIFICATION_STYLE.NORMAL) {
        this.setState({ timerHandle: setTimeout(this.closeNotification, nextProps?.notification.timeOut ? nextProps.notification.timeOut : 3000, this) });
      }
    }
  }

  closeNotification = () => {
    this.setState({ show: false });
  };

  render() {
    const { show, style, alertType, alertTitle, alertMessage, closeButtonTitle, secondButton } = this.state;
    const { t } = this.props;
    const customButton = Object.assign({}, {
      ...secondButton,
      onClickHandler: secondButton && secondButton.onClickHandler ? () => {
        secondButton.onClickHandler();
        this.closeNotification();
      } : undefined
    });
    const closeButtonText = (closeButtonTitle && closeButtonTitle.title) || closeButtonTitle || t('button.okay');
    const closeButton = Object.assign({}, {
      title: closeButtonText,
      onClickHandler: () => {
        this.closeNotification();
        // Honor click handler for cancel button, if provided
        try {
          const onClickHandler = closeButtonTitle && closeButtonTitle.onClickHandler;
          if (onClickHandler) {
            onClickHandler();
          }
        } catch (e) {
          // Ignore
        }
      }
    });
    return (
      <Fragment>
        <ChameleonCSSTransition
          transitionClasses={{
            appear: 'slide-down',
            enter: 'slide-down',
            exit: 'slide-up'
          }}
          isOpen={show && style === NOTIFICATION_STYLE.NORMAL}>
          {this.renderNotification()}
        </ChameleonCSSTransition>
        <AlertDialog
          isOpen={show && style === NOTIFICATION_STYLE.ALERT}
          alertTitle={alertTitle}
          alertType={alertType}
          alertMessage={alertMessage}
          buttons={[
            customButton,
            closeButton
          ]}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(NotificationContainer);
