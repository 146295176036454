import React from 'react';
import { func } from 'prop-types';
import { ResponsiveModal } from '@getgo/chameleon-react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../Store/configureStore';
import image from './assets/dropdown_menu.png';
import styles from './styles.scss';

const TranscriptProcessing = (props) => {
  const { t } = useTranslation();
  const redirect = () => {
    history.push('/contentLibrary', { reload: true });
    props.close();
  };

  const primaryActionButton = {
    text: t('button.okay'),
    onClick: redirect
  };

  return (
    <ResponsiveModal
      isOpen
      onClose={props.close}
      primaryActionButton={primaryActionButton}
      title={t('modals.transcriptProcessing.title')}
    >
      <div className={styles.transcriptProcessing}>
        <span>{t('modals.transcriptProcessing.body')}</span>
        <img alt='' src={image} />
      </div>
    </ResponsiveModal>
  );
};

TranscriptProcessing.propTypes = {
  close: func,
  dispatch: func
};

export default TranscriptProcessing;
