import * as Actions from './actions';

export default (state = { isFetching: false }, action) => {
  switch (action.type) {
    /* ----------- Get webinar Reporting Details ---------- */
    case Actions.GET_REPORTING_DETAILS_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case Actions.GET_REPORTING_DETAILS_COMPLETE: {
      return {
        ...state,
        analyticsData: action.data,
        isFetching: false
      };
    }
    case Actions.GET_REPORTING_DETAILS_FAILURE: {
      return {
        ...state,
        error: action.message,
        isFetching: false
      };
    }
    default: {
      return state;
    }
  }
};
