import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import errors from 'Api/Helpers/apiErrors.json';
import { onCreateVirtualEventOrganizerTracker, onDeleteVirtualEventOrganizerTracker, onUpdateVirtualEventOrganizerTracker, onAddOrRemoveOrganizerAsSpeaker } from 'Mixpanel/Features/VirtualEventDetails/About/OrganizersTracker';
import { onCreateVirtualEventPanelistTracker, onDeleteVirtualEventPanelistTracker, onUpdateVirtualEventPanelistTracker } from 'Mixpanel/Features/VirtualEventDetails/About/PanelistsTracker';
import { onUpdateVirtualEventTracker } from 'Mixpanel/Features/VirtualEventDetails/VirtualEventDetailsTracker';
import { history } from 'Store/configureStore';
import { onSaveApiStatusVirtualEventRegistrationQuestionTracker, onUpdateApprovalTypeTracker } from 'Mixpanel/Features/VirtualEventDetails/registrationSectionTracker';
import * as virtualEventDetailsService from '../../Api/VirtualEvent/VirtualEventDetails/VirtualEventDetailsService';
import { createVirtualEventPanelistAction, deleteVirtualEventPanelistAction, getVirtualWebinarAction, updateVirtualEventDetailsAction, updateVirtualEventPanelistAction,
  addOrganizerToVirtualEventAction, deleteOrganizerToVirtualEventAction, updateOrganizerToVirtualEventAction, showPanelistUpdateNotificationAction, PANELISTS_UPDATED,
  deleteVirtualEventAction, showOrganizerUpdateNotificationAction, showDateAndTimeNotificationAction, ORGANIZERS_UPDATED, getVirtualEventRegistrationQuestionAction,
  updateVERegistrationQuestionAction, updateVERegistrationSettingsAction, setVELiveStreamingConfigAction, updateVELiveStreamingChannelConfigAction, getAllVELiveStreamingConfigurationAction, createVEBroadcastSessionAction,
  stopVEBroadcastSessionAction, getVEActiveTargetsStatusAction, getVEBroadcastStatusAction, getVELinkedinConfigAction, linkedInVEReloginAction, getVELiveStreamingConfigAction } from './actions';
import * as webinarService from '../../Api/Webinar/webinarService';
import { SEND_NOTIFICATION } from '../Notification/actions';
import { SpeakerUpdatePath } from '../../Components/Card/VirtualEvent/Details/About/constants';

// to get the virtual event details
export function* fetchVirtualWebinar({ webinarKey, accountKey, isVECreation = false, userLocale }) {
  try {
    const data = yield call(virtualEventDetailsService.getVirtualEventDetails, webinarKey);
    if (data.questionnaireKey && isVECreation) {
      yield put({ type: getVirtualEventRegistrationQuestionAction.toString(), questionnaireKey: data.questionnaireKey, accountKey, userLocale });
    }
    yield put({ type: getVirtualWebinarAction.complete.toString(), data });
    if (isVECreation && data.conferences && data.conferences.length) {
      yield* data.conferences.map(function* (conference) {
        if (conference.conferenceType === 'LIVE') {
          yield put({ type: getAllVELiveStreamingConfigurationAction.toString(), config: { productKey: conference.conferenceKey, productType: 'G2W' } });
        }
      });
    }
  } catch (e) {
    history.replace({ pathname: '/' });
    yield put({
      type: getVirtualWebinarAction.failed.toString(),
      message: e.message
    });
  }
}

export function* watchFetchVirtualWebinar() {
  yield takeEvery(getVirtualWebinarAction.toString(), fetchVirtualWebinar);
}

// to update the virtual event details
export function* updateVirtualEventDetails({ eventKey, virtualEventData }) {
  const data = { ...virtualEventData };
  try {
    if (virtualEventData.image) {
      const { logoUrl } = yield call(virtualEventDetailsService.createPreSignedUrl, eventKey, virtualEventData.image);
      data.image = logoUrl;
    }
    yield call(virtualEventDetailsService.updateVirtualEventDetails, eventKey, virtualEventData);
    onUpdateVirtualEventTracker(eventKey, virtualEventData, 'Success');
    yield put({ type: updateVirtualEventDetailsAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.virtualEvent.updateSuccess', isError: false });
  } catch (e) {
    yield put({ type: updateVirtualEventDetailsAction.failed.toString(), message: e.body.message });
    onUpdateVirtualEventTracker(eventKey, virtualEventData, 'Fail');
    if (e.body.errorCode === errors.api.schedule.maintenanceErrorCode) {
      yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.maintenanceWindowFailure', isError: true, timeOut: 7000 });
      return;
    }
    if (e.status === 400) {
      switch (e.body.message) {
        case errors.api.updateEvent.invalidUpdateEventRequest:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
          break;
        default:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.virtualEvent.updateFail', isError: true });
      }
    }
  }
}

export function* watchUpdateVirtualEventDetails() {
  yield takeEvery(updateVirtualEventDetailsAction.toString(), updateVirtualEventDetails);
}

// add organizer to virtual event
export function* addOrganizerToVirtualEvent({ eventKey, organizerData }) {
  try {
    yield call(virtualEventDetailsService.addOrganizerToVirtualEvent, eventKey, organizerData);
    yield put({ type: addOrganizerToVirtualEventAction.complete.toString(), data: organizerData });
    yield call(onCreateVirtualEventOrganizerTracker, eventKey, organizerData, 'Success');
    yield put({ type: ORGANIZERS_UPDATED, data: true });
  } catch (e) {
    yield put({
      type: addOrganizerToVirtualEventAction.failed.toString(),
      message: e.body ? e.body.Details : e.message
    });
    yield call(onCreateVirtualEventOrganizerTracker, eventKey, organizerData, 'Failed');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.organizersUpdateFail', isError: true });
  }
}

export function* watchAddOrganizerToVirtualEvent() {
  yield takeEvery(addOrganizerToVirtualEventAction.toString(), addOrganizerToVirtualEvent);
}

// Delete organizer to virtual event
export function* deleteOrganizerToVirtualEvent({ eventKey, organizerKey }) {
  try {
    yield call(virtualEventDetailsService.deleteOrganizerToVirtualEvent, eventKey, organizerKey);
    yield put({ type: deleteOrganizerToVirtualEventAction.complete.toString(), data: organizerKey });
    yield call(onDeleteVirtualEventOrganizerTracker, eventKey, organizerKey, 'Success');
    yield put({ type: ORGANIZERS_UPDATED, data: true });
  } catch (e) {
    yield put({
      type: deleteOrganizerToVirtualEventAction.failed.toString(),
      message: e.body ? e.body.Details : e.message
    });
    yield call(onDeleteVirtualEventOrganizerTracker, eventKey, organizerKey, 'Failed');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.organizersUpdateFail', isError: true });
  }
}

export function* watchDeleteOrganizerToVirtualEvent() {
  yield takeEvery(deleteOrganizerToVirtualEventAction.toString(), deleteOrganizerToVirtualEvent);
}

// Update organizer to virtual event
export function* updateOrganizerToVirtualEvent({ eventKey, organizerKey, fieldName, data, updateType }) {
  try {
    yield call(virtualEventDetailsService.updateOrganizerToVirtualEvent, eventKey, organizerKey, fieldName, data, updateType);
    yield put({ type: updateOrganizerToVirtualEventAction.complete.toString(), payload: { eventKey, organizerKey, fieldName, data, updateType } });
    yield call(onUpdateVirtualEventOrganizerTracker, eventKey, organizerKey, fieldName, data, 'Success');
    if (fieldName === '/displayPanelistInfo') {
      yield call(onAddOrRemoveOrganizerAsSpeaker, eventKey, organizerKey, fieldName, data, 'Success');
    }
    yield put({ type: ORGANIZERS_UPDATED, data: true });
  } catch (e) {
    yield put({
      type: updateOrganizerToVirtualEventAction.failed.toString(),
      data: { organizerKey, fieldName, data, updateType }
    });
    yield call(onUpdateVirtualEventOrganizerTracker, eventKey, organizerKey, fieldName, data, 'Failed');
    if (fieldName === '/displayPanelistInfo') {
      yield call(onAddOrRemoveOrganizerAsSpeaker, eventKey, organizerKey, fieldName, data, 'Failed');
    }
    // yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.aboutEvent.organizersUpdateFail', isError: true });
  }
}

export function* watchUpdateOrganizerToVirtualEvent() {
  yield takeEvery(updateOrganizerToVirtualEventAction.toString(), updateOrganizerToVirtualEvent);
}

export function* createVirtualEventPanelist({ eventKey, panelistData, failureCallback }) {
  try {
    const data = yield call(virtualEventDetailsService.createVirtualEventPanelist, eventKey, panelistData);
    panelistData.id = data.id;
    yield put({ type: createVirtualEventPanelistAction.complete.toString(), data: panelistData });
    yield put({ type: PANELISTS_UPDATED, data: true });
    yield call(onCreateVirtualEventPanelistTracker, eventKey, panelistData, 'Success');
  } catch (e) {
    yield put({
      type: createVirtualEventPanelistAction.failed.toString(),
      message: e.body ? e.body.Details : e.message
    });
    failureCallback();
    yield call(onCreateVirtualEventPanelistTracker, eventKey, panelistData, 'Failed');
  }
}
export function* watchCreateVirtualEventPanelist() {
  yield takeEvery(createVirtualEventPanelistAction.toString(), createVirtualEventPanelist);
}

export function* updateVirtualEventPanelist({ eventKey, fieldName, data, updateType, panelistKey }) {
  let panelistData = data;
  try {
    if (fieldName === SpeakerUpdatePath.avatar && updateType !== 'remove') {
      const { logoUrl } = yield call(virtualEventDetailsService.createPreSignedUrl, eventKey, data);
      panelistData = logoUrl;
    }
    yield call(virtualEventDetailsService.updateVirtualEventPanelist, eventKey, panelistKey, fieldName, data, updateType);
    yield put({ type: updateVirtualEventPanelistAction.complete.toString(), payload: { panelistKey, fieldName, data: panelistData, updateType } });
    yield call(onUpdateVirtualEventPanelistTracker, eventKey, panelistKey, fieldName, data, 'Succuss');
    yield put({ type: PANELISTS_UPDATED, data: true });
  } catch (e) {
    yield put({
      type: updateVirtualEventPanelistAction.failed.toString(),
      data: { panelistKey, fieldName, data, updateType }
    });
    yield call(onUpdateVirtualEventPanelistTracker, eventKey, panelistKey, fieldName, data, 'Failed');
  }
}
export function* watchUpdateVirtualEventPanelist() {
  yield takeEvery(updateVirtualEventPanelistAction.toString(), updateVirtualEventPanelist);
}

export function* deleteVirtualEventPanelist({ eventKey, panelistKey, successCallBack }) {
  try {
    yield call(virtualEventDetailsService.deleteVirtualEventPanelist, eventKey, panelistKey);
    yield put({ type: deleteVirtualEventPanelistAction.complete.toString(), data: panelistKey });
    successCallBack();
    yield call(onDeleteVirtualEventPanelistTracker, eventKey, panelistKey, 'Success');
    yield put({ type: PANELISTS_UPDATED, data: true });
  } catch (e) {
    yield put({
      type: deleteVirtualEventPanelistAction.failed.toString(),
      message: e.body ? e.body.Details : e.message
    });
    yield call(onDeleteVirtualEventPanelistTracker, eventKey, panelistKey, 'Failed');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'error.saveError', isError: true });
  }
}
export function* watchDeleteVirtualEventPanelist() {
  yield takeEvery(deleteVirtualEventPanelistAction.toString(), deleteVirtualEventPanelist);
}

export function* showPanelistUpdateNotification({ message }) {
  yield put({ type: SEND_NOTIFICATION, message, isError: false });
  yield put({ type: PANELISTS_UPDATED, data: false });
}
export function* watchShowPanelistUpdateNotification() {
  yield takeEvery(showPanelistUpdateNotificationAction.toString(), showPanelistUpdateNotification);
}
// to update the virtual event details
export function* deleteVirtualEventWorker({ eventKey }) {
  try {
    yield call(virtualEventDetailsService.deleteVirtualEvent, eventKey);
    yield put({ type: deleteVirtualEventAction.complete.toString(), data: eventKey, redirectToDashboard: true });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.virtualEvent.details.aboutEvent.deleteVirtualEventSuccessWithNoEmailSent', isError: false });
  } catch (e) {
    yield put({ type: deleteVirtualEventAction.failed.toString(), message: e.body.message });
    if (e.status === 400) {
      switch (e.body.message) {
        case errors.api.updateEvent.invalidUpdateEventRequest:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'schedule.timeInPastFail', isError: true });
          break;
        default:
          yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.registrationUpdateFailed', isError: true });
      }
    }
  }
}
export function* watchDeleteVirtualEvent() {
  yield takeEvery(deleteVirtualEventAction.toString(), deleteVirtualEventWorker);
}

export function* showOrganizerUpdateNotification({ message }) {
  yield put({ type: SEND_NOTIFICATION, message, isError: false });
  yield put({ type: ORGANIZERS_UPDATED, data: false });
}
export function* watchShowOrganizerUpdateNotification() {
  yield takeEvery(showOrganizerUpdateNotificationAction.toString(), showOrganizerUpdateNotification);
}
export function* showDateAndTimeNotification({ message }) {
  yield put({ type: SEND_NOTIFICATION, message, isError: true });
}
export function* watchShowDateAndTimeNotification() {
  yield takeEvery(showDateAndTimeNotificationAction.toString(), showDateAndTimeNotification);
}

export function* getVirtualEventRegistrationQuestion({ questionnaireKey, accountKey, userLocale }) {
  try {
    const questions = yield call(virtualEventDetailsService.getVirtualEventRegistrationQuestions, questionnaireKey, accountKey, userLocale);
    yield put({ type: getVirtualEventRegistrationQuestionAction.complete.toString(), questions });
  } catch (e) {
    yield put({ type: getVirtualEventRegistrationQuestionAction.failed.toString(), message: e.body.message });
  }
}

export function* watchGetVirtualEventRegistrationQuestion() {
  yield takeEvery(getVirtualEventRegistrationQuestionAction.toString(), getVirtualEventRegistrationQuestion);
}

export function* updateVERegistrationQuestion({ eventKey, questions, questionnaireKey, accountKey, userLocale }) {
  try {
    const updatedQuestions = yield call(virtualEventDetailsService.updateVERegistrationQuestions, questions, questionnaireKey, accountKey, userLocale);
    onSaveApiStatusVirtualEventRegistrationQuestionTracker(eventKey, questions, 'success');
    yield put({ type: updateVERegistrationQuestionAction.complete.toString(), questions: updatedQuestions });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.registrationForm.successNotification', isError: false });
  } catch (e) {
    onSaveApiStatusVirtualEventRegistrationQuestionTracker(eventKey, questions, 'failed');
    yield put({ type: updateVERegistrationQuestionAction.failed.toString(), message: e.body.message });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.registrationForm.failureNotification', isError: true });
  }
}

export function* watchUpdateVERegistrationQuestion() {
  yield takeEvery(updateVERegistrationQuestionAction.toString(), updateVERegistrationQuestion);
}

export function* updateVERegistrationSettings({ eventKey, approvalType }) {
  try {
    yield call(virtualEventDetailsService.updateRegistrationSettings, eventKey, approvalType);
    onUpdateApprovalTypeTracker(eventKey, approvalType);
    yield put({ type: updateVERegistrationSettingsAction.complete.toString(), approvalType });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.registrationUpdated', isError: false });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION, messageKey: 'cards.webinar.details.registration.registrationUpdateFailed', isError: true });
    yield put({ type: updateVERegistrationSettingsAction.failed.toString(), message: e.body.message });
  }
}

export function* watchUpdateVERegistrationSettings() {
  yield takeEvery(updateVERegistrationSettingsAction.toString(), updateVERegistrationSettings);
}

function* setVELiveStreamingConfig({ config, channel, conferenceKey }) {
  const linkedInAccountType = {
    memberAccount: 'member',
    organisationAccount: 'organisation'
  };
  try {
    const response = yield call(webinarService.setLiveStreamingConfig, config, channel);
    if (response) {
      yield put({ type: setVELiveStreamingConfigAction.complete.toString(), data: response, channel, conferenceKey });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.successMessage' });
    }
  } catch (e) {
    yield put({
      type: setVELiveStreamingConfigAction.failed.toString(),
      message: e.message
    });
    switch (e.body.errorList[0].errorCode) {
      case errors.api.livestream.serverError:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.serverErrorMessage', isError: true });
        break;
      case errors.api.livestream.staleObjectError:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.staleObjectError', isError: true });
        break;
      case errors.api.livestream.unauthorizedUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedUserErrorMessage', isError: true });
        break;
      case errors.api.livestream.invalidLinkedInCode:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.invalidLinkedInCode', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedinUser', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinBroadcasterMember:
        yield put({
          type: setVELiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: linkedInAccountType.memberAccount,
          channel,
          conferenceKey
        });
        break;
      case errors.api.livestream.unauthorizedLinkedinBroadcasterOrganisation:
        yield put({
          type: setVELiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: linkedInAccountType.organisationAccount,
          channel,
          conferenceKey
        });
        break;
      case errors.api.livestream.companyPageNotFound:
        yield put({
          type: setVELiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: linkedInAccountType.organisationAccount,
          channel,
          conferenceKey
        });
        break;
      case errors.api.livestream.unauthorizedLinkedUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedUser', isError: true });
        break;
      case errors.api.livestream.linkedinMultiCompanyPage:
        yield put({
          type: setVELiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: false,
          showMultiCompanyPageMessage: true,
          channel,
          conferenceKey
        });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.serverErrorMessage', isError: true });
        break;
    }
  }
}

export function* watchSetVELiveStreamingConfig() {
  yield takeEvery(setVELiveStreamingConfigAction.toString(), setVELiveStreamingConfig);
}

function* updateVELiveStreamingChannelConfig({ config, channel, conferenceKey }) {
  try {
    const response = yield call(webinarService.updateLiveStreamingChannelConfig, config, channel);
    if (response) {
      const data = { ...config, liveStreamingSettingId: config.liveStreamingSettingsId };
      if (channel === 'linkedIn') {
        data.customAttributes = {
          broadcastAccountType: config.broadcastAccountType,
          postTitle: config.postTitle
        };
        delete data.broadcastAccountType;
        delete data.postTitle;
      }
      delete data.liveStreamingSettingsId;
      yield put({ type: updateVELiveStreamingChannelConfigAction.complete.toString(), data, channel, conferenceKey });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.successMessage' });
    }
  } catch (e) {
    yield put({
      type: updateVELiveStreamingChannelConfigAction.failed.toString(),
      message: e.message
    });
    switch (e.body.errorList[0].errorCode) {
      case errors.api.livestream.serverError:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.serverErrorMessage', isError: true });
        break;
      case errors.api.livestream.staleObjectError:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.staleObjectError', isError: true });
        break;
      case errors.api.livestream.unauthorizedUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedUserErrorMessage', isError: true });
        break;
      case errors.api.livestream.invalidLinkedInCode:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.invalidLinkedInCode', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedinUser', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinBroadcasterMember:
        yield put({
          type: setVELiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: 'member',
          channel,
          conferenceKey
        });
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedinBroadcasterUser', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedinBroadcasterOrganisation:
        yield put({
          type: setVELiveStreamingConfigAction.failed.toString(),
          message: e.message,
          showEnableLivestreamMessage: true,
          accountType: 'organisation',
          channel,
          conferenceKey
        });
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedinBroadcasterUser', isError: true });
        break;
      case errors.api.livestream.companyPageNotFound:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.companyPageNotFound', isError: true });
        break;
      case errors.api.livestream.unauthorizedLinkedUser:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.unauthorizedLinkedUser', isError: true });
        break;
      case errors.api.livestream.linkedinMultiCompanyPage:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.linkedinMultiCompanyPageNotSupported', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.serverErrorMessage', isError: true });
        break;
    }
  }
}

export function* watchUpdateVELiveStreamingChannelConfig() {
  yield takeEvery(updateVELiveStreamingChannelConfigAction.toString(), updateVELiveStreamingChannelConfig);
}

function* getAllVELiveStreamingConfiguration({ config }) {
  try {
    const response = yield call(webinarService.getAllLiveStreamingConfiguration, config);
    if (response) {
      yield put({ type: getAllVELiveStreamingConfigurationAction.complete.toString(), data: response, conferenceKey: config.productKey });
    }
  } catch (e) {
    yield put({
      type: getAllVELiveStreamingConfigurationAction.failed.toString(),
      message: e.message
    });
  }
}

export function* watchGetAllVELiveStreamingConfiguration() {
  yield takeEvery(getAllVELiveStreamingConfigurationAction.toString(), getAllVELiveStreamingConfiguration);
}

function* createVEBroadcastSession({ config, channel, conferenceKey }) {
  try {
    const response = yield call(webinarService.createBroadcastSession, config, channel);
    if (response) {
      yield put({ type: createVEBroadcastSessionAction.complete.toString(), data: response, channel, conferenceKey });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.streamingInitiatedSuccess' });
      // trackStartLiveStreamBroadcast(channel);
    }
  } catch (error) {
    switch (error.body.errorList[0].errorCode) {
      case errors.api.livestream.linkedInTokenExpiredError:
        yield put({
          type: createVEBroadcastSessionAction.failed.toString(),
          message: error.message,
          relogin: true,
          config
        });
        break;
      default:
        yield put({
          type: createVEBroadcastSessionAction.failed.toString(),
          message: error.message
        });
        yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.streamingInitiatedFailure', isError: true });
        break;
    }
  }
}

export function* watchCreateVEBroadcastSession() {
  yield takeEvery(createVEBroadcastSessionAction.toString(), createVEBroadcastSession);
}

function* linkedInVERelogin({ config }) {
  try {
    const response = yield call(webinarService.linkedInRelogin, config);
    if (response) {
      yield put({ type: linkedInVEReloginAction.complete.toString(), data: response });
    }
  } catch (e) {
    yield put({
      type: linkedInVEReloginAction.failed.toString(),
      message: e.message
    });
  }
}

export function* watchLinkedVEInRelogin() {
  yield takeEvery(linkedInVEReloginAction.toString(), linkedInVERelogin);
}

function* getVEActiveTargetsStatus({ config, conferenceKey }) {
  try {
    const response = yield call(webinarService.getActiveTargetsStatus, config);
    if (response) {
      yield put({ type: getVEActiveTargetsStatusAction.complete.toString(), data: response, conferenceKey });
    }
  } catch (e) {
    yield put({
      type: getVEActiveTargetsStatusAction.failed.toString(),
      message: e.message
    });
  }
}

export function* watchGetVEActiveTargetsStatus() {
  yield takeEvery(getVEActiveTargetsStatusAction.toString(), getVEActiveTargetsStatus);
}

function* stopVEBroadcastSession({ config, channel, conferenceKey }) {
  try {
    const response = yield call(webinarService.stopBroadcastSession, config, channel);
    if (response) {
      yield put({ type: stopVEBroadcastSessionAction.complete.toString(), data: response, channel, conferenceKey });
      yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.streamingStopSuccess' });
    }
  } catch (e) {
    yield put({
      type: stopVEBroadcastSessionAction.failed.toString(),
      message: e.message
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'liveStreaming.streamingStopFailure', isError: true });
  }
}

export function* watchStopVEBroadcastSession() {
  yield takeEvery(stopVEBroadcastSessionAction.toString(), stopVEBroadcastSession);
}

function* getVEBroadcastStatus({ config, channel, conferenceKey }) {
  try {
    const response = yield call(webinarService.getBroadcastStatus, config, channel);
    if (response) {
      yield put({ type: getVEBroadcastStatusAction.complete.toString(), data: response, channel, conferenceKey });
    }
  } catch (e) {
    switch (e.body.errorList[0].errorCode) {
      case errors.api.livestream.invalidTarget:
        yield put({
          type: getVEBroadcastStatusAction.failed.toString(),
          message: e.message,
          channel,
          conferenceKey
        });
        break;
      default:
        yield put({
          type: getVEBroadcastStatusAction.failed.toString(),
          message: e.message
        });
        break;
    }
  }
}

export function* watchGetVEBroadcastStatus() {
  yield takeEvery(getVEBroadcastStatusAction.toString(), getVEBroadcastStatus);
}

function* getVELinkedinConfig({ config }) {
  try {
    const response = yield call(webinarService.getLinkedinConfig, config);
    if (response) {
      yield put({ type: getVELinkedinConfigAction.complete.toString(), data: response });
    }
  } catch (e) {
    yield put({
      type: getVELinkedinConfigAction.failed.toString(),
      message: e.message
    });
  }
}

export function* watchGetVELinkedinConfig() {
  yield takeEvery(getVELinkedinConfigAction.toString(), getVELinkedinConfig);
}

function* getVELiveStreamingConfig({ config, channel, conferenceKey }) {
  try {
    const response = yield call(webinarService.getLiveStreamingConfig, config, channel);
    if (response) {
      yield put({ type: getVELiveStreamingConfigAction.complete.toString(), data: response, channel, conferenceKey });
    }
  } catch (e) {
    yield put({
      type: getVELiveStreamingConfigAction.failed.toString(),
      message: e.message
    });
  }
}

export function* watchGetVELiveStreamingConfig() {
  yield takeEvery(getVELiveStreamingConfigAction.toString(), getVELiveStreamingConfig);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchVirtualWebinar),
    fork(watchUpdateVirtualEventDetails),
    fork(watchAddOrganizerToVirtualEvent),
    fork(watchDeleteOrganizerToVirtualEvent),
    fork(watchUpdateOrganizerToVirtualEvent),
    fork(watchCreateVirtualEventPanelist),
    fork(watchUpdateVirtualEventPanelist),
    fork(watchDeleteVirtualEventPanelist),
    fork(watchShowPanelistUpdateNotification),
    fork(watchShowOrganizerUpdateNotification),
    fork(watchShowDateAndTimeNotification),
    fork(watchDeleteVirtualEvent),
    fork(watchGetVirtualEventRegistrationQuestion),
    fork(watchUpdateVERegistrationQuestion),
    fork(watchUpdateVERegistrationSettings),
    fork(watchSetVELiveStreamingConfig),
    fork(watchUpdateVELiveStreamingChannelConfig),
    fork(watchGetAllVELiveStreamingConfiguration),
    fork(watchCreateVEBroadcastSession),
    fork(watchLinkedVEInRelogin),
    fork(watchGetVEActiveTargetsStatus),
    fork(watchStopVEBroadcastSession),
    fork(watchGetVEBroadcastStatus),
    fork(watchGetVELinkedinConfig),
    fork(watchGetVELiveStreamingConfig)
  ]);
}
