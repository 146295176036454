import config from 'appConfig';
import getHeaders from 'Api/Helpers/getHeaders';
import { fetchJson } from 'Api/Helpers/fetch';
import { PUT } from 'Api/Helpers/httpMethods';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';

const URL_PREFIX = '/api/webinars/';
const URL_SETTINGS_POSTFIX = '/settings';
const URL_DISCLAMINER_POSTFIX = '/disclaimer';

export function getWebinarCustomDisclaimer(webinarKey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SETTINGS_POSTFIX}${URL_DISCLAMINER_POSTFIX}`;
  const options = {
    headers: getHeaders(true)
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function setWebinarCustomDisclaimer(webinarKey, body) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SETTINGS_POSTFIX}${URL_DISCLAMINER_POSTFIX}`;
  const options = {
    headers: getHeaders(true),
    method: PUT,
    body: JSON.stringify(body)
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}
