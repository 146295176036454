import { createAsyncAction as createAsyncActionV2 } from 'redux-promise-middleware-actions';
import { getMe } from 'Api/accountService';
import { getMetadata } from 'Api/Webinar/webinarService';
import { resetAuth } from 'Utils/authHelper';
import { createAsyncAction } from '../reduxActions';

export const NO_ENTITLEMENTS = 'no-entitlements';

export const getUserAction = createAsyncActionV2('GET_USER', async () => {
  try {
    const user = await getMe();
    const defaultLocale = 'en_US';
    // Setting default value of locale as en_US when locale don't exist
    if (!user.locale) {
      user.locale = defaultLocale;
    }
    if (user.entitlements && user.entitlements.includes('g2w')) {
      const metadata = await getMetadata(user.id);
      return { user, metadata };
    }
    return Promise.reject(new Error(NO_ENTITLEMENTS));
  } catch (error) {
    if (error.status === 401) {
      resetAuth();
    }
    return Promise.reject(error);
  }
});

export const getAccountUsersAction = createAsyncAction('GET_ACCOUNT_USERS');

export function getAccountUsers(organizerKey) {
  return { type: getAccountUsersAction.toString(), organizerKey };
}
