import XHR from 'i18next-xhr-backend';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

export function initi18n(debug, localesRoot) {
  i18next
    .use(initReactI18next)
    .use(XHR)
    .init({
      ns: ['translation', 'countries'],
      debug,
      backend: {
        loadPath: `${localesRoot}/{{lng}}/{{ns}}.json`
      },
      fallbackLng: 'en',
      lng: 'en',
      load: 'current',
      interpolation: {
        escapeValue: false
      },
      react: {
        wait: false,
        withRef: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
      }
    });
}

initi18n(process.env.NODE_ENV === 'development' || process.env.ENVIRONMENT === 'ed', process.env.LOCALES_ROOT);

export default i18next;

export const getShortLocale = (locale = '') => locale.substr(0, 2).toLowerCase();

export const getCountryFromLocale = (locale = '') => locale.substr(3).toLowerCase();
