import cloneDeep from 'lodash.clonedeep';
import { getRegistrantsAction,
  getRegistrationReportAction,
  clearRegistrantsAction,
  getFilteredRegistrantsAction,
  uploadRegistrantsListFileAction,
  getVERegistrantsAction,
  getFilteredVERegistrantsAction,
  getVERegistrationReportAction,
  updateVERegistrantsAction } from './actions';

const defaultState = {
  registrantsList: {
    isFetching: true,
    isSearchInProgress: false,
    data: [],
    filteredList: [],
    filterKey: '',
    error: null,
    isDownloadingReportInProgress: false,
    isUploadSuccessful: false,
    isUploadingInProgress: false,
    totalCount: 0
  }
};

const DEFAULT_CHECKED_STATE_FOR_PENDING_REGISTRANTS = true;
const DEFAULT_CHECKED_STATE_FOR_APPROVED_AND_DENIED_REGISTRANTS = false;

export default (state = defaultState, action) => {
  switch (action.type) {
    case getRegistrantsAction.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isFetching = true;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrantsAction.complete.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isFetching = false;
      const registrantsList = action.data;
      const isSourceTrackingAvailable = registrantsList.some((registrant) => (registrant.source));
      let mappedList;

      if (!isSourceTrackingAvailable) {
        mappedList = registrantsList.map((registrant) => ({
          ...registrant,
          fullName: [registrant.firstName, registrant.lastName].join(' '),
          selectedForAction: registrant.status === 'WAITING' ? DEFAULT_CHECKED_STATE_FOR_PENDING_REGISTRANTS : DEFAULT_CHECKED_STATE_FOR_APPROVED_AND_DENIED_REGISTRANTS
        }));
      } else {
        mappedList = registrantsList.map((registrant) => ({
          ...registrant,
          fullName: [registrant.firstName, registrant.lastName].join(' '),
          selectedForAction: registrant.status === 'WAITING' ? DEFAULT_CHECKED_STATE_FOR_PENDING_REGISTRANTS : DEFAULT_CHECKED_STATE_FOR_APPROVED_AND_DENIED_REGISTRANTS,
          source: registrant.source || ''
        }));
      }
      registrantsCopy.data = mappedList;
      registrantsCopy.filterKey = '';

      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrantsAction.failed.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isFetching = false;
      registrantsCopy.error = action.error;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getFilteredRegistrantsAction.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isFetching = false;
      registrantsCopy.isSearchInProgress = true;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getFilteredRegistrantsAction.complete.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      const filterKey = action.filter;
      registrantsCopy.filterKey = filterKey;

      const registrantsList = registrantsCopy.data;
      const filteredList = registrantsList.filter((registrant) => {
        if (registrant.firstName.toLocaleLowerCase().includes(filterKey)
            || registrant.lastName.toLocaleLowerCase().includes(filterKey)
            || registrant.email.toLocaleLowerCase().includes(filterKey)) {
          return true;
        }

        return false;
      });
      registrantsCopy.filteredList = filteredList;
      registrantsCopy.isSearchInProgress = false;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrationReportAction.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isDownloadingReportInProgress = true;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrationReportAction.complete.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isDownloadingReportInProgress = false;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getRegistrationReportAction.failed.toString(): {
      const registrantsCopy = Object.assign({}, state.registrantsList);
      registrantsCopy.isDownloadingReportInProgress = false;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case clearRegistrantsAction.complete.toString(): {
      return defaultState;
    }
    case uploadRegistrantsListFileAction.toString(): {
      const registrantsCopy = { ...state.registrantsList };
      registrantsCopy.isUploadingInProgress = true;
      return {
        registrantsList: registrantsCopy
      };
    }
    case uploadRegistrantsListFileAction.complete.toString(): {
      const registrantsCopy = { ...state.registrantsList };
      registrantsCopy.isUploadSuccessful = true;
      registrantsCopy.isUploadingInProgress = false;
      return {
        registrantsList: registrantsCopy
      };
    }
    case uploadRegistrantsListFileAction.failed.toString(): {
      const registrantsCopy = { ...state.registrantsList };
      registrantsCopy.isUploadingInProgress = false;
      return {
        registrantsList: registrantsCopy
      };
    }
    case getVERegistrantsAction.toString(): {
      const registrantsCopy = {
        ...state.registrantsList,
        isFetching: true
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getVERegistrantsAction.complete.toString(): {
      let totalCount = 0;
      Object.values(action.data).forEach((val) => {
        totalCount += val.totalCount;
      });
      const registrantsCopy = {
        ...state.registrantsList,
        filterKey: '',
        isFetching: false,
        data: action.data,
        totalCount
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getVERegistrantsAction.failed.toString(): {
      const registrantsCopy = {
        ...state.registrantsList,
        isFetching: false,
        error: action.error
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getFilteredVERegistrantsAction.toString(): {
      const registrantsCopy = {
        ...state.registrantsList,
        isFetching: false,
        isSearchInProgress: true
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getFilteredVERegistrantsAction.complete.toString(): {
      const registrantsCopy = { ...state.registrantsList };
      const filterKey = action.filter;
      const filteredList = cloneDeep(registrantsCopy.data);

      if (filterKey) {
        for (const [key, value] of Object.entries(filteredList)) {
          let updatedList = [];
          if (value.registrantDetails.length) {
            updatedList = value.registrantDetails.filter(
              (registrant) => (
                registrant.firstName.toLocaleLowerCase().includes(filterKey)
                || registrant.lastName.toLocaleLowerCase().includes(filterKey)
                || registrant.email.toLocaleLowerCase().includes(filterKey)
              )
            );
          }
          filteredList[key] = {
            registrantDetails: updatedList,
            totalCount: updatedList.length
          };
        }
      }
      registrantsCopy.filterKey = filterKey;
      registrantsCopy.filteredList = filteredList;
      registrantsCopy.isSearchInProgress = false;
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getVERegistrationReportAction.toString(): {
      const registrantsCopy = {
        ...state.registrantsList,
        isDownloadingReportInProgress: true
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getVERegistrationReportAction.complete.toString(): {
      const registrantsCopy = {
        ...state.registrantsList,
        isDownloadingReportInProgress: false
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case getVERegistrationReportAction.failed.toString(): {
      const registrantsCopy = {
        ...state.registrantsList,
        isDownloadingReportInProgress: false
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case updateVERegistrantsAction.toString(): {
      const registrantsCopy = {
        ...state.registrantsList,
        isFetching: true
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    case updateVERegistrantsAction.failed.toString(): {
      const registrantsCopy = {
        ...state.registrantsList,
        isFetching: false
      };
      return {
        ...state,
        registrantsList: registrantsCopy
      };
    }
    default:
      return {
        ...state
      };
  }
};
