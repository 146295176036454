import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { string, number, func } from 'prop-types';
import Loading from 'Components/Common/FullScreenLoader';
import { actions as actionCreators } from 'Reducks/Auth';

export function requireAuthentication(Component) {
  class AuthenticatedComponent extends React.Component {
    static propTypes = {
      authRedirect: func,
      authToken: string,
      authTokenExpiration: number
    };

    componentWillMount() {
      this.checkAuth();
    }

    isAuthenticated = () => {
      const { authToken, authTokenExpiration } = this.props;
      if (!authToken) {
        return false;
      }
      return authToken && authTokenExpiration - 300000 > Date.now();
    }

    checkAuth = () => {
      if (!this.isAuthenticated()) {
        this.props.authRedirect(window.location.href);
      }
    }

    render() {
      const isAuthenticated = this.isAuthenticated();
      if (!isAuthenticated) {
        return (
          <Loading />
        );
      }
      return (
        <Component />
      );
    }
  }

  return AuthenticatedComponent;
}

export default (Component) => {
  const WrappedComponent = requireAuthentication(Component);

  return connect(
    (state) => ({
      authToken: state.auth.access_token,
      authTokenExpiration: state.auth.expires
    }),
    (dispatch) => bindActionCreators(actionCreators, dispatch)
  )(WrappedComponent);
};
