import config from 'appConfig';
import * as httpMethods from 'Api/Helpers/httpMethods';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from 'Api/Helpers/fetch';

const URL_YOUTUBE_VIDEOS_PREFIX = '/youtube/v3/videos';

export function getYouTubeVideoDetails(youTubeId) {
  const url = `${config.youTube.googleApiBaseUrl}${URL_YOUTUBE_VIDEOS_PREFIX}?id=${youTubeId}&key=${config.youTube.googleApiKey}&part=snippet`;
  const options = {
    method: httpMethods.GET
  };
  return fetchJson(url, options, JSON_MIME_TYPE);
}
