const UAParser = require('ua-parser-js');

const parser = new UAParser();
const result = parser.getResult();

export function getBrowser() {
  return result.browser;
}

export function getCpu() {
  return result.cpu;
}

export function getDevice() {
  return result.device;
}

export function getEngine() {
  return result.engine;
}

export function getOS() {
  return result.os;
}

export function getUA() {
  return result.ua;
}
