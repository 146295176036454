import { MessageBusEvent } from '@getgo/message-bus-js-sdk';
import { validateEvent } from '../../Helpers/messageBusEventValidator';

/**
 * Common Fragment for all events sent from EndToEnd. The class that extends {MessageBusEvent} and hence inherits all
 * functions from MessageBusEvent.
 * Mandatory fields to be set are: 'userType', 'userFrontEndSessionId', 'webpageName'
 */
export default class CommonFragment extends MessageBusEvent {
  withUserType(userType) {
    this.userType = userType;
    return this;
  }

  withUserFrontEndSessionId(userFrontEndSessionId) {
    this.userFrontEndSessionId = userFrontEndSessionId;
    return this;
  }

  withWebpageName(webpageName) {
    this.webpageName = webpageName;
    return this;
  }

  withOrganizerKey(organizerKey) {
    this.organizerKey = organizerKey;
    return this;
  }

  withAccountKey(accountKey) {
    this.accountKey = accountKey;
    return this;
  }

  withAnonymousUserKey(anonymousUserKey) {
    this.anonymousUserKey = anonymousUserKey;
    return this;
  }

  withAppVersion(appVersion) {
    this.appVersion = appVersion;
    return this;
  }

  /**
   * @returns an object of type {DashboardCommonFragment} containing all properties set with the 'with' functions.
   * @throws Error if mandatory properties for {MessageBusEvent} or {CommonFragment} are not set.
   */
  build() {
    const event = super.build();
    validateEvent(this, 'EventCommon', ['userType', 'userFrontEndSessionId', 'webpageName']);
    const commonFragment = {
      userType: this.userType,
      userFrontEndSessionId: this.userFrontEndSessionId,
      webpageName: this.webpageName
    };
    if (this.organizerKey) {
      commonFragment.organizerKey = this.organizerKey;
    }
    if (this.accountKey) {
      commonFragment.accountKey = this.accountKey;
    }
    if (this.anonymousUserKey) {
      commonFragment.anonymousUserKey = this.anonymousUserKey;
    }

    if (this.appVersion) {
      commonFragment.appVersion = this.appVersion;
    }

    return Object.freeze({
      ...event,
      payload: commonFragment
    });
  }
}

