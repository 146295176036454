import { getInterprefyTokenAction, updateInterprefyTokenAction } from './actions';

const defaultState = {
  interprefyToken: null,
  showLoader: false,
  error: false
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case getInterprefyTokenAction.complete.toString(): {
      return {
        ...state,
        interprefyToken: action.data.interprefyToken ?? null,
        showLoader: false,
        error: false
      };
    }
    case getInterprefyTokenAction.toString(): {
      return {
        ...state,
        showLoader: true,
        error: false
      };
    }
    case getInterprefyTokenAction.failed.toString(): {
      return {
        ...state,
        interprefyToken: null,
        showLoader: false,
        error: true
      };
    }
    case updateInterprefyTokenAction.toString(): {
      return {
        ...state,
        showLoader: true,
        error: false
      };
    }
    case updateInterprefyTokenAction.complete.toString(): {
      return {
        ...state,
        interprefyToken: action.data,
        showLoader: false,
        error: false
      };
    }
    case updateInterprefyTokenAction.failed.toString(): {
      return {
        ...state,
        showLoader: false,
        error: true
      };
    }
    default:
      return state;
  }
};
