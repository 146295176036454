import MixpanelTracker from '../../mixpanelTracker';
import { FEATURE_LOCK, UPGRADE_URL_CLICKED } from '../../mixpanelConstants';

const mixpanelTracker = new MixpanelTracker();

export function trackFeatureUpgrade(featureName) {
  const properties = {
    Type: featureName
  };
  mixpanelTracker.setEventName(FEATURE_LOCK);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}

export function trackUpgradeUrlClicked() {
  const properties = {
    Action: 'upgrade url clicked'
  };
  mixpanelTracker.setEventName(UPGRADE_URL_CLICKED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}
