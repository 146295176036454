
import { createAsyncAction, createAction } from 'redux-promise-middleware-actions';
import { getNotificationPreferences, setNotificationPreference, setNotificationPreferences } from 'Api/Notifications/notificationSubscriptionService';

export const setNotificationPreferenceAction = createAsyncAction('SET_NOTIFICATION_PREFERENCE');

export const getNotificationPreferencesForUser = createAsyncAction('GET_NOTIFICATION_PREFERENCES', async (userKey = '') => {
  const notificationPreferences = await getNotificationPreferences(userKey);
  return notificationPreferences;
});

export const setNotificationPreferenceForUser = createAsyncAction('SET_NOTIFICATION_PREFERENCE', async (userKey = '', preference = {}) => {
  await setNotificationPreference(userKey, preference);
  return preference;
});

export const setNotificationPreferencesForUser = createAsyncAction('SET_NOTIFICATION_PREFERENCES', async (userKey = '', preferences = {}) => {
  await setNotificationPreferences(userKey, preferences);
  return preferences;
});

export const setPermission = createAction('SET_PERMISSION', (permission) => ({ permission }));

export const setPushNotificationsSupported = createAction('SET_PUSH_NOTIFICATIONS_SUPPORTED', (isSupported) => ({ isSupported }));

