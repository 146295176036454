import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as RegistrationTypes from 'Components/Card/Events/Details/Registration/registrationTypes';
import * as RegistrationConstants from 'Mixpanel/Features/Registration/registrationConstants';
import clonedeep from 'lodash.clonedeep';
import isequal from 'lodash.isequal';
import Action from 'Mixpanel/Model/action';
import { ResponsiveModal, AlertDialog } from '@getgo/chameleon-react';
import AddButton from 'Components/Card/SharedComponents/AddButton';
import classNames from 'classnames';
import { getNumericUUID } from 'Utils/randomUuidGenerator';
import DomainInput from './DomainInput/domainInput';
import styles from './styles.scss';
import { trackDomainsAdded } from '../../../Mixpanel/Features/EventDetails/RestrictAccess/restrictAccessTracker';

const allowedDomainsFieldId = 'allowed-domains-field';
const blockedDomainsFieldId = 'blocked-domains-field';

class RestrictDomainsModal extends Component {
  static propTypes = {
    webinar: PropTypes.object,
    toggleModal: PropTypes.func,
    updateRegistrationSettings: PropTypes.func,
    trackRegistrationUpdated: PropTypes.func,
    domainWhitelist: PropTypes.array,
    isBlockedDomainsPresent: PropTypes.bool,
    domainWhitelistServerErrors: PropTypes.array,
    t: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      allowedDomains: this.props.isBlockedDomainsPresent ? [] : clonedeep(props.domainWhitelist),
      blockedDomains: this.props.isBlockedDomainsPresent ? clonedeep(props.domainWhitelist) : [],
      domainCountLimitReached: false,
      isBlockedDomainsSelected: this.props.isBlockedDomainsPresent,
      showModal: false,
      showCloseAlertDialog: false,
      exceedsHeight: false,
      warningAlert: null
    };
    this.allowedDomainsListRef = React.createRef();
    this.blockedDomainsListRef = React.createRef();
    this.newDomainInputRef = React.createRef();
  }

  checkExceedsHeight = () => {
    let exceedsHeight = false;

    if (this.allowedDomainsListRef.current) {
      exceedsHeight = this.allowedDomainsListRef.current.scrollHeight > 275;
    }

    if (this.blockedDomainsListRef.current && !exceedsHeight) {
      exceedsHeight = this.blockedDomainsListRef.current.scrollHeight > 275;
    }

    // Only update state if exceedsHeight has changed
    if (exceedsHeight !== this.state.exceedsHeight) {
      this.setState({ exceedsHeight });
    }
  }

  componentDidMount() {
    this.checkExceedsHeight();
  }

  componentDidUpdate(prevProps, prevState) {
    const newDomainWhitelistErrors = this.props.domainWhitelistServerErrors;
    const oldDomainWhitelistErrors = prevProps.domainWhitelistServerErrors;
    this.checkExceedsHeight();

    const domains = this.state.isBlockedDomainsSelected ? this.state.blockedDomains : this.state.allowedDomains;
    const domainRef = this.newDomainInputRef; // Single ref for both cases

    if (prevState.allowedDomains.length !== this.state.allowedDomains.length
        || prevState.blockedDomains.length !== this.state.blockedDomains.length) {
      if (domains.length > 3 && domainRef.current) {
        domainRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (!isequal(newDomainWhitelistErrors, oldDomainWhitelistErrors)) {
      const allowedDomainsWithServerErrors = this.state.allowedDomains.reduce((agg, curr) => {
        if (newDomainWhitelistErrors.includes(curr.domainName)) {
          agg.push({
            domainName: curr.domainName,
            hasError: true,
            key: curr.key
          });
        } else {
          agg.push(curr);
        }
        return agg;
      }, []);
      this.setState({ allowedDomains: allowedDomainsWithServerErrors });
    }
  }

  canAddDomain = (domainsList) => domainsList.length < 50

  addDomain = () => {
    const { allowedDomains, blockedDomains, isBlockedDomainsSelected } = this.state;
    const domainObj = {
      domainName: '',
      hasError: false,
      key: getNumericUUID()
    };
    if (isBlockedDomainsSelected) {
      this.setState({ blockedDomains: [
        ...clonedeep(blockedDomains),
        domainObj
      ] });
    } else {
      this.setState({ allowedDomains: [
        ...clonedeep(allowedDomains),
        domainObj
      ] });
    }
  };

  onClose = () => {
    this.props.toggleModal();
  }

  onSave = () => {
    const { allowedDomains, blockedDomains, isBlockedDomainsSelected } = this.state;
    const domainsList = isBlockedDomainsSelected ? blockedDomains : allowedDomains;
    const domainsString = domainsList
      .filter((i) => i.domainName !== '')
      .reduce((agg, curr) => {
        agg.push(curr.domainName.toLowerCase());
        return agg;
      }, [])
      .join(',');

    const webinarKey = this.props.webinar.webinarKey;

    const data = isBlockedDomainsSelected
      ? { blockedEmailDomains: domainsString }
      : { allowedEmailDomains: domainsString };

    const domainCountParams = isBlockedDomainsSelected ? blockedDomains.length : allowedDomains.length;
    trackDomainsAdded(domainCountParams, isBlockedDomainsSelected);
    this.props.updateRegistrationSettings(webinarKey, data, RegistrationTypes.RESTRICT_DOMAINS);
  };

  renderAllowedDomains = () => {
    const { t } = this.props;
    const { isBlockedDomainsSelected, allowedDomains, blockedDomains, exceedsHeight } = this.state;
    const canAddDomain = this.canAddDomain(allowedDomains);
    const addDomainInfoStyles = classNames(styles.addDomainInfo, { [styles.warning]: !canAddDomain });
    const addDomainInfoText = canAddDomain
      ? t('cards.webinar.details.registration.restrictDomainsModal.addDomainInfo')
      : t('cards.webinar.details.registration.restrictDomainsModal.addDomainInfoLimitReached');

    return (
      <div className={styles.domainAccessContainer}>
        <input
          type='radio'
          name="allowedDomainType"
          value={!isBlockedDomainsSelected}
          id={allowedDomainsFieldId}
          checked={!isBlockedDomainsSelected}
          onClick={() => {
            if (isBlockedDomainsSelected && blockedDomains.length) {
              this.toggleDialog();
            } else {
              this.setState({ isBlockedDomainsSelected: false });
            }
          }}
        />
        <label htmlFor={allowedDomainsFieldId}>
          <div className={styles.radioLabel}>
            {t('cards.webinar.details.registration.restrictDomainsModal.allowedDomain.header')}
          </div>
        </label>
        <div className={styles.radioSubLabel}>
          {t('cards.webinar.details.registration.restrictDomainsModal.allowedDomain.info')}
        </div>
        <div className={styles.domainsField}>
          { allowedDomains && (<div className={classNames({ [styles.domainsList]: exceedsHeight && !isBlockedDomainsSelected })} ref={this.allowedDomainsListRef} >
            {this.renderDomains(allowedDomains)}
          </div>
          )}
          <div className={styles.addButtonContainer}>
            <AddButton className={styles.addButton} id='add_domain_button' title={'Domain'} onClick={this.addDomain} isDisabled={!canAddDomain || isBlockedDomainsSelected} />
            <div className={addDomainInfoStyles}>{addDomainInfoText}</div>
          </div>
        </div>
      </div>
    );
  }

  renderBlockedDomains = () => {
    const { t } = this.props;
    const { isBlockedDomainsSelected, blockedDomains, allowedDomains, exceedsHeight } = this.state;
    const canAddDomain = this.canAddDomain(blockedDomains);
    const addDomainInfoStyles = classNames(styles.addDomainInfo, { [styles.warning]: !canAddDomain });
    const addDomainInfoText = canAddDomain
      ? t('cards.webinar.details.registration.restrictDomainsModal.addDomainInfo')
      : t('cards.webinar.details.registration.restrictDomainsModal.addDomainInfoLimitReached');

    return (
      <div className={styles.domainAccessContainer}>
        <input
          type='radio'
          name="blockedDomainType"
          value={isBlockedDomainsSelected}
          id={blockedDomainsFieldId}
          checked={isBlockedDomainsSelected}
          onClick={() => {
            if (!isBlockedDomainsSelected && allowedDomains.length) {
              this.toggleDialog();
            } else {
              this.setState({ isBlockedDomainsSelected: true });
            }
          }}
        />
        <label htmlFor={blockedDomainsFieldId}>
          <div className={styles.radioLabel}>
            {t('cards.webinar.details.registration.restrictDomainsModal.blockedDomain.header')}
          </div>
        </label>
        <div className={styles.radioSubLabel}>
          {t('cards.webinar.details.registration.restrictDomainsModal.blockedDomain.info')}
        </div>
        <div className={styles.domainsField}>
          { blockedDomains && (<div className={classNames({ [styles.domainsList]: exceedsHeight && isBlockedDomainsSelected })} ref={this.blockedDomainsListRef} >
            {this.renderDomains(blockedDomains)}
          </div>
          )}
          <div className={styles.addButtonContainer}>
            <AddButton className={styles.addButton} id='add_domain_button' title={'Domain'} onClick={this.addDomain} isDisabled={!canAddDomain || !isBlockedDomainsSelected} />
            <div className={addDomainInfoStyles}>{addDomainInfoText}</div>
          </div>
        </div>
      </div>
    );
  }

  onDomainUpdated = (domainName, domainIndex, hasError) => {
    const { allowedDomains, blockedDomains, isBlockedDomainsSelected } = this.state;
    const domainsListCopy = clonedeep(isBlockedDomainsSelected ? blockedDomains : allowedDomains);
    domainsListCopy[domainIndex].domainName = domainName;
    domainsListCopy[domainIndex].hasError = hasError;
    if (isBlockedDomainsSelected) {
      this.setState({ blockedDomains: domainsListCopy });
    } else {
      this.setState({ allowedDomains: domainsListCopy });
    }
    this.props.trackRegistrationUpdated(RegistrationConstants.RESTRICT_DOMAINS, Action.EDIT);
  };

  onDomainDeleted = (domainIndex) => () => {
    const { allowedDomains, blockedDomains, isBlockedDomainsSelected } = this.state;
    if (isBlockedDomainsSelected) {
      const blockedDomainsList = clonedeep(blockedDomains);
      blockedDomainsList.splice(domainIndex, 1);
      this.setState({ blockedDomains: blockedDomainsList });
    } else {
      const allowedDomainsList = clonedeep(allowedDomains);
      allowedDomainsList.splice(domainIndex, 1);
      this.setState({ allowedDomains: allowedDomainsList });
    }
  };

  renderDomains = (domainsList) => domainsList.map((curr, index) => {
    const isLastDomain = index === domainsList.length - 1 && index > 2; // Check if it's the last domain
    return (
      <div key={curr.key} ref={isLastDomain ? this.newDomainInputRef : null} >
        <DomainInput
          key={curr.key}
          domainIndex={index}
          domainName={curr.domainName}
          onDomainUpdated={this.onDomainUpdated}
          onDomainDeleted={this.onDomainDeleted}
          hasError={curr.hasError}
        />
      </div>
    );
  });

  renderSwitchDomainConfirmationDialog() {
    const { t } = this.props;
    const { showModal, isBlockedDomainsSelected } = this.state;
    const newDomain = isBlockedDomainsSelected ? t('cards.webinar.details.registration.restrictDomainsModal.allowedDomainText') : t('cards.webinar.details.registration.restrictDomainsModal.blockedDomainText');
    const prevDomain = isBlockedDomainsSelected ? t('cards.webinar.details.registration.restrictDomainsModal.blockedDomainText') : t('cards.webinar.details.registration.restrictDomainsModal.allowedDomainText');
    return (
      <AlertDialog
        alertType='warning'
        alertTitle={t('cards.webinar.details.registration.restrictDomainsModal.modal.confirmationTitle', { newDomain })}
        alertMessage={t('cards.webinar.details.registration.restrictDomainsModal.modal.confirmSwitchInfo', { newDomain, prevDomain })}
        buttons={[
          { title: t('cards.webinar.details.registration.restrictDomainsModal.modal.switch'), id: 'switchBtn', onClickHandler: this.handleDomainSwitchConfirm },
          { title: t('cards.webinar.details.registration.restrictDomainsModal.modal.cancel'), id: 'cancelBtn', onClickHandler: this.toggleDialog }
        ]}
        isDismissible
        isOpen={showModal}
        onDismiss={() => this.setState({ showModal: false })}
      />
    );
  }

  handleDomainSwitchConfirm = () => {
    this.setState({
      showModal: !this.state.showModal,
      isBlockedDomainsSelected: !this.state.isBlockedDomainsSelected,
      exceedsHeight: false
    }, () => {
      const { allowedDomains, blockedDomains, isBlockedDomainsSelected } = this.state;
      if (isBlockedDomainsSelected) {
        allowedDomains.length = 0;
        this.setState({ allowedDomains });
      } else {
        blockedDomains.length = 0;
        this.setState({ blockedDomains });
      }
    });
  }

  toggleDialog = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  setCloseWarningAlert() {
    const { isBlockedDomainsPresent, domainWhitelist, t } = this.props;
    const { isBlockedDomainsSelected, allowedDomains, blockedDomains, showCloseAlertDialog } = this.state;
    if (isBlockedDomainsSelected === isBlockedDomainsPresent && showCloseAlertDialog) {
      if (isBlockedDomainsSelected) {
        if (isequal(blockedDomains, domainWhitelist)) {
          this.onClose();
          return null;
        }
      } else if (isequal(allowedDomains, domainWhitelist)) {
        this.onClose();
        return null;
      }
    }
    return (
      <AlertDialog
        alertType='warning'
        alertTitle={t('cards.webinar.details.registration.restrictDomainsModal.onCloseWarning.confirmationTitle')}
        alertMessage={t('cards.webinar.details.registration.restrictDomainsModal.onCloseWarning.confirmationInfo')}
        buttons={[
          { title: t('cards.webinar.details.registration.restrictDomainsModal.onCloseWarning.cancel'), id: 'keepEditingBtn', onClickHandler: this.onCancelAlert },
          { title: t('cards.webinar.details.registration.restrictDomainsModal.onCloseWarning.confirm'), id: 'discardChangesBtn', onClickHandler: this.onClose }
        ]}
        isOpen={showCloseAlertDialog}
      />
    );
  }

  onCancelAlert = () => {
    this.setState({
      showCloseAlertDialog: !this.state.showCloseAlertDialog
    });
  }

  render() {
    const { t } = this.props;
    const { isBlockedDomainsSelected, allowedDomains, blockedDomains } = this.state;
    const hasError = (isBlockedDomainsSelected ? blockedDomains : allowedDomains).some((i) => i.hasError);
    return (
      <ResponsiveModal
        onClose={this.onCancelAlert}
        isOpen={true}
        title={t('cards.webinar.details.registration.restrictDomainsModal.title')}
        titleId='restrict_domains_modal_title'
        customContainerClass={styles.modal}
        primaryActionButton={{
          text: t('button.save'),
          onClick: this.onSave,
          id: 'restrict_domains_modal_save_details',
          disabled: hasError
        }}
        secondaryActionButton={{
          text: t('button.cancel'),
          onClick: this.onCancelAlert,
          id: 'restrict_domains_modal_cancel'
        }}
      >
        <div className={styles.container} id='restrict_domains_modal_content'>
          <div className={styles.domainsInfo} id='restrict_domains_modal_info'>{t('cards.webinar.details.registration.restrictDomainsModal.info')}</div>
          {this.renderAllowedDomains()}
          {this.renderBlockedDomains()}
        </div>

        {this.renderSwitchDomainConfirmationDialog()}
        {this.setCloseWarningAlert()}

      </ResponsiveModal>
    );
  }
}

export default withTranslation()(RestrictDomainsModal);
