import * as actions from './actions';
import { saveTranscriptEdit } from '../Transcript/actions';
import { TRANSCRIPT_PATH } from '../Transcript/reducer';

function shiftInStack(targetState, num) {
  const { index, stack } = targetState;
  const newIndex = index + num;

  return {
    ...targetState,
    previous: (stack[newIndex] && stack[newIndex].undo) || null,
    next: (stack[newIndex + 1] && stack[newIndex + 1].redo) || null,
    index: newIndex
  };
}

const initialStack = {
  index: -1,
  previous: null,
  next: null,
  stack: []
};

const initialState = {
  [TRANSCRIPT_PATH]: initialStack
};

export default (state = initialState, action) => {
  switch (action.type) {
    case String(actions.redo):
    case String(actions.undo): {
      const { target, num } = action.payload;
      const targetState = state[target];

      return {
        ...state,
        [target]: shiftInStack(targetState, num)
      };
    }

    case String(saveTranscriptEdit): {
      const { edit, original } = action.payload;
      const { index, stack } = state.transcript;

      // NOTE: If item is added to the stack while user has undone any edits,
      // reset the stack with only this incoming edit to limit stack size.
      if (index < stack.length - 1) {
        return {
          ...state,
          transcript: {
            index: 0,
            next: null,
            stack: [{ undo: original, redo: edit }],
            previous: original
          }
        };
      }

      return {
        ...state,
        transcript: {
          index: index + 1,
          next: null,
          stack: [...stack, { undo: original, redo: edit }],
          previous: original
        }
      };
    }

    default: {
      return state;
    }
  }
};
