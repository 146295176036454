import { createAsyncAction as createAsyncActionV2 } from 'redux-promise-middleware-actions';
import { getOrganizerWebinarSettings } from 'Api/Webinar/webinarService';
import { createAsyncAction } from '../reduxActions';

export const getOrganizerWebinarSettingsAction = createAsyncActionV2('GET_ORGANIZER_WEBINAR_SETTINGS', getOrganizerWebinarSettings);
export const updateOrganizerWebinarSettingsAction = createAsyncAction('PUT_ORGANIZER_WEBINAR_SETTINGS');
export const updateOrganizerAudioSettingsAction = createAsyncAction('UPDATE_ORGANIZER_AUDIO_SETTINGS');
export const getOrganizerAudioSettingsAction = createAsyncAction('GET_ORGANIZER_AUDIO_SETTINGS');
export const trackPaymentTosClickedAction = createAsyncAction('TRACK_PAYMENT_TOS_CLICKED');
export const trackPaymentConnectToStripeClickedAction = createAsyncAction('TRACK_PAYMENT_CONNECT_TO_STRIPE_CLICKED');

export function updateOrganizerWebinarSettings(accountKey, userKey, dataToBeUpdated) {
  return {
    type: updateOrganizerWebinarSettingsAction.toString(), accountKey, userKey, dataToBeUpdated
  };
}

export function updateOrganizerAudioSettings(audioDetails) {
  return { type: updateOrganizerAudioSettingsAction.toString(), audioDetails };
}

export function fetchOrganizerAudioSettings() {
  return { type: getOrganizerAudioSettingsAction.toString() };
}
