import config from 'appConfig';
import getHeaders from 'Api/Helpers/getHeaders';
import { fetchJson } from 'Api/Helpers/fetch';
import { POST } from 'Api/Helpers/httpMethods';

export function registerUserForWebNotifications(userKey, subscriptionData) {
  const registrationOffset = '/v1/registration';
  const notificationServiceUrl = config.featureService.url + registrationOffset;
  const authHeaders = getHeaders(true, 'Bearer');
  authHeaders['X-UserKey'] = userKey;

  const options = {
    headers: authHeaders,
    method: POST,
    body: JSON.stringify(subscriptionData)
  };
  return fetchJson(notificationServiceUrl, options, 'json');
}
