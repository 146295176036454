import { take, takeLatest, takeEvery } from 'redux-saga/effects';

export function* takeAction(actionName, func) {
  yield take(actionName, func);
}

export function* takeLatestAction(actionName, func) {
  yield takeLatest(actionName, func);
}

export function* takeEveryAction(actionName, func) {
  yield takeEvery(actionName, func);
}
