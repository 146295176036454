import { connect } from 'react-redux';
import MixpanelTracker from '../Mixpanel/mixpanelTracker';

const mixpanelTracker = new MixpanelTracker();

export const connectDispatch = (state = null) => connect(state, (dispatch) => ({ dispatch }));

export const mixpanelEvent = (eventBuilder) => (target, name, descriptor) => {
  const decoratee = descriptor.value;
  descriptor.value = function (...args) {
    const event = eventBuilder(this.props, ...args);

    if (event && event.name && event.properties) {
      mixpanelTracker.setEventName(event.name);
      mixpanelTracker.setEventProperties(event.properties);
      mixpanelTracker.sendEvent();
    }

    decoratee.apply(this, args);
  };
  return descriptor;
};

export const bound = (target, name, descriptor) => {
  if (!descriptor || (typeof descriptor.value !== 'function')) {
    throw new TypeError(`Only methods can be decorated with @bound. ${name} is not a method.`);
  }

  return {
    configurable: true,
    get() {
      const decoratee = descriptor.value.bind(this);
      Object.defineProperty(this, name, {
        value: decoratee,
        configurable: true,
        writable: true
      });
      return decoratee;
    }
  };
};
