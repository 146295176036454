import config from 'appConfig';
import { DELETE, GET, PUT } from './Helpers/httpMethods';
import { getBearerHeaders } from './Helpers/getHeaders';
import { getUser } from '.';
import { JSON_MIME_TYPE } from './Helpers/requestProperties';
import { fetchJson } from './Helpers/fetch';

export function getMessages(page = 0, pageSize = 8) {
  const url = `${config.featureService.url}/messagecenterservice/messages?userKey=${getUser().id}&productIdentifier=com.logmein.gotowebinar&page=${page}&pageSize=${pageSize}`;
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export async function getMessagesByTag(tag, page = 0, pageSize = 8) {
  const url = `${config.featureService.url}/messagecenterservice/messages?userKey=${getUser().id}&productIdentifier=com.logmein.gotowebinar&page=${page}&pageSize=${pageSize}&tag=${tag}`;
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };

  const payload = await fetchJson(url, options, JSON_MIME_TYPE);
  return {
    tag,
    data: payload
  };
}

export function updateMessage(body) {
  const url = `${config.featureService.url}/messagecenterservice/message`;
  const options = {
    headers: getBearerHeaders(),
    body: JSON.stringify(body),
    method: PUT
  };

  return fetchJson(url, options, JSON_MIME_TYPE, false);
}

export function deleteMessage(id) {
  const url = `${config.featureService.url}/messagecenterservice/message`;
  const body = {
    identifier: id,
    productIdentifier: 'com.logmein.gotowebinar',
    userKey: getUser().id
  };

  const options = {
    headers: getBearerHeaders(),
    body: JSON.stringify(body),
    method: DELETE
  };

  return fetchJson(url, options, JSON_MIME_TYPE, false);
}

export function getMessagesInfo() {
  const url = `${config.featureService.url}/messagecenterservice/messagesinfos?userKey=${getUser().id}&productIdentifier=com.logmein.gotowebinar`;
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };

  return fetchJson(url, options, JSON_MIME_TYPE, true);
}
