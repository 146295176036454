/* eslint-disable key-spacing */
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Survey from './Survey';
import { get } from '../../Utils/objectHelpers';

import { selectSurvey,
  selectDoesQuestionExist,
  selectIsSaveDisabled,
  selectSurveyQuestionsCopy,
  selectActiveQuestion,
  selectActiveChoices } from '../../Reducks/Survey/selectors';

import { createWebinarSurvey,
  addNewSurveyQuestion,
  addQuestionToSurvey,
  addSurveyChoice,
  deleteWebinarSurvey,
  dismissActiveQuestion,
  invalidateQuestion,
  moveSurveyQuestion,
  removeSurveyChoice,
  removeSurveyQuestion,
  setActiveSurveyQuestion,
  updateSurveyChoice,
  updateSurveyQuestion,
  updateSurveySettings,
  updateSurveyTitle } from '../../Reducks/Survey/actions';

const MAX_NUM_OF_QUESTIONS = 25;

const mapStateToProps = (state) => ({
  activeChoices    : get((state), (s) => s.survey.activeChoices),
  activeQuestionKey: get((state), (s) => s.survey.activeQuestionKey),
  choices          : selectActiveChoices(state),
  doesQuestionExist: selectDoesQuestionExist(state),
  isSaveDisabled   : selectIsSaveDisabled(state),
  isSurveyFound    : get((state), (s) => s.survey.status.code === 200),
  question         : selectActiveQuestion(state),
  settings         : get((state), (s) => s.survey.settings),
  survey           : selectSurvey(state),
  surveyQuestions  : selectSurveyQuestionsCopy(state),
  title            : get((state), (s) => s.survey.title),
  invalidFields    : get((state), (s) => s.survey.invalidFields)
});

const mapDispatchToProps = {
  addNewSurveyQuestion,
  addQuestionToSurvey,
  addSurveyChoice,
  deleteWebinarSurvey,
  dismissActiveQuestion,
  invalidateQuestion,
  moveSurveyQuestion,
  removeSurveyChoice,
  removeSurveyQuestion,
  createWebinarSurvey,
  setActiveSurveyQuestion,
  updateSurveySettings,
  updateSurveyChoice,
  updateSurveyQuestion,
  updateSurveyTitle
};

const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  ...props,
  showAddQuestionBtn: state.surveyQuestions.length < MAX_NUM_OF_QUESTIONS && !state.activeQuestionKey
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
);

const composed = compose(
  withTranslation(),
  connected
);

export default composed(Survey);
