import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { getReportingDetails } from '../../Api/reportingService';
import * as Actions from './actions';

function* fetchReportingDetails() {
  try {
    const data = yield call(getReportingDetails);
    yield put({ type: Actions.GET_REPORTING_DETAILS_COMPLETE, data });
  } catch (e) {
    yield put({
      type: Actions.GET_REPORTING_DETAILS_FAILURE,
      message: e.message
    });
  }
}

// ------------------------------------ WATCHERS -----------------------------------
export function* watchFetchReportingDetails() {
  yield takeLatest(Actions.GET_REPORTING_DETAILS_START, fetchReportingDetails);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchReportingDetails)
  ]);
}
