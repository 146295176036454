import { createAsyncAction } from '../reduxActions';

export const getInterprefyTokenAction = createAsyncAction('GET_INTERPREFY_TOKEN');
export const updateInterprefyTokenAction = createAsyncAction('PUT_INTERPREFY_TOKEN');

export function updateInterprefyToken(webinarKey, data, isSaving) {
  return {
    type: updateInterprefyTokenAction.toString(), webinarKey, data, isSaving
  };
}

export function getInterprefyToken(webinarKey) {
  return {
    type: getInterprefyTokenAction.toString(), webinarKey
  };
}
