export function createfileUploadPayload(uploadPolicy, fileMetaData, fileObject) {
  const formData = new FormData();
  formData.append('acl', uploadPolicy.uploadPolicies[0].params.acl);
  formData.append('Content-Type', uploadPolicy.uploadPolicies[0].params['Content-Type']);
  formData.append('key', uploadPolicy.uploadPolicies[0].params.objectkey);
  formData.append('Policy', uploadPolicy.uploadPolicies[0].params.policy);
  formData.append('Signature', uploadPolicy.uploadPolicies[0].params.signature);
  formData.append('AWSAccessKeyId', uploadPolicy.uploadPolicies[0].params.AWSAccessKeyId);
  formData.append('Content-Disposition', uploadPolicy.uploadPolicies[0].params['Content-Disposition']);
  formData.append('file', fileObject);
  return formData;
}

export function createHandoutPayload(uploadPolicy, fileMetaData, userWebinarInfo) {
  const createPayload = {
    accountkey: userWebinarInfo.accountKey,
    organizerkey: userWebinarInfo.userKey,
    webinarkey: userWebinarInfo.webinarKey,
    bytes: fileMetaData[0].bytes,
    filename: fileMetaData[0].fileName,
    filetype: fileMetaData[0].mimeType,
    objectkey: uploadPolicy.uploadPolicies[0].params.objectkey,
    status: 'SUCCESS',
    uploadphase: 'PRE-SESSION'
  };
  return createPayload;
}
