import mixpanel from 'mixpanel-browser';
import WebinarProperties from '../Reporting/webinarProperties';

function validate(context) {
  if (!(context instanceof Object)) {
    throw new Error('Cannot validate without a context object');
  }
  if (typeof context.eventName !== 'string') {
    throw new Error('Cannot send event without event name');
  }
  if (!(context.eventProperties instanceof Object)) {
    throw new Error('Cannot send event without event properties');
  }
}

/**
 * Adds webinar properties to event for corelatting event with the webinar that it belongs to.
 * The function relies on setting/clearing webinar properties on the webinar details component.
 * @param {*} eventProperty - The event property.
 */
function setWebinarProperties(eventProperty) {
  if (WebinarProperties.webinarKey) {
    eventProperty.webinarKey = WebinarProperties.webinarKey;
  }
  if (WebinarProperties.webinarName) {
    eventProperty.webinarName = WebinarProperties.webinarName;
  }
}

export default class MixpanelTracker {
  setEventName(eventName) {
    this.eventName = eventName;
  }

  setEventProperties(eventProperty) {
    this.eventProperties = eventProperty;
    setWebinarProperties(this.eventProperties);
  }

  sendEvent(sendToCrossProject = false) {
    if (mixpanel.has_opted_out_tracking()) {
      return;
    }

    validate(this);
    mixpanel.track(this.eventName, this.eventProperties);

    if ((sendToCrossProject === true) && mixpanel.crossProduct) {
      mixpanel.crossProduct.track(this.eventName, this.eventProperties);
    }
  }
}
