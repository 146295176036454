import { call, put, all, fork } from 'redux-saga/effects';
import { takeLatestAction } from 'Utils/sagaUtils';
import { onPollsAddedToSessionSagaTracker,
  onPollsDeletedFromSessionSagaTracker } from 'Mixpanel/Features/VirtualEventDetails/engagementDetailsTracker';
import * as pollService from '../../Api/Webinar/Poll/pollService';
import { SEND_NOTIFICATION } from '../Notification/actions';
import { retrieveWebinarPollsAction,
  createWebinarPollAction,
  deleteWebinarPollAction,
  updateWebinarPollAction,
  updateWebinarPollOrderAction } from './actions';

function* createWebinarPoll(webinar) {
  try {
    let data = yield call(pollService.createWebinarPoll, webinar.webinarKey, webinar.poll);
    onPollsAddedToSessionSagaTracker(webinar.webinarKey, webinar.poll, 'Success');
    yield put({ type: createWebinarPollAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.saved', isError: false });

    data = yield call(pollService.retrieveWebinarPoll, webinar.webinarKey);
    yield put({ type: retrieveWebinarPollsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: createWebinarPollAction.failed.toString(),
      error
    });
    onPollsAddedToSessionSagaTracker(webinar.webinarKey, webinar.poll, 'Failure');
    switch (error.body.validationErrorCodes[0].code) {
      case 'api.PollAndSurveyQuestionResource.questionTextXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.invalidQuestionTextFail', isError: true });
        break;
      case 'api.PollAndSurveyQuestionResource.answersTextXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.invalidAnswerTextFail', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.failed', isError: true });
    }
  }
}

function* retreiveWebinarPoll(webinar) {
  try {
    const data = yield call(pollService.retrieveWebinarPoll, webinar.webinarKey);
    yield put({ type: retrieveWebinarPollsAction.complete.toString(), data });
  } catch (error) {
    yield put({
      type: retrieveWebinarPollsAction.failed.toString(),
      error
    });
  }
}

function* deleteWebinarPoll(webinar) {
  try {
    let data = yield call(pollService.deleteWebinarPoll, webinar.webinarKey, webinar.pollKey);
    onPollsDeletedFromSessionSagaTracker(webinar.webinarKey, webinar.pollKey, 'Success');
    yield put({ type: deleteWebinarPollAction.complete.toString(), data });

    data = yield call(pollService.retrieveWebinarPoll, webinar.webinarKey);
    yield put({ type: retrieveWebinarPollsAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.removed', isError: false });
  } catch (error) {
    yield put({
      type: deleteWebinarPollAction.failed.toString(),
      error
    });
    onPollsDeletedFromSessionSagaTracker(webinar.webinarKey, webinar.pollKey, 'Failure');
    yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.removeFailed', isError: true });
  }
}

function* updateWebinarPoll(webinar) {
  try {
    const pollKey = webinar.poll.pollKey;
    const webinarPoll = webinar.poll;
    delete webinarPoll.pollKey;

    let data = yield call(pollService.updateWebinarPoll, webinar.webinarKey, pollKey, webinarPoll);
    yield put({ type: updateWebinarPollAction.complete.toString(), data });

    data = yield call(pollService.retrieveWebinarPoll, webinar.webinarKey);
    yield put({ type: retrieveWebinarPollsAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.updated', isError: false });
  } catch (error) {
    yield put({
      type: updateWebinarPollAction.failed.toString(),
      error
    });
    switch (error.body.validationErrorCodes[0].code) {
      case 'api.PollAndSurveyQuestionResource.questionTextXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.invalidQuestionTextFail', isError: true });
        break;
      case 'api.PollAndSurveyQuestionResource.answersTextXssNotAllowed':
        yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.invalidAnswerTextFail', isError: true });
        break;
      default:
        yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.updateFailed', isError: true });
    }
  }
}

function* updateWebinarPollOrder(webinar) {
  try {
    let data = yield call(pollService.updateWebinarPollOrder, webinar.webinarKey, webinar.pollQuestions);
    yield put({ type: updateWebinarPollOrderAction.complete.toString(), data });
    data = yield call(pollService.retrieveWebinarPoll, webinar.webinarKey);

    yield put({ type: retrieveWebinarPollsAction.complete.toString(), data });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.orderChanged', isError: false });
  } catch (error) {
    yield put({
      type: updateWebinarPollOrderAction.failed.toString(),
      error
    });
    yield put({ type: SEND_NOTIFICATION, messageKey: 'poll.notification.orderChangeFailed', isError: true });
  }
}

export default function* rootSaga() {
  yield all([
    fork(takeLatestAction.bind(this, createWebinarPollAction.toString(), createWebinarPoll)),
    fork(takeLatestAction.bind(this, retrieveWebinarPollsAction.toString(), retreiveWebinarPoll)),
    fork(takeLatestAction.bind(this, deleteWebinarPollAction.toString(), deleteWebinarPoll)),
    fork(takeLatestAction.bind(this, updateWebinarPollAction.toString(), updateWebinarPoll)),
    fork(takeLatestAction.bind(this, updateWebinarPollOrderAction.toString(), updateWebinarPollOrder))
  ]);
}
