import { asyncReducer } from 'redux-promise-middleware-actions';
import { addContentToChannelAction, addWebinarToChannelAction } from 'Reducks/Channel/actions';
import { getChannelForContentsAction,
  createAndUpdateChannelAction,
  getUserChannelsAction,
  getChannelUserSettingsAction,
  updateShowNewVideoAlertAction,
  getChannelContentsAction,
  getChannelForContentKeyAction,
  getContentKeyForWebinarAction,
  updateChannelAssociationAction,
  deleteChannelAction } from './actions';

const defaultState = {
  contentChannels: {},
  channels: {},
  contents: {},
  webinarChannel: {},
  userSettings: {
    data: {
      g2sNumberOfChannelsAllowed: 0
    }
  },
  ui: {
    showNewVideoAlert: true
  }
};

const channelsReducer = asyncReducer(getUserChannelsAction);
const channelUserSettingsReducer = asyncReducer(getChannelUserSettingsAction);

export default (state = defaultState, action) => {
  const newState = {
    ...state,
    channels: channelsReducer(state.channels, action),
    userSettings: channelUserSettingsReducer(state.userSettings, action)
  };
  switch (action.type) {
    case getChannelForContentsAction.fulfilled.toString(): {
      const newContentChannels = { ...newState.contentChannels };
      action.payload.forEach((c) => {
        newContentChannels[c.contentKey] = c;
      });

      return {
        ...newState,
        contentChannels: newContentChannels
      };
    }

    case updateShowNewVideoAlertAction.toString(): {
      return {
        ...newState,
        ui: {
          ...newState.ui,
          showNewVideoAlert: action.payload
        }
      };
    }

    case getChannelContentsAction.fulfilled.toString(): {
      return {
        ...newState,
        contents: {
          ...newState.contents,
          [action.meta.channelKey]: action.payload
        }
      };
    }

    case deleteChannelAction.fulfilled.toString(): {
      const channels = newState.channels.data || [];
      return {
        ...newState,
        channels: {
          ...newState.channels,
          data: channels.filter((channel) => channel.channelKey !== action.payload.channelKey)
        }
      };
    }

    case getChannelForContentKeyAction.fulfilled.toString(): {
      let webinarChannel = {};
      if (action.payload.length > 0) {
        webinarChannel = {
          contentKey: action.payload[0].contentKey,
          channelKey: action.payload[0].channelKey,
          channelTitle: action.payload[0].channelTitle,
          channelLogo: action.payload[0].channelLogo
        };
      }
      return {
        ...newState,
        webinarChannel
      };
    }

    case getContentKeyForWebinarAction.fulfilled.toString(): {
      let webinarChannel = {};
      if (action.payload.length > 0) {
        webinarChannel = {
          contentKey: action.payload[0].id
        };
      }
      return {
        ...newState,
        webinarChannel
      };
    }

    case addWebinarToChannelAction.fulfilled.toString(): {
      let webinarChannel = {};
      const filteredChannel = newState.channels.data.filter((channel) => channel.channelKey === action.payload.channelKey);
      const channelTitle = filteredChannel[0].channelTitle;

      webinarChannel = {
        channelKey: action.payload.channelKey,
        contentKey: action.payload.contentKey,
        channelTitle
      };

      return {
        ...newState,
        webinarChannel
      };
    }

    case addContentToChannelAction.fulfilled.toString(): {
      let webinarChannel = {};
      const filteredChannel = newState.channels.data.filter((channel) => channel.channelKey === action.meta.content);
      const channelTitle = filteredChannel[0].channelTitle;

      webinarChannel = {
        channelKey: action.meta.content,
        contentKey: action.meta.contentKey.contentKeys[0],
        channelTitle
      };

      return {
        ...newState,
        webinarChannel
      };
    }

    case updateChannelAssociationAction.fulfilled.toString(): {
      const webinarChannel = { contentKey: newState.webinarChannel.contentKey };
      return {
        ...newState,
        webinarChannel
      };
    }
    case createAndUpdateChannelAction.fulfilled.toString(): {
      const channel = {
        channelKey: action.payload,
        channelTitle: action.meta.title,
        channelDescription: action.meta.description
      };
      const channelsData = Object.assign([], newState.channels.data || []);
      channelsData.push(channel);
      return {
        ...newState,
        channels: {
          ...newState.channels,
          data: channelsData
        }
      };
    }

    default: {
      return newState;
    }
  }
};
