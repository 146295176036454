import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TooltipIcon from '@getgo/chameleon-react/dist/TooltipIcon';
import { toggleModal } from 'Reducks/Ui/actions';
import { connectDispatch } from 'Utils/decorators';
import { withTranslation } from 'react-i18next';
import { FEATURE_UNLOCK } from '../../../Modals/constants';
import styles from './styles.scss';
import liveIcon from '../Assets/live.svg';
import recordedIcon from '../Assets/recorded.svg';
import classicIcon from '../Assets/standard.svg';
import webcastIcon from '../Assets/webcast.svg';
import breakoutsIcon from '../Assets/breakouts.svg';
import recordedGreyIcon from '../Assets/recorded-grey.svg';
import classicGreyIcon from '../Assets/standard-grey.svg';
import webcastGreyIcon from '../Assets/webcast-grey.svg';

export const WEBINAR_TYPES = {
  CLASSIC: 'CLASSIC',
  WEBCAST: 'BROADCAST',
  SIMULIVE: 'SIMULIVE',
  VIRTUAL: 'VIRTUAL'
};

export const WEBINAR_SELECTION = {
  LIVE: 'LIVE',
  RECORDED: 'RECORDED',
  VIRTUAL: 'VIRTUAL'
};

@connectDispatch()
class WebinarType extends PureComponent {
  static propTypes = {
    onWebinarSelectionChanged: PropTypes.func,
    webinarSelection: PropTypes.string,
    isWebinarType: PropTypes.bool,
    question: PropTypes.string,
    webcastEnabled: PropTypes.bool,
    breakoutsEnabled: PropTypes.bool,
    onDemandEnabled: PropTypes.bool,
    webCastLimit: PropTypes.number,
    webCastMaxLimit: PropTypes.number,
    breakoutsMaxLimit: PropTypes.number,
    classicLimit: PropTypes.number,
    classicMaxLimit: PropTypes.number,
    standardBroadCastEnabled: PropTypes.bool,
    shouldShow: PropTypes.bool,
    dispatch: PropTypes.func,
    isVideoLibraryEnabled: PropTypes.bool,
    t: PropTypes.func,
    isVirtualEnabled: PropTypes.bool,
    breakoutsSelected: PropTypes.bool
  };

  static defaultProps = {
    shouldShow: true
  };

  onSelected = (webinarSelection, enabled, breakoutsEnabled) => {
    if (enabled) {
      this.props.onWebinarSelectionChanged(webinarSelection, breakoutsEnabled);
    } else {
      this.props.dispatch(toggleModal({ id: FEATURE_UNLOCK }));
    }
  };

  renderBox = (webinarSelection, title, description, icon, enabled, breakoutsEnabled, isSelected) => {
    const selected = enabled && isSelected;
    const imgId = `schedule_type_selector_icon_${webinarSelection.toLowerCase()}`;
    return (
      <button
        id={`schedule_type_selector_${webinarSelection.toLowerCase()}`}
        className= {classNames(selected && styles.boxSelected, !enabled && styles.boxLocked, styles.box)}
        onClick={() => this.onSelected(webinarSelection, enabled, breakoutsEnabled)}
      >
        <i className={classNames(styles.lockIcon, 'togo-icon togo-icon-lock-closed')} />
        {icon
          && <img id={imgId} src={icon} alt={title} className={styles.iconStyle} />
        }
        <label htmlFor={imgId} className={styles.titleText}>{title} </label>
        <label htmlFor={imgId} className={styles.hintText}>{description} </label>
        <TooltipIcon id={`schedule_tooltip_${webinarSelection.toLowerCase()}`} description={description} containerClassName={styles.iconToolTip}/>
      </button>
    );
  };

  renderSelection() {
    const { onDemandEnabled, isVideoLibraryEnabled, isVirtualEnabled, webinarSelection } = this.props;
    const { t } = this.props;
    return (
      <Fragment>
        {this.renderBox(WEBINAR_SELECTION.LIVE, t('schedule.live'), t('schedule.liveTooltip'), liveIcon, true, false, WEBINAR_SELECTION.LIVE === webinarSelection)}
        {isVideoLibraryEnabled && this.renderBox(WEBINAR_SELECTION.RECORDED, t('schedule.onDemand'), t('schedule.onDemandNewTooltip'), onDemandEnabled ? recordedIcon : recordedGreyIcon, onDemandEnabled, false, WEBINAR_SELECTION.RECORDED === webinarSelection)}
        {isVirtualEnabled && this.renderBox(WEBINAR_SELECTION.VIRTUAL, t('schedule.virtual'), t('schedule.virtualEventTooltip'), '', isVirtualEnabled, false, WEBINAR_SELECTION.VIRTUAL === webinarSelection) }
      </Fragment>
    );
  }

  renderWebinarType() {
    const { t } = this.props;
    const { standardBroadCastEnabled, webcastEnabled, breakoutsEnabled, webCastLimit, webCastMaxLimit, classicLimit, classicMaxLimit,
      breakoutsMaxLimit, breakoutsSelected, webinarSelection } = this.props;

    return (
      <Fragment>
        {this.renderBox(WEBINAR_TYPES.CLASSIC, t('schedule.classic'), standardBroadCastEnabled ? t('schedule.classicTooltip', { limit: classicLimit.toCurrentLocaleString() }) : t('schedule.classicTooltip', { limit: classicMaxLimit.toCurrentLocaleString() }), standardBroadCastEnabled ? classicIcon : classicGreyIcon, standardBroadCastEnabled, false, WEBINAR_TYPES.CLASSIC === webinarSelection && !breakoutsSelected)}
        {breakoutsEnabled && standardBroadCastEnabled && this.renderBox(WEBINAR_TYPES.CLASSIC, t('schedule.breakouts'), t('schedule.breakoutsTooltip', { limit: breakoutsMaxLimit.toCurrentLocaleString() }), breakoutsIcon, true, true, WEBINAR_TYPES.CLASSIC === webinarSelection && breakoutsSelected)}
        {this.renderBox(WEBINAR_TYPES.WEBCAST, t('schedule.webcast'), webcastEnabled ? t('schedule.webcastTooltip', { limit: webCastLimit.toCurrentLocaleString() }) : t('schedule.webcastTooltip', { limit: webCastMaxLimit.toCurrentLocaleString() }), webcastEnabled ? webcastIcon : webcastGreyIcon, webcastEnabled, false, WEBINAR_TYPES.WEBCAST === webinarSelection)}
      </Fragment>
    );
  }

  render() {
    const { shouldShow, question, isWebinarType, breakoutsEnabled } = this.props;
    if (!shouldShow) {
      return null;
    }
    return (
      <div className={styles.container}>
        <p className={styles.question}>{question}</p>
        <div className={classNames(styles.boxContainer, (isWebinarType && breakoutsEnabled) && styles.compactContainer)}>
          { isWebinarType ? this.renderWebinarType() : this.renderSelection() }
        </div>
      </div>
    );
  }
}

export default withTranslation()(WebinarType);
