import { createAsyncAction, createAction } from '../reduxActions';

export const getRecordingAction = createAsyncAction('GET_RECORDINGS');
export const searchRecordingAction = createAsyncAction('SEARCH_RECORDINGS');
export const resetSearchedRecordingAction = createAsyncAction('RESET_SEARCHED_RECORDINGS');
export const getViewsAction = createAsyncAction('GET_VIEWS');
export const updateContentAction = createAsyncAction('UPDATE_RECORDING_TITLE');
export const deleteRecordingAction = createAsyncAction('DELETE_RECORDING');
export const createRecordingAction = createAsyncAction('CREATE_RECORDING');
export const uploadRecordingProgressAction = createAction('UPLOAD_RECORDING_PROGRESS');
export const pollRecordingStatus = createAction('POLL_RECORDING_STATUS');

export function fetchRecordings(accountKey, page) {
  return { type: getRecordingAction.toString(), accountKey, page };
}

export function searchRecordings(accountKey, page, search) {
  return { type: searchRecordingAction.toString(), accountKey, page, search };
}

export function resetSearchedRecordings() {
  return { type: resetSearchedRecordingAction.toString() };
}

export function fetchViews(recordingKeys) {
  return { type: getViewsAction.toString(), recordingKeys };
}

export function updateContent(contentId, details) {
  return { type: updateContentAction.toString(), contentId, details };
}

export function deleteRecording(assetKey) {
  return { type: deleteRecordingAction.toString(), assetKey };
}

export function createRecording(file, accountKey, resources) {
  return { type: createRecordingAction.toString(), file, accountKey, resources };
}

export const updateRecordingStatus = createAction(
  'UPDATE_RECORDING_STATUS',
  (recording, terminate) => ({ recording, terminate })
);
