/* eslint-disable */
import moment from 'moment';
import {getAppState} from '../Api/applicationStateService.js';
import {getWebinars} from '../Api/Webinar/webinarService.js'

async function getWebinarCount(){
  return await getWebinars({experienceType: 'all', includes: 'activesessionsinfo'});
}

async function getAppstateObj () {
  return await getAppState();
}

export const pendoObject={
  visitor: {
    id: "",
    g2w_visitor_id: "",
    g2w_user_locale:"",
    g2w_new_user: "",
    g2w_entitled_seats: "",
    g2w_schedledV2Enabled: "",
    g2w_hasCreatedChannel: "",
    g2w_hasPublishedVideo:"",
    g2w_hasScheduledWebinar:"",
    g2w_hasRunLiveSession:"",
    g2w_hasRunPracticeSession:"",
    g2w_isTranscriptsEnabled:"",
    g2w_upcomingWebinarsCount:"",
    g2w_registrationLimitReached:"",
    g2w_attendeeLimitReached:"",
  },
  account: {
    id: "",
    g2w_account_id: "",
    g2w_service_type: "",
    g2w_created_at_date: "",
    g2w_account_age_days: "",
    g2w_provision_channel: "",
    g2w_plan_start_date: "",
    g2w_product_full_name: "",
    g2w_trial_days_remaining: "",
  }
}

function getAccountsAgeInDays(date) {
  const end = moment(date);
  const start = moment();
  return Math.round(moment.duration(start.diff(end)).asDays());
}

export function pendoUpdateOptions(key,value) {
  if (pendo && pendo.isReady && pendo.isReady()) {
    pendoObject.visitor[key]=value;
      return pendo.updateOptions(pendoObject);
  } 
  setTimeout(function() {
    pendoUpdateOptions(data);
  }, 5000);
}
export const pendoInitialize = (user, metadata) => {
  const accountAgeDays = getAccountsAgeInDays(user.meta.created);
  const tempStartDate = new Date(Number(metadata.tenureStartDate));
  const startDate = `${tempStartDate.getDate()}/${tempStartDate.getMonth()}/${tempStartDate.getFullYear()}`;
  const newUser = (moment(user.meta.created)).isAfter(moment('2018-09-04T07:00:00.000Z'));
  const upcomingWebinarsCount = getWebinarCount();
  const appStateObject = getAppstateObj();
  appStateObject.then(response=>{
  let pendoData = response && response.data && response.data.appcues ? response.data.appcues : "";
  upcomingWebinarsCount.then(res=>{
     let totalWebinars = res && res.total ? res.total : 0; 
      pendoObject.visitor.id= metadata.userKey;
      pendoObject.visitor.g2w_visitor_id= metadata.userKey;
      pendoObject.visitor.g2w_user_locale= user.locale;
      pendoObject.visitor.g2w_new_user= newUser;
      pendoObject.visitor.g2w_entitled_seats= metadata.seats;
      pendoObject.visitor.g2w_schedledV2Enabled= pendoData.schedledV2Enabled || false;
      pendoObject.visitor.g2w_hasPublishedVideo= pendoData.hasPublishedVideo || false;
      pendoObject.visitor.g2w_hasCreatedChannel= pendoData.hasCreatedChannel || false;
      pendoObject.visitor.g2w_hasScheduledWebinar= pendoData.hasScheduledWebinar|| false;
      pendoObject.visitor.g2w_hasRunLiveSession= pendoData.hasRunLiveSession || false;
      pendoObject.visitor.g2w_hasRunPracticeSession= pendoData.hasRunPracticeSession||false;
      pendoObject.visitor.g2w_upcomingWebinarsCount= totalWebinars||0;
      pendoObject.visitor.g2w_isTranscriptsEnabled= pendoData.isTranscriptsEnabled || false;
      pendoObject.visitor.g2w_registrationLimitReached= pendoData.registrationLimitReached||false;
      pendoObject.visitor.g2w_attendeeLimitReached= pendoData.attendeeLimitReached||false;
      pendoObject.account.id= metadata.accountKey;
      pendoObject.account.g2w_account_id= metadata.accountKey;
      pendoObject.account.g2w_service_type= metadata.serviceType;
      pendoObject.account.g2w_created_at_date= user.meta.created;
      pendoObject.account.g2w_account_age_days= accountAgeDays;
      pendoObject.account.g2w_provision_channel= metadata.channel;
      pendoObject.account.g2w_plan_start_date= startDate;
      pendoObject.account.g2w_product_full_name= metadata.description;
      pendoObject.account.g2w_trial_days_remaining= sessionStorage.getItem('trialDaysRemaining');
      pendo.initialize(pendoObject);
    })
})
};

export const setUpPendo = (user, metadata) => {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v; let w; let x; let y; let z; o = p[d] = p[d] || {}; o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
        }(v[w]));
      }
      y = e.createElement(n); y.async = !0; y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
    }(window, document, 'script', 'pendo'));
    pendoInitialize(user, metadata);
  }('e678ed40-23a1-41c0-69f5-cf804e8df8fd'));
};