import React, { PureComponent } from 'react';
import MainRoute from 'Routes/main';
import filestackHelper from 'Utils/filestackHelper';
import ClipboardJS from 'clipboard';
import { setAnonymousId, getAnonymousId, setUserSessionId, getUserSessionId } from 'Reporting/userProperties';
import BodyStyles from './bodyStyles';
import { setUserFrontendSessionId, setUserType, setAnonymousUserKey } from '../../MessageBus/messageBusEventCreator';
import * as UserTypeConstants from '../../MessageBus/Constants/userTypeConstants';
import ModalRoot from '../ModalRoot';
import Notification from '../Notification';
import styles from './styles.scss';
import reportReactError from '../../Utils/sentryUtils';

export default class AppContainer extends PureComponent {
  clipboard = new ClipboardJS('.copy-text-clipboard');

  constructor(props) {
    super(props);
    setAnonymousId();
    setAnonymousUserKey(getAnonymousId());
    setUserSessionId();
    setUserFrontendSessionId(getUserSessionId());
    setUserType(UserTypeConstants.ANONYMOUS);
    this.initializeFilestack();
  }

  componentDidMount() {
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', () => {
      document.body.classList.remove('using-mouse');
    });
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  componentDidCatch(error, errorInfo) {
    reportReactError(error, errorInfo);
  }

  initializeFilestack() {
    filestackHelper.initialize();
  }

  render() {
    return (
      <div className={styles.app}>
        <BodyStyles />
        <Notification />
        <MainRoute />
        <ModalRoot />
      </div>
    );
  }
}

