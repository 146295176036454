import { asyncReducer } from 'redux-promise-middleware-actions';
import { USER_LOGOUT } from 'Reducks/Auth/actions';
import { toggleIpdCard, getEngagementData } from './actions';

const defaultState = {
  isCollapsed: true,
  engagementData: {}
};
const engagementDataReducer = asyncReducer(getEngagementData);

export default (state = defaultState, action) => {
  const newState = {
    ...state,
    engagementData: engagementDataReducer(state.engagementData, action)
  };

  switch (action.type) {
    case toggleIpdCard.toString(): {
      return {
        ...newState,
        isCollapsed: action.payload.isCollapsed
      };
    }
    case USER_LOGOUT: {
      return {
        ...newState,
        isCollapsed: true
      };
    }
    default: {
      return newState;
    }
  }
};
