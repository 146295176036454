import { SpeakerUpdatePath } from 'Components/Card/VirtualEvent/Details/About/constants';
import { onChangeOfNoOfSpeakersTracker, onChangeOfNoOfOrganizersTracker, onChangeOfNoOfPanelistsTracker } from 'Mixpanel/Features/VirtualEventDetails/VirtualEventDetailsTracker';
import { setLocalStorage, clearLocalStorage } from 'Utils/localStorage';
import { createVirtualEventPanelistAction, getVirtualWebinarAction, updateVirtualEventDetailsAction, deleteVirtualEventPanelistAction,
  addOrganizerToVirtualEventAction, deleteOrganizerToVirtualEventAction, updateOrganizerToVirtualEventAction, updateVirtualEventPanelistAction,
  PANELISTS_UPDATED, deleteVirtualEventAction, ORGANIZERS_UPDATED, CLEAR_ERROR_STATES, getVirtualEventRegistrationQuestionAction,
  updateVERegistrationQuestionAction, updateVERegistrationSettingsAction, setVELiveStreamingConfigAction, updateVELiveStreamingChannelConfigAction,
  getAllVELiveStreamingConfigurationAction, createVEBroadcastSessionAction, stopVEBroadcastSessionAction, getVEActiveTargetsStatusAction,
  getVEBroadcastStatusAction, getVELiveStreamingConfigAction, linkedInVEReloginAction, resetlinkedInErrStatusAction, updateConferenceDetailsAction } from './actions';
import { localStorageKey } from '../../Components/Card/VirtualEvent/Details/Session/GoLive/constants';

const defaultState = {
  image: null,
  companyBio: '',
  questions: null,
  isUpdatingVirtualEvent: false,
  isCancellingVirtualEvent: false,
  error: '',
  locale: '',
  timeZone: '',
  subject: '',
  registrationCount: {},
  registrationUrl: '',
  autoRecord: true,
  organizers: [],
  panelists: [],
  companyUrls: { website: '', twitter: '', facebook: '', linkedin: '' },
  panelistsUpdated: false,
  approvalType: '',
  organizersUpdated: false,
  liveStreamConfigurations: {},
  linkedInErrStatus: {},
  deletedSessions: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    // to get virtual event

    case getVirtualWebinarAction.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: true
      };
    }
    case getVirtualWebinarAction.complete.toString(): {
      return {
        ...state,
        ...action.data,
        isUpdatingVirtualEvent: false
      };
    }
    case getVirtualWebinarAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        isUpdatingVirtualEvent: false
      };
    }

    // to delete virtual event

    case deleteVirtualEventAction.toString(): {
      return {
        ...state,
        isCancellingVirtualEvent: true
      };
    }
    case deleteVirtualEventAction.complete.toString(): {
      return {
        ...defaultState,
        isCancellingVirtualEvent: false
      };
    }
    case deleteVirtualEventAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        isCancellingVirtualEvent: false
      };
    }

    // to update virtual event
    case updateVirtualEventDetailsAction.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: true
      };
    }
    case updateVirtualEventDetailsAction.complete.toString(): {
      return {
        ...state,
        ...action.data,
        isUpdatingVirtualEvent: false

      };
    }
    case updateVirtualEventDetailsAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        isUpdatingVirtualEvent: false
      };
    }

    // add organizer to virtual event
    case addOrganizerToVirtualEventAction.toString(): {
      return {
        ...state,
        isUpdatingOrganizers: true
      };
    }
    case addOrganizerToVirtualEventAction.complete.toString(): {
      const organizers = [...state.organizers, action.data];
      onChangeOfNoOfOrganizersTracker({ eventKey: state.eventKey, noOfOrganizers: organizers.length });
      return {
        ...state,
        organizers,
        isUpdatingOrganizers: false
      };
    }
    case addOrganizerToVirtualEventAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        isUpdatingOrganizers: false
      };
    }

    // delete organizer to virtual event
    case deleteOrganizerToVirtualEventAction.toString(): {
      return {
        ...state,
        isUpdatingOrganizers: true
      };
    }
    case deleteOrganizerToVirtualEventAction.complete.toString(): {
      const organizers = state.organizers.filter((organizer) => organizer.organizerKey !== action.data);
      onChangeOfNoOfOrganizersTracker({ eventKey: state.eventKey, noOfOrganizers: organizers.length });
      const noOfSpeakers = state.panelists ? state.panelists.length : 0 + organizers.filter((organizer) => organizer.displayPanelistInfo).length;
      onChangeOfNoOfSpeakersTracker({ eventKey: state.eventKey, noOfSpeakers });
      return {
        ...state,
        organizers,
        isUpdatingOrganizers: false
      };
    }
    case deleteOrganizerToVirtualEventAction.failed.toString(): {
      return {
        ...state,
        error: action.message,
        isUpdatingOrganizers: false
      };
    }

    // update organizer to virtual event
    case updateOrganizerToVirtualEventAction.toString(): {
      return {
        ...state,
        isUpdatingOrganizers: true
      };
    }
    case updateOrganizerToVirtualEventAction.complete.toString(): {
      const payload = action.payload;
      const feildName = payload.fieldName;
      const organizerKey = payload.organizerKey;
      const value = payload.data;
      const index = state.organizers ? state.organizers.findIndex((organizer) => organizer.organizerKey === organizerKey) : -1;
      let newOrganizers = state.organizers ? Array.from(state.organizers) : [];
      let newSpeaker = null;
      if (index !== -1) {
        if (feildName === SpeakerUpdatePath.displayPanelistInfo) {
          newSpeaker = { ...state.organizers[index], displayPanelistInfo: value };
        } else if (feildName === SpeakerUpdatePath.jobTitle) {
          newSpeaker = { ...state.organizers[index], panelistInfo: { ...state.organizers[index].panelistInfo, jobTitle: value } };
        } else if (feildName === SpeakerUpdatePath.organization) {
          newSpeaker = { ...state.organizers[index], panelistInfo: { ...state.organizers[index].panelistInfo, organization: value } };
        } else if (feildName === SpeakerUpdatePath.bio) {
          newSpeaker = { ...state.organizers[index], panelistInfo: { ...state.organizers[index].panelistInfo, bio: value } };
        } else if (feildName === SpeakerUpdatePath.avatar) {
          newSpeaker = { ...state.organizers[index], panelistInfo: { ...state.organizers[index].panelistInfo, avatar: { url: value } } };
        } else if (feildName === SpeakerUpdatePath.urls) {
          const data = payload.data;
          let urls = state.organizers[index].panelistInfo.urls;
          data.forEach((item) => {
            if (item.path === SpeakerUpdatePath.website) {
              urls = { ...urls, website: item.value };
            } else if (item.path === SpeakerUpdatePath.twitter) {
              urls = { ...urls, twitter: item.value };
            } else if (item.path === SpeakerUpdatePath.linkedin) {
              urls = { ...urls, linkedin: item.value };
            } else if (item.path === SpeakerUpdatePath.facebook) {
              urls = { ...urls, facebook: item.value };
            }
          });
          newSpeaker = { ...state.organizers[index], panelistInfo: { ...state.organizers[index].panelistInfo, urls: { ...urls } } };
        }
        newOrganizers = [
          ...state.organizers.slice(0, index),
          newSpeaker,
          ...state.organizers.slice(index + 1)
        ];
      }

      if (feildName === SpeakerUpdatePath.displayPanelistInfo) {
        const noOfSpeakers = state.panelists ? state.panelists.length : 0 + newOrganizers.filter((organizer) => organizer.displayPanelistInfo).length;
        onChangeOfNoOfSpeakersTracker({ eventKey: state.eventKey, noOfSpeakers });
      }

      return {
        ...state,
        organizers: newOrganizers
      };
    }
    case updateOrganizerToVirtualEventAction.failed.toString(): {
      const organizerKey = action.data.organizerKey;
      const index = state.organizers ? state.organizers.findIndex((organizer) => organizer.organizerKey === organizerKey) : -1;
      const value = action.data.data;
      let newOrganizers = state.organizers ? Array.from(state.organizers) : [];
      let newSpeaker = null;
      const [field] = action.data.fieldName.split('/').slice(-1);
      if (index !== -1) {
        let errorObj;
        const urls = state.organizers[index].panelistInfo.urls;
        if (action.data.fieldName === SpeakerUpdatePath.urls) {
          const urlKeys = { website: false, facebook: false, twitter: false, linkedin: false };
          (action.data.data).forEach((item) => {
            const key = item.path.split('/').slice(-1);
            urlKeys[key] = true;
          });
          errorObj = { ...state.organizers[index].error, urls: urlKeys };
          newSpeaker = { ...state.organizers[index], panelistInfo: { ...state.organizers[index].panelistInfo, urls: { ...urls } }, error: errorObj };
        } else if (action.data.fieldName === SpeakerUpdatePath.avatar) {
          errorObj = { ...state.organizers[index].error, avatar: true };
          newSpeaker = { ...state.organizers[index], panelistInfo: { ...state.organizers[index].panelistInfo, avatar: { url: value } }, error: errorObj };
        } else {
          errorObj = { ...state.organizers[index].error, [field]: true };
          newSpeaker = { ...state.organizers[index], panelistInfo: { ...state.organizers[index].panelistInfo, [field]: value }, error: errorObj };
        }
        newOrganizers = [
          ...state.organizers.slice(0, index),
          newSpeaker,
          ...state.organizers.slice(index + 1)
        ];
      }
      return {
        ...state,
        organizers: newOrganizers
      };
    }

    case createVirtualEventPanelistAction.toString(): {
      return {
        ...state
      };
    }
    case createVirtualEventPanelistAction.complete.toString(): {
      const newPanelists = state.panelists ? Array.from(state.panelists) : [];
      newPanelists.push(action.data);
      onChangeOfNoOfPanelistsTracker({ eventKey: state.eventKey, noOfPanelists: newPanelists.length });
      const noOfSpeakers = newPanelists.length + state.organizers.filter((organizer) => organizer.displayPanelistInfo).length;
      onChangeOfNoOfSpeakersTracker({ eventKey: state.eventKey, noOfSpeakers });
      return {
        ...state,
        panelists: newPanelists
      };
    }
    case createVirtualEventPanelistAction.failed.toString(): {
      return {
        ...state,
        error: action.message
      };
    }
    case deleteVirtualEventPanelistAction.toString(): {
      return {
        ...state
      };
    }
    case deleteVirtualEventPanelistAction.complete.toString(): {
      const newPanelists = state.panelists ? Array.from(state.panelists) : [];
      const index = newPanelists.findIndex((panelist) => panelist.id === action.data);
      if (index !== -1) newPanelists.splice(index, 1);
      onChangeOfNoOfPanelistsTracker({ eventKey: state.eventKey, noOfPanelists: newPanelists.length });
      const noOfSpeakers = newPanelists.length + state.organizers.filter((organizer) => organizer.displayPanelistInfo).length;
      onChangeOfNoOfSpeakersTracker({ eventKey: state.eventKey, noOfSpeakers });
      return {
        ...state,
        panelists: newPanelists
      };
    }
    case deleteVirtualEventPanelistAction.failed.toString(): {
      return {
        ...state,
        error: action.message
      };
    }
    case updateVirtualEventPanelistAction.toString(): {
      return {
        ...state
      };
    }
    case updateVirtualEventPanelistAction.complete.toString(): {
      const payload = action.payload;
      const fieldName = payload.fieldName;
      const [field] = fieldName.split('/').slice(-1);
      const panelistKey = payload.panelistKey;
      const value = payload.data;
      const index = state.panelists ? state.panelists.findIndex((panelist) => panelist.id === panelistKey) : -1;
      let newPanelists = state.panelists ? Array.from(state.panelists) : [];
      let newSpeaker = null;
      if (index !== -1) {
        if (fieldName === SpeakerUpdatePath.urls) {
          const data = payload.data;
          let urls = state.panelists[index].panelistInfo.urls;
          data.forEach((item) => {
            const key = item.path.split('/').slice(-1);
            urls = { ...urls, [key]: item.value };
          });
          const urlKeys = { website: false, facebook: false, twitter: false, linkedin: false };
          newSpeaker = { ...state.panelists[index], panelistInfo: { ...state.panelists[index].panelistInfo, urls: { ...urls } }, error: { ...state.panelists[index].error, urls: urlKeys } };
        } else if (fieldName === SpeakerUpdatePath.avatar) {
          newSpeaker = { ...state.panelists[index], panelistInfo: { ...state.panelists[index].panelistInfo, avatar: { url: value } }, error: { ...state.panelists[index].error, avatar: false } };
        } else {
          newSpeaker = { ...state.panelists[index], panelistInfo: { ...state.panelists[index].panelistInfo, [field]: value }, error: { ...state.panelists[index].error, [field]: false } };
        }
        newPanelists = [
          ...state.panelists.slice(0, index),
          newSpeaker,
          ...state.panelists.slice(index + 1)
        ];
      }
      return {
        ...state,
        panelists: newPanelists
      };
    }
    case updateVirtualEventPanelistAction.failed.toString(): {
      const index = state.panelists ? state.panelists.findIndex((panelist) => panelist.id === action.data.panelistKey) : -1;
      const value = action.data.data;
      let newPanelists = state.panelists ? Array.from(state.panelists) : [];
      let newSpeaker;
      const [field] = action.data.fieldName.split('/').slice(-1);
      if (index !== -1) {
        let errorObj;
        const urls = state.panelists[index].panelistInfo.urls;
        if (action.data.fieldName === SpeakerUpdatePath.urls) {
          const urlKeys = { website: false, facebook: false, twitter: false, linkedin: false };
          (action.data.data).forEach((item) => {
            const key = item.path.split('/').slice(-1);
            urlKeys[key] = true;
          });
          errorObj = { ...state.panelists[index].error, urls: urlKeys };
          newSpeaker = { ...state.panelists[index], panelistInfo: { ...state.panelists[index].panelistInfo, urls: { ...urls } }, error: errorObj };
        } else if (action.data.fieldName === SpeakerUpdatePath.avatar) {
          errorObj = { ...state.panelists[index].error, avatar: true };
          newSpeaker = { ...state.panelists[index], panelistInfo: { ...state.panelists[index].panelistInfo, avatar: { url: value } }, error: errorObj };
        } else {
          errorObj = { ...state.panelists[index].error, [field]: true };
          newSpeaker = { ...state.panelists[index], panelistInfo: { ...state.panelists[index].panelistInfo, [field]: value }, error: errorObj };
        }
        newPanelists = [
          ...state.panelists.slice(0, index),
          newSpeaker,
          ...state.panelists.slice(index + 1)
        ];
      }
      return {
        ...state,
        panelists: newPanelists
      };
    }
    case PANELISTS_UPDATED:
      return {
        ...state,
        panelistsUpdated: action.data
      };
    case CLEAR_ERROR_STATES: {
      let { panelists } = state;
      const newPanelists = [];
      panelists = panelists || [];
      panelists.forEach((panelist) => {
        let newPanelist = { ...panelist };
        let urls = panelist.panelistInfo.urls;
        if (panelist.error) {
          Object.keys(panelist.error).forEach((key) => {
            if (panelist.error[key]) {
              if (key === 'avatar') newPanelist = { ...newPanelist, panelistInfo: { ...newPanelist.panelistInfo, avatar: { url: '' } }, error: {} };
              else if (key === 'urls') {
                Object.keys(key).forEach((socialMediaKey) => {
                  if (key[socialMediaKey] === true) urls = { ...urls, [socialMediaKey]: '' };
                });
                newPanelist = { ...newPanelist, panelistInfo: { ...newPanelist.panelistInfo, urls }, error: { urls: {} } };
              } else newPanelist = { ...newPanelist, panelistInfo: { ...newPanelist.panelistInfo, [key]: '' }, error: {} };
            }
          });
        }
        newPanelists.push(newPanelist);
      });
      return {
        ...state,
        panelists: newPanelists
      };
    }
    case ORGANIZERS_UPDATED:
      return {
        ...state,
        organizersUpdated: action.data
      };

    // get virtual event registration question
    case getVirtualEventRegistrationQuestionAction.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: true
      };
    }
    case getVirtualEventRegistrationQuestionAction.complete.toString(): {
      return {
        ...state,
        questions: action.questions,
        isUpdatingVirtualEvent: false
      };
    }
    case getVirtualEventRegistrationQuestionAction.failed.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: false,
        error: action.message
      };
    }

    // update virtual event registration question
    case updateVERegistrationQuestionAction.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: true
      };
    }
    case updateVERegistrationQuestionAction.complete.toString(): {
      return {
        ...state,
        questions: action.questions,
        isUpdatingVirtualEvent: false
      };
    }
    case updateVERegistrationQuestionAction.failed.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: false,
        error: action.message
      };
    }

    // update VE registration settings
    case updateVERegistrationSettingsAction.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: true
      };
    }
    case updateVERegistrationSettingsAction.complete.toString(): {
      return {
        ...state,
        approvalType: action.approvalType,
        isUpdatingVirtualEvent: false
      };
    }
    case updateVERegistrationSettingsAction.failed.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: false,
        error: action.message
      };
    }

    case setVELiveStreamingConfigAction.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: true
      };
    }
    case setVELiveStreamingConfigAction.complete.toString(): {
      let liveStreamConfigurations = { ...state.liveStreamConfigurations };
      if (action.conferenceKey) {
        liveStreamConfigurations = liveStreamConfigurations?.[action.conferenceKey]
          ? { ...liveStreamConfigurations, [action.conferenceKey]: { ...liveStreamConfigurations[action.conferenceKey], [action.channel]: { ...action.data } } }
          : { ...liveStreamConfigurations, [action.conferenceKey]: { [action.channel]: { ...action.data } } };
      }
      return {
        ...state,
        liveStreamConfigurations,
        isUpdatingVirtualEvent: false
      };
    }

    case setVELiveStreamingConfigAction.failed.toString(): {
      const linkedInErrStatus = { ...state.linkedInErrStatus };
      if (action.channel === 'linkedIn') {
        linkedInErrStatus[action.conferenceKey] = {
          accountType: action.accountType,
          enableLivestreamAlert: action.showEnableLivestreamMessage,
          multiCompanyPageFlag: action.showMultiCompanyPageMessage
        };
      }
      return {
        ...state,
        linkedInErrStatus,
        isUpdatingVirtualEvent: false
      };
    }

    case updateVELiveStreamingChannelConfigAction.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: true
      };
    }

    case updateVELiveStreamingChannelConfigAction.complete.toString(): {
      const liveStreamConfigurations = { ...state.liveStreamConfigurations };
      if (action.conferenceKey) {
        liveStreamConfigurations[action.conferenceKey][action.channel] = { ...action.data };
      }
      return {
        ...state,
        liveStreamConfigurations,
        isUpdatingVirtualEvent: false
      };
    }

    case updateVELiveStreamingChannelConfigAction.failed.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: false
      };
    }

    case getAllVELiveStreamingConfigurationAction.toString(): {
      return {
        ...state,
        liveStreamConfigurations: {},
        isUpdatingVirtualEvent: true
      };
    }

    case getAllVELiveStreamingConfigurationAction.complete.toString(): {
      const liveStreamConfigurations = { ...state.liveStreamConfigurations };
      const linkedInErrStatus = { ...state.linkedInErrStatus };
      if (action.data && action.data.length && action.conferenceKey) {
        const liveStreamConfiguration = action.data.reduce((acc, curr) => {
          acc[curr.channelType] = { ...curr };
          return acc;
        }, {});
        liveStreamConfigurations[action.conferenceKey] = { ...liveStreamConfiguration };
      }
      linkedInErrStatus[action.conferenceKey] = {
        enableLivestreamAlert: false,
        accountType: '',
        multiCompanyPageFlag: false
      };
      return {
        ...state,
        liveStreamConfigurations,
        linkedInErrStatus,
        isUpdatingVirtualEvent: false
      };
    }

    case getAllVELiveStreamingConfigurationAction.failed.toString(): {
      return {
        ...state,
        isUpdatingVirtualEvent: false
      };
    }

    case createVEBroadcastSessionAction.toString(): {
      const conferences = [...state.conferences];
      const index = conferences ? conferences.findIndex((conference) => conference.conferenceKey === action.conferenceKey) : -1;
      if (index !== -1 && state.liveStreamConfigurations[action.conferenceKey]) {
        conferences[index] = { ...conferences[index], isSessionInitiated: true };
      }
      return {
        ...state,
        conferences,
        isLoadingLiveStreamConfiguration: true
      };
    }

    case createVEBroadcastSessionAction.complete.toString(): {
      setLocalStorage(`broadCastStatus_${action.channel}`, JSON.stringify(action.data));
      return {
        ...state,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case createVEBroadcastSessionAction.failed.toString(): {
      if (action.config) {
        setLocalStorage(localStorageKey.liveStreamingId, action.config.liveStreamingSettingsId);
      }
      const conferences = [...state.conferences];
      const index = conferences ? conferences.findIndex((conference) => conference.conferenceKey === action.conferenceKey) : -1;
      if (index !== -1 && state.liveStreamConfigurations[action.conferenceKey]) {
        conferences[index] = { ...conferences[index], linkedinReLoginRequired: action.relogin };
      }
      return {
        ...state,
        conferences,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case linkedInVEReloginAction.toString(): {
      return {
        ...state,
        isLoadingLiveStreamConfiguration: true
      };
    }

    case linkedInVEReloginAction.complete.toString(): {
      clearLocalStorage(localStorageKey.linkedinAuthCodeReLogin);
      clearLocalStorage(localStorageKey.reloginRequired);
      return {
        ...state,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case linkedInVEReloginAction.failed.toString(): {
      clearLocalStorage(localStorageKey.linkedinAuthCodeReLogin);
      clearLocalStorage(localStorageKey.reloginRequired);
      return {
        ...state,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case getVEActiveTargetsStatusAction.toString(): {
      return {
        ...state,
        isLoadingLiveStreamConfiguration: true
      };
    }

    case getVEActiveTargetsStatusAction.complete.toString(): {
      const conferences = [...state.conferences];
      const index = conferences ? conferences.findIndex((conference) => conference.conferenceKey === action.conferenceKey) : -1;
      if (index !== -1 && state.liveStreamConfigurations[action.conferenceKey]) {
        conferences[index] = { ...conferences[index], activeChannels: action.data };
      }
      return {
        ...state,
        conferences,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case getVEActiveTargetsStatusAction.failed.toString(): {
      return {
        ...state,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case stopVEBroadcastSessionAction.toString(): {
      const conferences = [...state.conferences];
      const index = conferences ? conferences.findIndex((conference) => conference.conferenceKey === action.conferenceKey) : -1;
      if (index !== -1 && state.liveStreamConfigurations[action.conferenceKey]) {
        conferences[index] = { ...conferences[index], isSessionInitiated: true };
      }
      return {
        ...state,
        conferences,
        isLoadingLiveStreamConfiguration: true
      };
    }

    case stopVEBroadcastSessionAction.complete.toString(): {
      clearLocalStorage(`broadCastStatus_${action.channel}`);
      const conferences = [...state.conferences];
      const index = conferences ? conferences.findIndex((conference) => conference.conferenceKey === action.conferenceKey) : -1;
      if (index !== -1 && state.liveStreamConfigurations[action.conferenceKey]) {
        delete conferences[index].broadcastStatus[action.channel];
      }
      return {
        ...state,
        conferences,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case stopVEBroadcastSessionAction.failed.toString(): {
      return {
        ...state,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case getVEBroadcastStatusAction.complete.toString(): {
      const conferences = [...state.conferences];
      const index = conferences ? conferences.findIndex((conference) => conference.conferenceKey === action.conferenceKey) : -1;
      if (action.data && action.data.status === 'not-connected') {
        clearLocalStorage(`broadCastStatus_${action.channel}`);
      }
      const broadcastStatus = { ...conferences[index].broadcastStatus, [action.channel]: action.data };
      let isLoadingLiveStreamConfiguration = false;
      if (index !== -1 && state.liveStreamConfigurations[action.conferenceKey]) {
        const isSessionInitiated = conferences[index].isSessionInitiated;
        conferences[index] = { ...conferences[index], broadcastStatus, isSessionInitiated: false };
        isLoadingLiveStreamConfiguration = action.data.status !== 'connected' && isSessionInitiated === true;
      }
      return {
        ...state,
        conferences,
        isLoadingLiveStreamConfiguration
      };
    }

    case getVEBroadcastStatusAction.failed.toString(): {
      if (action.channel) {
        clearLocalStorage(`broadCastStatus_${action.channel}`);
      }
      const conferences = [...state.conferences];
      const index = conferences ? conferences.findIndex((conference) => conference.conferenceKey === action.conferenceKey) : -1;
      if (index !== -1 && state.liveStreamConfigurations[action.conferenceKey]) {
        conferences[index] = { ...conferences[index], broadcastStatus: {} };
      }
      return {
        ...state,
        conferences,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case getVELiveStreamingConfigAction.toString(): {
      return {
        ...state,
        isLoadingLiveStreamConfiguration: true
      };
    }

    case getVELiveStreamingConfigAction.complete.toString(): {
      const liveStreamConfigurations = { ...state.liveStreamConfigurations };
      if (action.conferenceKey) {
        liveStreamConfigurations[action.conferenceKey][action.channel] = { ...action.data };
      }
      return {
        ...state,
        liveStreamConfigurations,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case getVELiveStreamingConfigAction.failed.toString(): {
      return {
        ...state,
        isLoadingLiveStreamConfiguration: false
      };
    }

    case resetlinkedInErrStatusAction: {
      const linkedInErrStatus = { ...state.linkedInErrStatus };
      linkedInErrStatus[action.conferenceKey] = {
        enableLivestreamAlert: false,
        accountType: '',
        multiCompanyPageFlag: false
      };
      return {
        ...state,
        linkedInErrStatus
      };
    }

    case updateConferenceDetailsAction.toString(): {
      let deletedSessions = [...state.deletedSessions];
      let conferences = [...state.conferences];
      if (state.conferences && state.conferences.length) {
        conferences = conferences.filter((conference) => {
          if (action.conferenceKey === conference.conferenceKey) {
            deletedSessions = [...deletedSessions, conference];
          }
          return action.conferenceKey !== conference.conferenceKey;
        });
      }
      return {
        ...state,
        deletedSessions,
        conferences
      };
    }

    case updateConferenceDetailsAction.complete.toString(): {
      let deletedSessions = [...state.deletedSessions];
      if (state.conferences && state.conferences.length) {
        deletedSessions = deletedSessions.filter((session) => session.conferenceKey === action.conferenceKey);
      }
      return {
        ...state,
        deletedSessions
      };
    }

    case updateConferenceDetailsAction.failed.toString(): {
      let deletedSessions = [...state.deletedSessions];
      let conferences = [...state.conferences];
      if (state.conferences && state.conferences.length) {
        deletedSessions = deletedSessions.filter((session) => {
          if (action.conferenceKey === session.conferenceKey) {
            conferences = [...conferences, session];
          }
          return session.conferenceKey !== action.conferenceKey;
        });
      }
      return {
        ...state,
        deletedSessions,
        conferences
      };
    }

    default: {
      return state;
    }
  }
};
