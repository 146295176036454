import config from 'appConfig';
import { getBearerHeaders } from 'Api/Helpers/getHeaders';
import { POST } from '../Helpers/httpMethods';
import { fetchJson } from '../Helpers/fetch';
import { JSON_MIME_TYPE } from '../Helpers/requestProperties';

export function createVideoFromEdits(assetKey, assetVersion, clips) {
  const body = {
    assetKey,
    assetVersion,
    clips
  };

  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };

  const url = `${config.featureService.url}/videoediting/videos/edit`;
  return fetchJson(url, options, JSON_MIME_TYPE, false);
}
