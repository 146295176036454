import config from 'appConfig';
import * as filestack from 'filestack-js';
import appLogger from 'Utils/logging';
import { unsafeCharactersRegex } from 'Utils/formats';

const NAME_MAXLENGTH = 128;

const logger = appLogger.create('Filestack Helper');

let client;

export default class filestackHelper {
  static initialize() {
    const securityOptions = {
      security: {
        policy: config.filestack.policy,
        signature: config.filestack.signature
      }
    };
    client = filestack.init(config.filestack.key, securityOptions);
  }

  /**
  *   See sources: https://www.filestack.com/docs/
  */

  /**
   * Used to pick files for the E2E filestack account. This uploads to Filestack.
   */
  static pick(customOptions, onlyLocalFileSystem = false, deleteFromFileStack = false) {
    if (!client) {
      throw new Error('Cannot pick File Picker without initializing the client');
    }
    let fileSource = ['local_file_system'];
    if (!onlyLocalFileSystem) {
      fileSource = ['local_file_system', 'googledrive', 'onedriveforbusiness', 'dropbox', 'box'];
      /** Available File sources
       * ['local_file_system', 'imagesearch', 'facebook', 'instagram', 'googledrive', 'dropbox', 'url',
       *   'evernote', 'flickr', 'box', 'github', 'gmail', 'picasa', 'onedrive', 'onedriveforbusiness',
       *   'clouddrive', 'webcam'];
       */
    }

    const sharedOptions = {
      fromSources: fileSource,
      uploadInBackground: false
    };

    if (!deleteFromFileStack) {
      sharedOptions.onFileUploadFinished = (file) => { this.removeFileFromFilestack(file); };
    }

    const pickerOptions = {
      ...sharedOptions,
      ...customOptions
    };

    return client.picker(pickerOptions).open()
      .catch(() => {});
  }

  static cancelUpload() {
    client.cancel();
  }

  static getTitleSafe(filename) {
    const fileTitle = (filename || '').indexOf('.') > -1 ? filename.substring(0, filename.lastIndexOf('.')) : (filename || '');
    const fileTitleSafeCharacters = fileTitle.replace(unsafeCharactersRegex, '');
    const fileTitleSafeCharactersAndLength = fileTitleSafeCharacters.length > NAME_MAXLENGTH
      ? fileTitleSafeCharacters.substr(0, NAME_MAXLENGTH)
      : fileTitleSafeCharacters;
    return fileTitleSafeCharactersAndLength;
  }

  static getExtensionSafe(filename) {
    const fileExtension = (filename || '').indexOf('.') > -1 ? (filename).substr(filename.lastIndexOf('.') + 1) : '';
    const fileExtensionSafeCharacters = fileExtension.replace(unsafeCharactersRegex, '');
    const fileExtensionSafeCharactersAndLength = fileExtensionSafeCharacters.length > NAME_MAXLENGTH
      ? fileExtensionSafeCharacters.substr(0, NAME_MAXLENGTH)
      : fileExtensionSafeCharacters;
    return fileExtensionSafeCharactersAndLength.toLowerCase();
  }

  /**
   * Use removeMetadata method if the file needs to be removed only from filestack storage and not the filestack configured S3 storage.
   * The policy and signature used allows only removal of files from storage.
   */
  static removeFileFromFilestack(file) {
    if (file && file.handle) {
      const policyAndSignature = { policy: config.filestack.policy, signature: config.filestack.signature };
      client.remove(file.handle, policyAndSignature)
        .catch((error) => { logger.error('Error while removing file from filestack storage.', 'error=', error); });
    }
  }

  /**
   * Should only be used for G2S Channel Images. This uses the G2S Filestack account and uploads to LMI S3.
   */
  static pickStageChannelImage(customOptions, onlyLocalSources) {
    const g2sSecurityOptions = {
      security: {
        policy: config.filestack.g2s.policy,
        signature: config.filestack.g2s.signature
      }
    };
    const g2sClient = filestack.init(config.filestack.g2s.key, g2sSecurityOptions);
    if (!g2sClient) {
      throw new Error('Cannot pick File Picker without initializing the G2S client');
    }

    const sharedOptions = {
      fromSources: onlyLocalSources ? ['local_file_system'] : ['local_file_system', 'googledrive', 'onedriveforbusiness', 'dropbox', 'box'], uploadInBackground: false
    };
    const pickerOptions = { ...sharedOptions, ...customOptions };

    pickerOptions.storeTo = {
      location: 'S3',
      path: `${config.filestack.storage.s3.path}/logos/`
    };

    return g2sClient.picker(pickerOptions).open()
      .catch(() => {});
  }

  static transform(imageUrl, outputFormat) {
    if (!client) {
      throw new Error('Cannot pick File Picker without initializing the client');
    }
    return client.transform(imageUrl, { output: { format: outputFormat } });
  }

  static addSecurityToURL(url) {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.set('policy', config.filestack.policy);
    parsedUrl.searchParams.set('signature', config.filestack.signature);
    return parsedUrl.toString();
  }

  static getFileFromUrl(url) {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        blob.name = 'blob.jpeg';
        return blob;
      });
  }

  static fileEncoder(key) {
    // Encode the s3 file name
    const sliceIndex = key.lastIndexOf('/');
    const s3subfolder = key.slice(0, sliceIndex);
    const s3fileName = encodeURIComponent(key.split('/').pop());
    return `${s3subfolder}/${s3fileName}`;
  }

  static getVideoFileFromUrl(url, filename) {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        blob.name = filename;
        blob.lastModified = new Date();
        const file = new File([blob], filename, { type: blob.type });
        return file;
      });
  }
}
