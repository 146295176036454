import { createAsyncAction } from '../reduxActions';

export const generateSummaryAction = createAsyncAction('GENERATE_SUMMARY');
export const getSummaryAction = createAsyncAction('GET_SUMMARY');
export const discardSummaryAction = createAsyncAction('DISCARD_SUMMARY');
export const saveSummaryAction = createAsyncAction('SAVE_SUMMARY');
export const deleteSummaryAction = createAsyncAction('DELETE_SUMMARY');

export function generateSummary(userKey, accountKey, recordingAssetKey, showContentFilteringAlert) {
  return {
    type: generateSummaryAction.toString(), userKey, accountKey, recordingAssetKey, showContentFilteringAlert
  };
}

export function getSummary(userKey, accountKey, recordingAssetKey) {
  return {
    type: getSummaryAction.toString(), userKey, accountKey, recordingAssetKey
  };
}

export function saveSummary(userKey, accountKey, recordingAssetKey, summaryData) {
  return {
    type: saveSummaryAction.toString(), userKey, accountKey, recordingAssetKey, summaryData
  };
}

export function deleteSummary(assetKey) {
  return {
    type: deleteSummaryAction.toString(), assetKey
  };
}
