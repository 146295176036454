import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import trackEngagement from 'Mixpanel/Features/EventDetails/Engagement/engagementTracker';
import { HANDOUTS } from 'Mixpanel/Features/EventDetails/Engagement/engagementConstants';
import Action from 'Mixpanel/Model/action';
import styles from './styles.scss';
import { getHandoutFileStyle } from '../handoutFileTypes';

class HandoutCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocumentPreview: false
    };
  }

  static propTypes = {
    webinarKey: PropTypes.string,
    handouts: PropTypes.array,
    handoutsConstraints: PropTypes.object,
    deleteHandout: PropTypes.func,
    showHandout: PropTypes.func,
    isPastWebinar: PropTypes.bool,
    t: PropTypes.func
  }

  handleDeleteHandout(handoutKey) {
    trackEngagement(HANDOUTS, null, Action.REMOVE);
    this.props.deleteHandout(handoutKey);
  }

  showHandout = (handout) => {
    this.props.showHandout(handout);
  }

  displayDocumentPreview(handout) {
    const imageFileType = ['gif', 'png', 'jpeg', 'jpg'];
    if (this.state.showDocumentPreview && imageFileType.includes(handout.filetype)) {
      if (handout._links && handout._links.file && handout._links.file.href) {
        return (
          <figure // eslint-disable-line
            onClick={this.showHandout.bind(this, handout)}>
            <img className={styles.documentPreview} src={handout._links.file.href} alt='' />
          </figure>);
      }
    }
    return (
      // eslint-disable-next-line
      <figure style={{ backgroundColor: `${getHandoutFileStyle(handout.filetype, 'color')}` }} onClick={this.showHandout.bind(this, handout)}>
        <div className={styles.handoutCardIcon}><i className={`togo-icon togo-icon-${getHandoutFileStyle(handout.filetype, 'icon')}`} /></div>
      </figure>
    );
  }

  displayHandout() {
    const { handouts, isPastWebinar } = this.props;
    const handoutCard = [];
    handouts.forEach((handout) => {
      handoutCard.push(
        <li id={`handoutcards_${handout.filename}`} key={handout.objectkey} className={styles.handoutCard}>
          {this.displayDocumentPreview(handout)}
          <div className={styles.handoutCardDetail}>
            <div id='handoutcards_filename' className={styles.handoutCardTitle}><span>{handout.filename}</span></div>
            {!isPastWebinar
              && <button id='handoutcards_trash_icon' className={styles.handoutCardDelete} onClick={this.handleDeleteHandout.bind(this, handout.objectkey)}>
                <i className='togo-icon togo-icon-trash' />
              </button>}
          </div>
        </li>
      );
    });
    return handoutCard;
  }

  render() {
    return (
      <div className={styles.handoutCardContainer}>
        <section className={styles.handoutCards}>
          <div className={styles.handoutCardGrid}>
            <ul className={styles.handoutCardList}>
              {this.displayHandout()}
            </ul>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(HandoutCards);
