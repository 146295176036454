import { createSelector } from 'reselect';
import { get } from '../../Utils/objectHelpers';

export const filterRecordingVersionDetailsFromAsset = (assetVersionDetails = []) => (
  assetVersionDetails
    .filter(({ status, requestedResources }) => (
      status === 'COMPLETED'
      && requestedResources.some(({ resourceType }) => resourceType === 'recording')
    ))
);

const findLinkedRecordingKey = (state) => {
  const asssociatedRecordings = get(state, (s) => s.associatedRecordings.associatedRecordings.arr, []);
  const [linkedRecording] = asssociatedRecordings.filter((recording) => recording.recordingSettings.linkedToRegistrationSettings);
  if (linkedRecording) {
    return linkedRecording.recordingKey;
  }
  return undefined;
};

export const selectLinkedAssetWithRecordingKey = createSelector(
  [
    findLinkedRecordingKey,
    (state) => get(state, (s) => s.associatedRecordings.recordingMetadata.map)
  ],
  (recordingKey, recordingMap) => {
    if (recordingKey && recordingMap) {
      const asset = recordingMap.get(recordingKey);

      if (asset) {
        return { ...asset, recordingKey };
      }
    }
    return undefined;
  }
);
