import { redirect } from 'Utils/redirector';
import { clearUserSessionId } from 'Reporting/userProperties';
import { setUserFrontendSessionId } from '../../MessageBus/messageBusEventCreator';

export default (state = {}, action) => {
  switch (action.type) {
    case 'AUTH_REDIRECT':
    case 'AUTH_RESET': {
      return state;
    }
    case 'USER_LOGOUT': {
      sessionStorage.removeItem('endToEnd');
      localStorage.removeItem('endToEnd');
      clearUserSessionId();
      setUserFrontendSessionId('');
      redirect(action.path);
      return {
        ...state,
        logout: true
      };
    }
    default: {
      return state;
    }
  }
};
