import * as Types from 'Components/Card/Events/Details/Registration/registrationTypes';
import { trackVirtualEventTabSelectionOnHomePage } from 'Mixpanel/Features/VirtualEvents/VirtualEventTracker';
import * as actions from './actions';
import { generateAudioWaveform } from '../VideoEditor/actions';
import { updateRegistrationSettingsAction } from '../RegistrationSettings/actions';

const defaultState = {
  eventTabIndex: 0,
  eventTypeTabIndex: 0,
  hideVideoEditingTrimAlert: false,
  isEventCompactView: false,
  modal: {},
  pastEventPageIndex: 0,
  showAllEvents: false,
  showAllVirtualEvents: false,
  upcomingEventPageIndex: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case actions.updateEventsTab.toString(): {
      return {
        ...state,
        eventTabIndex: action.payload
      };
    }

    case actions.updateEventTypeTab.toString(): {
      trackVirtualEventTabSelectionOnHomePage(action.payload === 0 ? 'WEBINARS' : 'VIRTUAL EVENTS');
      return {
        ...state,
        eventTypeTabIndex: action.payload
      };
    }

    case actions.pastEventPageChangedAction.toString(): {
      return {
        ...state,
        pastEventPageIndex: action.pageIndex
      };
    }

    case actions.upcomingEventPageChangedAction.toString(): {
      return {
        ...state,
        upcomingEventPageIndex: action.pageIndex
      };
    }

    case actions.toggleEventsList.toString(): {
      return {
        ...state,
        showAllEvents: action.payload
      };
    }

    case actions.toggleVirtualEventsList.toString(): {
      return {
        ...state,
        showAllVirtualEvents: action.payload
      };
    }

    case actions.hideVideoEditingTrimAlert.toString(): {
      return {
        ...state,
        hideVideoEditingTrimAlert: true
      };
    }

    case actions.toggleModal.toString(): {
      return {
        ...state,
        modal: action.payload
      };
    }

    case generateAudioWaveform.pending.toString(): {
      return {
        ...state,
        modal: {
          ...state.modal,
          hasInitiated: false
        }
      };
    }

    case generateAudioWaveform.fulfilled.toString(): {
      return {
        ...state,
        modal: {
          ...state.modal,
          hasInitiated: true
        }
      };
    }

    case generateAudioWaveform.rejected.toString(): {
      return {
        ...state,
        modal: {
          ...state.modal,
          error: action.payload.message || action.payload.body.reason
        }
      };
    }

    case actions.setEventCompactView.toString(): {
      return {
        ...state,
        isEventCompactView: action.payload.isCompactView
      };
    }

    case updateRegistrationSettingsAction.complete.toString(): {
      if (action.registrationType === Types.RESTRICT_DOMAINS) {
        return {
          ...state,
          modal: {}
        };
      }
      return state;
    }

    default: {
      return state;
    }
  }
};
