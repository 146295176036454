import { getUserAction, getAccountUsersAction } from './actions';

export default (state = { isFetching: false, isFetchingAccountUsers: false }, action) => {
  switch (action.type) {
    case getUserAction.pending.toString(): {
      return {
        ...state,
        isFetching: true
      };
    }
    case getUserAction.fulfilled.toString(): {
      return {
        ...state,
        user: action.payload.user,
        metadata: action.payload.metadata,
        isFetching: false
      };
    }
    case getUserAction.rejected.toString(): {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };
    }
    case getAccountUsersAction.toString(): {
      return {
        ...state,
        isFetchingAccountUsers: true
      };
    }
    case getAccountUsersAction.complete.toString(): {
      const { internal: actionInternal = [], external = [] } = action;
      const internal = actionInternal.map(({
        email, key: organizerKey, givenName, surname
      }) => ({
        email,
        organizerKey,
        givenName: `${givenName} ${surname}`,
        external: false
      }));

      return {
        ...state,
        internal,
        external,
        isFetchingAccountUsers: false
      };
    }
    case getAccountUsersAction.failed.toString(): {
      return {
        ...state,
        isFetchingAccountUsers: false,
        accoutUsersError: action.error
      };
    }
    default: {
      return state;
    }
  }
};
