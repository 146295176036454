import config from 'appConfig';
import { getAuth } from 'Api';
import * as httpMethods from 'Api/Helpers/httpMethods';
import { JSON_MIME_TYPE } from 'Api/Helpers/requestProperties';
import { fetchJson } from 'Api/Helpers/fetch';

const URL_PREFIX = '/api/webinars/';
const URL_SURVEYS_POSTFIX = '/surveys';
const URL_SURVEY_INFO_POSTFIX = '/surveyInfo';
const URL_SESSIONS = '/sessions/';
const URL_ATTENDEES = '/attendees/';

export function retrieveWebinarSurvey(webinarKey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SURVEYS_POSTFIX}`;
  const options = {
    headers: {
      Authorization: `Token ${getAuth().access_token}`,
      'Content-Type': 'application/json'
    },
    method: httpMethods.GET
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function createWebinarSurvey(webinarKey, survey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SURVEYS_POSTFIX}`;
  const options = {
    headers: {
      Authorization: `Token ${getAuth().access_token}`,
      'Content-Type': 'application/json'
    },
    method: httpMethods.POST,
    body: JSON.stringify(survey)
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function deleteWebinarSurvey(webinarKey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SURVEYS_POSTFIX}`;
  const options = {
    headers: { Authorization: `Token ${getAuth().access_token}` },
    method: httpMethods.DELETE
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function editWebinarSurvey(webinarKey, survey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SURVEYS_POSTFIX}`;
  const options = {
    headers: { Authorization: `Bearer ${getAuth().access_token}` },
    method: httpMethods.PUT,
    body: JSON.stringify(survey)
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

// TODO: Delete these functions if not needed
export function retrieveWebinarSurveyInfo(webinarKey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SURVEY_INFO_POSTFIX}`;
  const options = {
    headers: { 'Content-Type': 'application/json' }
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function retrieveWebinarSurveyResponses(webinarKey, sessionKey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SESSIONS}${sessionKey}${URL_SURVEYS_POSTFIX}`;
  const options = {
    headers: { Authorization: `Bearer ${getAuth().access_token}` }
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}

export function retrieveWebinarSurveyResponsesForAttendee(webinarKey, sessionKey, registrantKey) {
  const url = `${config.g2w.url}${URL_PREFIX}${webinarKey}${URL_SESSIONS}${sessionKey}${URL_ATTENDEES}${registrantKey}${URL_SURVEYS_POSTFIX}`;
  const options = {
    headers: { Authorization: `Bearer ${getAuth().access_token}` }
  };

  return fetchJson(url, options, JSON_MIME_TYPE);
}
