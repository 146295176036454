export const onComplete = (type) => `${type}_COMPLETE`;
export const onFailed = (type) => `${type}_FAILED`;

export function createAction(type, payloadCreator) {
  if (!payloadCreator) {
    return Object.assign(
      () => ({ type }),
      { toString: () => type }
    );
  }

  return Object.assign(
    (...args) => ({ type, payload: payloadCreator(...args) }),
    { toString: () => type }
  );
}

export function createAsyncAction(type) {
  return Object.assign(
    createAction(type),
    {
      complete: createAction(onComplete(type), (payload) => payload),
      failed: createAction(onFailed(type), (payload) => payload)
    }
  );
}
