import { createAction as createActionV2 } from 'redux-promise-middleware-actions';
import { createAction } from '../reduxActions';

export const pastEventPageChangedAction = createAction('PAST_EVENTS_PAGE_CHANGED');
export const toggleModal = createAction('TOGGLE_MODAL', (modal = {}) => modal);
export const upcomingEventPageChangedAction = createAction('UPCOMING_EVENTS_PAGE_CHANGED');

export const hideVideoEditingTrimAlert = createActionV2('HIDE_VIDEO_EDITING_TRIM_ALERT');
export const setEventCompactView = createActionV2('SET_EVENT_COMPACT_VIEW', (isCompactView) => ({ isCompactView }));
export const toggleEventsList = createActionV2('TOGGLE_EVENTS_LIST', (showAll) => showAll);
export const toggleVirtualEventsList = createActionV2('TOGGLE_VIRTUAL_EVENTS_LIST', (showAll) => showAll);
export const updateEventsTab = createActionV2('EVENTS_TAB_CHANGED', (index) => index);
export const updateEventTypeTab = createActionV2('EVENT_TYPE_TAB_CHANGED', (index) => index);

export function onPastEventPageChanged(pageIndex) {
  return { type: pastEventPageChangedAction.toString(), pageIndex };
}

export function onUpcomingEventPageChanged(pageIndex) {
  return { type: upcomingEventPageChangedAction.toString(), pageIndex };
}

export const changeDateFilter = createActionV2(
  'CHANGE_DATE_FILTER',
  ({ start, end }) => ({ start, end }),
  ({ id }) => ({ id })
);
