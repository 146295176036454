import config from 'appConfig';
import logger from '@getgo/logger';
import loggerTargetLoggingServicePlugin from '@getgo/logger-target-logging-service';
import uuid from 'uuid';
import { getBrowser, getUA, getCpu, getDevice, getEngine, getOS } from './userAgentInfo';

const loggerTargetLoggingService = loggerTargetLoggingServicePlugin({
  server: `${config.logger.url}`,
  context: {
    ls_source: 'e2e-client',
    ls_sourcetype: 'logging'
  }
});

const appLogger = logger.createRoot(loggerTargetLoggingService);

appLogger.setContext('uuid', uuid.v4());
appLogger.setContext('browserInfo', getBrowser());
appLogger.setContext('userAgent', getUA());
appLogger.setContext('cpuInfo', getCpu());
appLogger.setContext('deviceInfo', getDevice());
appLogger.setContext('engineInfo', getEngine());
appLogger.setContext('osInfo', getOS());

try {
  let machineId = window.localStorage.getItem('machineId');
  if (!machineId) {
    machineId = uuid.v4();
    window.localStorage.setItem('machineId', machineId);
  }
  appLogger.setContext('machineId', machineId);
} catch (e) {
  console.log('error', e);
}

export default appLogger;
