import config from 'appConfig';
import { getBearerHeaders } from 'Api/Helpers/getHeaders';
import { POST } from '../Helpers/httpMethods';
import { fetchJson } from '../Helpers/fetch';
import { JSON_MIME_TYPE } from '../Helpers/requestProperties';

const removeMultiSpaces = (edits = []) => (
  edits.map((edit) => {
    const text = edit.text.replace(/&nbsp;/g, '');
    return { ...edit, text };
  })
);

export function updateTranscriptFromEdits(assetKey, assetVersion, editedUtterances) {
  const body = {
    product: 'g2w',
    editedUtterances: removeMultiSpaces(editedUtterances),
    assetKey,
    assetVersion
  };

  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };

  const url = `${config.featureService.url}/transcripts/edit`;
  return fetchJson(url, options, JSON_MIME_TYPE, true);
}
