import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import QuestionType from 'Api/Webinar/Survey/Model/questionType';
import commonPollStyles from 'Containers/Poll/styles.scss';
import { anyCharRegex } from 'Utils/formats';
import FloatingLabel from '@getgo/chameleon-react/dist/FloatingLabel';
import { withTranslation } from 'react-i18next';
import Options from '../Options';
import styles from './styles.scss';
import multipleChoiceIcon from '../Assets/multipleChoice.svg';
import singleChoiceIcon from '../Assets/singleChoice.svg';
import rateOnScaleIcon from '../Assets/rateOnAScale.svg';
import shortAnswerIcon from '../Assets/shortAnswer.svg';
import chevronIcon from '../Assets/chevron.svg';

class Choices extends Component {
  static propTypes = {
    onAnswersChange: PropTypes.func,
    onQuestionTypeChange: PropTypes.func,
    questionType: PropTypes.string,
    options: PropTypes.array,
    maxNumberOfChoices: PropTypes.number,
    choices: PropTypes.array,
    onQuestionTitleChange: PropTypes.func,
    questionText: PropTypes.string,
    maxQuestionLength: PropTypes.number,
    maxLengthForAnswerOption: PropTypes.number,
    autoFocusQuestionTitle: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    questionType: QuestionType.SINGLE_CHOICE,
    maxNumberOfChoices: 5,
    maxQuestionLength: 128,
    choices: [],
    autoFocusQuestionTitle: true,
    options: [
      QuestionType.MULTIPLE_CHOICE, QuestionType.SHORT_ANSWER,
      QuestionType.SINGLE_CHOICE, QuestionType.RATING]
  };

  constructor(props) {
    super(props);
    this.choices = new Map();
    this.choiceIcons = new Map();
    const { options } = this.props;
    if (options.includes(QuestionType.SINGLE_CHOICE)) {
      this.choices.set(QuestionType.SINGLE_CHOICE, 'survey.questions.choices.multipleChoiceOneAnswer');
      this.choiceIcons.set(QuestionType.SINGLE_CHOICE, singleChoiceIcon);
    }

    if (options.includes(QuestionType.MULTIPLE_CHOICE)) {
      this.choiceIcons.set(QuestionType.MULTIPLE_CHOICE, multipleChoiceIcon);
      this.choices.set(QuestionType.MULTIPLE_CHOICE, 'survey.questions.choices.multipleChoiceMultipleAnswers');
    }

    if (options.includes(QuestionType.RATING)) {
      this.choiceIcons.set(QuestionType.RATING, rateOnScaleIcon);
      this.choices.set(QuestionType.RATING, 'survey.questions.choices.rateOnScale.title');
    }

    if (options.includes(QuestionType.SHORT_ANSWER)) {
      this.choiceIcons.set(QuestionType.SHORT_ANSWER, shortAnswerIcon);
      this.choices.set(QuestionType.SHORT_ANSWER, 'survey.questions.choices.shortAnswer');
    }
    this.state = {
      showChoiceDropdown: false,
      toggleKey: props.questionType
    };
  }

  componentDidMount() {
    const { onQuestionTypeChange } = this.props;
    if (onQuestionTypeChange) {
      onQuestionTypeChange(this.state.toggleKey, false);
    }
  }

  toggleChoiceDropdown() {
    this.setState({ showChoiceDropdown: !this.state.showChoiceDropdown });
  }

  choiceSelection(key) {
    this.setState({ toggleKey: key });
    const { onQuestionTypeChange, questionType } = this.props;
    let shouldClearChoices = true;
    if ((questionType === QuestionType.MULTIPLE_CHOICE || questionType === QuestionType.SINGLE_CHOICE)
        && (key === QuestionType.SINGLE_CHOICE || key === QuestionType.MULTIPLE_CHOICE)) {
      shouldClearChoices = false;
    }
    if (onQuestionTypeChange) {
      onQuestionTypeChange(key, shouldClearChoices);
    }
  }

  createDropdownMenu() {
    const { t } = this.props;
    const { options } = this.props;
    return (<DropdownMenu className={styles.choicesDropdownMenu}>
      {options.includes(QuestionType.MULTIPLE_CHOICE)
        ? <DropdownItem className={styles.choicesDropdownItem} onClick={this.choiceSelection.bind(this, QuestionType.MULTIPLE_CHOICE)}><div id='choices_question_type_multiple_choice'>{t(this.choices.get(QuestionType.MULTIPLE_CHOICE))}</div></DropdownItem>
        : null}
      {options.includes(QuestionType.SINGLE_CHOICE)
        ? <DropdownItem className={styles.choicesDropdownItem} onClick={this.choiceSelection.bind(this, QuestionType.SINGLE_CHOICE)}><div id='choices_question_type_single_choice'>{t(this.choices.get(QuestionType.SINGLE_CHOICE))}</div></DropdownItem>
        : null}
      {options.includes(QuestionType.RATING)
        ? <DropdownItem className={styles.choicesDropdownItem} onClick={this.choiceSelection.bind(this, QuestionType.RATING)}><div id='choices_question_type_rating'>{t(this.choices.get(QuestionType.RATING))}</div></DropdownItem>
        : null
      }
      {options.includes(QuestionType.SHORT_ANSWER)
        ? <DropdownItem className={styles.choicesDropdownItem} onClick={this.choiceSelection.bind(this, QuestionType.SHORT_ANSWER)}><div id='choices_question_type_short_answer'>{t(this.choices.get(QuestionType.SHORT_ANSWER))}</div></DropdownItem>
        : null
      }
    </DropdownMenu>);
  }

  renderQuestion() {
    const { t } = this.props;
    const props = {
      placeholder: t('poll.questions.placeholder'),
      value: this.props.questionText
    };
    return (
      <FloatingLabel
        id='choices_input_questionfield'
        pattern={anyCharRegex}
        onChange={this.props.onQuestionTitleChange}
        form
        floatLabel={false}
        showAnimatedBar
        autoFocus={this.props.autoFocusQuestionTitle}
        errorMessage={t('poll.questions.requiredField')}
        inputClassName={commonPollStyles.textInputField}
        containerClassNames={styles.questionFloatingLabel}
        placeholder={props.placeholder}
        maxLength={this.props.maxQuestionLength}
        charLimitText={t('survey.charLimit')}
        value={props.value} />
    );
  }

  showOptions() {
    const { onAnswersChange, choices } = this.props;
    const props = {
      questionType: this.state.toggleKey,
      onChange: onAnswersChange,
      maxNumberOfChoices: this.props.maxNumberOfChoices,
      maxLengthForAnswerOption: this.props.maxLengthForAnswerOption,
      choices
    };
    return <Options {...props} />;
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.choices}>
        <Dropdown isOpen={this.state.showChoiceDropdown} toggle={this.toggleChoiceDropdown.bind(this)}>
          <DropdownToggle color={'white'} className={styles.choiceToggle}>
            <label className={styles.choiceType}>{t('survey.questions.choices.type')}</label>
            <img className={styles.choiceToggleIcon} src={this.choiceIcons.get(this.state.toggleKey)} alt='' role='presentation' />
            <label id={`choices_selected_dropdown_item_${this.state.toggleKey}`}>{t(this.choices.get(this.state.toggleKey))}</label>
            <img className={styles.chevronIcon} src={chevronIcon} alt='' role='presentation'/>
          </DropdownToggle>
          {this.createDropdownMenu()}
          {this.renderQuestion()}
          {this.showOptions()}
        </Dropdown></div>
    );
  }
}

export default withTranslation()(Choices);
