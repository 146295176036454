import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';
import ChameleonLoader from '../ChameleonLoader';

const Loading = (props) => <div className={classNames('chameleon-loader-background', styles.container)} style={props.showBackground === false ? { backgroundImage: 'none' } : null}>
  <ChameleonLoader color={props.color} />
</div>;

Loading.propTypes = {
  showBackground: PropTypes.bool,
  color: PropTypes.string
};

export default Loading;

