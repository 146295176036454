import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { array, func } from 'prop-types';

import Question from '../Question/Question';
import styles from './QuestionsList.scss';

const QuestionsList = ({
  moveSurveyQuestion,
  questions,
  removeSurveyQuestion,
  setActiveSurveyQuestion
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.questionsList}>
      <div className={styles.header}>
        <h1 id="questions_title" className={styles.title}>
          {t('survey.questions.title')}
        </h1>

        <div className={styles.questionsRemaining}>
          {t('survey.questions.count', { count: questions.length, max: 25 })}
        </div>
      </div>

      <div>
        <hr className={styles.separator} />

        {questions.map((question, idx) => (
          <Fragment key={question.questionKey}>
            <div>
              <Question
                isFirst={idx === 0}
                isLast={idx === questions.length - 1}
                moveSurveyQuestion={moveSurveyQuestion}
                question={question}
                removeSurveyQuestion={removeSurveyQuestion}
                setActiveSurveyQuestion={setActiveSurveyQuestion}
              />
            </div>
            <hr className={styles.separator} />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

QuestionsList.propTypes = {
  moveSurveyQuestion: func,
  questions: array,
  removeSurveyQuestion: func,
  setActiveSurveyQuestion: func
};

QuestionsList.defaultProps = {
  questions: []
};

export default QuestionsList;
