import React from 'react';
import { object, bool } from 'prop-types';
import PreActionPage from './PreActionPage';
import PostActionPage from './PostActionPage';

export default class GenerateAudioWaveform extends React.PureComponent {
  static propTypes = {
    data: object,
    hasInitiated: bool
  }

  render() {
    const { hasInitiated } = this.props;
    return (
      hasInitiated
        ? <PostActionPage { ...this.props } />
        : <PreActionPage { ...this.props } />
    );
  }
}
