import React, { Component } from 'react';
import Action from 'Mixpanel/Model/action';
import trackEngagement from 'Mixpanel/Features/EventDetails/Engagement/engagementTracker';
import { Checkbox } from '@getgo/chameleon-react';
import { SURVEY } from 'Mixpanel/Features/EventDetails/Engagement/engagementConstants';
import { func, object } from 'prop-types';
import { withTranslation } from 'react-i18next';

import styles from './Settings.scss';

const MIXPANEL_ATTRIBUTE_SETTINGS = 'Settings';

class Settings extends Component {
  static propTypes = {
    onSettingsChange: func.isRequired,
    surveySettings: object,
    t: func
  };

  static defaultProps = {
    surveySettings: {
      shownAfterWebinarSession: false,
      shownInFollowUpEmail: false,
      shownInAbsenteeFollowUpEmail: false
    }
  };

  state = {
    surveySettings: this.props.surveySettings
  };

  reportSettingsChange() {
    trackEngagement(SURVEY, MIXPANEL_ATTRIBUTE_SETTINGS, Action.EDIT);
  }

  renderError() {
    const { t } = this.props;
    const { surveySettings } = this.state;

    if (Object.values(surveySettings).some((element) => element === true)) {
      return null;
    }
    return (<div className={styles.error}>
      {t('survey.settings.error')}
    </div>);
  }

  onChange = (settingName) => {
    const surveySettingsCopy = { ...this.state.surveySettings };
    surveySettingsCopy[settingName] = !surveySettingsCopy[settingName];
    this.setState({
      surveySettings: surveySettingsCopy
    });
    const { onSettingsChange } = this.props;
    if (onSettingsChange) {
      onSettingsChange(surveySettingsCopy);
    }
    this.reportSettingsChange();
  }

  renderSetting(id, title, subtitle, settingName) {
    return (
      <div id={id}>
        <Checkbox
          checked={this.state.surveySettings[settingName]}
          text={title}
          onChange={() => this.onChange(settingName)}
        />
        <div id={settingName.toLowerCase()} className={styles.subtitle}>
          {subtitle}
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div id='survey_window_settings_title' className={styles.settings}>
        <h1 id='survey_settings_title' className={styles.title}>
          {t('survey.settings.title')}
        </h1>
        <div id='survey_window_settings_subtitle'>
          {t('survey.settings.subtitle')}
        </div>

        {this.renderError()}
        {this.renderSetting('tc_survey_settings_after_webinar', t('survey.settings.options.optionA.title'), t('survey.settings.options.optionA.subtitle'), 'shownAfterWebinarSession')}
        {this.renderSetting('survey_settings_attendee_followup_email', t('survey.settings.options.optionB.title'), t('survey.settings.options.optionB.subtitle'), 'shownInFollowUpEmail')}
        {this.renderSetting('survey_settings_absentee_followup_email', t('survey.settings.options.optionC.title'), t('survey.settings.options.optionC.subtitle'), 'shownInAbsenteeFollowUpEmail')}
      </div>
    );
  }
}

export default withTranslation()(Settings);
