import { asyncReducer } from 'redux-promise-middleware-actions';
import { TAG_TYPE } from 'Components/Card/MessageCenterNotifications/constants';
import { getMessages, getMessagesByTag, updateMessage, deleteMessage, getMessagesInfo,
  addNotificationPreferenceMessage, removeNotificationPreferenceMessage, updateNotificationPreferenceMessage } from './actions';
import { updateInfoMessageCount, createNotificationPreferenceMessage } from './messageCenterHelper';

const messageCenterReducer = asyncReducer(getMessages);
const getMessageCenterByTagReducer = asyncReducer(getMessagesByTag);
const getMessagesInfoReducer = asyncReducer(getMessagesInfo);

const initialState = {
  data: [],
  info: {
    data: { unreadMessagesCount: 0 }
  }
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    ...messageCenterReducer,
    tagSpecific: getMessageCenterByTagReducer(state.tagSpecific, action),
    info: getMessagesInfoReducer(state.info, action)
  };

  switch (action.type) {
    case String(updateMessage.fulfilled): {
      if (action.meta && action.meta.identifier) {
        const info = updateInfoMessageCount(action.meta, newState);
        if (info) {
          newState.info = info;
        }
        const index = newState.data.findIndex((m) => m.identifier === action.meta.identifier);
        newState.data[index] = action.meta;
      }
      return newState;
    }

    case String(deleteMessage.fulfilled): {
      if (action.meta) {
        const info = updateInfoMessageCount(action.meta, newState);
        if (info) {
          newState.info = info;
        }
        newState.data = newState.data.filter((m) => m.identifier !== action.meta);
      }

      return newState;
    }
    case String(getMessages.fulfilled): {
      const index = newState.data.findIndex((element) => element.tag === TAG_TYPE.NOTIFICATION_PREFERENCE);
      if (index > -1) {
        const data = newState.data.slice(index, index + 1);
        newState.data = data.concat(action.payload);
      } else {
        newState.data = action.payload;
      }
      return newState;
    }

    case String(getMessagesByTag.fulfilled): {
      const tagSpecificState = { ...newState.tagSpecific };
      tagSpecificState.data = tagSpecificState.data || {};
      tagSpecificState.data[action.payload.tag] = action.payload.data;
      newState.tagSpecific = tagSpecificState;
      return newState;
    }

    case String(getMessagesInfo.fulfilled): {
      const newInfo = { ...newState.info };
      const notificationPreferenceMessage = newState.data.find((element) => element.tag === TAG_TYPE.NOTIFICATION_PREFERENCE);
      if (notificationPreferenceMessage && !notificationPreferenceMessage.read) {
        newInfo.data.unreadMessagesCount = action.payload.unreadMessagesCount + 1;
      } else {
        newInfo.data.unreadMessagesCount = action.payload.unreadMessagesCount;
      }
      newState.info = newInfo;
      return newState;
    }

    case addNotificationPreferenceMessage.toString(): {
      const notificationPreferenceMessage = createNotificationPreferenceMessage(action.payload);
      newState.data.unshift(notificationPreferenceMessage);
      if (!notificationPreferenceMessage.read) {
        const newInfo = { ...newState.info };
        newInfo.data.unreadMessagesCount += 1;
        newState.info = newInfo;
      }
      return newState;
    }

    case removeNotificationPreferenceMessage.toString(): {
      const index = newState.data.findIndex((element) => element.tag === TAG_TYPE.NOTIFICATION_PREFERENCE);
      if (index < 0) {
        return newState;
      }
      newState.data.splice(index, 1);
      const newInfo = { ...newState.info };
      newInfo.data.unreadMessagesCount -= 1;
      newState.info = newInfo;
      return newState;
    }

    case updateNotificationPreferenceMessage.toString(): {
      const notificationPreferenceMessage = newState.data.find((element) => element.tag === TAG_TYPE.NOTIFICATION_PREFERENCE);
      if (notificationPreferenceMessage) {
        notificationPreferenceMessage.read = action.payload;
      }
      const newInfo = { ...newState.info };
      newInfo.data.unreadMessagesCount -= 1;
      newState.info = newInfo;
      return newState;
    }
    default: {
      return newState;
    }
  }
};
