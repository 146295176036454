import * as actions from './actions';
import * as channelActions from '../Channel/actions';
import { formatVideo } from './selectors';
import * as recordingActions from '../Recording/actions';

const initialState = {
  isDisabled: false,
  inPreviewMode: false,
  video: {
    assetKey: '',
    assetVersion: '',
    assetVersionDetails: [],
    name: ' ',
    isPublished: false,
    contentId: '',
    recordingKey: '',
    src: '',
    s3Src: '',
    transcript: '',
    waveform: ''
  },
  edits: {},
  pending: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case String(actions.saveEdit): {
      const edit = {
        [action.payload.id]: action.payload.edit
      };
      return {
        ...state,
        edits: { ...state.edits, ...edit }
      };
    }
    case String(actions.deleteEdit): {
      const { edits } = state;
      delete edits[action.payload.id];
      return {
        ...state,
        edits: { ...edits }
      };
    }
    case String(actions.fetchAsset.pending): {
      return {
        ...state,
        pending: true
      };
    }
    case String(actions.fetchAsset.fulfilled): {
      return {
        ...state,
        video: formatVideo(action.payload),
        error: undefined,
        pending: false
      };
    }
    case String(actions.fetchAsset.rejected): {
      return {
        ...state,
        error: action.payload.body.reason,
        pending: false
      };
    }
    case String(channelActions.publishVideoToChannelAction.fulfilled): {
      if (action.payload.recordingKey === state.video.recordingKey) {
        const content = state.video.content || (action.payload.contents && action.payload.contents[0]);
        return {
          ...state,
          video: {
            ...state.video,
            isPublished: true,
            content: {
              ...content,
              shareStatus: 'PUBLIC'
            }
          }
        };
      }

      return state;
    }
    case recordingActions.updateContentAction.complete.toString(): {
      return {
        ...state,
        video: {
          ...state.video,
          content: action.data,
          isPublished: action.data.shareStatus === 'PUBLIC'
        }
      };
    }
    case String(actions.closePreview): {
      return {
        ...state,
        inPreviewMode: false
      };
    }
    case String(actions.openPreview): {
      return {
        ...state,
        inPreviewMode: true
      };
    }
    case String(actions.disableVideoEditor): {
      return {
        ...state,
        isDisabled: true
      };
    }
    case String(actions.enableVideoEditor): {
      return {
        ...state,
        isDisabled: false
      };
    }
    case String(actions.updateVideo): {
      return {
        ...state,
        video: { ...state.video, ...action.payload }
      };
    }
    case String(actions.discardVideo): {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
