import MixpanelTracker from 'Mixpanel/mixpanelTracker';
import { CUSTOMIZE_CERTIFICATE_CLICKED, SIGNATURE_UPLOADED } from '../../../mixpanelConstants';
import { DRAWN_SIGNATURE } from './certificateConstants';

const mixpanelTracker = new MixpanelTracker();

export function trackSignatureUploaded(type = DRAWN_SIGNATURE, webinarKey) {
  const properties = {
    Type: type,
    webinarKey
  };
  mixpanelTracker.setEventName(SIGNATURE_UPLOADED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}

export function trackCustomiseCertificateClicked(webinarKey) {
  const properties = {
    webinarKey
  };
  mixpanelTracker.setEventName(CUSTOMIZE_CERTIFICATE_CLICKED);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent();
}
