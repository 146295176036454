import dispatchClickedEvent from '../messageBusEventsDispatcher';
import { removeSensitiveDataFromWebinar } from '../webinarInfoSanitizer';
import { WEBINAR_DETAILS } from '../Constants/webpageNameConstants';

let webinarDetails;

export function setWebinarInfo(webinar) {
  // Sanitize Object Resource which can contain personal identifiable information
  if (webinar) {
    const webinarInfo = Object.assign({}, webinar);
    webinarDetails = removeSensitiveDataFromWebinar(webinarInfo);
  }
}

export function dispatchClickedEventToMessageBus(objectClickedName, objectType, metadata) {
  dispatchClickedEvent({
    webpageName: WEBINAR_DETAILS,
    objectClicked: objectType,
    objectName: objectClickedName,
    objectResource: {
      webinarInfo: webinarDetails
    },
    objectMetadata: metadata
  });
}
