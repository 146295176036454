import { createAsyncAction } from '../reduxActions';

export const getSourceTrackingInfoAction = createAsyncAction('GET_SOURCE_TRACKING_INFO');
export const addSourceTrackingOptionAction = createAsyncAction('ADD_SOURCE_TRACKING_OPTION');
export const archiveSourceTrackingOptionAction = createAsyncAction('DELETE_SOURCE_TRACKING_OPTION');

export function fetchSourceTrackingInformation(webinarKey) {
  return { type: getSourceTrackingInfoAction.toString(), webinarKey };
}

export function addSourceTrackingOption(webinarKey, sourceName) {
  return { type: addSourceTrackingOptionAction.toString(), webinarKey, sourceName };
}

export function archiveSourceTrackingOption(webinarKey, sourceKey) {
  return { type: archiveSourceTrackingOptionAction.toString(), webinarKey, sourceKey };
}

