import { createSelector } from 'reselect';
import { getNumericUUID } from '../../Utils/randomUuidGenerator';
import { get } from '../../Utils/objectHelpers';

const getSettings = (state) => state.registrationSettings.registration;
const getWebinarKey = (_, props) => props.webinar && props.webinar.webinarKey;

const getDomainsFromSettings = (settings, webinarKey) => {
  if (settings === undefined || webinarKey === undefined) {
    return undefined;
  }
  const webinarSettings = settings[webinarKey];

  if (webinarSettings) {
    return webinarSettings.allowedEmailDomains || webinarSettings.blockedEmailDomains;
  }

  return undefined;
};

const domainWhitelistToArray = (domainWhitelist) => {
  if (domainWhitelist === undefined) {
    return [];
  }

  return domainWhitelist
    .split(',')
    .map((i) => i.trim())
    .reduce((agg, curr) => {
      agg.push({
        domainName: curr,
        hasError: false,
        key: getNumericUUID()
      });
      return agg;
    }, []);
};

const getDomainsString = createSelector(
  [getSettings, getWebinarKey],
  getDomainsFromSettings
);

export const getDomains = createSelector(
  getDomainsString,
  domainWhitelistToArray
);

const getServerErrors = (state) => state.registrationSettings.detailsError;

const serverErrorsToArray = (detailsString) => {
  if (!detailsString) {
    return [];
  }

  const allowedSearchValue = 'allowedEmailDomains: ';
  const blockedSearchValue = 'blockedEmailDomains: ';

  // Search for both allowed and blocked email domains
  const searchValue = detailsString.includes(allowedSearchValue) ? allowedSearchValue : blockedSearchValue;

  const index = detailsString.search(searchValue) + searchValue.length;
  console.log('*** detailsString.search(searchValue), searchValue.length - ', detailsString.search(searchValue), searchValue.length);
  return detailsString
    .slice(index)
    .split(', ')
    .sort();
};

export const getErrors = createSelector(
  getServerErrors,
  serverErrorsToArray
);

export const isBlockedDomainsPresent = createSelector(
  [getSettings, getWebinarKey],
  (registrationSettings, webinarKey) => {
    const result = registrationSettings[webinarKey]?.blockedEmailDomains !== undefined;
    return result;
  }
);

export const selectRegistrationSettings = createSelector(
  [
    (state, props) => Object.assign({}, get(state, (s) => s.registrationSettings.registration[props.webinar.webinarKey], {}))
  ],
  (registrationSettings) => registrationSettings
);

export const selectEventRegistrationSettings = createSelector(
  [
    (state, props) => Object.assign({}, get(state, (s) => s.registrationSettings.registration[props.virtualEventDetails.eventKey], {}))
  ],
  (registrationSettings) => registrationSettings
);
