import MixpanelTracker from '../../../mixpanelTracker';
import { VIRTUAL_EVENT_ON_CLICK_PANELISTS, VIRTUAL_EVENT_ON_ADD_PANELIST, VIRTUAL_EVENT_ON_DELETE_PANELIST, VIRTUAL_EVENT_ON_UPDATE_PANELIST } from '../../../mixpanelConstants';
import Action from '../../../Model/action';

const mixpanelTracker = new MixpanelTracker();

export function onVirtualEventPanelistsClick(eventKey, isOpen) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    isOpen
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_CLICK_PANELISTS);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onCreateVirtualEventPanelistTracker(eventKey, panelistKey, status) {
  const properties = {
    Action: Action.ADD,
    eventKey,
    panelistKey,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_ADD_PANELIST);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onDeleteVirtualEventPanelistTracker(eventKey, panelistKey, status) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    panelistKey,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_DELETE_PANELIST);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

export function onUpdateVirtualEventPanelistTracker(eventKey, panelistKey, feildName, value, status) {
  const properties = {
    Action: Action.EDIT,
    eventKey,
    panelistKey,
    feildName,
    value,
    status
  };
  mixpanelTracker.setEventName(VIRTUAL_EVENT_ON_UPDATE_PANELIST);
  mixpanelTracker.setEventProperties(properties);
  mixpanelTracker.sendEvent(true);
}

